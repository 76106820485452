import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  ButtonSwitch,
  Table,
  Input,
} from '@veit/veit-web-controls';

import DateFormat from '../../components/DateFormat';
import bem from '../../utils/bem';

import ModalActions, { goToFile } from '../../store/ModalFile.actions';
import { getStatsCategory } from '../../store/Stats.actions';
import ModalWithLoader from '../ModalWithLoader';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import Round from '../../components/Round';
import { getSortingName } from '../../components/SortingName';
import CategoryName from '../../components/CategoryName';
import HistogramChart from '../../charts/Histogram';
import SortingChart from '../../charts/Sorting';
import WeightValue from '../../components/WeightValue';

const bm = bem.modal('histogram');

class FileModal extends Component {
  state = {
    show: 0,
  }

  componentDidMount() {
    this.fetchSubcategory();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modal.data.stat !== this.props.modal.data.stat) {
      this.fetchSubcategory();
    }
  }

  fetchSubcategory = () => {
    const { locationId, dateTime } = this.props.modal.data.stat;
    this.props.getStatsCategory(locationId, dateTime);
  };

  cancel = () => {
    this.props.cancelDialog();
  }

  goTo = (stat) => {
    if (stat.sorting == null) {
      this.setState({ show: 0 });
    }
    this.props.goToFile({
      ...stat,
      locationId: this.props.modal.data.stat.locationId,
    }, this.props.modal.data.stats);
  }

  get = () => {
    if (this.state.show === 0) {
      return (
        <HistogramChart
          data={this.props.modal.data.stat.histogram || {}}
          weightUnit={this.props.weightUnit}
        />
      );
    }

    if (this.state.show === 1) {
      const sorting = this.props.modal.data.stat.sorting || {};
      return (
        <SortingChart
          data={{
            keys: Object.keys(sorting),
            values: Object.values(sorting),
          }}
        />
      );
    }
    return null;
  }

  render() {
    const { stat, next, prev } = this.props.modal.data;
    const { subcategory } = this.props;
    const histogram = stat.histogram || {};
    const hasData = Object.keys(histogram.steps || {}).length > 0;
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        className={bm.b()}
      >
        <ModalHeader toggle={this.cancel}>
          <DateFormat date={stat.dateTime} format={this.props.dateFormat} />
          <Label>
            {
              hasData ? (
                <FormattedMessage
                  values={{ count: stat.count }}
                  id="modals.histogram.measurements-in-total"
                  defaultMessage="{count, plural, one {# MEASUREMENT} other {# MEASUREMENTS}} IN TOTAL"
                />
              ) : <FormattedMessage id="modals.histogram.no-histogram-data" defaultMessage="NO HISTOGRAM DATA" />
            }
          </Label>
          <div>
            {stat.sorting && (
              <ButtonSwitch
                options={[
                  { title: <FormattedMessage id="common.histogram" defaultMessage="Histogram" />, value: 0 },
                  { title: <FormattedMessage id="common.sorting" defaultMessage="Sorting" />, value: 1 },
                  { title: <FormattedMessage id="common.details" defaultMessage="Details" />, value: 2 },
                ]}
                selected={this.state.show}
                onChange={v => this.setState({ show: v })}
              />
            )}
          </div>
        </ModalHeader>
        <ModalBody style={{ height: 'unset' }}>
          {this.get()}
          {this.state.show === 2 && (
            <Container>
              <Row>
                <Col>
                  <Label type="text">
                    <FormattedMessage id="common.file-name" defaultMessage="File name" />
                  </Label>
                  <Input value={stat.fileName} disabled />
                </Col>
                <Col>
                  <Label type="text">
                    <FormattedMessage id="common.sorting" defaultMessage="Sorting" />
                  </Label>
                  <Input value={getSortingName(stat.category)} disabled />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table type="data" style={{ height: 'unset' }} className="narrow">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="common.category" defaultMessage="Category" />
                        </th>
                        <th>
                          <FormattedMessage values={{ unit: <FormattedMessage id={`units.${this.props.weightUnit}`} defaultMessage="g" /> }} id="common.average-unit" defaultMessage="Average({unit})" />
                        </th>
                        <th>
                          <FormattedMessage id="common.count-pcs" defaultMessage="Count(pcs)" />
                        </th>
                        <th>
                          <FormattedMessage id="common.unifor-percent" defaultMessage="Unifor.(%)" />
                        </th>
                        <th>
                          <FormattedMessage id="common.cv-percent" defaultMessage="CV(%)" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(subcategory || []).map((d, i) => (
                        <tr key={`${d.dateTime || i}_${d.category}`}>
                          <td><Label><CategoryName category={d.category} /></Label></td>
                          <td>
                            <Label>
                              <WeightValue
                                value={d.average}
                                weightUnit={this.props.weightUnit}
                                showUnit={false}
                              />
                            </Label>
                          </td>
                          <td><Label>{d.count}</Label></td>
                          <td><Label><Round value={d.uni} decimals={1} /></Label></td>
                          <td><Label><Round value={d.cv} decimals={1} /></Label></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          )}
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row>
              <Col>
                <Button outline color="primary" onClick={this.cancel}>
                  <FormattedMessage id="modals.histogram.close" defaultMessage="Close" />
                </Button>
              </Col>
              <Col>
                <Button disabled={!next} outline color="primary" onClick={() => this.goTo(next)}>
                  <FormattedMessage id="common.previous-file" defaultMessage="Previous File" />
                </Button>
                <Button disabled={!prev} color="primary" onClick={() => this.goTo(prev)}>
                  <FormattedMessage id="common.next-file" defaultMessage="Next File" />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
        <SubmitHandler
          disabled={!next}
          keyCode={keyCodes.arrowLeft}
          action={() => this.goTo(next)}
        />
        <SubmitHandler
          disabled={!prev}
          keyCode={keyCodes.arrowRight}
          action={() => this.goTo(prev)}
        />
        <SubmitHandler keyCode={keyCodes.escape} action={this.cancel} />
      </ModalWithLoader>
    );
  }
}

const mapStateToProps = state => ({
  subcategory: state.stats.subcategory,
  weightUnit: state.auth.weightUnit,
  dateFormat: state.auth.dateFormat,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  goToFile,
  getStatsCategory,
}, dispatch);

FileModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  goToFile: PropTypes.func.isRequired,
  getStatsCategory: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
    stat: PropTypes.object,
  }).isRequired,
  subcategory: PropTypes.arrayOf(PropTypes.shape({
    fileName: PropTypes.string,
  })),
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

FileModal.defaultProps = {
  subcategory: [],
  weightUnit: null,
  dateFormat: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileModal);
