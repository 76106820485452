import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TabLink from './TabLink';
import { Label } from '@veit/veit-web-controls';

import bem from '../../utils/bem';

export const tabType = {
  weighing: 'weighing',
  graphs: 'graphs',
  scales: 'scales',
  flocks: 'flocks',
};

export const tabTypes = [
  tabType.weighing,
  tabType.graphs,
  tabType.scales,
  tabType.flocks,
];

const TabText = (props) => {
  switch (props.type) {
    case tabType.graphs: return <FormattedMessage id="common.graphs" defaultMessage="Graphs" />;
    case tabType.scales: return <FormattedMessage id="common.scales" defaultMessage="Scales" />;
    case tabType.flocks: return <FormattedMessage id="common.flocks" defaultMessage="Flocks" />;
    default: return <FormattedMessage id="common.weighing" defaultMessage="Weighing" />;
  }
};

TabText.propTypes = {
  type: PropTypes.string.isRequired,
};

const bm = bem.component('tabs-bar');

const TabsBar = ({
  baseUrl, tabs, match, children,
}) => {
  const { tab } = match.params;
  return (
    <div className={bm.b()}>
      <div className={bm.e('tabs')}>
        {tabs.map(m => (
          <TabLink
            key={m}
            active={tab === m || (tab == null && m === tabType.weighing)}
            to={m === tabType.weighing ? baseUrl : `${baseUrl}/${m}`}
          >
            <Label type="subtitle">
              <TabText type={m} />
            </Label>
          </TabLink>
        ))}
      </div>
      {children && (
        <div className={bm.e('actions')}>
          {children}
        </div>
      )}
    </div>
  );
};

TabsBar.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

TabsBar.defaultProps = {
  tabs: tabTypes,
  children: null,
};

export default TabsBar;
