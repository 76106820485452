import * as ModalActions from './Modal.actions';
import {
  deleteCompare, putCompare, postCompare, DELETE_COMPARE,
} from './Analytics.actions';

export const ADD_COMPARE = 'ADD_COMPARE';
export const EDIT_COMPARE = 'EDIT_COMPARE';

export const openDialogAddCompare = data => (dispatch) => {
  return ModalActions.openDialog(ADD_COMPARE, data)(dispatch);
};

export const openDialogEditCompare = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_COMPARE, data)(dispatch);
};

export const finishDialogAddCompare = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postCompare(data), ADD_COMPARE)(dispatch);
};

export const finishDialogEditCompare = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putCompare(data.id, data), EDIT_COMPARE)(dispatch);
};

export const deleteDialogCompare = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteCompare(id), DELETE_COMPARE, { delete: true })(dispatch);
};

export default ModalActions;
