import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Button } from '@veit/veit-web-controls';

import DeleteModal from '../../../modals/DeleteModal';
import bem from '../../../utils/bem';
import {
  getGateways, getNumbers, createGateway, updateGateway, deleteGateway,
} from '../../../store/Gateway.actions';

import EditRow from './EditRow';

const bm = bem.view('gateway-list');

class GatewayList extends Component {
  state = {
    edit: null,
  }

  componentDidMount() {
    this.props.getGateways();
    this.props.getNumbers();
  }

  reload = () => {
    this.props.getGateways().then(() => {
      this.setState({ edit: null });
    });
  }

  delete = (item) => {
    this.props.deleteGateway(item.id).then(this.reload);
  }

  save = (item) => {
    if (item == null) {
      this.setState({ edit: null });
    } else if (item.id === '') {
      this.props.createGateway(item).then(this.reload);
    } else {
      this.props.updateGateway(item).then(this.reload);
    }
  }

  edit = (item) => {
    this.setState({
      edit: item.id,
    });
  }

  render() {
    const { items, numbers } = this.props.gateway;
    const { edit } = this.state;
    const gateways = edit === '' ? [{ id: '' }, ...items] : items;
    return (
      <div className={bm.b()}>
        <Table>
          <thead>
            <tr>
              <th>Allowed Countries</th>
              <th>Country Code</th>
              <th>Can Request Data</th>
              <th style={{ width: '1%' }}>
                <Button color="primary" className="btn-narrow" onClick={() => this.edit({ id: '' })}>
                  Add Country
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {gateways.sort((a, b) => ((a.code > b.code) ? 1 : -1))
              .map(c => (this.state.edit === c.id
                ? (<EditRow items={gateways} key={c.id} data={c} onChange={this.save} />)
                : (
                  <tr key={c.id}>
                    <td>{c.country}</td>
                    <td>{c.code}</td>
                    <td>{c.canRequest ? 'YES' : 'NO'}</td>
                    <td className="actions">
                      <Button color="primary" className="btn-narrow" onClick={() => this.edit(c)}>
                        Edit
                      </Button>
                      <DeleteModal
                        advaced
                        narrow
                        name=""
                        confirm={() => this.delete(c)}
                      />
                    </td>
                  </tr>
                )
              ))}
          </tbody>
        </Table>
        <Table>
          <thead>
            <tr>
              <th>
                Vonage Phone Numbers
              </th>
              <th>Country</th>
              <th>Type</th>
              <th>
                Features
                <span style={{ float: 'right' }}><a href="https://dashboard.nexmo.com/" target="_blank" rel="noopener noreferrer">Vonage Dashboard</a></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {numbers.sort((a, b) => ((a.country > b.country) ? 1 : -1))
              .map(c => (
                <tr key={c.msisdn}>
                  <td>+{c.msisdn}</td>
                  <td>{c.country}</td>
                  <td>{c.type}</td>
                  <td>{c.features.map(m => `${m} `)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

GatewayList.propTypes = {
  getGateways: PropTypes.func.isRequired,
  getNumbers: PropTypes.func.isRequired,
  createGateway: PropTypes.func.isRequired,
  updateGateway: PropTypes.func.isRequired,
  deleteGateway: PropTypes.func.isRequired,
  gateway: PropTypes.shape({
    items: PropTypes.array,
    numbers: PropTypes.array,
  }),
};

GatewayList.defaultProps = {
  gateway: {},
};

const mapStateToProps = state => ({
  gateway: state.gateway,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getGateways,
  getNumbers,
  createGateway,
  updateGateway,
  deleteGateway,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GatewayList);
