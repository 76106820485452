import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ModalActions, { finishDialogAddLocation } from '../../store/ModalLocation.actions';

import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import ModalContent, { hasModalContentError } from './ModalContent';

class AddLocationModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogAddLocation(this.props.modal.data);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="locations.modal.add-location" defaultMessage="Add Location" />
        </ModalHeader>
        <ModalBody>
          <ModalContent modal={this.props.modal} />
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          finisDialogText={<FormattedMessage id="locations.modal.add-location" defaultMessage="Add Location" />}
          validators={{ 1: hasModalContentError }}
          modal={this.props.modal}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogAddLocation,
}, dispatch);


AddLocationModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogAddLocation: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(AddLocationModal);
