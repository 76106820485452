import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';

import Round from '../../components/Round';

const LastUniformity = ({ lastStatistics }) => {
  if (lastStatistics == null || lastStatistics.lenght === 0) return null;
  return lastStatistics.map((ls) => {
    return ls == null || ls.uni == null ? null : (
      <Label key={`${ls.category}_${ls.type}`}>
        <Round value={ls.uni} decimals={1} />
      </Label>
    );
  });
};

LastUniformity.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    category: PropTypes.string,
    uni: PropTypes.number,
  })),
  scaleType: PropTypes.string,
};

LastUniformity.defaultProps = {
  lastStatistics: [],
};

export default LastUniformity;
