import PropTypes from 'prop-types';

export const statisticsType = PropTypes.shape({

});

export const farmType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  country: PropTypes.string,
});

export const locationType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  farm: farmType,
  farmId: PropTypes.string,
  lastStatistics: PropTypes.arrayOf(statisticsType),
});

export const flockType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  location: locationType,
  locationId: PropTypes.string,
  lastStatistics: PropTypes.arrayOf(statisticsType),
});

const pointType = PropTypes.shape({
  det: PropTypes.number,
  weight: PropTypes.number,
});

export const birdType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  product: PropTypes.string,
  duration: PropTypes.number,
  dateType: PropTypes.string,
  scope: PropTypes.string,
  sex: PropTypes.string,
  curvePoints: PropTypes.arrayOf(pointType),
});
