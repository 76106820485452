import { create, run } from '../utils/api';
import * as ModalActions from './Modal.actions';

export const IMPORT = 'IMPORT';

export const IMPORT_DATA = 'IMPORT_DATA';
export const IMPORT_DATA_SUCCESS = 'IMPORT_DATA_SUCCESS';
export const IMPORT_DATA_ERROR = 'IMPORT_DATA_ERROR';

export const IMPORT_FILE = 'IMPORT_FILE';
export const IMPORT_FILE_SUCCESS = 'IMPORT_FILE_SUCCESS';
export const IMPORT_FILE_ERROR = 'IMPORT_FILE_ERROR';

export const GET_FILE_LOCATIONS = 'GET_FILE_LOCATIONS';
export const GET_FILE_LOCATIONS_SUCCESS = 'GET_FILE_LOCATIONS_SUCCESS';
export const GET_FILE_LOCATIONS_ERROR = 'GET_FILE_LOCATIONS_ERROR';

export const dialogStates = {
  connecting: 'connecting',
  connectingError: 'connectingError',
  invalidVersion: 'invalidVersion',
  memoryNotConnected: 'memoryNotConnected',
  scaleNotConnected: 'scaleNotConnected',
  form: 'form',
  downloading: 'downloading',
  uploading: 'uploading',
  uploadingError: 'uploadingError',
  success: 'success',
  readError: 'readError',
  writeError: 'writeError',
};

export const openDialogImport = data => (dispatch) => {
  return ModalActions.openDialog(IMPORT, data)(dispatch);
};

export const openDialogImportData = data => (dispatch) => {
  return ModalActions.openDialog(IMPORT_DATA, data)(dispatch);
};

export const openDialogImportFile = data => (dispatch) => {
  return ModalActions.openDialog(IMPORT_FILE, data)(dispatch);
};

export const getFileLocations = () => create(GET_FILE_LOCATIONS, () => run('/import/files', 'GET'));

export const importDataRequest = (deviceId, stats) => create(IMPORT_DATA, () => run(`/import/${deviceId}`, 'POST', JSON.stringify(stats)));
export const importBat1DataRequest = files => create(IMPORT_DATA, () => run('/import/files', 'POST', JSON.stringify(files)));

export const importData = (deviceId, stats) => (dispatch) => {
  return importDataRequest(deviceId, stats)(dispatch).then(() => {
    setTimeout(() => dispatch({ type: IMPORT_DATA_SUCCESS }), 10000);
  }, () => {
    dispatch({ type: IMPORT_DATA_ERROR });
  });
};

export const importBat1Data = files => (dispatch) => {
  return importBat1DataRequest(files)(dispatch).then(() => {
    setTimeout(() => dispatch({ type: IMPORT_DATA_SUCCESS }), 10000);
  }, () => {
    dispatch({ type: IMPORT_DATA_ERROR });
  });
};

export default ModalActions;
