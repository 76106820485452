import React from 'react';
import PropTypes from 'prop-types';
import IMHand from '../../components/icons/IMHand';
import { statsType } from '../../model/enums';

const TypeIcon = ({ type, ...otherProps }) => {
  return type === statsType.manual
    ? <IMHand {...otherProps} />
    : <svg fill="currentColor" {...otherProps} preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 1024 1024" />;
};

TypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  sex: PropTypes.string,
};

TypeIcon.defaultProps = {
  sex: null,
};

export default TypeIcon;
