import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalActions, { finishDialogEditHelpPage, deleteDialogHelpPage } from '../../store/ModalHelpPage.actions';

import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import ModalContent, { hasModalContentError } from './ModalContent';

class EditHelpPageModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditHelpPage(this.props.modal.data);
  }

  delete = () => {
    this.props.deleteDialogHelpPage(this.props.modal.data.id);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>Edit Help page</ModalHeader>
        <ModalBody>
          <ModalContent />
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          deleteDialog={{ action: this.delete, name: 'Page' }}
          modal={this.props.modal}
          validators={{ 1: hasModalContentError }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogEditHelpPage,
  deleteDialogHelpPage,
}, dispatch);

EditHelpPageModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogEditHelpPage: PropTypes.func.isRequired,
  deleteDialogHelpPage: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditHelpPageModal);
