import { injectIntl } from 'react-intl';

let rintl;

/** React component to capture the context.  */
const intlCapture = (props) => {
  rintl = props.intl;
  return props.children;
};

export const IntlCapture = injectIntl(intlCapture);

/** This is the wrapper for `intl` where I expose its functionality. */
class _Intl {
  t = (message, values) => {
    return rintl.formatMessage(message, values);
  }
}
const RIntl = new _Intl();
export default RIntl;
