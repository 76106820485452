import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import bem from '../utils/bem';
import { roleType } from '../model/enums';
import RoleName from './RoleName';
import Switch from './Switch';

const bm = bem.component('role-exception');

const RoleException = ({
  farm, role, exception, onChange,
}) => {
  const options = [roleType.noAccess, roleType.user, roleType.admin].filter(f => f !== role);
  return role !== exception ? (
    <div className={bm.b()}>
      <div className={bm.e('header')}>
        <Label>{(farm || {}).name}</Label>
        <button type="button" className={bm.e('remove')} onClick={() => onChange(null)}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <Switch
        options={options}
        value={exception}
        onChange={onChange}
        getName={opt => <RoleName role={opt} />}
      />
    </div>
  ) : null;
};

RoleException.propTypes = {
  onChange: PropTypes.func.isRequired,
  role: PropTypes.string,
  exception: PropTypes.string,
  farm: PropTypes.PropTypes.shape({
    name: PropTypes.string,
  }),
};

RoleException.defaultProps = {
  exception: null,
  role: null,
  farm: null,
};

export default RoleException;
