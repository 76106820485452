import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ModalActions, { finishDialogInviteUser } from '../../store/ModalUser.actions';
import { getFarm } from '../../store/Farm.actions';

import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import ModalContent, { hasModalContentError } from './ModalContent';

class InviteUserModal extends Component {
  componentDidMount() {
    this.props.getFarm();
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogInviteUser(this.props.modal.data);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="users.invite-user" defaultMessage="Invite User" />
        </ModalHeader>
        <ModalBody>
          <ModalContent modal={this.props.modal} />
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          finisDialogText={<FormattedMessage id="users.invite-user" defaultMessage="Invite User" />}
          modal={this.props.modal}
          validators={{ 1: hasModalContentError }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogInviteUser,
  getFarm,
}, dispatch);

InviteUserModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogInviteUser: PropTypes.func.isRequired,
  getFarm: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(InviteUserModal);
