import React from 'react';
import { FormattedMessage } from 'react-intl';
import IM404 from '@veit/veit-web-controls/dist/icons/IM404';
import { Button } from '@veit/veit-web-controls/dist/index';
import IconPage from './IconPage';
import QueryLink from './QueryLink';

const PageNotFound = () => {
  return (
    <FormattedMessage id="error.404" defaultMessage="Page not found :(">
      {text => (
        <IconPage
          text={text}
          icon={IM404}
          column
          action={(
            <Button tag={QueryLink} to="/flocks">
              <FormattedMessage id="error.go-to-dashboard" defaultMessage="Go to dashboard" />
            </Button>
          )}
        />
      )}
    </FormattedMessage>
  );
};

export default PageNotFound;
