import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import { IMClose, IMErrorMark, IMCheckMark } from '@veit/veit-web-controls/dist/icons';
import bem from '../utils/bem';

export const ToastError = ({ message, detail }) => {
  return <ToastBase message={message} detail={detail} icon={IMErrorMark} type="error" />;
};

ToastError.propTypes = {
  message: PropTypes.node.isRequired,
  detail: PropTypes.node,
};

ToastError.defaultProps = {
  detail: null,
};

export const ToastWarn = ({ message, detail }) => {
  return <ToastBase message={message} detail={detail} icon={IMErrorMark} type="warn" />;
};

ToastWarn.propTypes = {
  message: PropTypes.node.isRequired,
  detail: PropTypes.node,
};

ToastWarn.defaultProps = {
  detail: null,
};

export const ToastSuccess = ({ message, detail }) => {
  return <ToastBase message={message} detail={detail} icon={IMCheckMark} type="success" />;
};

ToastSuccess.propTypes = {
  message: PropTypes.node.isRequired,
  detail: PropTypes.node,
};

ToastSuccess.defaultProps = {
  detail: null,
};

const bm = bem.component('toast-message');

const ToastBase = ({
  message, detail, icon: Icon, type,
}) => {
  return (
    <div className={bm.b(type)}>
      <div className={bm.e('icon')}>
        <Icon />
      </div>
      <div className={bm.e('message')}>
        <Linkify
          componentDecorator={(href, _, key) => (
            <a style={{ fontWeight: 'bold' }} rel="noopener noreferrer" target="_blank" href={href} key={key}>
              here
            </a>
          )}
        >
          {message}
        </Linkify>
        {
          detail && (
            <div className={bm.e('detail')}>
              {detail}
            </div>
          )
        }
      </div>
      <div className={bm.e('close')}>
        <IMClose />
      </div>
    </div>
  );
};

ToastBase.propTypes = {
  icon: PropTypes.func.isRequired,
  message: PropTypes.node.isRequired,
  detail: PropTypes.node,
  type: PropTypes.oneOf(['success', 'error', 'warn', null]),
};

ToastBase.defaultProps = {
  type: null,
  detail: null,
};

export default ToastBase;
