import { fetchAll } from '../../store/Fetch.actions';
import { getBirdBy, expandBird } from '../../store/Bird.actions';

const fetchData = id => (dispatch) => {
  if (id == null) return;
  fetchAll(() => [
    getBirdBy(id, { expand: expandBird.all })(dispatch),
  ])(dispatch);
};

export default fetchData;
