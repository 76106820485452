import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import PageNotFound from '../components/PageNotFound';
import Loader from '../components/Loader';
import QueryRedirect from '../components/QueryRedirect';

import { getCurrentUser, logoutRedirect } from '../store/Auth.actions';
import { getRequestDataCountries } from '../store/Device.actions';
import { roleType } from '../model/enums';

class Startup extends Component {
  componentDidMount() {
    if (this.props.auth.refreshToken !== '') {
      this.props.getCurrentUser().then(() => {
        this.props.getRequestDataCountries();
      });
    } else if (!this.props.publicPath) {
      this.props.logoutRedirect();
    }
  }

  hasRole = ({ user }) => {
    if (this.props.roles.length === 0) return true;
    return this.props.roles.indexOf(user.role) !== -1;
  }

  render() {
    const { auth, publicPath } = this.props;

    const pathname = this.props.pathname.toLowerCase();
    if (auth.isAuthenticated && pathname === '/') {
      return auth.user != null ? <QueryRedirect to="/locations" /> : <Loader />;
    }

    if (!publicPath) {
      if (auth.user == null) {
        return <Loader />;
      }

      if (roleType.isAdmin(auth.user.role)
        && auth.user.companyId == null
        && !pathname.toLowerCase().startsWith('/admin')) {
        return <Redirect to="/admin" />;
      }
    }

    return this.hasRole(auth) ? this.props.children : <PageNotFound />;
  }
}

const mapStateToProps = (state) => {
  return {
    pathname: state.router.location.pathname,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getCurrentUser,
  getRequestDataCountries,
  logoutRedirect,
}, dispatch);

Startup.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
  getRequestDataCountries: PropTypes.func.isRequired,
  logoutRedirect: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    refreshToken: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string.isRequired,
  publicPath: PropTypes.bool.isRequired,
};

Startup.defaultProps = {
  children: null,
  roles: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Startup);
