import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
} from '@veit/veit-web-controls';


import { modalGetBird, expandBird } from '../../store/Bird.actions';
import ModalActions, { finishDialogEditBird, deleteDialogBird } from '../../store/ModalBird.actions';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';
import Step2, { hasStep2Error } from './Step2';

class EditBirdModal extends Component {
  componentDidMount() {
    this.props.modalGetBird({
      expand: [expandBird.sex, expandBird.dateType, expandBird.duration],
    });
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditBird(this.props.modal.data);
  }

  delete = () => {
    this.props.deleteDialogBird(this.props.modal.data.id);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-fluid"
      >
        <ModalHeader toggle={this.cancel}>Edit Bird</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <Step1 edit modal={this.props.modal} />
              </Col>
              <Col>
                <Step2 edit modal={this.props.modal} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <Footer
          deleteDialog={{ action: this.delete, name: 'Bird', item: this.props.modal.data }}
          finisDialog={this.finish}
          modal={this.props.modal}
          validators={{ 1: m => ({ ...hasStep2Error(m), ...hasStep1Error(m) }) }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogEditBird,
  deleteDialogBird,
  modalGetBird,
}, dispatch);

EditBirdModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogEditBird: PropTypes.func.isRequired,
  deleteDialogBird: PropTypes.func.isRequired,
  modalGetBird: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditBirdModal);
