import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SubtitleItem from '../../components/SubtitleItem';

const FarmSubtitle = ({ farm }) => {
  return farm && (
    <SubtitleItem title={<FormattedMessage id="common.farm" defaultMessage="Farm" />}>
      {`${farm.name}${farm.country ? `, ${farm.country}` : ''}`}
    </SubtitleItem>
  );
};

FarmSubtitle.propTypes = {
  farm: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.string,
  }),
};

FarmSubtitle.defaultProps = {
  farm: null,
};

export default FarmSubtitle;
