import auth from './auth';

let promise = null;

function resolveAction(result) {
  return {
    resolve: () => {
      promise = null;
      return result;
    },
  };
}

export default function validateToken(dispatch) {
  if (promise == null) {
    promise = new Promise(async (res) => {
      if (auth.isValid()) {
        res(resolveAction(true));
      } else {
        dispatch({ type: 'REFRESH_TOKENS' });
        const result = await auth.refreshSession();
        dispatch({ type: result ? 'REFRESH_TOKENS_SUCCESS' : 'REFRESH_TOKENS_ERROR' });
        res(resolveAction(result));
      }
    });
  }
  return promise;
}
