import { push } from 'connected-react-router';
import queryString from 'query-string';
import { trimPathQueryParams } from '../utils/queryCache';

const getPath = () => window.location.pathname;
const getQueryParams = () => queryString.parse(window.location.search);

export const setQueryParameters = params => (dispatch) => {
  const path = getPath();
  const queryParams = { ...getQueryParams(), ...params };
  const query = queryString.stringify(queryParams, { skipNull: true });
  dispatch(push(query == null || query === '' ? path : `${path}?${query}`));
};

export const setQueryParameter = (paramName, paramValue) => (dispatch) => {
  const path = getPath();
  const queryParams = getQueryParams();
  queryParams[paramName] = paramValue;
  const query = queryString.stringify(queryParams, { skipNull: true });
  dispatch(push(query == null || query === '' ? path : `${path}?${query}`));
};

export const clearFilters = paramNames => (dispatch) => {
  const names = Array.isArray(paramNames) ? paramNames : Object.values(paramNames || {});
  const path = trimPathQueryParams(`${window.location.pathname + window.location.search}`, names);
  dispatch(push(path));
};
