import dayjs from 'dayjs';

export default function formatDate(date, format = 'DD/MM/YYYY', defaultValue = '') {
  if (date == null || format == null) return defaultValue;
  return dayjs(date).format(format);
}

export function getResponseMessage(data) {
  if (data == null) return '';
  if (data.response == null) return data.message;
  if (data.response.body == null) return data.response.message || '';
  return data.response.body.message || '';
}
