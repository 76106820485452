import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@veit/veit-web-controls';
import { setQueryParameter, setQueryParameters, clearFilters } from '../../store/Query.actions';
import { deviceFilter } from '../../model/filter';
import SelectFarm from '../../components/SelectFarm';

const DeviceFilter = ({
  farms, query, clearFilters, setQueryParameter,
}) => {
  const farm = farms.find(f => f.id === query.farm);
  return (
    <React.Fragment>
      <SelectFarm
        farms={farms}
        farm={farm}
        onChange={c => setQueryParameter(deviceFilter.farm, (c || {}).id)}
      />

      <Button color="link" onClick={() => clearFilters(deviceFilter)}>
        <FormattedMessage id="common.clear-all" defaultMessage="Clear All" />
      </Button>
    </React.Fragment>
  );
};

DeviceFilter.propTypes = {
  setQueryParameters: PropTypes.func.isRequired,
  setQueryParameter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  query: PropTypes.shape({
    farm: PropTypes.string,
    location: PropTypes.string,
  }),
  farms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
};

DeviceFilter.defaultProps = {
  farms: [],
  query: {},
};

const mapStateToProps = state => ({
  farms: state.farm.items,
  query: state.query,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setQueryParameters,
  setQueryParameter,
  clearFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeviceFilter);
