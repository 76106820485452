import * as ModalActions from './Modal.actions';
import toastMessage from '../utils/toastMessage';
import sendFeedback from '../utils/feedback';

export const SEND_FEEDBACK = 'SEND_FEEDBACK';

export const openDialogSendFeedback = data => (dispatch) => {
  return ModalActions.openDialog(SEND_FEEDBACK, data)(dispatch);
};

export const finishDialogSendFeedback = data => (dispatch, getState) => {
  ModalActions
    .finishDialogRequest(() => () => sendFeedback(data, getState(), 'feedback'), SEND_FEEDBACK)(dispatch)
    .then(() => toastMessage.success('Thanks for the feedback!'));
};

export default ModalActions;
