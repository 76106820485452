import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';

const FormLabel = ({ required, children, ...otherProps }) => {
  return (
    <Label type="text" {...otherProps}>
      {children}
      {required && (<span className="vwc-label__required"><FormattedMessage id="common.required" defaultMessage="Required" /></span>)}
    </Label>
  );
};

FormLabel.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node,
};

FormLabel.defaultProps = {
  required: false,
  children: null,
};

export default FormLabel;
