import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import { connect } from 'react-redux';

function getFarmLocation(farm) {
  if (farm.address != null && farm.country != null) return `${farm.address}, ${farm.country}`;
  return farm.address || farm.country;
}

const Farm = ({
  farms, farmId, locations, locationId,
}) => {
  if (farms == null || (farmId == null && locationId == null)) return null;
  const id = farmId || (locations.find(f => f.id === locationId) || {}).farmId;
  const farm = farms.find(f => f.id === id);
  return farm == null ? null : (
    <React.Fragment>
      <Label>{farm.name}</Label>
      <Label type="info">{getFarmLocation(farm)}</Label>
    </React.Fragment>
  );
};

Farm.propTypes = {
  farmId: PropTypes.string,
  farms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  locationId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
};

Farm.defaultProps = {
  farms: null,
  farmId: null,
  locations: null,
  locationId: null,
};

const mapStateToProps = (state) => {
  return {
    farms: state.farm.items,
    locations: state.location.items,
  };
};

export default connect(mapStateToProps)(Farm);
