import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Table } from '@veit/veit-web-controls';
import { flockType } from '../../types';
import IMBird from '@veit/veit-web-controls/dist/icons/IMBird';

import IconPage from '../../components/IconPage';
import { PageSkeletonBody } from '../../components/PageSkeleton';
import { fetchPartial } from '../../store/Fetch.actions';
import { getFlock, expandFlock } from '../../store/Flock.actions';
import FlocksTabRow from './FlocksTabRow';

class FlocksTab extends PureComponent {
  componentDidMount() {
    this.props.fetchPartial(() => [
      this.fetchFlock(),
    ]);
  }

  fetchFlock = () => {
    return this.props.getFlock({
      ...this.props.params,
      expand: [expandFlock.lastStatistics, expandFlock.bird],
    });
  }

  render() {
    const { flocks, isFetching } = this.props;
    if (isFetching) return <PageSkeletonBody />;
    return flocks.length !== 0 ? (
      <Table type="info">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.flock" defaultMessage="Flock" />
            </th>
            <th>
              <FormattedMessage id="common.last-update" defaultMessage="Last update" />
            </th>
            <th className="text-center">
              <FormattedMessage id="common.growth" defaultMessage="Growth" />
            </th>
            <th>
              <FormattedMessage id="common.age" defaultMessage="Age" />
            </th>
            <th className="text-center">
              <FormattedMessage id="common.sex" defaultMessage="Sex" />
            </th>
            <th>
              <FormattedMessage values={{ unit: <FormattedMessage id={`units.${this.props.weightUnit}`} defaultMessage="g" /> }} id="common.weight-unit" defaultMessage="Weight({unit})" />
            </th>
            <th>
              <FormattedMessage values={{ unit: <FormattedMessage id={`units.${this.props.weightUnit}`} defaultMessage="g" /> }} id="common.gain-unit" defaultMessage="Gain({unit})" />
            </th>
            <th>
              <FormattedMessage id="common.count-pcs" defaultMessage="Count(pcs)" />
            </th>
            <th>
              <FormattedMessage id="common.unifor-percent" defaultMessage="Unifor.(%)" />
            </th>
            <th>
              <FormattedMessage id="common.cv-percent" defaultMessage="CV(%)" />
            </th>
          </tr>
        </thead>
        <tbody>
          {
            flocks.map(flock => (
              <FlocksTabRow
                key={flock.id}
                flock={flock}
                weightUnit={this.props.weightUnit}
                dateFormat={this.props.dateFormat}
              />
            ))
          }
        </tbody>
      </Table>
    ) : (
      <IconPage
        icon={IMBird}
        text={<FormattedMessage id="common.no-flock-data" defaultMessage="No flocks available ..." />}
      />
    );
  }
}

FlocksTab.propTypes = {
  getFlock: PropTypes.func.isRequired,
  fetchPartial: PropTypes.func.isRequired,
  params: PropTypes.shape({
    location: PropTypes.string,
  }).isRequired,
  flocks: PropTypes.arrayOf(flockType),
  isFetching: PropTypes.bool.isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

FlocksTab.defaultProps = {
  flocks: [],
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = state => ({
  flocks: state.flock.items,
  isFetching: state.fetch.isFetchingPartial,
  weightUnit: state.auth.weightUnit,
  dateFormat: state.auth.dateFormat,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getFlock,
  fetchPartial,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FlocksTab);
