import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { Label, PaginationBar, Button } from '@veit/veit-web-controls';
import IconPage from './IconPage';
import SubmitHandler, { keyCodes } from './SubmitHandler';
import { limitsList } from '../utils/pagination';
import { clearFilters } from '../store/Router.actions';

const getPage = (size, limit, page) => {
  const offset = limit * (page - 1);
  return offset > size ? 0 : offset;
};

const Content = ({ children, paginate }) => {
  const {
    size, offset, limit, onChange, limits,
  } = paginate || {};
  if (offset == null || limit == null || size == null) return children;
  const page = offset / limit + 1;
  const pages = Math.ceil(size / limit);
  const useLimits = limits || limitsList.default;
  const change = (newPage, newLimit) => onChange(
    getPage(size, newLimit, newPage),
    useLimits.indexOf(newLimit) === 0 ? null : newLimit,
  );
  return (
    <React.Fragment>
      {children}
      <PaginationBar
        onPageChange={newPage => change(newPage, limit)}
        onLimitChange={newLimit => change(page, newLimit)}
        page={page}
        limit={limit}
        limits={useLimits}
        count={size}
        limitText={<FormattedMessage id="common.items-per-page" defaultMessage="Items per page:" />}
        resultText={({ from, to, of }) => (
          <Label>
            <FormattedMessage
              id="common.showing-results"
              defaultMessage="Showing results {range} of {of}"
              values={{ range: <b>{`${from}-${to}`}</b>, of }}
            />
          </Label>
        )}
      />
      <SubmitHandler
        keyCode={keyCodes.arrowLeft}
        action={() => change(page - 1, limit)}
        disabled={page <= 1}
      />
      <SubmitHandler
        keyCode={keyCodes.arrowRight}
        action={() => change(page + 1, limit)}
        disabled={page >= pages}
      />
    </React.Fragment>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  paginate: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    size: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }),
};

Content.defaultProps = {
  children: null,
  paginate: null,
};

const PageContent = ({
  children, emptyPage, paginate, overLimit, location, clearFilters,
}) => {
  const path = location.pathname + location.search;
  const isFilterActive = queryString.parse(location.search).search != null;
  return (
    <React.Fragment>
      {emptyPage != null && emptyPage.isEmpty && !overLimit
        ? isFilterActive
          ? (
            <IconPage
              icon={emptyPage.icon}
              action={(
                <Button color="primary" onClick={() => clearFilters(path)}>
                  <FormattedMessage id="common.reset-filter" defaultMessage="Reset filter" />
                </Button>
              )}
              text={<FormattedMessage id="common.no-record-found" defaultMessage="No matching records found." />}
            />
          )
          : <IconPage icon={emptyPage.icon} action={emptyPage.action} text={emptyPage.text} />
        : <Content paginate={paginate}>{children}</Content>
      }
    </React.Fragment>
  );
};

PageContent.propTypes = {
  overLimit: PropTypes.bool.isRequired,
  clearFilters: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  emptyPage: PropTypes.shape({
    isEmpty: PropTypes.bool,
    icon: PropTypes.func,
    action: PropTypes.node,
    text: PropTypes.node,
  }),
  paginate: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    size: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }),
};

PageContent.defaultProps = {
  children: null,
  emptyPage: null,
  paginate: null,
};

const mapStateToProps = state => ({
  location: state.router.location,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
