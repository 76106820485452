import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SubtitleWrapper from '../../components/SubtitleWrapper';
import LocationSubtitle from '../_subtitle/LocationSubtitle';
import DeviceTypeSubtitle from '../_subtitle/DeviceTypeSubtitle';
import PhoneNumberSubtitle from '../_subtitle/PhoneNumberSubtitle';

const DeviceSubtitle = ({
  device, farm, location,
}) => {
  return device && (
    <SubtitleWrapper>
      {location && device.locationId === location.id && (
        <LocationSubtitle farm={farm} location={location} />
      )}
      <DeviceTypeSubtitle device={device} />
      <PhoneNumberSubtitle device={device} />
    </SubtitleWrapper>
  );
};

DeviceSubtitle.propTypes = {
  device: PropTypes.shape({
    type: PropTypes.string,
    phoneNumber: PropTypes.string,
    farmId: PropTypes.string,
    locationId: PropTypes.string,
  }).isRequired,
  farm: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.string,
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

DeviceSubtitle.defaultProps = {
  farm: null,
  location: null,
};

const mapStateToProps = (state) => {
  return {
    farm: state.farm.item,
    location: state.location.item,
  };
};

export default connect(mapStateToProps)(DeviceSubtitle);
