import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '@veit/veit-web-controls';

import SectionTitle from './SectionTitle';

const Section4 = () => {
  return (
    <section className="section-4">
      <Row className="invert">
        <Col md="6">
          <SectionTitle>VEIT Electronics</SectionTitle>
          <hr />
          <h3><FormattedMessage id="home.come-to-visit" defaultMessage="home.come-to-visit" /></h3>
          <div>
            <div>
              <span itemProp="name">VEIT Electronics</span>
              <br />
              Modřická 52
              <br />
              644 48 Moravany
              <br />
              <FormattedMessage id="home.czech-republic" defaultMessage="Czech Republic" />
            </div>
          </div>
          <div>
            <div>
              <strong><FormattedMessage id="home.gps" defaultMessage="GPS" />:</strong>
              &nbsp;49.145258, 16.586535
            </div>
          </div>
          <div>
            <div>
              <strong><FormattedMessage id="home.business-hours" defaultMessage="Business hours" />:</strong>
              &nbsp;<FormattedMessage id="home.working-days" defaultMessage="working days" /> 7:00 – 15:30
            </div>
          </div>
          <br />
          <div>
            <strong>
              <a href="tel:+420 545 235 252" target="_blank" rel="noopener noreferrer" title="phone">+420 545 235 252</a>
            </strong>
            <br />
            <strong>
              <a href="mailto:info@veit.cz" target="_blank" rel="noopener noreferrer" title="email">info@veit.cz</a>
            </strong>
            <br />
            <strong>
              <a href="http://www.veit.cz" target="_blank" rel="noopener noreferrer" title="VEIT">www.veit.cz</a>
            </strong>
          </div>
        </Col>
        <Col md="6">
          <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2609.909894379233!2d16.584707615889172!3d49.145335188413334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4712bfd52da8bb59%3A0x748f6422c0fb4338!2sVEIT+Electronics!5e0!3m2!1scs!2scz!4v1471954292493" width="100%" height="330" frameBorder="0" allowFullScreen></iframe>
        </Col>
      </Row>

    </section>
  );
};

export default Section4;
