/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION__"] }] */
import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import logger from 'redux-logger';

import errorMiddleware from './errorMiddleware';

import Modal from '../store/Modal.reducer';
import Auth from '../store/Auth.reducer';
import Flock from '../store/Flock.reducer';
import Farm from '../store/Farm.reducer';
import Location from '../store/Location.reducer';
import Bird from '../store/Bird.reducer';
import Device from '../store/Device.reducer';
import Company from '../store/Company.reducer';
import Stats from '../store/Stats.reducer';
import User from '../store/User.reducer';
import Fetch from '../store/Fetch.reducer';
import Status from '../store/Status.reducer';
import Help from '../store/Help.reducer';
import BatSocket from '../store/BatSocket.reducer';
import Query from '../store/Query.reducer';
import Gateway from '../store/Gateway.reducer';
import File from '../store/File.reducer';
import Analytics from '../store/Analytics.reducer';
import Samples from '../store/Samples.reducer';
import Lora from '../store/Lora.reducer';

export default function configureStore(history, initialState) {
  const reducers = {
    auth: Auth,
    flock: Flock,
    modal: Modal,
    farm: Farm,
    bird: Bird,
    device: Device,
    location: Location,
    company: Company,
    stats: Stats,
    user: User,
    fetch: Fetch,
    status: Status,
    help: Help,
    socket: BatSocket,
    query: Query,
    gateway: Gateway,
    file: File,
    analytics: Analytics,
    samples: Samples,
    lora: Lora,
  };

  const isDevelopment = process.env.NODE_ENV === 'development';

  const middleware = [
    routerMiddleware(history),
    thunk,
    errorMiddleware(isDevelopment),
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  if (isDevelopment) {
    middleware.push(logger);
    if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const rootReducer = combineReducers({
    ...reducers,
  });

  return createStore(
    connectRouter(history)(rootReducer),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );
}
