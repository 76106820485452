import React from 'react';
import PropTypes from 'prop-types';

import SearchInput from '../../components/filters/SearchInput';

const ActionBar = ({ children, searchChanged }) => {
  return children != null || searchChanged != null ? (
    <div className="bwc-action-bar">
      {searchChanged && (
        <SearchInput searchChanged={searchChanged} />
      )}
      {children}
    </div>
  ) : null;
};

ActionBar.propTypes = {
  children: PropTypes.node,
  searchChanged: PropTypes.func,
};

ActionBar.defaultProps = {
  children: null,
  searchChanged: null,
};

export default ActionBar;
