import React from 'react';
import PropTypes from 'prop-types';
import bem from '../utils/bem';

const Panel = ({ children, className, ...restProps }) => {
  const bm = bem.component('panel');
  return (
    <div className={bm.b(className)} {...restProps}>
      {children}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

Panel.defaultProps = {
  children: null,
  className: null,
};

export default Panel;
