export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_ALL_SILENT = 'FETCH_ALL_SILENT';
export const FETCH_ALL_SUCCESS = 'FETCH_ALL_SUCCESS';
export const FETCH_ALL_ERROR = 'FETCH_ALL_ERROR';

export const FETCH_PARTIAL = 'FETCH_PARTIAL';
export const FETCH_PARTIAL_SUCCESS = 'FETCH_PARTIAL_SUCCESS';
export const FETCH_PARTIAL_ERROR = 'FETCH_PARTIAL_ERROR';

export const fetchAll = (promises, silent) => (dispatch) => {
  dispatch({ type: silent ? FETCH_ALL_SILENT : FETCH_ALL });
  return Promise.all(promises())
    .then(() => dispatch({ type: FETCH_ALL_SUCCESS }))
    .catch(error => dispatch({ type: FETCH_ALL_ERROR, payload: error }));
};


export const fetchPartial = (promises, target = 'tab') => (dispatch) => {
  dispatch({ type: FETCH_PARTIAL, target });
  return Promise.all(promises())
    .then(() => dispatch({ type: FETCH_PARTIAL_SUCCESS, target }))
    .catch(error => dispatch({ type: FETCH_ALL_ERROR, payload: error, target }));
};
