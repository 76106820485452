import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Label, Button } from '@veit/veit-web-controls';

import Location from '../_table/Location';
import {
  LastUpdate, LastAverageGroup, LastStatus, LastSexGroup, LastDay,
} from '../_table';
import { groupBySex } from '../../utils/stats';
import { flockType } from '../../types';

const TableRow = ({
  location, farms, createFlock, weightUnit, dateFormat,
}) => {
  const history = useHistory();
  const lastStatistics = location.lastStatistics || [];
  const stats = groupBySex(lastStatistics);
  const farm = farms.find(f => f.id === location.farmId);
  return (
    <tr className="has-pointer" onClick={() => history.push(`/locations/${location.id}`)} key={location.id}>
      <td>
        <Label>
          <Location location={location} farm={farm} />
        </Label>
      </td>
      <td><LastStatus lastStatistics={location.lastStatistics} /></td>
      <td><LastUpdate lastStatistics={lastStatistics} dateFormat={dateFormat} /></td>
      <td><LastSexGroup lastStatistics={stats} /></td>
      <td><LastAverageGroup lastStatistics={stats} weightUnit={weightUnit} /></td>
      <td><LastDay lastStatistics={lastStatistics} /></td>
      <td onClick={e => e.stopPropagation()} aria-hidden="true">
        {
          location.activeFlock ? (
            <Link to={`/flock/${location.activeFlock.id}`}>
              <Label>{location.activeFlock.name}</Label>
              {location.activeFlock.bird && (
                <Label type="info">{location.activeFlock.bird.name}</Label>
              )}
            </Link>
          ) : (
            <Button color="link" onClick={() => createFlock(location.id)} className="btn-create-flock">
              <FormattedMessage id="common.create-flock" defaultMessage="Create Flock" />
            </Button>
          )
        }
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  createFlock: PropTypes.func.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    lastStatistics: PropTypes.array,
    farmId: PropTypes.string,
    type: PropTypes.string,
    activeFlock: flockType,
  }).isRequired,
  farms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
  })),
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

TableRow.defaultProps = {
  farms: [],
  weightUnit: 'g',
  dateFormat: '',
};

export default TableRow;
