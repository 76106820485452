import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import ModalActions, { finishDialogAddBird } from '../../store/ModalBird.actions';
import { modalGetBird, expandBird } from '../../store/Bird.actions';

import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import AnimateHeight from '../AnimateHeight';

import Footer from '../Footer';
import ModalWithLoader from '../ModalWithLoader';
import Steps from '../Steps';
import Step1, { hasStep1Error } from './Step1';
import Step2, { hasStep2Error } from './Step2';

class AddBirdModal extends Component {
  steps = 2;

  componentDidMount() {
    this.props.modalGetBird({
      expand: [expandBird.sex, expandBird.dateType, expandBird.duration],
    });
  }

  getStep() {
    switch (this.props.modal.step) {
      case 2:
        return <Step2 modal={this.props.modal} />;
      default:
        return <Step1 modal={this.props.modal} />;
    }
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    const { data } = this.props.modal;
    const bird = this.props.birds.find(f => f.id === data.birdId) || {};
    this.props.finishDialogAddBird({ ...bird, ...data });
  }

  render() {
    const { step } = this.props.modal;
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        keyboard={false}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="breed.add-breed" defaultMessage="Add Breed" />
        </ModalHeader>
        <div>
          <Steps steps={this.steps} actualStep={step} />
        </div>
        <ModalBody>
          <AnimateHeight step={step}>
            {this.getStep()}
          </AnimateHeight>
        </ModalBody>
        <Footer
          step={step}
          maxStep={this.steps}
          finisDialog={this.finish}
          finisDialogText="Add Bird"
          modal={this.props.modal}
          validators={{ 1: hasStep1Error, 2: hasStep2Error }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogAddBird,
  modalGetBird,
}, dispatch);

const mapStateToProps = (state) => {
  return {
    birds: state.bird.items,
  };
};

AddBirdModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogAddBird: PropTypes.func.isRequired,
  modalGetBird: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
  }).isRequired,
  birds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBirdModal);
