import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import mapHistogramData from '../../utils/histogram';
import intl from '../../setup/RIntl';
import { defineMessages } from 'react-intl';
import convertGramsToPounds from '../../utils/unitConversion';

const messages = defineMessages({
  weightRange: {
    id: 'modals.histogram.weight-range',
    defaultMessage: 'Weight range',
  },
  weightRangeValue: {
    id: 'modals.histogram.weight-range-value',
    defaultMessage: 'Weight range : {min} - {max}',
  },
  weightRangeValueMin: {
    id: 'modals.histogram.weight-range-value-min',
    defaultMessage: 'Weight less than {max}',
  },
  weightRangeValueMax: {
    id: 'modals.histogram.weight-range-value-max',
    defaultMessage: 'Weight more than {min}',
  },
  samplesCount: {
    id: 'modals.histogram.samples-count',
    defaultMessage: 'Samples count : {value}',
  },
  recommendedWeight: {
    id: 'modals.histogram.recommended-weight',
    defaultMessage: 'Recommended weight',
  },
});

let units = 'g';

const options = {
  animation: false,
  cornerRadius: 10,
  maintainAspectRatio: false,
  responsive: true,
  legend: false,
  backgroundColor: '#212529',
  layout: {
    padding: {
      left: 33,
      right: 0,
      top: 10,
      bottom: 0,
    },
  },
  tooltips: {
    displayColors: false,
    callbacks: {
      title: (tooltipItem, data) => {
        if (data.labels.length > 1) {
          let range = (data.labels[1] - data.labels[0]) / 2;
          if (data.weightUnit !== undefined && data.weightUnit === 'lb') {
            range = +(range.toFixed(3));
          } else {
            // in grams round to whole number
            range = Math.floor(range);
          }

          let min = +(data.labels[tooltipItem[0].index]) - range;
          let max = +(data.labels[tooltipItem[0].index]) + range;
          if (min % 1 !== 0) {
            min = min.toFixed(3);
          }
          if (max % 1 !== 0) {
            max = max.toFixed(3);
          }

          const { index } = tooltipItem[0];
          let text = intl.t(messages.weightRangeValue, { min, max });
          if (index === 0) text = intl.t(messages.weightRangeValueMin, { max });
          if (index === data.labels.length - 1) {
            text = intl.t(messages.weightRangeValueMax, { min });
          }
          return `${tooltipItem[0].value} - ${text}`;
        }
        return intl.t(messages.weightRange);
      },
      label: () => {
        return null; // intl.t(messages.weightRange, { value: tooltipItem.value });
      },
    },
  },
  scales: {
    yAxes: [{
      gridLines: {
        drawOnChartArea: false,
        borderDash: [3, 6],
      },
      display: true,
      ticks: {
        beginAtZero: true,
        fontSize: 14,
        fontColor: '#97a4ba',
        callback: (value, index) => {
          if (index === 0) return '';
          return value;
        },
      },
    }],
    xAxes: [{
      gridLines: {
        offsetGridLines: false,
        drawOnChartArea: true,
        borderDash: [3, 6],
      },
      offset: false,
      ticks: {
        fontSize: 14,
        fontColor: '#97a4ba',
        autoSkip: false,
        maxRotation: 0,
        callback: (value, index, values) => {
          if (index === 0) return '- 30%';
          if (index === values.length - 1) return '+ 30%';
          if (index === Math.floor(values.length / 2)) return `${value} ${units}`;
          if (index === 7 || index === 14 || index === 24 || index === 31) return '';
          return undefined;
        },
      },
    }],
  },
};


class HistogramChart extends Component {
  state = {
    chartData: {
      labels: [],
      datasets: [],
    },
    ctx: null,
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.drawChart();
    }
  }

  drawChart = () => {
    let keys = [];
    let values = [];

    if (this.props.data != null) {
      ({ keys, values } = mapHistogramData(this.props.data));
    }

    if (this.props.weightUnit === 'lb' && keys[0] % 1 === 0) {
      // modify keys - convert them to pounds, if they are not converted yet
      // (checked by decimal places)
      keys = keys.map(m => convertGramsToPounds(m).toFixed(3));
    }

    const chartData = {
      labels: keys,
      datasets: [{
        data: values,
        backgroundColor: values.map(() => '#469acf'),
        categoryPercentage: 1,
        barPercentage: 1,
      }],
    };

    const ctx = document.getElementById('histogram').getContext('2d');


    this.setState(prevState => ({
      chartData,
      ctx: prevState.ctx || ctx,
    }));
  }

  render() {
    const barData = this.state.chartData;
    barData.weightUnit = this.props.weightUnit;
    units = this.props.weightUnit;
    return (
      <Bar
        id="histogram"
        options={options}
        data={barData}
      />
    );
  }
}

HistogramChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    center: PropTypes.number,
    stepSize: PropTypes.number,
    stepsCount: PropTypes.number,
    steps: PropTypes.object,
  }),
  weightUnit: PropTypes.string,
};

HistogramChart.defaultProps = {
  data: null,
  weightUnit: null,
};

export default HistogramChart;
