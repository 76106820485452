import * as ModalActions from './Modal.actions';
import toastMessage from '../utils/toastMessage';
import sendFeedback from '../utils/feedback';

export const SEND_GATEWAY_REQUEST = 'SEND_GATEWAY_REQUEST';

export const GATEWAY_REQUEST_DATA = 'GATEWAY_REQUEST_DATA';

export const openDialogSendGatewayRequest = () => (dispatch) => {
  ModalActions.openDialog(SEND_GATEWAY_REQUEST, {})(dispatch);
};

export const finishDialogSendGatewayRequest = data => (dispatch, getState) => {
  ModalActions
    .finishDialogRequest(() => () => sendFeedback(data, getState(), 'gateway_request'), SEND_GATEWAY_REQUEST)(dispatch)
    .then(() => toastMessage.success('Thanks for the feedback!'));
};

export default ModalActions;
