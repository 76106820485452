import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';
import IMBird from '@veit/veit-web-controls/dist/icons/IMBird';

import Graphs, { messages, propToName } from './Graphs';
import Panel from '../../components/Panel';
import IconPage from '../../components/IconPage';
import intl from '../../setup/RIntl';

const PropName = ({ propName }) => {
  const m = messages[propName];
  return m ? intl.t(m) : '';
};

export const SelectCurve = ({
  value, options, onChange, weightUnit, ...otherProps
}) => {
  let u = '';
  if (value !== null && value.unit !== null) {
    u = value.unit;
    if (u !== weightUnit && (u === 'g' || value.unit === 'lb' || value.unit === 'kg')) {
      u = weightUnit;
    }
  }

  return (
    <UncontrolledDropdown addonType="append" {...otherProps}>
      <DropdownToggle tag="span" className="btn-legend" style={{ backgroundColor: value ? value.color : 'lightgray' }}>
        {value
          ? (
            <React.Fragment>
              <PropName propName={value.key} /> {value.unit && `(${u})`}
            </React.Fragment>
          ) : (
            <FormattedMessage id="flocks.select-curve" defaultMessage="Select curve" />
          )}
      </DropdownToggle>
      <DropdownMenu>
        {value && (
          <DropdownItem className="btn-clear" onClick={() => onChange(null)}>
            <FormattedMessage id="common.clear" defaultMessage="Clear" />
          </DropdownItem>
        )}
        {
          options.map(k => (
            <DropdownItem key={k.key} onClick={() => onChange(k)}>
              <PropName propName={k.key} />
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

SelectCurve.propTypes = {
  value: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.string,
    color: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.string,
    color: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
  weightUnit: PropTypes.string,
};

SelectCurve.defaultProps = {
  value: null,
  options: [],
  weightUnit: null,
};

const GraphsTab = ({ stats, flock, weightUnit }) => {
  const [leftAxis, setLeftAxis] = useState(propToName.average);
  const [rightAxis, setRightAxis] = useState(null);
  const options = Object.values(propToName).filter(f => f !== leftAxis && f !== rightAxis);
  return stats.length > 0 ? (
    <Panel className="bwc-graphs-tab">
      <div className="bwc-graphs-tab__header">
        <SelectCurve
          value={leftAxis}
          onChange={setLeftAxis}
          options={options}
          weightUnit={weightUnit}
        />
        <div style={{ flex: '1' }}></div>
        <SelectCurve
          value={rightAxis}
          onChange={setRightAxis}
          options={options}
          weightUnit={weightUnit}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Graphs
          stats={[...(stats || [])]}
          leftAxis={leftAxis}
          rightAxis={rightAxis}
          flock={flock}
          weightUnit={weightUnit}
        />
      </div>
    </Panel>
  ) : <IconPage icon={IMBird} text={<FormattedMessage id="common.no-weighing-data" defaultMessage="No weighing data  ..." />} />;
};

GraphsTab.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.number,
  })),
  flock: PropTypes.shape({
    bird: PropTypes.object,
  }),
  weightUnit: PropTypes.string,
};

GraphsTab.defaultProps = {
  stats: [],
  flock: null,
  weightUnit: null,
};

export default GraphsTab;
