import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { dateType } from '../model/enums';

const Duration = ({ value, unit }) => {
  return unit === dateType.week
    ? <FormattedMessage values={{ count: Math.floor(value / 7) }} id="common.count-weeks" defaultMessage="{count, plural, one {# week} other {# weeks}}" />
    : <FormattedMessage values={{ count: value }} id="common.count-days" defaultMessage="{count, plural, one {# day} other {# days}}" />;
};

Duration.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.oneOf([dateType.day, dateType.week]),
};

Duration.defaultProps = {
  value: 0,
  unit: dateType.day,
};

export default Duration;
