import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { roleType } from '../model/enums';

const RoleName = ({ role }) => {
  switch (role) {
    case roleType.companyAdmin: return <FormattedMessage id="users.modal.role-company-admin" defaultMessage="Company Administrator" />;
    case roleType.admin: return <FormattedMessage id="users.modal.role-administrator" defaultMessage="Administrator" />;
    case roleType.user: return <FormattedMessage id="users.modal.role-user" defaultMessage="User" />;
    case roleType.noAccess: return <FormattedMessage id="users.modal.role-no-access" defaultMessage="No Access" />;
    case roleType.veitOperator: return <FormattedMessage id="users.modal.role-veit-operator" defaultMessage="Veit Operator" />;
    default: return <FormattedMessage id="users.modal.role-user" defaultMessage="User" />;
  }
};

RoleName.propTypes = {
  role: PropTypes.string,
};

RoleName.defaultProps = {
  role: null,
};

export default RoleName;
