import classNames from 'classnames';

const createBEM = (namespace) => {
  return {
    create: (blockName) => {
      let block = blockName;

      if (typeof namespace === 'string') {
        block = `${namespace}-${blockName}`;
      }

      return {
        b: (...more) => {
          return classNames(block, more);
        },
        e: (className, ...more) => {
          return classNames(`${block}__${className}`, more);
        },
        m: (className, ...more) => {
          return classNames(`${block}--${className}`, more);
        },
      };
    },
  };
};

const bem = {
  view: name => createBEM('bwv').create(name),
  component: name => createBEM('bwc').create(name),
  modal: name => createBEM('bwm').create(name),
};

export default bem;
