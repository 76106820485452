export const evnetStore = {
  disabled: {},
};

function onKeyDown(e) {
  const actions = evnetStore[e.keyCode];
  if (actions == null
    || actions == null
    || actions.length === 0
    || evnetStore.disabled[e.keyCode] === true) return;
  e.preventDefault();
  e.stopPropagation();
  actions[actions.length - 1]();
}

document.addEventListener('keydown', onKeyDown, false);

export const registerEvent = (keyCode, action) => {
  if (keyCode == null || action == null || typeof action !== 'function') return;
  if (evnetStore[keyCode] == null) evnetStore[keyCode] = [];
  if (evnetStore[keyCode].indexOf(action) !== -1) return;
  evnetStore[keyCode].push(action);
};

export const unregisterEvent = (keyCode, action) => {
  if (keyCode == null
    || action == null
    || typeof action !== 'function'
    || evnetStore[keyCode] == null
    || evnetStore[keyCode].indexOf(action) === -1) return;
  const index = evnetStore[keyCode].indexOf(action);
  evnetStore[keyCode].splice(index, 1);
};

export const disableKey = (keyCode) => {
  evnetStore.disabled[keyCode] = true;
};

export const enableKey = (keyCode) => {
  evnetStore.disabled[keyCode] = false;
};
