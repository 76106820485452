import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const DateFormat = ({
  date, format, diff, wrapper,
}) => {
  let dateFormat = format;
  if (date == null) return wrapper(null);
  if (format !== undefined && (format === '' || format === null)) {
    dateFormat = 'DD.MM.YYYY';
  }
  const result = diff != null
    ? Math.ceil(dayjs().diff(date, diff, true))
    : dayjs(date).format(dateFormat);
  return wrapper(result);
};

DateFormat.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  format: PropTypes.string,
  diff: PropTypes.oneOf([null, 'months', 'weeks', 'days', 'hours']),
  wrapper: PropTypes.func,
};

DateFormat.defaultProps = {
  date: null,
  format: 'DD.MM.YYYY',
  diff: null,
  wrapper: v => v,
};

export default DateFormat;
