import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import bem from '../utils/bem';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from '@veit/veit-web-controls';

import ModalWithLoader from './ModalWithLoader';
import SubmitHandler, { keyCodes } from '../components/SubmitHandler';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      text: '',
    };

    this.toggle = this.toggle.bind(this);
  }

  canDelete = () => {
    if (!this.props.advaced) return true;
    return this.state.text === 'delete';
  }

  confirm = () => {
    if (this.canDelete()) this.props.confirm();
  }

  toggle = () => {
    this.setState(prev => ({
      modal: !prev.modal,
      text: '',
    }));
  }

  render() {
    const bm = bem.modal('delete');
    const { title, subtitle } = this.props;
    return (
      <React.Fragment>
        <Button className={this.props.narrow ? 'btn-narrow' : ''} color="primary" outline onClick={this.toggle}>
          <FormattedMessage values={{ name: this.props.name }} id="common.delete" defaultMessage="Delete" />
        </Button>
        <div className={bm.b()}>
          <ModalWithLoader keyboard={false} className={bm.e('modal')} centered isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <ModalBody>
              <Label type="title">
                {title || <FormattedMessage id="modals.are-you-sure" defaultMessage="Are you sure?" />}
              </Label>
              <Label className="subtitle">
                {subtitle || <FormattedMessage values={{ name: this.props.name }} id="modals.you-will-delete" defaultMessage="You will delete {name} permanently" />}
              </Label>
              {
                this.props.advaced && (
                  <React.Fragment>
                    <Input
                      value={this.state.text}
                      onChange={e => this.setState({ text: e.target.value })}
                    />
                    <Label className="text">
                      <FormattedMessage id="modals.write-delete" defaultMessage="Write 'delete' to enable delete button" />
                    </Label>
                  </React.Fragment>
                )
              }
            </ModalBody>
            <ModalFooter>
              <Button color="danger" outline onClick={this.toggle}>
                <FormattedMessage id="modals.dont-do-it" defaultMessage="Don’t do it" />
              </Button>
              <Button color="danger" onClick={this.confirm} disabled={!this.canDelete()}>
                <FormattedMessage values={{ name: this.props.name }} id="common.delete-name" defaultMessage="Delete {name}" />
              </Button>
            </ModalFooter>
            <SubmitHandler keyCode={keyCodes.enter} action={this.confirm} />
            <SubmitHandler keyCode={keyCodes.escape} action={this.toggle} />
          </ModalWithLoader>
        </div>
      </React.Fragment>
    );
  }
}

DeleteModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  name: PropTypes.node,
  advaced: PropTypes.bool,
  narrow: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DeleteModal.defaultProps = {
  name: 'Flock',
  advaced: false,
  narrow: false,
  title: null,
  subtitle: null,
};

export default DeleteModal;
