import * as ModalActions from './Modal.actions';
import toastMessage from '../utils/toastMessage';
import {
  deleteUser, putUser, postUser, resetPasswordUser, registerUser,
} from './User.actions';

export const INVITE_USER = 'INVITE_USER';
export const EDIT_USER = 'EDIT_USER';
export const REGISTER_USER = 'REGISTER_USER';

export const openDialogInviteUser = data => (dispatch) => {
  return ModalActions.openDialog(INVITE_USER, data)(dispatch);
};

export const openDialogEditUser = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_USER, data)(dispatch);
};

export const finishDialogInviteUser = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postUser(data), INVITE_USER)(dispatch);
};

export const finishDialogEditUser = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putUser(data.id, data), EDIT_USER)(dispatch);
};

export const deleteDialogUser = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteUser(id), EDIT_USER, { delete: true })(dispatch);
};

export const resetPasswordDialogUser = user => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => resetPasswordUser(user.id), EDIT_USER)(dispatch)
    .then(() => toastMessage.success(`New password was sent to ${user.email} for user ${user.name}!`));
};

export const openDialogRegisterUser = () => (dispatch) => {
  return ModalActions.openDialog(REGISTER_USER, {})(dispatch);
};

export const finishDialogRegisterUser = user => (dispatch) => {
  ModalActions.setLoader(true)(dispatch);
  return registerUser(user)(dispatch)
    .then((result) => {
      ModalActions.setLoader(false)(dispatch);
      return { status: 201, data: result.data };
    }).catch((e) => {
      ModalActions.setLoader(false)(dispatch);
      return e;
    });
};

export default ModalActions;
