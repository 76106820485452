function isFunction(obj) {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}

function isString(obj) {
  return typeof obj === 'string' || obj instanceof String;
}

function getFunction(selector) {
  if (isString(selector)) return v => v[selector];
  if (isFunction(selector)) return selector;
  return v => v;
}

function sortBy(items, comparer, desc) {
  if (items == null || comparer == null) return [];
  return desc ? items.sort(comparer).reverse() : items.sort(comparer);
}

function compareBase(a, b, selector, comparer) {
  const fn = getFunction(selector);
  const valA = fn(a);
  const valB = fn(b);
  if (valA == null || valB == null) return valA == null ? 1 : -1;
  return comparer(valA, valB);
}

function compare(a, b, selector) {
  return compareBase(a, b, selector, (valA, valB) => valA - valB);
}

function compareString(a, b, selector) {
  return compareBase(a, b, selector, (valA, valB) => valA.localeCompare(valB));
}

export function sort(items, selector, desc = false) {
  return sortBy(items, (a, b) => compare(a, b, selector), desc);
}

export function sortString(items, selector, desc = false) {
  return sortBy(items, (a, b) => compareString(a, b, selector), desc);
}
