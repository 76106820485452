import rest from './rest.actions';
import { LocationApi } from '@veit/bat-cloud-api/lib/api/LocationApi';

export const LOCATION = 'LOCATION';
export const GET_LOCATION = 'GET_LOCATION';
export const GETBY_LOCATION = 'GETBY_LOCATION';
export const POST_LOCATION = 'POST_LOCATION';
export const PUT_LOCATION = 'PUT_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';

export const GET_LOCATION_MODAL = 'GET_LOCATION_MODAL';

const expand = {
  farm: 'farm',
  scalesCount: 'scalesCount',
  lastStatistics: 'lastStatistics',
  activeFlock: 'activeFlock',
};

export const expandLocation = Object.freeze({
  ...expand,
  all: Object.values(expand),
});

const restLocation = rest(new LocationApi());

export const getLocation = restLocation.createGet(GET_LOCATION, 'locationGet');
export const getLocationBy = restLocation.createGetBy(GETBY_LOCATION, 'locationLocationIdGet');
export const postLocation = restLocation.createPost(POST_LOCATION, 'locationPost');
export const putLocation = restLocation.createPut(PUT_LOCATION, 'locationLocationIdPut');
export const deleteLocation = restLocation.createDelete(DELETE_LOCATION, 'locationLocationIdDelete');

export const modalGetLocation = restLocation.createGet(GET_LOCATION_MODAL, 'locationGet');
