import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  ButtonSwitch,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from '@veit/veit-web-controls';


import ModalActions, { downloadData } from '../../store/ModalExportStats.actions';
import { getFlock } from '../../store/Flock.actions';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import DateInput from '../../components/DateInput';
import ModalWithLoader from '../ModalWithLoader';
import AnimateHeight from '../AnimateHeight';

const exportFormats = {
  csv: 'CSV (csv)',
  // xlsx: 'Excel 2010+ (xlsx)',
};

class ExportStatsModal extends Component {
  componentDidMount() {
    const { flock } = this.props.modal.data;
    this.setFlockRange(flock, flock.id == null ? true : null);
  }

  setFlockRange = (flock, useDate) => {
    const date = dayjs();
    const { startDate, endDate, id } = flock || {};
    const range = {
      from: startDate || date.add(-90, 'day'),
      to: endDate || date,
      flockId: id,
      useDate,
      format: 'csv',
    };
    this.props.updateFormObject(range);
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.downloadData(this.props.modal.data);
  }

  render() {
    const form = this.props.modal.data;
    if (form == null) return null;
    const { updateForm } = this.props;
    const { flock, isFlockExport, dateFormat } = form;
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="modals.export.export-data" defaultMessage="Export data" />
        </ModalHeader>
        <ModalBody style={{ padding: '32px 0' }}>
          <AnimateHeight step={!form.useDate}>
            <Container>
              {!isFlockExport && flock.id != null && (
                <Row>
                  <Col>
                    <ButtonSwitch
                      options={[
                        { title: <FormattedMessage id="modals.export.actual-flock" defaultMessage="Actual flock" />, value: null },
                        { title: <FormattedMessage id="modals.export.date-range" defaultMessage="Date range" />, value: true },
                      ]}
                      selected={form.useDate}
                      onChange={v => this.setFlockRange(flock, v)}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Label type="text">
                    <FormattedMessage id="common.data" defaultMessage="Data" />
                  </Label>
                  <ButtonSwitch
                    options={[
                      { title: <FormattedMessage id="modals.export.daily-statistics" defaultMessage="Daily statistics" />, value: null },
                      { title: <FormattedMessage id="modals.export.all-weighings" defaultMessage="All weighings" />, value: true },
                    ]}
                    selected={form.exportSamples}
                    onChange={v => this.props.updateFormObject({ exportSamples: v })}
                  />
                </Col>
              </Row>
              {
                !form.useDate && (
                  <Row>
                    <Col>
                      <Label type="text">
                        <FormattedMessage id="common.flock" defaultMessage="Flock" />
                      </Label>
                      <Input readOnly type="text" value={flock.name} />
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col>
                  <Label type="text">
                    <FormattedMessage id="common.from" defaultMessage="From" />
                  </Label>
                  <DateInput disabled={!form.useDate} value={new Date(form.from || new Date())} dateFormat={dateFormat} onChange={date => updateForm(date, 'from')} />
                </Col>
                <Col>
                  <Label type="text">
                    <FormattedMessage id="common.to" defaultMessage="To" />
                  </Label>
                  <DateInput disabled={!form.useDate} value={new Date(form.to || new Date())} dateFormat={dateFormat} onChange={date => updateForm(date, 'to')} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label type="text">
                    <FormattedMessage id="modals.export.export-format" defaultMessage="Export format" />
                  </Label>
                  <UncontrolledDropdown>
                    <DropdownToggle color="primary" outline caret readOnly={isFlockExport}>
                      {exportFormats[form.format]}
                    </DropdownToggle>
                    <DropdownMenu readOnly={isFlockExport}>
                      {Object.keys(exportFormats).map(m => (
                        <DropdownItem key={m} onClick={() => updateForm(m, 'format')}>
                          {exportFormats[m]}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
            </Container>
          </AnimateHeight>
        </ModalBody>
        <ModalFooter style={{ padding: '15px' }}>
          <Button color="primary" outline onClick={this.cancel}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </Button>
          <div style={{ flex: '1' }}></div>
          <Button color="primary" onClick={this.finish}>
            <FormattedMessage id="modals.export.download-data" defaultMessage="Download data" />
          </Button>
        </ModalFooter>
        <SubmitHandler keyCode={keyCodes.enter} action={this.finish} />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateForm: ModalActions.updateForm,
  updateFormObject: ModalActions.updateFormObject,
  setLoader: ModalActions.setLoader,
  downloadData,
  getFlock,
}, dispatch);

ExportStatsModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  downloadData: PropTypes.func.isRequired,
  getFlock: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(ExportStatsModal);
