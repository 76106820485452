import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';

import { farmType, locationType } from '../types';
import DropDownWithFilter from './DropDownWithFilter';

function isNullOrWhiteSpace(str) {
  return !str || str.trim() === '';
}

const Location = ({ farm, location }) => {
  const locationName = isNullOrWhiteSpace(location.name) ? null : location.name;
  const farmName = isNullOrWhiteSpace(farm.name) ? null : farm.name;

  return (
    <Label type="info">
      {`${farmName || ''}${farmName && locationName ? ' · ' : ''}${locationName || ''}`}
    </Label>
  );
};

Location.defaultProps = {
  location: {},
  farm: {},
};

Location.propTypes = {
  location: locationType,
  farm: farmType,
};


const SelectScale = ({
  scales, scale, onChange, addScaleAction, ...otherProps
}) => {
  return (
    <DropDownWithFilter
      items={scales}
      selected={scale}
      getKey={k => k.id}
      getValue={v => `${v.name} ${(v.location || {}).name} ${(v.farm || {}).name}`}
      onChange={onChange}
      actions={addScaleAction ? [{
        onClick: addScaleAction,
        text: <FormattedMessage id="common.create-new-scale" defaultMessage="Create New Scale" />,
      }] : []}
      placeholder={<FormattedMessage id="common.Scale" defaultMessage="Scale" />}
      renderItem={i => (
        <React.Fragment>
          <Label>{i.name}</Label>
          <Location farm={i.farm} location={i.location} />
        </React.Fragment>
      )}
      {...otherProps}
    />
  );
};

SelectScale.propTypes = {
  onChange: PropTypes.func.isRequired,
  addScaleAction: PropTypes.func,
  scale: PropTypes.shape({
    farm: PropTypes.object,
    farmId: PropTypes.string,
  }),
  scales: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    location: PropTypes.object,
    farmId: PropTypes.string,
  })),
};


SelectScale.defaultProps = {
  addScaleAction: null,
  scales: [],
  scale: null,
};

export default SelectScale;
