import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Nav,
  NavItem,
  NavLink as BSNavLink,
  Collapse,
} from '@veit/veit-web-controls';
import { IMHouse, IMCloudUpload, IMBird } from '@veit/veit-web-controls/dist/icons';

import BatIcon from '../../components/BatIcon';
import bm from '../../utils/bem';

const nav = {
  companies: '/admin',
  smsGateways: '/admin/gateways',
  loraGateways: '/admin/lora/gateways',
  loraDevices: '/admin/lora/devices',
};

const bem = bm.component('sidebar');

class Sidebar extends Component {
  render() {
    const { pathname } = this.props.location;
    return (
      <div className={bem.b()}>
        <div className="bwc-sidebar__content">
          <NavLink to="/">
            <BatIcon className={bem.e('logo')} />
          </NavLink>
          <Nav vertical>
            <NavItem className={bem.e('nav-item')} active={pathname === nav.companies}>
              <BSNavLink tag={props => <NavLink {...props} />} className={bem.e('nav-item-collapse')} to={nav.companies}>
                <div className="d-flex">
                  <IMHouse className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">
                    Companies
                  </span>
                </div>
              </BSNavLink>
            </NavItem>
            <NavItem className={bem.e('nav-item')} active={pathname === nav.smsGateways}>
              <BSNavLink tag={props => <NavLink {...props} />} className={bem.e('nav-item-collapse')} to={nav.smsGateways}>
                <div className="d-flex">
                  <IMCloudUpload className={bem.e('nav-item-icon')} />
                  <span className="align-self-start">
                    SMS Gateways
                  </span>
                </div>
              </BSNavLink>
            </NavItem>
            <NavItem className={bem.e('nav-item')}>
              <BSNavLink tag={props => <NavLink {...props} />} className={bem.e('nav-item-collapse')} to="/admin/lora/gateways">
                <div className="d-flex">
                  <IMCloudUpload className={bem.e('nav-item-icon')} />
                  <span className="align-self-start">
                    LoRa Network
                  </span>
                </div>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen>
              <NavItem className={bem.e('nav-item', 'nav-item-sub')} active={pathname === nav.loraGateways}>
                <BSNavLink tag={props => <NavLink {...props} to="/admin/lora/gateways" />}>
                  <span>
                    Gateways
                  </span>
                </BSNavLink>
              </NavItem>
              <NavItem className={bem.e('nav-item', 'nav-item-sub')} active={pathname === nav.loraDevices}>
                <BSNavLink tag={props => <NavLink {...props} to="/admin/lora/devices" />}>
                  <span>
                    Devices
                  </span>
                </BSNavLink>
              </NavItem>
            </Collapse>
          </Nav>
          <div style={{ flex: 1 }}></div>
          <Nav vertical>
            <NavItem className={bem.e('nav-item')}>
              <BSNavLink tag={props => <NavLink {...props} />} className={bem.e('nav-item-collapse')} to="/">
                <div className="d-flex">
                  <IMBird className={bem.e('nav-item-icon')} />
                  <span className="align-self-start">
                    Go to Cloud
                  </span>
                </div>
              </BSNavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  show: PropTypes.bool,
};

Sidebar.defaultProps = {
  show: true,
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
  };
};

export default connect(mapStateToProps, null)(Sidebar);
