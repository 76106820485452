import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@veit/veit-web-controls';

import ModalActions, { importBat1Data, dialogStates as ds } from '../../store/ModalImport.actions';
import {
  closeSocket, getBat1Info, getBat1Stats, setStep,
} from '../../store/BatSocket.actions';
import { goTo } from '../../store/Router.actions';
import ModalWithLoader from '../ModalWithLoader';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import bem from '../../utils/bem';
import { getPath } from '../../utils/queryCache';
import Steps from '../Steps';
import Progress from './Progress';
import StepSelectScale from './StepSelectScale';
import StepSelectLocation from './StepSelectLocation';
import settings from '../../settings';

const bm = bem.modal('import-file');

class ImportFileModal extends Component {
  componentDidMount() {
    this.props.getBat1Info();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.socket.state !== this.props.socket.state
      && this.props.socket.state === ds.uploading) {
      const { files } = this.props.modal.data;
      const { stats } = this.props.socket;
      const deviceFiles = (stats[0] || {}).files || [];
      deviceFiles.forEach((e) => {
        const s = (files || []).find(f => f.name === e.name);
        e.locationId = (s || {}).locationId;
      });
      this.props.importBat1Data(deviceFiles);
    }
  }

  componentWillUnmount() {
    this.props.closeSocket();
  }

  getScale = (scales, data) => {
    return scales.find(f => f.actualLocId === data.actualLocId) || scales[0] || { files: [] };
  }

  uploadData = () => {
    const scale = this.getScale(this.props.socket.info || [], this.props.modal.data);
    this.props.getBat1Stats({ actualLocId: scale.actualLocId, files: this.props.modal.data.files });
  }

  openHelpdesk = () => {
    window.open(settings.help.importFile, '_blank');
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  done = () => {
    window.location = getPath('/locations');
  }

  render() {
    const { data } = this.props.modal;
    const { state, step } = this.props.socket;
    const scales = this.props.socket.info || [];
    const scale = this.getScale(scales, data);
    const files = data.files || [];
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        className={bm.b()}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          {(state === ds.connecting || (step === 1 && state === ds.downloading)) && (
            <FormattedMessage id="modals.import.connecting-to-bat1" defaultMessage="Connecting to BAT1 scale" />
          )}
          {(state === ds.downloading || state === ds.uploading) && step !== 1 && (
            <FormattedMessage id="modals.import.importing-data" defaultMessage="Importing data" />
          )}
          {state === ds.form && step === 1 && (
            <FormattedMessage id="modals.import.select-files" defaultMessage="Select files to import" />
          )}
          {state === ds.form && step === 2 && (
            <FormattedMessage id="modals.import.select-locations" defaultMessage="Select locations" />
          )}
          {state === ds.success && (
            <FormattedMessage id="modals.import.import-successful" defaultMessage="Import successful" />
          )}
        </ModalHeader>
        <div>
          <Steps steps={4} actualStep={step || 1} />
        </div>
        {state === ds.form && step === 1 && (
          <StepSelectScale
            scale={scale}
            scales={scales}
            files={files}
            updateForm={this.props.updateForm}
            updateFormObject={this.props.updateFormObject}
            dateFormat={this.props.dateFormat}
          />
        )}
        {state === ds.form && step === 2 && (
          <StepSelectLocation
            scale={scale}
            modal={data}
            dateFormat={this.props.dateFormat}
          />
        )}
        {state !== ds.form && (
          <ModalBody style={{ padding: '32px 0' }}>
            <Progress state={state} />
          </ModalBody>
        )}
        <ModalFooter style={{ padding: '15px' }}>

          {(state === ds.memoryNotConnected
            || state === ds.connectingError
            || state === ds.readError
            || state === ds.writeError
          )
            ? (
              <React.Fragment>
                <Button onClick={this.openHelpdesk} color="primary" outline>
                  <FormattedMessage id="modals.import.how-to-fix" defaultMessage="How To Fix The Problem" />
                </Button>
                <div style={{ flex: '1' }}></div>
                <Button color="primary" outline onClick={this.cancel}>
                  <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {
                  step < 2 && (
                    <React.Fragment>
                      <Button color="primary" outline onClick={this.cancel}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                      </Button>
                      <div style={{ flex: '1' }}></div>
                      <Button color="primary" onClick={() => this.props.setStep(2)} disabled={files.length === 0}>
                        <FormattedMessage id="common.continue" defaultMessage="Continue" />
                      </Button>
                      <SubmitHandler
                        keyCode={keyCodes.enter}
                        action={() => this.props.setStep(2)}
                      />
                    </React.Fragment>
                  )
                }
                {
                  step === 2 && (
                    <React.Fragment>
                      <Button color="primary" outline onClick={() => this.props.setStep(1)}>
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                      </Button>
                      <div style={{ flex: '1' }}></div>
                      <Button color="primary" onClick={this.uploadData} disabled={files.find(f => f.locationId == null) != null}>
                        <FormattedMessage id="common.Import" defaultMessage="Import" />
                      </Button>
                      <SubmitHandler keyCode={keyCodes.enter} action={() => { }} />
                    </React.Fragment>
                  )
                }
                {
                  step > 2 && step < 4 && (
                    <React.Fragment>
                      <Button color="primary" outline onClick={this.cancel}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                      </Button>
                    </React.Fragment>
                  )
                }
                {
                  step === 4 && (
                    <React.Fragment>
                      <Button color="primary" outline onClick={this.done}>
                        <FormattedMessage id="common.show-data" defaultMessage="Show Data" />
                      </Button>
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            )}
        </ModalFooter>
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  closeSocket,
  getBat1Info,
  getBat1Stats,
  importBat1Data,
  setStep,
  goTo,
  updateForm: ModalActions.updateForm,
  updateFormObject: ModalActions.updateFormObject,
  cancelDialog: ModalActions.cancelDialog,
}, dispatch);

const mapStateToProps = (state) => {
  return {
    devices: state.device.items,
    farms: state.farm.items,
    locations: state.location.modal,
    socket: state.socket,
    dateFormat: state.auth.dateFormat,
  };
};

ImportFileModal.propTypes = {
  getBat1Info: PropTypes.func.isRequired,
  getBat1Stats: PropTypes.func.isRequired,
  closeSocket: PropTypes.func.isRequired,
  importBat1Data: PropTypes.func.isRequired,
  cancelDialog: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  socket: PropTypes.shape({
    state: PropTypes.string,
    config: PropTypes.object,
    info: PropTypes.array,
    stats: PropTypes.array,
    step: PropTypes.number,
  }).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
    data: PropTypes.object,
  }).isRequired,
  farms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    farmId: PropTypes.string,
    name: PropTypes.string,
  })),
  dateFormat: PropTypes.string,
};

ImportFileModal.defaultProps = {
  locations: [],
  dateFormat: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportFileModal);
