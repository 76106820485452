import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { IconInput } from '@veit/veit-web-controls';
import IMSearch from '@veit/veit-web-controls/dist/icons/IMSearch';
import { defineMessages } from 'react-intl';
import intl from '../../setup/RIntl';

const messages = defineMessages({
  search: {
    id: 'common.search',
    defaultMessage: 'Search',
  },
});

const debounceSearch = debounce(1000, (value, dispatch) => {
  const { pathname, search } = window.location;
  const queryParams = queryString.parse(search);
  if (((value === '' || value == null) && queryParams.search == null) || value === queryParams.search) {
    return;
  }

  queryParams.search = value === '' ? null : value;
  queryParams.offset = null;

  const query = queryString.stringify(queryParams, { skipNull: true });
  const pathWithQuery = pathname + (query === '' ? '' : `?${query}`);
  dispatch(push(pathWithQuery));
});

class SearchInput extends Component {
  state = {
    search: '',
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateState();
    }
  }

  updateState = () => {
    const { search } = this.props.location;
    const queryParams = queryString.parse(search);
    this.setState({ search: queryParams.search || '' });
  }

  onChange = (e) => {
    this.setState({ search: e.target.value });
    this.props.searchChanged(e.target.value);
  }

  render() {
    return (
      <IconInput
        icon={IMSearch}
        value={this.state.search}
        placeholder={`${intl.t(messages.search)} ...`}
        onChange={this.onChange}
      />
    );
  }
}
SearchInput.propTypes = {
  searchChanged: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  location: state.router.location,
  locale: state.auth.locale,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchChanged: value => debounceSearch(value, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
