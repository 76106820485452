import queryString from 'query-string';

function isNormalInteger(val) {
  const n = Math.floor(Number(val));
  return n !== Infinity && String(n) === val && n >= 0;
}

export const limitsList = {
  default: [10, 25, 50, 100],
};

export default (query, defaultLimit = 10) => {
  const { offset, limit, ...other } = queryString.parse(query);
  return {
    offset: isNormalInteger(offset) ? Math.floor(Number(offset)) : 0,
    limit: isNormalInteger(limit) ? Math.floor(Number(limit)) : defaultLimit,
    ...other,
  };
};
