
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { Table, Label } from '@veit/veit-web-controls';
import IMBird from '@veit/veit-web-controls/dist/icons/IMBird';

import IconPage from '../../components/IconPage';
import { PageSkeletonBody } from '../../components/PageSkeleton';
import SourcesTabWeighing from './SourcesTabWeighing';

import bem from '../../utils/bem';
import { fetchPartial } from '../../store/Fetch.actions';
import { openDialogFile } from '../../store/ModalFile.actions';
import { openDialogHistogram } from '../../store/ModalHistogram.actions';
import { deviceType, deviceTypeName } from '../../model/enums';
import {
  LastUpdate, LastDay, LastAverage, DeviceStatusIcon, LastSex,
} from '../_table';

const bm = bem.component('source-tab');

function filterSource(source) {
  return source.type !== deviceType.bat1
    || (source.lastStatistics != null && source.lastStatistics[0] != null);
}

function sameDay(d1, d2) {
  return d1.year() === d2.year()
    && d1.month() === d2.month()
    && d1.date() === d2.date();
}

function reduce(p, c) {
  const first = p[0];
  if (first == null) return [c];
  const d1 = dayjs(first.dateTime);
  const d2 = dayjs(c.dateTime);
  if (c.category !== first.category && sameDay(d1, d2)) {
    return [...p, c];
  }
  return p;
}

class SourcesTab extends Component {
  state = {
    expand: null,
  }

  componentDidMount() {
    this.props.fetchPartial(() => [
      this.props.fetchData(),
    ]);
  }

  expand = (sourceId) => {
    this.setState(prevState => ({ expand: prevState.expand === sourceId ? null : sourceId }));
  }

  openDetail = type => (stat, stats) => {
    if (type === deviceType.bat1) {
      this.props.openDialogFile(stat, stats);
    } else {
      this.props.openDialogHistogram(stat, stats);
    }
  }

  render() {
    const {
      sources, isFetching, weightUnit, dateFormat,
    } = this.props;
    if (isFetching) return <PageSkeletonBody />;
    return sources != null && sources.length > 0 ? (
      <Table type="info" className={bm.b()}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.device" defaultMessage="Device" />
            </th>
            <th>
              <FormattedMessage id="common.type" defaultMessage="Type" />
            </th>

            <th>
              <FormattedMessage id="common.status" defaultMessage="Status" />
            </th>
            <th>
              <FormattedMessage id="common.last-update" defaultMessage="Last update" />
            </th>
            <th>
              <FormattedMessage id="common.age" defaultMessage="Age" />
            </th>
            <th>
              <FormattedMessage id="common.sex" defaultMessage="Sex" />
            </th>
            <th>
              <FormattedMessage id="common.last-value" defaultMessage="Last value" />
            </th>
          </tr>
        </thead>
        <tbody>
          {sources
            .sort((a, b) => (b.dateTime - a.dateTime))
            .filter(filterSource)
            .map(device => ({ ...device, last: (device.lastStatistics || []).reduce(reduce, []) }))
            .map(device => (
              <React.Fragment key={device.id}>
                <tr className="has-pointer" onClick={() => this.expand(device.id)}>
                  {
                    device.type === deviceType.bat1 ? (
                      <td aria-hidden="true">
                        <Label>{device.last[0] != null ? device.last[0].fileName : null}</Label>
                      </td>
                    ) : (
                      <td onClick={e => e.stopPropagation()} aria-hidden="true" style={{ cursor: 'default' }}>
                        <Link to={`/scale/${device.id}`}>
                          <Label>{device.name}</Label>
                        </Link>
                      </td>
                    )
                  }
                  <td><Label>{deviceTypeName[device.type]}</Label></td>
                  <td><DeviceStatusIcon lastStatistics={device.last} /></td>
                  <td><LastUpdate lastStatistics={device.last} dateFormat={dateFormat} /></td>
                  <td><LastDay lastStatistics={device.last} /></td>
                  <td><LastSex lastStatistics={device.last} /></td>
                  <td>
                    <LastAverage
                      lastStatistics={device.last}
                      scaleType={device.type}
                      noIcon
                      weightUnit={weightUnit}
                    />
                  </td>
                </tr>
                {
                  this.state.expand === device.id && (
                    <tr className={bm.e('detail-row')}>
                      <td colSpan="9" style={{ width: '100%' }}>
                        <SourcesTabWeighing
                          locationId={device.locationId}
                          stats={device.lastStatistics}
                          openDetail={this.openDetail(device.type)}
                          type={device.type}
                          weightUnit={weightUnit}
                          dateFormat={dateFormat}
                        />
                      </td>
                    </tr>
                  )
                }
              </React.Fragment>
            ))}
        </tbody>
      </Table>
    ) : (
      <IconPage
        icon={IMBird}
        text={<FormattedMessage id="common.no-data" defaultMessage="No scales available ..." />}
      />
    );
  }
}

SourcesTab.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  fetchPartial: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  openDialogFile: PropTypes.func.isRequired,
  openDialogHistogram: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

SourcesTab.defaultProps = {
  sources: [],
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = state => ({
  sources: state.device.items,
  isFetching: state.fetch.isFetchingPartial,
  weightUnit: state.auth.weightUnit,
  dateFormat: state.auth.dateFormat,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPartial,
  openDialogFile,
  openDialogHistogram,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SourcesTab);
