import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Label from '@veit/veit-web-controls/dist/Components/Label';
import bem from '../../utils/bem';

const bm = bem.component('tab-link');

const TabLink = ({ to, active, children }) => {
  return (
    <Label className={bm.b(active ? 'active' : null)}>
      <Link to={to}>{children}</Link>
    </Label>
  );
};

TabLink.propTypes = {
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TabLink.defaultProps = {
  active: false,
};

export default TabLink;
