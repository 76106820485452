import { ApiClient } from '@veit/bat-cloud-api/lib/ApiClient';
import { run, create } from '../utils/api';

const get = (path) => {
  return ApiClient.instance.callApi(path, 'GET', {}, {}, {}, {}, {}, ['OAuth2'], ['application/json'], ['application/json'], Object);
};

export const updateGroups = () => {
  return get('/manage/flock-groups');
};

export const updateDeviceMap = () => {
  return get('/manage/device-map');
};

export const updateLastValues = () => {
  return get('/manage/last-values');
};

export const getUsageReport = () => {
  return get('/manage/usage-report');
};

export const changeCompany = companyId => create('CHANGE_COMPANY', () => run('/manage/change-company', 'POST', JSON.stringify({ companyId })));
