import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from '@veit/veit-web-controls';

import Switch from '../../../components/Switch';

const EditRow = ({ items, data, onChange }) => {
  const [country, setCountry] = useState(data.country || '');
  const [code, setCode] = useState(data.code || '');
  const [canRequest, setCanRequest] = useState(data.canRequest || false);

  const isValidCode = code.length === 2;
  const isValidCountry = country.length > 0;
  const isUniqueCode = items.find(f => f.code === code && f.id !== data.id) == null;

  const disableSave = !isValidCode || !isValidCountry || !isUniqueCode;
  return (
    <tr>
      <td>
        <Input
          placeholder="Country name"
          autoFocus
          onChange={e => setCountry(e.target.value)}
          value={country}
        />
      </td>
      <td>
        <Input
          placeholder="Country code"
          maxLength={2}
          onChange={e => setCode(e.target.value.toLocaleUpperCase())}
          value={code}
        />
      </td>
      <td>
        <Switch
          getName={e => (e ? 'YES' : 'NO')}
          options={[true, false]}
          onChange={e => setCanRequest(e)}
          value={canRequest}
        />
      </td>
      <td className="actions">
        <Button
          disabled={disableSave}
          color="primary"
          onClick={() => onChange({
            id: data.id, country, code, canRequest,
          })}
        >
          Save
        </Button>
        <Button onClick={() => onChange(null)}>
          Cancel
        </Button>
      </td>
    </tr>
  );
};

EditRow.propTypes = {
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
  })).isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    country: PropTypes.string,
    code: PropTypes.string,
    canRequest: PropTypes.bool,
  }).isRequired,
};

export default EditRow;
