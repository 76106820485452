import React from 'react';
import Icon from 'react-icon-base';

const IMDot = (props) => {
  return (
    <Icon viewBox="0 0 100 100" {...props}>
      <circle cx="50" cy="50" r="25" />
    </Icon>
  );
};

export default IMDot;
