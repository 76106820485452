import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';

import AuthButton from '../../components/AuthButton';
import { goTo } from '../../store/Router.actions';
import { deviceTypeName } from '../../model/enums';
import LocationLink from '../_table/LocationLink';
import LastStatus from '../_table/LastStatus';
import {
  LastUpdate, LastDay, LastAverage, LastSex,
} from '../_table';

import { sortLastValuesBySex } from '../../utils/flock';

const TableRow = ({
  device, editDeviceHandler, linkTo, weightUnit, dateFormat,
}) => {
  const lastStatistics = sortLastValuesBySex(device.lastStatistics || []);
  return (
    <tr className="has-pointer" onClick={() => linkTo(`/scale/${device.id}`)} key={device.id}>
      <td><Label>{device.name}</Label></td>
      <td><Label>{deviceTypeName[device.type]}</Label></td>
      <td className="text-center"><LastStatus lastStatistics={lastStatistics} /></td>
      <td><LastUpdate lastStatistics={lastStatistics} dateFormat={dateFormat} /></td>
      <td><LastDay lastStatistics={lastStatistics} /></td>
      <td className="text-center"><LastSex lastStatistics={lastStatistics} /></td>
      <td><LastAverage lastStatistics={lastStatistics} noIcon weightUnit={weightUnit} /></td>
      <td onClick={e => e.stopPropagation()} aria-hidden="true">
        {device.locationId && (
          <LocationLink to={`/locations/${device.locationId}`} location={device.location} farm={device.farm} showWarning />
        )}
      </td>
      <td className="narrow">
        <AuthButton canUpdate={device} onClick={e => editDeviceHandler(e, device)} className="btn-narrow" color="primary">
          <FormattedMessage id="devices.edit-scale" defaultMessage="Edit Scale" />
        </AuthButton>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    lastStatistics: PropTypes.array,
    farmId: PropTypes.string,
    location: PropTypes.object,
    farm: PropTypes.object,
    type: PropTypes.string,
    locationId: PropTypes.string,
  }).isRequired,
  linkTo: PropTypes.func.isRequired,
  editDeviceHandler: PropTypes.func.isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

TableRow.defaultProps = {
  weightUnit: null,
  dateFormat: null,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  linkTo: goTo,
}, dispatch);

export default connect(null, mapDispatchToProps)(TableRow);
