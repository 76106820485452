import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import { changeWeightUnit } from '../store/Auth.actions';
import bem from '../utils/bem';
import WeightUnitName from './WeightUnitName';

const unit = {
  g: 'grams',
  lb: 'pounds',
};

const component = bem.component('weight-unit-selector');

export const WeightUnitSelector = ({
  auth, changeWeightUnit, className, noStyle,
}) => {
  return (
    <UncontrolledDropdown className={noStyle ? className : component.b(className)}>
      <DropdownToggle color="primary" outline caret>
        <WeightUnitName unit={unit[auth.weightUnit] || unit.g} />
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(unit).map(key => (
          <DropdownItem key={key} onClick={() => changeWeightUnit(key, auth.isAuthenticated)}>
            <WeightUnitName unit={unit[key]} />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

WeightUnitSelector.propTypes = {
  changeWeightUnit: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    lang: PropTypes.string,
    locale: PropTypes.string,
    weightUnit: PropTypes.string,
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  noStyle: PropTypes.bool,
};

WeightUnitSelector.defaultProps = {
  className: null,
  noStyle: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeWeightUnit,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WeightUnitSelector);
