import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';

import { farmType } from '../types';
import DropDownWithFilter from './DropDownWithFilter';

function isNullOrWhiteSpace(str) {
  return !str || str.trim() === '';
}

const FarmAddress = ({ farm }) => {
  const address = isNullOrWhiteSpace(farm.address) ? null : farm.address;
  const country = isNullOrWhiteSpace(farm.country) ? null : farm.country;

  return address || country
    ? (<Label type="info">{address}{address && country ? ', ' : null}{country}</Label>)
    : null;
};

FarmAddress.defaultProps = {
  farm: {},
};

FarmAddress.propTypes = {
  farm: farmType,
};

const SelectFarm = ({
  farms, farm, onChange, addFarmAction, ...otherProps
}) => {
  return (
    <DropDownWithFilter
      items={farms}
      selected={farm}
      getKey={k => k.id}
      getValue={v => `${v.name} ${v.address} ${v.country}`}
      onChange={onChange}
      actions={addFarmAction ? [{
        onClick: addFarmAction,
        text: <FormattedMessage id="common.create-new-farm" defaultMessage="Create New Farm" />,
      }] : []}
      placeholder={<FormattedMessage id="common.farm" defaultMessage="Farm" />}
      renderItem={i => (
        <React.Fragment>
          <Label>{i.name}</Label>
          <FarmAddress farm={i} />
        </React.Fragment>
      )}
      {...otherProps}
    />
  );
};

SelectFarm.propTypes = {
  onChange: PropTypes.func.isRequired,
  addFarmAction: PropTypes.func,
  farm: farmType,
  farms: PropTypes.arrayOf(farmType),
};


SelectFarm.defaultProps = {
  addFarmAction: null,
  farms: [],
  farm: null,
};

export default SelectFarm;
