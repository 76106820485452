import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from '@veit/veit-web-controls';

import DateFormat from '../../../components/DateFormat';

import { getLoraDevices, getLoraGateways } from '../../../store/Lora.actions';

class LoraGatewayList extends Component {
  componentDidMount() {
    this.props.getLoraGateways();
  }

  render() {
    const { gateways } = this.props;
    return (
      <div className="bwv-gateway-list">
        <Table>
          <thead>
            <tr>
              <th>Gateway Eui</th>
              <th>Name</th>
              <th>Description</th>
              <th>Region</th>
              <th>Last Uplink</th>
            </tr>
          </thead>
          <tbody>
            {gateways.sort((a, b) => ((a.eui > b.eui) ? 1 : -1))
              .map(c => (
                <tr key={c.id}>
                  <td>{c.eui}</td>
                  <td>{c.name}</td>
                  <td>{c.description}</td>
                  <td>{c.region}</td>
                  <td><DateFormat date={c.lastUplink} format="DD/MM/YYYY HH:mm" /></td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

LoraGatewayList.propTypes = {
  getLoraGateways: PropTypes.func.isRequired,
  getLoraDevices: PropTypes.func.isRequired,
  gateways: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  devices: PropTypes.arrayOf(PropTypes.shape({

  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      view: PropTypes.string,
    }),
  }).isRequired,
};

LoraGatewayList.defaultProps = {
  gateways: [],
  devices: [],
};

const mapStateToProps = state => ({
  gateways: state.lora.gateways,
  devices: state.lora.devices,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getLoraDevices,
  getLoraGateways,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoraGatewayList);
