import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { openDialogEditFlock, openDialogAddFlock, openDialogCloseFlock } from '../../store/ModalFlock.actions';
import { openDialogExportStats } from '../../store/ModalExportStats.actions';
import { goTo } from '../../store/Router.actions';
import { getDevicesByFlock } from '../../store/Device.actions';

import Actions from './Actions';
import Subtitle from './Subtitle';
import Page from '../../components/Page';
import bem from '../../utils/bem';
import arrayUtils from '../../utils/array';
import { groupByDay } from '../../utils/stats';

import TabsBar, { tabType } from '../_tabs/TabsBar';
import WeighingTab from '../_tabs/WeighingTab';
import SourcesTab from '../_tabs/SourcesTab';
import GraphsTab from '../_tabs/GraphsTab';

import fetchData from './fetchData';

class Flock extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.props.fetchData(this.props.id, true, this.props.filter);
  }

  editFlock = () => {
    this.props.openDialogEditFlock(this.props.flock.item).then((result) => {
      if (result != null && result.delete) {
        this.props.goTo(`/flocks/${this.props.filter || ''}`);
      }
      if (result != null && !result.delete) {
        this.fetchData();
      }
    });
  }

  closeFlock = () => {
    const flock = this.props.flock.item;
    this.props.openDialogCloseFlock(flock).then((result) => {
      if (result != null) this.props.goTo(`/flock/finished/${flock.id}`);
    });
  }

  copyFlock = () => {
    this.props.openDialogAddFlock({
      ...this.props.flock.item,
      id: null,
      endDate: null,
      startDate: new Date(),
      name: `${this.props.flock.item.name} - copy`,
    }).then((result) => {
      if (result != null) {
        this.props.goTo('/flocks');
      }
    });
  }

  showCurve = (curve, show) => {
    this.setState((prevState) => {
      const curves = arrayUtils.toggle(prevState.curves, curve, show);
      return { curves };
    });
  }

  toggle = (prop) => {
    this.setState(prevState => ({
      [prop]: !prevState[prop],
    }));
  }

  exportData = () => {
    const { locationId } = this.props.flock.item;
    this.props.openDialogExportStats({
      flock: this.props.flock.item,
      locationId,
      isFlockExport: true,
      dateFormat: this.props.dateFormat,
    });
  }

  render() {
    const bm = bem.view('flock');
    const flock = this.props.flock.item || {};
    const stats = this.props.stats.item || {};
    const data = (Array.isArray(stats) ? stats : stats.data) || [];
    const dataGroup = groupByDay(data);
    const { tab } = this.props.match.params;
    const unit = this.props.weightUnit;
    const format = this.props.dateFormat;
    return (
      <Page
        className={bm.b()}
        title={flock.name}
        subtitle={(
          <Subtitle
            flock={flock}
            bird={this.props.bird.item}
            location={this.props.location.item}
            weightUnit={unit}
            dateFormat={format}
          />
        )}
        actions={(
          <Actions
            editFlock={this.editFlock}
            exportData={this.exportData}
            copyFlock={this.copyFlock}
            closeFlock={this.closeFlock}
            flock={flock}
          />
        )}
        isFetching={this.props.fetch.isFetching}
        notFound={flock.id !== this.props.id}
      >
        <TabsBar
          baseUrl={`/flock/${this.props.filter ? `${this.props.filter}/` : ''}${flock.id}`}
          match={this.props.match}
          tabs={[tabType.weighing, tabType.graphs, tabType.scales]}
        />

        {tab == null && (
          <WeighingTab stats={dataGroup} flockId={flock.id} weightUnit={unit} dateFormat={format} />
        )}

        {tab === tabType.graphs && (
          <GraphsTab stats={dataGroup} flock={flock} weightUnit={unit} />
        )}

        {tab === tabType.scales && (
          <SourcesTab
            fetchData={() => this.props.getDevicesByFlock(this.props.id)}
          />
        )}
      </Page>
    );
  }
}

Flock.propTypes = {
  id: PropTypes.string.isRequired,
  filter: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  openDialogEditFlock: PropTypes.func.isRequired,
  openDialogAddFlock: PropTypes.func.isRequired,
  openDialogCloseFlock: PropTypes.func.isRequired,
  openDialogExportStats: PropTypes.func.isRequired,
  getDevicesByFlock: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  flock: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      startDate: PropTypes.object,
      endDate: PropTypes.object,
      name: PropTypes.string,
      locationId: PropTypes.string,
    }),
    items: PropTypes.array,
    links: PropTypes.object,
  }),
  stats: PropTypes.shape({
    item: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string,
      }),
      PropTypes.array,
    ]),
  }),
  bird: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      curvePoints: PropTypes.array,
    }),
  }),
  location: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      farm: PropTypes.object,
    }),
  }).isRequired,
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

Flock.defaultProps = {
  filter: null,
  flock: null,
  stats: null,
  bird: null,
  fetch: null,
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    filter: ownProps.match.params.filter,
    flock: state.flock,
    stats: state.stats,
    bird: state.bird,
    fetch: state.fetch,
    locale: state.auth.locale,
    location: state.location,
    weightUnit: state.auth.weightUnit,
    dateFormat: state.auth.dateFormat,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchData,
  openDialogEditFlock,
  openDialogAddFlock,
  openDialogExportStats,
  openDialogCloseFlock,
  goTo,
  getDevicesByFlock,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Flock);
