import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  Input,
} from '@veit/veit-web-controls';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ModalActions from '../../store/Modal.actions';

const Step2 = ({ modal, updateFormEvent }) => {
  const form = modal.data;
  if (form == null) return null;
  return (
    <React.Fragment>
      <Label type="text">
        <FormattedMessage id="modals.contact-name" defaultMessage="Contact name" />
      </Label>
      <Input value={form.contactName || ''} name="contactName" onChange={e => updateFormEvent(e, 'contactName')} autoFocus={form.id == null} />
      <Label type="text">
        <FormattedMessage id="common.phone-number" defaultMessage="Phone number" />
      </Label>
      <Input value={form.phone || ''} name="phone" onChange={e => updateFormEvent(e, 'phone')} />
      <Label type="text">
        <FormattedMessage id="common.email" defaultMessage="E-mail" />
      </Label>
      <Input value={form.email || ''} name="email" onChange={e => updateFormEvent(e, 'email')} />
    </React.Fragment>
  );
};

Step2.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
}, dispatch);

export default connect(null, mapDispatchToProps)(Step2);
