import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Label } from '@veit/veit-web-controls';
import IMBird from '@veit/veit-web-controls/dist/icons/IMBird';

import IMDot from '../../components/icons/IMDot';
import { flockType } from '../../types';
import Card from './Card';

const PresetCard = ({
  preset, active, flocks, bird,
}) => {
  return preset ? (
    <Card
      className={classNames('bwc-preset-card', active ? 'active' : null)}
      tag={Link}
      to={active ? '/compare' : `/compare/${preset.id}`}
    >
      <Label className="bwc-preset-card__title">{preset.name}</Label>
      {bird && (
        <Label className="bwc-preset-card__bird">
          <IMBird style={{ marginLeft: '-4px', marginRight: '4px' }} />
          {bird.name}
        </Label>
      )}
      <div className="bwc-preset-card__content custom-scroll">
        {preset.selectedFlocks && flocks && flocks
          .filter(f => preset.selectedFlocks.find(x => x.flockId === f.id) != null).map(m => (
            <Label className="bwc-preset-card__flock" key={m.id}>
              <IMDot style={{ marginLeft: '-4px', marginRight: '4px' }} />
              {m.name}
            </Label>
          ))}
      </div>
    </Card>
  ) : null;
};

PresetCard.propTypes = {
  active: PropTypes.bool,
  preset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    selectedFlocks: PropTypes.array,
  }),
  bird: PropTypes.shape({
    name: PropTypes.string,
  }),
  flocks: PropTypes.arrayOf(flockType),
};

PresetCard.defaultProps = {
  active: false,
  preset: null,
  bird: null,
  flocks: [],
};

export default PresetCard;
