import * as ModalActions from './Modal.actions';
import { getStatsLocationByFormat, getSamplesLocationByFormat } from './Stats.actions';

export const EXPORT_STATS = 'EXPORT_STATS';

export const openDialogExportStats = data => (dispatch) => {
  return ModalActions.openDialog(EXPORT_STATS, { ...data, format: 'csv' })(dispatch);
};

const getFileName = (headers) => {
  let cd = (headers || {})['content-disposition'];
  if (cd == null) {
    return null;
  }
  cd = cd.replace(new RegExp('"', 'g'), '');
  const index = cd.indexOf('=');
  return index !== -1 ? cd.substring(index + 1) : cd;
};

export const downloadData = params => (dispatch) => {
  const fn = params.exportSamples ? getSamplesLocationByFormat : getStatsLocationByFormat;
  fn(params.locationId, params)(dispatch).then((result) => {
    if (result != null && result.response != null) {
      const fileName = getFileName(result.response.header) || `data.${params.format}`;
      const pom = document.createElement('a');
      const url = URL.createObjectURL(result.response.body);
      pom.href = url;
      pom.setAttribute('download', fileName);
      pom.click();
    }
    ModalActions.cancelDialog()(dispatch);
  });
};

export default ModalActions;
