import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ModalActions, { finishDialogAddFile } from '../../store/ModalFile.actions';
import { expandLocation, modalGetLocation } from '../../store/Location.actions';
import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';
import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';

class AddFileModal extends Component {
  componentDidMount() {
    this.fetchLocations();
  }

  fetchLocations = () => {
    this.props.modalGetLocation({ expand: expandLocation.farm });
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogAddFile(this.props.modal.data);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="manual.add-file" defaultMessage="Add File" />
        </ModalHeader>
        <ModalBody>
          <Step1 modal={this.props.modal} fetchLocations={this.fetchLocations} />
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          finisDialogText={<FormattedMessage id="manual.add-file" defaultMessage="Add File" />}
          modal={this.props.modal}
          validators={{ 1: hasStep1Error }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormObject: ModalActions.updateFormObject,
  finishDialogAddFile,
  modalGetLocation,
}, dispatch);

AddFileModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  finishDialogAddFile: PropTypes.func.isRequired,
  modalGetLocation: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(AddFileModal);
