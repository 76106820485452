import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import { IMErrorMarkCircle, IMStick } from '@veit/veit-web-controls/dist/icons';
import { FormattedMessage } from 'react-intl';
import ProgressCircular from '../../components/ProgressCircular';
import bem from '../../utils/bem';
import { dialogStates } from '../../store/ModalImport.actions';

const bm = bem.component('import-state');

export const ProgressTemplate = ({
  icon: Icon, text, className,
}) => {
  return (
    <div className={bm.b(className)}>
      <Icon className={bm.e('icon')} />
      {text && (<Label className={bm.e('subtitle')}>{text}</Label>)}
    </div>
  );
};

ProgressTemplate.propTypes = {
  icon: PropTypes.func.isRequired,
  text: PropTypes.node,
  className: PropTypes.string,
};

ProgressTemplate.defaultProps = {
  text: null,
  className: null,
};

export const TimeProgressTemplate = ({
  icon: Icon, text, className, seconds,
}) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(t => (t === 0 ? 0 : t - 1));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const min = Math.floor(timeLeft / 60);
  const sec = timeLeft % 60;
  return (
    <div className={bm.b(className)}>
      <ProgressCircular percent={100 - (timeLeft / seconds) * 100}>
        <Icon className={bm.e('icon')} />
      </ProgressCircular>
      {text && (<Label className={bm.e('subtitle')}>{text} {min}:{sec >= 10 ? sec : `0${sec}`}</Label>)}
    </div>
  );
};

TimeProgressTemplate.propTypes = {
  text: PropTypes.node,
  className: PropTypes.string,
  seconds: PropTypes.number,
  icon: PropTypes.func.isRequired,
};

TimeProgressTemplate.defaultProps = {
  text: null,
  className: null,
  seconds: 0,
};

const Connecting = () => {
  return (
    <ProgressTemplate
      className="connecting"
      icon={IMStick}
      text={<FormattedMessage id="modals.import.connecting" defaultMessage="Connecting to BAT Agent ..." />}
    />
  );
};

const ConnectingError = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.not-installed" defaultMessage="BAT Agent is not installed or running" />}
    />
  );
};

const InvalidVersionError = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.out-of-date" defaultMessage="BAT Agent is out of date. Please update the application" />}
    />
  );
};

const MemoryNotConnected = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.disconnected" defaultMessage="BAT2 Memory stick is disconnected" />}
    />
  );
};

const UploadingError = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.upload-error" defaultMessage="We weren’t able to finish upload to BAT Cloud" />}
    />
  );
};

const ProgressBase = ({ state }) => {
  switch (state) {
    case dialogStates.memoryNotConnected: return <MemoryNotConnected />;
    case dialogStates.connecting: return <Connecting />;
    case dialogStates.invalidVersion: return <InvalidVersionError />;
    case dialogStates.connectingError: return <ConnectingError />;
    case dialogStates.uploadingError: return <UploadingError />;
    default: return null;
  }
};

ProgressBase.propTypes = {
  state: PropTypes.string,
};

ProgressBase.defaultProps = {
  state: null,
};

export default ProgressBase;
