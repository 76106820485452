import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { statsType } from '../model/enums';

const WeighingName = ({ type }) => {
  if (type === statsType.automatic) return <FormattedMessage id="common.automatic" defaultMessage="Automatic" />;
  if (type === statsType.manual) return <FormattedMessage id="common.manual" defaultMessage="Manual" />;
  return null;
};

WeighingName.propTypes = {
  type: PropTypes.string,
};

WeighingName.defaultProps = {
  type: null,
};

export default WeighingName;
