import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import { changeLanguage } from '../store/Auth.actions';
import bem from '../utils/bem';

const locale = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
};

const component = bem.component('lang-selector');

export const LanguageSelector = ({
  auth, changeLanguage, className, noStyle,
}) => {
  return (
    <UncontrolledDropdown className={noStyle ? className : component.b(className)}>
      <DropdownToggle color="primary" outline caret>
        {locale[auth.locale] || locale.en}
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(locale).map(key => (
          <DropdownItem key={key} onClick={() => changeLanguage(key, auth.isAuthenticated)}>
            {locale[key]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

LanguageSelector.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    lang: PropTypes.string,
    locale: PropTypes.string,
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  noStyle: PropTypes.bool,
};

LanguageSelector.defaultProps = {
  className: null,
  noStyle: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeLanguage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
