import React from 'react';
import PropTypes from 'prop-types';
import bem from '../../utils/bem';

const Content = ({
  tag: Tag, className, hideSidebar, children, ...restProps
}) => {
  const bm = bem.component('content');
  return (
    <Tag className={bm.b(className)} {...restProps}>
      {children}
      {/* <div
        onClick={() => hideSidebar && hideSidebar()}
        className={bm.e('overlay')}
        aria-hidden="true"
      >
      </div> */}
    </Tag>
  );
};

Content.propTypes = {
  tag: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  hideSidebar: PropTypes.func,
};

Content.defaultProps = {
  tag: 'div',
  className: null,
  children: null,
  hideSidebar: null,
};

export default Content;
