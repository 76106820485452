import React from 'react';
import { flockType, farmType } from '../../types';

// import IMCorner from '../../components/icons/IMCorner';
import SubtitleWrapper from '../../components/SubtitleWrapper';
import FlockSubtitle from '../_subtitle/FlockSubtitle';
import FarmSubtitle from '../_subtitle/FarmSubtitle';

const Subtitle = ({ flock, farm }) => {
  return (
    <SubtitleWrapper>
      <FarmSubtitle farm={farm} />
      <FlockSubtitle flock={flock} />
    </SubtitleWrapper>
  );
};

Subtitle.propTypes = {
  flock: flockType,
  farm: farmType,
};

Subtitle.defaultProps = {
  flock: null,
  farm: null,
};

export default Subtitle;
