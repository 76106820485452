import { push } from 'connected-react-router';
import { getPath, trimPathQueryParams, filterKeys } from '../utils/queryCache';

export const goTo = url => (dispatch) => {
  dispatch(push(getPath(url)));
};

export const clearFilters = url => (dispatch) => {
  const path = trimPathQueryParams(url, [filterKeys.search]);
  dispatch(push(path));
};
