import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { ModalHeader, ModalBody } from '@veit/veit-web-controls';

import ModalActions, { finishDialogEditCompare } from '../../store/ModalCompare.actions';
import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import ModalContent, { hasModalContentError } from './ModalContent';

class EditCompareModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditCompare({
      ...this.props.modal.data,
    });
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="compare.edit-comparison" defaultMessage="Edit Comparison" />
        </ModalHeader>
        <ModalBody>
          <ModalContent modal={this.props.modal} />
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          finisDialogText={<FormattedMessage id="common.save-changes" defaultMessage="Save Changes" />}
          modal={this.props.modal}
          validators={{ 1: hasModalContentError }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogEditCompare,
}, dispatch);

EditCompareModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogEditCompare: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
    data: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditCompareModal);
