import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Table, Button, Input, Label,
} from '@veit/veit-web-controls';
import IMTrashBin from '@veit/veit-web-controls/dist/icons/IMTrashBin';

import { openDialogEditBird } from '../../store/ModalBird.actions';
import { putBird } from '../../store/Bird.actions';
import fetchData from './fetchData';
import Subtitle from './Subtitle';
import Graphs from './Graphs';
import Page from '../../components/Page';
import AuthButton from '../../components/AuthButton';
import bem from '../../utils/bem';
import array from '../../utils/array';
import { dateType, authType } from '../../model/enums';
import convertGramsToPounds, { convertPoundsToGrams } from '../../utils/unitConversion';

const bm = bem.view('bird');
let minimumWeightValue = 45;
let unit = 'g';

class Bird extends Component {
  state = {
    edit: null,
    day: 0,
    weight: 0,
  }

  componentDidMount() {
    this.props.fetchData(this.props.id);
  }

  editBird = () => {
    this.props.openDialogEditBird(this.props.bird.item).then((t) => {
      if (t != null && !t.delete) {
        this.props.fetchData(this.props.id);
      }
    });
  }

  removePoint = (point) => {
    this.setState(prevState => ({ edit: prevState.edit.filter(f => f.day !== point.day) }));
  }

  editPoint = (point) => {
    const editedPoint = {
      day: point.day,
      weight: point.weight % 1 !== 0 ? +(point.weight.toFixed(3)) : point.weight,
    };
    this.setState(prevState => ({
      edit: array.addOrReplace(prevState.edit, editedPoint, k => k.day),
    }));
  }

  savePoint = (point) => {
    const editedPoint = {
      day: point.day * (this.useWeeks() ? 7 : 1),
      weight: point.weight,
    };
    this.setState(prevState => ({
      edit: array.addOrReplace(prevState.edit, editedPoint, k => k.day),
    }));
  }

  setEdit = (edit) => {
    if (edit) {
      const { item } = this.props.bird;
      const sortedPoints = (item.curvePoints || []).sort((a, b) => a.day - b.day);
      const useWeeks = this.useWeeks();
      this.setState({
        edit: sortedPoints,
        day: Math.max(...sortedPoints.map(m => (useWeeks ? (m.day / 7) : m.day)), 1),
        weight: Math.max(...sortedPoints.map(m => m.weight), minimumWeightValue),
      });
    } else {
      this.setState({ edit: null });
    }
  }

  saveChanges = () => {
    const { item } = this.props.bird;
    const points = this.state.edit;
    if (unit === 'lb') {
      // update curve can be only in grams
      for (let index = 0; index < points.length; index += 1) {
        points[index].weight = convertPoundsToGrams(points[index].weight);
      }
    }

    this.props.putBird(item.id, { ...item, curvePoints: points }).then(() => {
      this.setState({ edit: null });
      this.props.fetchData(this.props.id);
    });
  }

  savePointAction = (point) => {
    this.savePoint(point);
    this.setState(prev => ({ day: prev.day + 1 }));
  };

  onEnterPress = (e) => {
    if (e.key === 'Enter') {
      this.savePointAction({ day: this.state.day, weight: this.state.weight });
    }
  };

  useWeeks = () => {
    return this.props.bird.item.dateType === dateType.week;
  }

  actions = () => {
    return (
      <React.Fragment>
        <AuthButton canUpdate={this.props.bird.item} outline color="primary" onClick={this.editBird}>
          <FormattedMessage id="breed.edit-breed" defaultMessage="" />
        </AuthButton>
      </React.Fragment>
    );
  }

  render() {
    const { item } = this.props.bird;
    if (item == null) return null;
    const curvePoints = this.state.edit || item.curvePoints || [];
    unit = this.props.weightUnit;
    const sortedPoints = curvePoints.sort((a, b) => a.day - b.day);
    // values are originally in grams, so check if convert to pounds is necessary
    if (unit === 'lb' && (item.alreadyConverted === undefined || item.alreadyConverted === false)) {
      item.alreadyConverted = true;
      for (let index = 0; index < sortedPoints.length; index += 1) {
        sortedPoints[index].weight = convertGramsToPounds(sortedPoints[index].weight);
      }
    }
    // check unit if user will switch them on this page, then it can be needed convert them
    // back to grams
    if (unit === 'g' && item.alreadyConverted === true) {
      item.alreadyConverted = false;
      for (let index = 0; index < sortedPoints.length; index += 1) {
        sortedPoints[index].weight = convertPoundsToGrams(sortedPoints[index].weight);
      }
    }

    if (unit === 'lb') {
      minimumWeightValue = 0.100;
    }
    const useWeeks = this.useWeeks();
    const edit = this.state.edit != null;
    const canEdit = authType.canUpdate(item);
    const { day, weight } = this.state;
    return (
      <Page
        className={bm.b()}
        title={item ? item.name : ''}
        subtitle={<Subtitle bird={item} />}
        actions={this.actions()}
        isFetching={this.props.fetch.isFetching}
        notFound={item.id !== this.props.id}
      >
        <div className={bm.e('content')}>
          <div style={{
            flex: '1', paddingRight: '25px', position: 'sticky', top: '0',
          }}
          >
            <Graphs points={sortedPoints} useWeeks={useWeeks} weightUnit={unit} />
          </div>
          <Table type="data">
            <thead>
              <tr>
                <th>
                  {
                    useWeeks
                      ? <FormattedMessage id="common.week" defaultMessage="Week" />
                      : <FormattedMessage id="common.day" defaultMessage="Day" />
                  }
                </th>
                <th>
                  <FormattedMessage
                    id="common.weight-unit"
                    values={{ unit: <FormattedMessage id={`units.${unit}`} defaultMessage="g" /> }}
                    defaultMessage="Weight({unit})"
                  />
                </th>
                {edit && (
                  <th>
                    <Button color="primary" className="btn-narrow" outline onClick={() => this.setEdit(false)}>
                      <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </Button>
                  </th>
                )}
              </tr>
              {
                canEdit && (
                  <tr>
                    {
                      edit ? (
                        <th colSpan="3">
                          <Button className="btn-narrow" outline onClick={() => this.saveChanges()}>
                            <FormattedMessage id="common.save-changes" defaultMessage="Save Changes" />
                          </Button>
                        </th>
                      ) : (
                        <th colSpan="2">
                          <Button className="btn-narrow" outline onClick={() => this.setEdit(true)}>
                            <FormattedMessage id="breed.edit-points" defaultMessage="Edit Points" />
                          </Button>
                        </th>
                      )
                    }
                  </tr>
                )
              }
              {edit && (
                <tr>
                  <th>
                    <Input
                      autoFocus
                      min={0}
                      max={9999}
                      className="point-input"
                      type="number"
                      value={day}
                      onFocus={e => e.target.select()}
                      onChange={e => this.setState({ day: e.target.value === '' ? '' : parseInt(e.target.value, 10) })}
                      onKeyPress={this.onEnterPress}
                    />
                  </th>
                  <th>
                    <Input
                      min={0}
                      max={unit === 'lb' ? 220 : 99999}
                      className="point-input"
                      type="number"
                      value={weight}
                      onFocus={e => e.target.select()}
                      onChange={e => this.setState({ weight: e.target.value === '' ? '' : unit === 'lb' ? parseFloat(e.target.value) : parseInt(e.target.value, 10) })}
                      onKeyPress={this.onEnterPress}
                    />
                  </th>

                  <th style={{
                    position: 'sticky', backgroundColor: '#ecedf2', verticalAlign: 'middle',
                  }}
                  >
                    <Button
                      color="primary"
                      className="btn-narrow"
                      outline
                      style={{ width: '100%' }}
                      onClick={() => this.savePointAction({ day, weight })}
                      disabled={weight === '' || day === ''}
                    >
                      {
                        sortedPoints.find(f => f.day === (useWeeks ? (day * 7) : day)) != null
                          ? <FormattedMessage id="common.update" defaultMessage="Update" />
                          : <FormattedMessage id="common.add" defaultMessage="Add" />
                      }
                    </Button>
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {sortedPoints.map(point => (
                <tr key={point.day}>
                  <td>
                    {useWeeks ? (
                      <Label>
                        {Math.floor(point.day / 7)}
                        {point.day % 7 === 0 ? null : (<React.Fragment>&nbsp;({point.day}&nbsp;<FormattedMessage id="common.day" defaultMessage="day" />)</React.Fragment>)}
                      </Label>
                    ) : (
                      <Label>
                        {point.day}
                      </Label>
                    )}
                  </td>
                  <td aria-hidden="true">
                    {
                      edit
                        ? (
                          <input
                            className="point-input"
                            type="number"
                            onFocus={e => e.target.select()}
                            value={point.weight}
                            onChange={e => this.editPoint({
                              day: point.day,
                              weight: e.target.value === '' ? '' : unit === 'lb' ? parseFloat(e.target.value) : parseInt(e.target.value, 10),
                            })}
                          />
                        )
                        : <Label>{point.weight}</Label>
                    }
                  </td>
                  {edit && (
                    <td className="action" onClick={() => this.removePoint(point)} aria-hidden="true">
                      <IMTrashBin className="delete" />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Page>
    );
  }
}

Bird.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogEditBird: PropTypes.func.isRequired,
  putBird: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  bird: PropTypes.shape({
    item: PropTypes.object,
  }),
  weightUnit: PropTypes.string,
};

Bird.defaultProps = {
  bird: null,
  weightUnit: null,
};

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    bird: state.bird,
    fetch: state.fetch,
    weightUnit: state.auth.weightUnit,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchData,
  openDialogEditBird,
  putBird,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Bird);
