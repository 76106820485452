import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getPath } from '../utils/queryCache';

const QueryRedirect = ({ to, ...otherParams }) => {
  return <Redirect to={getPath(to)} {...otherParams} />;
};

QueryRedirect.propTypes = {
  to: PropTypes.string.isRequired,
};

export default QueryRedirect;
