import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import DateFormat from '../../components/DateFormat';

export const LastUpdate = ({ lastStatistics, dateFormat }) => {
  if (lastStatistics == null || lastStatistics.length === 0) return null;
  return (
    <Label>
      <DateFormat date={lastStatistics[0].dateTime} format={dateFormat} />
    </Label>
  );
};


LastUpdate.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.number,
    dateTime: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  })),
  dateFormat: PropTypes.string,
};

LastUpdate.defaultProps = {
  lastStatistics: [],
  dateFormat: '',
};

export default LastUpdate;
