import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Input } from '@veit/veit-web-controls';

import SelectLocation from '../../components/SelectLocation';
import FormLabel from '../../components/FormLabel';
import ModalActions, { openDialogAddLocation } from '../../store/ModalLocation.actions';

export function hasStep1Error({
  name, locationId,
}) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 1 }),
    locationId: locationId == null,
  };
}

const Step1 = ({
  modal, locations, updateForm, updateFormEvent, fetchLocations, openDialogAddLocation,
}) => {
  const form = modal.data;
  if (form == null) return null;
  const hasErrors = hasStep1Error(form);
  const addLocationAction = (location) => {
    if (location != null) {
      fetchLocations();
      updateForm(location.id, 'locationId');
    }
  };

  return (
    <React.Fragment>
      <FormLabel required={hasErrors.name}>
        <FormattedMessage id="common.name" defaultMessage="Name" />
      </FormLabel>
      <Input className="form-control" type="text" maxLength="15" value={(form.name || '').toUpperCase()} onChange={e => updateFormEvent(e, 'name')} autoFocus />
      <FormLabel required={hasErrors.locationId}>
        <FormattedMessage id="modals.select-location" defaultMessage="Select Location" />
      </FormLabel>
      <SelectLocation
        locations={locations}
        location={locations.find(f => f.id === form.locationId)}
        onChange={c => updateForm((c || {}).id, 'locationId')}
        addLocationAction={() => openDialogAddLocation().then(addLocationAction)}
      />
    </React.Fragment>
  );
};

Step1.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  openDialogAddLocation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  locations: state.location.modal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
  openDialogAddLocation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
