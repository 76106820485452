import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import bem from '../utils/bem';

const bm = bem.component('label-with-tag');

const LabelWithTag = ({ children, tag, ...otherProps }) => {
  return (
    <Label className={bm.b()} {...otherProps}>
      <span className={bm.e('text')}>
        {children}
      </span>
      {tag && (
        <span className={bm.e('tag')}>
          {tag}
        </span>
      )}
    </Label>
  );
};

LabelWithTag.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.node,
};

LabelWithTag.defaultProps = {
  children: null,
  tag: null,
};

export default LabelWithTag;
