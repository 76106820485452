import rest from './rest.actions';
import { BirdApi } from '@veit/bat-cloud-api/lib/api/BirdApi';

export const BIRD = 'BIRD';
export const GET_BIRD = 'GET_BIRD';
export const GET_BIRD_MODAL = 'GET_BIRD_MODAL';
export const GETBY_BIRD = 'GETBY_BIRD';
export const POST_BIRD = 'POST_BIRD';
export const PUT_BIRD = 'PUT_BIRD';
export const DELETE_BIRD = 'DELETE_BIRD';


export const GET_BIRD_COMPANY = 'GET_BIRD_COMPANY';

const expand = {
  sex: 'sex',
  duration: 'duration',
  scope: 'scope',
  curvePoints: 'curvePoints',
  dateType: 'dateType',
};

export const expandBird = Object.freeze({
  ...expand,
  all: Object.values(expand),
});

export const scopeBird = Object.freeze({
  private: 'private',
  public: 'public',
  all: 'all',
});

const api = new BirdApi();
const restBird = rest(api);

export const getBird = restBird.createGet(GET_BIRD, 'birdGet');
export const getBirdBy = restBird.createGetBy(GETBY_BIRD, 'birdBirdIdGet');
export const postBird = restBird.createPost(POST_BIRD, 'birdPost');
export const putBird = restBird.createPut(PUT_BIRD, 'birdBirdIdPut');
export const deleteBird = restBird.createDelete(DELETE_BIRD, 'birdBirdIdDelete');


export const modalGetBird = restBird.createGet(GET_BIRD_MODAL, 'birdGet');
export const getBirdCompany = restBird.createGet(GET_BIRD_COMPANY, 'birdCompanyGet');
