import { create, run } from '../utils/api';

export const GET_HELP_PAGE = 'GET_HELP_PAGE';
export const GETBY_HELP_PAGE = 'GETBY_HELP_PAGE';
export const POST_HELP_PAGE = 'POST_HELP_PAGE';
export const PUT_HELP_PAGE = 'PUT_HELP_PAGE';
export const DELETE_HELP_PAGE = 'DELETE_HELP_PAGE';

export const PUT_HELP_PAGE_CONTENT = 'PUT_HELP_PAGE_CONTENT';

export const getHelpPage = () => create(GET_HELP_PAGE, () => run('/help'));

export const getHelpPageBy = slug => create(GETBY_HELP_PAGE, () => run(`/help/${slug}`));

export const postHelpPage = page => create(POST_HELP_PAGE, () => run('/help', 'POST', page));

export const putHelpPage = page => create(PUT_HELP_PAGE, () => run(`/help/${page.id}`, 'PUT', page));

export const deleteHelpPage = id => create(DELETE_HELP_PAGE, () => run(`/help/${id}`, 'DELETE'));

export const putHelpPageContent = (id, content) => create(PUT_HELP_PAGE_CONTENT, () => run(`/help/content/${id}`, 'PUT', JSON.stringify(content)));
