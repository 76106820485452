import queryString from 'query-string';

export const cachedPages = [
  '/farms',
  '/flocks',
  '/flocks/scheduled',
  '/flocks/finished',
  '/flocks/all',
  '/scales',
  '/connectors',
  '/farms',
  '/houses',
  '/birds',
  '/birds/public',
  '/users',
  '/locations',
];

export const cachedParams = ['limit', 'orderby', 'order'];

export const filterKeys = { search: 'search' };

const pick = (obj, props) => {
  return Object.keys(obj)
    .filter(key => props.indexOf(key) >= 0)
    .reduce((obj2, key) => {
      return { ...obj2, [key]: obj[key] };
    }, {});
};

const saveQuery = (path, queryParams) => {
  localStorage.setItem(`query:${path}`, JSON.stringify(queryParams));
};

export const loadQuery = (path) => {
  const queryParams = localStorage.getItem(`query:${path}`);
  return queryParams == null ? {} : JSON.parse(queryParams);
};

export const setQueryCache = (path) => {
  if (path == null || path === '') return;
  const [pathPart, queryPart] = path.split('?');
  const queryParams = queryString.parse(queryPart);
  saveQuery(pathPart, pick(queryParams, cachedParams));
};

export const initQueryCache = (path) => {
  if (path == null || path === '') return;
  const [pathPart] = path.split('?');
  if (cachedPages.indexOf(pathPart) >= 0) {
    setQueryCache(path);
  }
};

export const getPath = (path) => {
  const queryParams = loadQuery(path);
  if (queryParams == null || Object.keys(queryParams).length === 0) {
    return path;
  }
  return `${path}?${queryString.stringify(queryParams)}`;
};

const getPathParts = (path) => {
  const parts = { pathPart: '', queryPart: {} };
  if (path == null || path === '') return parts;
  if (path.indexOf('?') === -1) {
    parts.pathPart = path;
    return parts;
  }

  const [pathPart, queryPart] = path.split('?');
  parts.pathPart = pathPart;
  parts.queryPart = queryString.parse(queryPart);
  return parts;
};

export const trimPathQueryParams = (path, paramKeys = []) => {
  const pathParts = getPathParts(path);
  if (paramKeys == null || paramKeys.length === 0) return pathParts.pathPart;
  paramKeys.filter(f => f != null && f !== '').forEach((param) => {
    pathParts.queryPart[param] = null;
  });
  const query = queryString.stringify(pathParts.queryPart, { skipNull: true });
  return query == null || query === '' ? pathParts.pathPart : `${pathParts.pathPart}?${query}`;
};
