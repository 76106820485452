import { fetchAll } from '../../store/Fetch.actions';
import { getUserBy } from '../../store/User.actions';
import { getFarm } from '../../store/Farm.actions';

const fetchData = (id, fetchMetadata) => (dispatch) => {
  fetchAll(() => [
    getUserBy(id)(dispatch),
    fetchMetadata ? getFarm()(dispatch) : null,
  ])(dispatch);
};

export default fetchData;
