import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Label,
} from '@veit/veit-web-controls';

const RegisterResultTemplate = ({ children, close }) => (
  <React.Fragment>
    <center>
      <Label>
        {children}
      </Label>
    </center>
    <Button color="primary" style={{ width: '100%', marginBottom: '40px' }} onClick={close}>
      <FormattedMessage id="common.ok" defaultMessage="Ok" />
    </Button>
  </React.Fragment>
);

RegisterResultTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
};

const ins = (...chunks) => <ins>{chunks}</ins>;

const RegisterResult = ({ code, email, close }) => {
  switch (code) {
    case 201:
      return (
        <RegisterResultTemplate close={close}>
          <FormattedMessage
            values={{ email, ins }}
            id="modals.register.sign-up-successful"
            defaultMessage="Mail with temporary password has been sent to your email <ins>{email}</ins>."
          />
        </RegisterResultTemplate>
      );
    case 412:
      return (
        <RegisterResultTemplate close={close}>
          <FormattedMessage
            values={{ email, ins }}
            id="modals.register.sign-up-exist"
            defaultMessage="User with email address <ins>{email}</ins> already exist!"
          />
        </RegisterResultTemplate>
      );
    default:
      return (
        <RegisterResultTemplate close={close}>
          <FormattedMessage id="modals.register.sign-up-error" defaultMessage="An unexpected error occurred during registration!" />
        </RegisterResultTemplate>
      );
  }
};

RegisterResult.propTypes = {
  code: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  email: PropTypes.string,
};

RegisterResult.defaultProps = {
  email: null,
};

export default RegisterResult;
