import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@veit/veit-web-controls';
import SexFilter from '../../components/filters/SexFilter';
import DropDownWithFilter from '../../components/DropDownWithFilter';
import LabelWithTag from '../../components/LabelWithTag';
import { setQueryParameter, setQueryParameters, clearFilters } from '../../store/Query.actions';
import { flockFilter } from '../../model/filter';

const FlockFilter = ({
  query, farms, birds, setQueryParameter, clearFilters,
}) => {
  const farm = farms.find(f => f.id === query.farm);
  const bird = birds.find(f => f.id === query.bird);

  return (
    <React.Fragment>
      <DropDownWithFilter
        placeholder={<FormattedMessage id="common.bird" defaultMessage="Bird" />}
        items={birds}
        getKey={k => k.id}
        getValue={v => v.name}
        selected={bird}
        onChange={c => setQueryParameter(flockFilter.bird, (c || {}).id)}
        renderItem={i => <LabelWithTag>{i.name}</LabelWithTag>}
      />
      <SexFilter sex={query.sex} onChange={sex => setQueryParameter(flockFilter.sex, sex)} />
      <DropDownWithFilter
        placeholder={<FormattedMessage id="common.farm" defaultMessage="Farm" />}
        items={farms}
        getKey={k => k.id}
        getValue={v => v.name}
        selected={farm}
        onChange={c => setQueryParameter(flockFilter.farm, (c || {}).id)}
        renderItem={i => <LabelWithTag>{i.name}</LabelWithTag>}
      />
      <Button color="link" onClick={() => clearFilters(flockFilter)}>
        <FormattedMessage id="common.clear-all" defaultMessage="Clear All" />
      </Button>
    </React.Fragment>
  );
};

FlockFilter.propTypes = {
  setQueryParameter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  query: PropTypes.shape({
    farm: PropTypes.string,
    bird: PropTypes.string,
    sex: PropTypes.string,
  }),
  farms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  birds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
};

FlockFilter.defaultProps = {
  farms: [],
  birds: [],
  query: {},
};

const mapStateToProps = state => ({
  farms: state.farm.items,
  birds: state.bird.items,
  query: state.query,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setQueryParameters,
  setQueryParameter,
  clearFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FlockFilter);
