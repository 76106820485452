import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalBody,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
} from '@veit/veit-web-controls';

import DateFormat from '../../components/DateFormat';
import Toggle from '../../components/Toggle';
import FormLabel from '../../components/FormLabel';
import arrayUtils from '../../utils/array';
import FileSorting from './FileSorting';

export function sortByLast(a, b) {
  const aValue = Date.parse((a.lastSample || {}).dateTime || '1999-01-01');
  const bValue = Date.parse((b.lastSample || {}).dateTime || '1999-01-01');
  return bValue - aValue;
}

const StepSelectScale = ({
  scales, scale, files, updateFormObject, updateForm, dateFormat,
}) => {
  return (
    <ModalBody style={{ padding: '32px 0' }}>
      <FormLabel required={scale == null}>
        <FormattedMessage id="common.select-scale" defaultMessage="Select scale" />
      </FormLabel>
      <UncontrolledDropdown>
        <DropdownToggle color="primary" outline caret>
          {scale.name || ''}
        </DropdownToggle>
        <DropdownMenu>
          {scales.map(d => (
            <DropdownItem
              key={d.actualLocId}
              onClick={
                () => updateFormObject({ actualLocId: d.actualLocId, files: [] })
              }
            >
              {d.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {/* <Label type="text">
      <FormattedMessage id="common.scale-name" defaultMessage="Files to import" />
    </Label> */}
      <Table type="info">
        <thead>
          <tr>
            <th><Label><FormattedMessage id="common.file" defaultMessage="File" /></Label></th>
            <th><Label><FormattedMessage id="common.last" defaultMessage="Last update" /></Label></th>
            <th><Label><FormattedMessage id="common.count" defaultMessage="Count" /></Label></th>
            <th><Label><FormattedMessage id="common.sorting" defaultMessage="Sorting" /></Label></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {scale.files.filter(f => f.lastSample != null).sort(sortByLast).map(f => (
            <tr key={f.name} onClick={() => updateForm(arrayUtils.toggleByKey(files, f, d => d.name), 'files')}>
              <td><Label>{f.name}</Label></td>
              <td>
                <Label>
                  <DateFormat date={f.lastSample.dateTime} format={dateFormat} />
                </Label>
              </td>
              <td><Label>{f.samplesCount}</Label></td>
              <td><Label><FileSorting config={f.weighingConfig} /></Label></td>
              <td>
                {f.lastSample && (
                  <Toggle isOn={files.find(fi => fi.name === f.name) != null} disabled />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Label type="info">
        <FormattedMessage id="modals.import.select-files" defaultMessage="Choose files to import" />
      </Label>
    </ModalBody>
  );
};

StepSelectScale.propTypes = {
  updateForm: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  scales: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    files: PropTypes.array,
  })),
  scale: PropTypes.shape({
    name: PropTypes.string,
    files: PropTypes.array,
  }),
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  dateFormat: PropTypes.string,
};

StepSelectScale.defaultProps = {
  scales: [],
  scale: null,
  files: [],
  dateFormat: null,
};

export default StepSelectScale;
