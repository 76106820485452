import {
  GET_BIRD,
  GETBY_BIRD,
  GET_BIRD_COMPANY,
  GET_BIRD_MODAL,
} from './Bird.actions';

const initialState = {
  companies: [],
  items: [],
  public: [],
  item: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BIRD: {
      const {
        size, offset, limit, links,
      } = action;
      return {
        ...state, items: action.payload || [], size, offset, limit, links,
      };
    }
    case GETBY_BIRD:
      return { ...state, item: action.payload };
    case GET_BIRD_COMPANY:
      return { ...state, companies: action.payload };
    case GET_BIRD_MODAL:
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};


export default reducer;
