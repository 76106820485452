import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';

import { birdType } from '../types';
import DropDownWithFilter from './DropDownWithFilter';

const SelectBird = ({
  birds, bird, onChange, ...otherProps
}) => {
  return (
    <DropDownWithFilter
      items={birds}
      selected={bird}
      getKey={k => k.id}
      getValue={v => `${v.name} ${v.product} ${v.company}`}
      onChange={onChange}
      placeholder={<FormattedMessage id="common.breed" defaultMessage="Breed" />}
      renderItem={i => (
        <React.Fragment>
          <Label>{i.name}</Label>
          <Label type="info">{i.company}, {i.product}</Label>
        </React.Fragment>
      )}
      {...otherProps}
    />
  );
};

SelectBird.propTypes = {
  onChange: PropTypes.func.isRequired,
  bird: birdType,
  birds: PropTypes.arrayOf(birdType),
};


SelectBird.defaultProps = {
  birds: [],
  bird: null,
};

export default SelectBird;
