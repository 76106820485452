import rest from './rest.actions';
import { FileApi } from '@veit/bat-cloud-api/lib/api/FileApi';

export const FILE = 'FILE';
export const GET_FILE = 'GET_FILE';
export const GET_FILE_MODAL = 'GET_FILE_MODAL';
export const GETBY_FILE = 'GETBY_FILE';
export const POST_FILE = 'POST_FILE';
export const PUT_FILE = 'PUT_FILE';
export const DELETE_FILE = 'DELETE_FILE';

const expand = {
  farm: 'farm',
  location: 'location',
};

export const expandFile = Object.freeze({
  ...expand,
  all: Object.values(expand),
});

const restFile = rest(new FileApi());

export const getFile = restFile.createGet(GET_FILE, 'fileGet');
export const getFileBy = restFile.createGetBy(GETBY_FILE, 'fileFileIdGet');
export const postFile = restFile.createPost(POST_FILE, 'filePost');
export const putFile = restFile.createPut(PUT_FILE, 'fileFileIdPut');
export const deleteFile = restFile.createDelete(DELETE_FILE, 'fileFileIdDelete');

export const modalGetFile = restFile.createGet(GET_FILE_MODAL, 'fileGet');
