import * as ModalActions from './Modal.actions';
import {
  deleteFarm, putFarm, postFarm, DELETE_FARM,
} from './Farm.actions';

export const ADD_FARM = 'ADD_FARM';
export const EDIT_FARM = 'EDIT_FARM';

// flock actions
export const openDialogAddFarm = data => (dispatch) => {
  return ModalActions.openDialog(ADD_FARM, data)(dispatch);
};

export const openDialogEditFarm = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_FARM, data)(dispatch);
};

export const finishDialogAddFarm = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postFarm(data), ADD_FARM)(dispatch);
};

export const finishDialogEditFarm = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putFarm(data.id, data), EDIT_FARM)(dispatch);
};

export const deleteDialogFarm = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteFarm(id), DELETE_FARM, { delete: true })(dispatch);
};

export default ModalActions;
