import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import bem from '../utils/bem';

const bm = bem.component('tooltip');

export const TooltipItem = ({ children, ...props }) => (
  <Label className="tooltiptext-item" {...props}>
    <div>&#8226;</div>
    <div>{children}</div>
  </Label>
);

TooltipItem.propTypes = {
  children: PropTypes.node,
};

TooltipItem.defaultProps = {
  children: null,
};

const Tooltip = ({ text, children, disabled }) => {
  return disabled ? children : (
    <div className={bm.b()}>
      {children}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.node.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

Tooltip.defaultProps = {
  children: null,
  disabled: false,
};

export default Tooltip;
