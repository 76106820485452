import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { OPEN_DIALOG } from '../store/Modal.actions';
import { ADD_BIRD, EDIT_BIRD } from '../store/ModalBird.actions';
import { EDIT_COMPANY } from '../store/ModalCompany.actions';
import { ADD_DEVICE, EDIT_DEVICE } from '../store/ModalDevice.actions';
import { ADD_FARM, EDIT_FARM } from '../store/ModalFarm.actions';
import { SEND_FEEDBACK } from '../store/ModalFeedback.actions';
import { SEND_FEATURE_REQUEST } from '../store/ModalFeature.actions';
import { ADD_FLOCK, EDIT_FLOCK, CLOSE_FLOCK } from '../store/ModalFlock.actions';
import { ADD_LOCATION, EDIT_LOCATION } from '../store/ModalLocation.actions';
import { INVITE_USER, EDIT_USER, REGISTER_USER } from '../store/ModalUser.actions';
import { EDIT_PROFILE } from '../store/ModalProfile.actions';
import { ADD_HELP_PAGE, EDIT_HELP_PAGE } from '../store/ModalHelpPage.actions';
import { SEND_GATEWAY_REQUEST } from '../store/ModalGateway.actions';
import { EXPORT_STATS } from '../store/ModalExportStats.actions';
import { SHOW_HISTOGRAM } from '../store/ModalHistogram.actions';
import { SHOW_FILE, ADD_FILE, EDIT_FILE } from '../store/ModalFile.actions';
import { IMPORT, IMPORT_DATA, IMPORT_FILE } from '../store/ModalImport.actions';
import { ADD_COMPARE, EDIT_COMPARE } from '../store/ModalCompare.actions';
import { SHOW_WEIGHING } from '../store/ModalWeighing.actions';
import { ADD_LORA_DEVICE, EDIT_LORA_DEVICE, ADD_LORA_SCALE } from '../store/ModalAdmin.actions';


import Modals from '../modals/index';

const getModalWindow = (modal) => {
  if (modal == null || modal.type !== OPEN_DIALOG) return null;
  switch (modal.target) {
    case ADD_BIRD:
      return Modals.AddBirdModal;
    case EDIT_BIRD:
      return Modals.EditBirdModal;
    case EDIT_COMPANY:
      return Modals.EditComapnyModal;
    case ADD_DEVICE:
      return Modals.AddDeviceModal;
    case EDIT_DEVICE:
      return Modals.EditDeviceModal;
    case ADD_FARM:
      return Modals.AddFarmModal;
    case EDIT_FARM:
      return Modals.EditFarmModal;
    case SEND_FEEDBACK:
      return Modals.FeedbackModal;
    case SEND_FEATURE_REQUEST:
      return Modals.FeatureRequestModal;
    case ADD_FLOCK:
      return Modals.AddFlockModal;
    case EDIT_FLOCK:
      return Modals.EditFlockModal;
    case CLOSE_FLOCK:
      return Modals.CloseFlockDialog;
    case ADD_LOCATION:
      return Modals.AddLocationModal;
    case EDIT_LOCATION:
      return Modals.EditLocationModal;
    case EDIT_PROFILE:
      return Modals.EditProfileModal;
    case INVITE_USER:
      return Modals.InviteUserModal;
    case EDIT_USER:
      return Modals.EditUserModal;
    case REGISTER_USER:
      return Modals.RegisterUserModal;
    case ADD_HELP_PAGE:
      return Modals.AddHelpPageModal;
    case EDIT_HELP_PAGE:
      return Modals.EditHelpPageModal;
    case SEND_GATEWAY_REQUEST:
      return Modals.GatewayRequestModal;
    case EXPORT_STATS:
      return Modals.ExportStatsModal;
    case SHOW_HISTOGRAM:
      return Modals.HistogramModal;
    case SHOW_FILE:
      return Modals.FileModal;
    case ADD_FILE:
      return Modals.AddFileModal;
    case EDIT_FILE:
      return Modals.EditFileModal;
    case IMPORT_DATA:
      return Modals.ImportMemoryModal;
    case IMPORT_FILE:
      return Modals.ImportFileModal;
    case IMPORT:
      return Modals.ImportModal;
    case ADD_COMPARE:
      return Modals.AddCompareModal;
    case EDIT_COMPARE:
      return Modals.EditCompareModal;
    case SHOW_WEIGHING:
      return Modals.WeighingModal;
    case ADD_LORA_DEVICE:
      return Modals.AddLoraDevice;
    case EDIT_LORA_DEVICE:
      return Modals.EditLoraDevice;
    case ADD_LORA_SCALE:
      return Modals.AddLoraScale;
    default:
      return null;
  }
};

const ModalWindow = (props) => {
  const ModalComponent = getModalWindow(props.modal);
  return ModalComponent ? <ModalComponent modal={props.modal} /> : null;
};

ModalWindow.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
};

const ModalContainer = ({ modal }) => {
  return (
    <React.Fragment>
      {modal.map(m => <ModalWindow key={m.target} modal={m} />)}
    </React.Fragment>
  );
};

ModalContainer.propTypes = {
  modal: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
  })).isRequired,
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

export default connect(mapStateToProps)(ModalContainer);
