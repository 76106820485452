import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Input,
  Label,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import TimeInput, { timeUnits } from '../../components/TimeInput';
import DateInput from '../../components/DateInput';
import SelectLocation from '../../components/SelectLocation';
import FormLabel from '../../components/FormLabel';
import ModalActions from '../../store/ModalFlock.actions';
import { farmType, locationType } from '../../types';

export function hasStep1Error({
  name, initialAge, locationId,
}) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 1 }),
    initialAge: initialAge == null || !validator.isInt(`${initialAge}`, { min: 0 }),
    locationId: locationId == null,
  };
}

const timeRound = (value, unit) => {
  const days = Number.parseFloat(value);
  return unit !== timeUnits.weeks || Number.isNaN(days) ? value : days - (days % 7);
};

const Step2 = ({
  updateForm,
  updateFormEvent,
  updateFormObject,
  modal,
  locations,
  farms,
  addLocationAction,
  dateFormat,
}) => {
  const form = modal.data;
  if (form == null) return null;
  const errors = hasStep1Error(form);
  const edit = form.id != null;
  const location = locations.find(f => f.id === form.locationId);

  return (
    <React.Fragment>
      <FormLabel required={errors.name}>
        <FormattedMessage id="flocks.modal.flock-name" defaultMessage="Flock name/batch" />
      </FormLabel>
      <Input value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} autoFocus />
      {!edit && (
        <Label type="info">
          <FormattedMessage id="flocks.modal.flock-name-info" defaultMessage="Name your new batch" />
        </Label>
      )}
      <Label type="text">
        <FormattedMessage id="flocks.modal.start-date" defaultMessage="Start date" />
      </Label>
      <DateInput value={new Date(form.startDate)} dateFormat={dateFormat} onChange={date => updateForm(date, 'startDate')} />
      {!edit && (
        <Label type="info">
          <FormattedMessage id="flocks.modal.start-date-info" defaultMessage="Date from which you start measure your flock" />
        </Label>
      )}

      {
        form.endDate && (
          <React.Fragment>
            <Label type="text">
              <FormattedMessage id="flocks.modal.end-date" defaultMessage="End date" />
            </Label>
            <DateInput value={new Date(form.endDate)} dateFormat={dateFormat} onChange={date => updateForm(date, 'endDate')} />
          </React.Fragment>
        )
      }

      <FormLabel required={errors.initialAge}>
        <FormattedMessage id="flocks.modal.initial-age" defaultMessage="Initial age" />
      </FormLabel>
      <InputGroup>
        <TimeInput
          min={0}
          type="number"
          value={form.initialAge != null ? form.initialAge : ''}
          timeUnit={form.dayUnit}
          onChange={e => updateFormEvent(e, 'initialAge')}
        />
        <UncontrolledDropdown addonType="append">
          <DropdownToggle caret color="primary" outline className="form-control">
            {
              form.dayUnit === timeUnits.weeks
                ? <FormattedMessage id="common.weeks" defaultMessage="weeks" />
                : <FormattedMessage id="common.days" defaultMessage="days" />
            }
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => updateFormObject({
                dayUnit: timeUnits.weeks,
                initialAge: timeRound(form.initialAge, timeUnits.weeks),
              })}
            >
              <FormattedMessage id="common.weeks" defaultMessage="weeks" />
            </DropdownItem>
            <DropdownItem onClick={() => updateForm(timeUnits.days, 'dayUnit')}>
              <FormattedMessage id="common.days" defaultMessage="days" />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </InputGroup>
      {!edit && !errors.initialAge && (
        <Label type="info">
          <FormattedMessage id="flocks.modal.initial-age-info" defaultMessage="How old is your flock" />
        </Label>
      )}
      {errors.initialAge && (
        <Label type="error">
          <FormattedMessage id="flocks.modal.initial-age-error" defaultMessage="Minimal value is 0" />
        </Label>
      )}
      <FormLabel required={errors.locationId}>
        <FormattedMessage id="common.location" defaultMessage="Location" />
      </FormLabel>
      <SelectLocation
        locations={locations}
        farms={farms}
        location={location}
        disabled={edit}
        onChange={l => updateForm((l || {}).id, 'locationId')}
        addLocationAction={addLocationAction}
      />
    </React.Fragment>
  );
};

Step2.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
    errors: PropTypes.object,
    base: PropTypes.object,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  addLocationAction: PropTypes.func,
  locations: PropTypes.arrayOf(locationType),
  farms: PropTypes.arrayOf(farmType),
  dateFormat: PropTypes.string,
};

Step2.defaultProps = {
  locations: [],
  farms: [],
  addLocationAction: null,
  dateFormat: null,
};

const mapStateToProps = (state) => {
  return {
    locations: state.location.modal,
    farms: state.farm.modal,
    dateFormat: state.auth.dateFormat,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
  updateFormObject: ModalActions.updateFormObject,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Step2);
