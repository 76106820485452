import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import IMErrorMark from '@veit/veit-web-controls/dist/icons/IMErrorMark';
import { Label } from '@veit/veit-web-controls';
import Tooltip from '../../components/Tooltip';
import bem from '../../utils/bem';

import Location from './Location';

const bm = bem.component('location-link');

const LocationLink = ({
  location, farm, to, showWarning,
}) => {
  if (location == null) return null;
  const loc = <Location location={location} farm={farm} />;
  return (
    <div className={bm.b(location.deleted ? 'deleted' : null)}>
      {
        location.deleted ? (
          <React.Fragment>
            <Tooltip
              text={(
                <Label>
                  <FormattedMessage id="location.location-deleted" defaultMessage="Location has been deleted" />
                </Label>
              )}
            >
              {showWarning && (<IMErrorMark className={bm.e('icon')} />)}
            </Tooltip>
            {loc}
          </React.Fragment>
        ) : (
          <Link to={to}>
            {loc}
          </Link>
        )
      }
    </div>
  );
};

LocationLink.propTypes = {
  to: PropTypes.string.isRequired,
  showWarning: PropTypes.bool,
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    deleted: PropTypes.instanceOf(Date),
  }),
  farm: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    country: PropTypes.string,
  }),
};

LocationLink.defaultProps = {
  showWarning: false,
  location: null,
  farm: null,
};

export default LocationLink;
