import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import auth from '../../utils/auth';
import Loader from '../../components/Loader';
import QueryRedirect from '../../components/QueryRedirect';

class Tokens extends Component {
  componentDidMount() {
    auth.parseTokens(window.location.href);
  }

  render() {
    return this.props.isAuthenticated
      ? <QueryRedirect to="/locations" />
      : <Loader />;
  }
}

Tokens.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Tokens);
