import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { sexType } from '../model/enums';

const SexName = ({ sex }) => {
  switch (sex) {
    case sexType.male: return <FormattedMessage id="common.sex-male" defaultMessage="Male" />;
    case sexType.female: return <FormattedMessage id="common.sex-female" defaultMessage="Female" />;
    case sexType.mixed: return <FormattedMessage id="common.sex-mixed" defaultMessage="Mixed" />;
    case sexType.irrelevant: return <FormattedMessage id="common.sex-irrelevant" defaultMessage="Irrelevant" />;
    default: return null;
  }
};

SexName.propTypes = {
  sex: PropTypes.string,
};

SexName.defaultProps = {
  sex: null,
};

export default SexName;
