import { fetchAll } from '../../store/Fetch.actions';
import { getFlockBy, expandFlock } from '../../store/Flock.actions';
import { getLocationBy, expandLocation } from '../../store/Location.actions';
import { getStatsFlockBy } from '../../store/Stats.actions';
import { getBirdBy, expandBird } from '../../store/Bird.actions';
import { loadQuery } from '../../utils/queryCache';

export default function (id, fetchAllFlocks, scope) {
  const queryParams = loadQuery(scope === 'active' ? '/flocks' : `/flocks/${scope}`);
  return (dispatch) => {
    fetchAll(() => [getFlockBy(id, {
      expand: expandFlock.all,
      scope: scope || 'active',
      order: queryParams.order,
      orderby: queryParams.orderby,
    })(dispatch).then(async (r) => {
      if (r != null) {
        await Promise.all([
          r.locationId
            ? getLocationBy(r.locationId, { expand: expandLocation.farm })(dispatch)
            : null,
          r.birdId
            ? getBirdBy(r.birdId, { expand: expandBird.curvePoints })(dispatch)
            : null,
        ]);
      }
    }),
    getStatsFlockBy(id)(dispatch),
    ])(dispatch);
  };
}
