import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { StatusIcon } from '@veit/veit-web-controls';
import Tooltip from '../../components/Tooltip';

function isGrowing(stats) {
  return stats.length === 0
    ? false
    : stats.map(m => m.gain).reduce((current, value) => current && value > 0, true);
}

const GrowthStatus = ({ lastStatistics }) => {
  const stats = (lastStatistics || []).filter(f => f != null);
  const growing = isGrowing(stats);
  const tooltip = stats.length === 0
    ? <FormattedMessage id="common.no-data-yet" defaultMessage="No data yet." />
    : growing
      ? <FormattedMessage id="flocks.flock-is-growing" defaultMessage="Flock is growing." />
      : <FormattedMessage id="flocks.flock-is-not-growing" defaultMessage="Flock is not growing." />;

  return (
    <Tooltip text={tooltip}>
      <StatusIcon hasError={!growing} />
    </Tooltip>
  );
};

GrowthStatus.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    gain: PropTypes.number,
  })),
};

GrowthStatus.defaultProps = {
  lastStatistics: null,
};

export default GrowthStatus;
