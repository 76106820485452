import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginRedirect } from '../../store/Auth.actions';
import QueryRedirect from '../../components/QueryRedirect';


class Login extends Component {
  login = () => {
    this.props.loginRedirect();
    return 'Redirecting';
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.isAuthenticated
            ? <QueryRedirect to="/locations" />
            : this.login()
        }
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  loginRedirect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

Login.defaultProps = {
  isAuthenticated: false,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  loginRedirect,
}, dispatch);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
