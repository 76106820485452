import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
} from '@veit/veit-web-controls';

import ModalActions, { finishDialogEditFarm, deleteDialogFarm } from '../../store/ModalFarm.actions';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';
import Step2 from './Step2';

class EditFarmModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditFarm(this.props.modal.data);
  }

  delete = () => {
    this.props.deleteDialogFarm(this.props.modal.data.id);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-fluid"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="farms.edit-farm" defaultMessage="Edit Farm" />
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <Step1 modal={this.props.modal} />
              </Col>
              <Col>
                <Step2 modal={this.props.modal} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          deleteDialog={{ action: this.delete, name: 'Farm', item: this.props.modal.data }}
          modal={this.props.modal}
          validators={{ 1: hasStep1Error }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogEditFarm,
  deleteDialogFarm,
}, dispatch);

EditFarmModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogEditFarm: PropTypes.func.isRequired,
  deleteDialogFarm: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditFarmModal);
