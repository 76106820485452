import {
  GETBY_DEVICE_STATS,
  GETBY_FLOCK_STATS,
  GETBY_LOCATION_STATS,
  GETBY_SUBCATEGORY_STATS,
} from './Stats.actions';

const initialState = {
  item: null,
  subcategory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GETBY_DEVICE_STATS:
    case GETBY_FLOCK_STATS:
    case GETBY_LOCATION_STATS:
      return { ...state, item: action.payload };
    case GETBY_SUBCATEGORY_STATS:
      return { ...state, subcategory: action.payload };
    default:
      return state;
  }
};


export default reducer;
