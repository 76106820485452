export function roundTo(num, places = 2) {
  if (num == null) return null;
  const value = Math.round(`${num}e+${places}`);
  return +(`${value}e-${places}`);
}

const Round = ({ value, decimals }) => {
  return roundTo(value, decimals);
};

export default Round;
