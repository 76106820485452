import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  Input,
  ButtonSwitch,
} from '@veit/veit-web-controls';

import SelectBird from '../../components/SelectBird';
import DropDownInput from '../../components/DropDownInput';
import FormLabel from '../../components/FormLabel';
import ModalActions from '../../store/ModalFlock.actions';
import { getBirdBy, expandBird } from '../../store/Bird.actions';
import { roleType } from '../../model/enums';

export function hasStep1Error({ name, company, product }) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 3 }),
    company: company == null || !validator.isLength(validator.trim(company), { min: 3 }),
    product: product == null || !validator.isLength(validator.trim(product), { min: 3 }),
  };
}

const clearTemplate = {
  name: '', product: '', company: '', birdId: null, curvePoints: null, sex: null,
};

const Step1 = ({
  modal, bird, updateFormObject, updateFormEvent, edit, getBirdBy, user,
}) => {
  const form = modal.data;
  const birds = bird.modal;
  if (form == null || birds == null) return null;
  const { product, company } = form;
  const companies = birds.map(b => b.company).filter((v, i, a) => a.indexOf(v) === i);
  const companyProducts = company ? birds.filter(b => b.company === company)
    .map(b => b.product).filter((v, i, a) => a.indexOf(v) === i) : [];

  const template = birds.find(f => f.id === form.birdId);
  const hasErrors = hasStep1Error(form);

  const copyBird = (t) => {
    if (t == null) {
      updateFormObject(clearTemplate);
      return;
    }
    getBirdBy(t.id, { expand: expandBird.curvePoints })
      .then(b => updateFormObject({
        ...t,
        birdId: t.id,
        curvePoints: b.curvePoints,
      }));
  };

  return (
    <React.Fragment>
      {!edit && (
        <React.Fragment>
          <Label type="text">
            <FormattedMessage id="breed.modal.select-breed" defaultMessage="Select breed" />
          </Label>
          <SelectBird
            birds={birds}
            bird={template}
            onChange={b => copyBird(b)}
          />
          {!edit && (
            <Label type="info">
              <FormattedMessage id="breed.modal.from-company" defaultMessage="Create copy of selected breed" />
            </Label>
          )}
        </React.Fragment>
      )}

      {roleType.isAdmin(user.role) && (
        <React.Fragment>
          <Label type="text">Scope</Label>
          <ButtonSwitch
            options={[{ title: 'Private', value: 'private' }, { title: 'Global', value: 'global' }]}
            onChange={v => updateFormObject({ scope: v })}
            selected={form.scope || 'private'}
          />
        </React.Fragment>
      )}

      <FormLabel required={hasErrors.name}>
        <FormattedMessage id="breed.modal.breed-name" defaultMessage="Breed name" />
      </FormLabel>
      <Input value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} />

      <FormLabel required={hasErrors.company}>
        <FormattedMessage id="common.company" defaultMessage="Company" />
      </FormLabel>
      <DropDownInput value={company} values={companies} onChange={c => updateFormObject({ company: c, product: null })} placeholder="Company" />
      {!edit && (
        <Label type="info">
          <FormattedMessage id="breed.modal.from-company" defaultMessage="From which company is your breed" />
        </Label>
      )}
      <FormLabel required={hasErrors.product}>
        <FormattedMessage id="common.product" defaultMessage="Product" />
      </FormLabel>
      <DropDownInput value={product} values={companyProducts} onChange={c => updateFormObject({ product: c })} placeholder="Product" />
      {!edit && (
        <Label type="info">
          <FormattedMessage id="breed.modal.product-line" defaultMessage="Breed product line" />
        </Label>
      )}
    </React.Fragment>
  );
};

Step1.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  bird: PropTypes.shape({
    modal: PropTypes.array,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  getBirdBy: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

Step1.defaultProps = {
  disabled: false,
  edit: false,
};

const mapStateToProps = (state) => {
  return {
    bird: state.bird,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormObject: ModalActions.updateFormObject,
  updateFormEvent: ModalActions.updateFormEvent,
  getBirdBy,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Step1);
