import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import PageError from './PageError';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  componentDidMount() {
    this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <PageError />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }).isRequired,
};

ErrorBoundary.defaultProps = {
  children: null,
};

export default withRouter(ErrorBoundary);
