import React from 'react';
import Icon from 'react-icon-base';

const BatIcon = props => (
  <Icon viewBox="0 0 254 72" {...props}>
    <g>
      <path d="M0,0.003h50.439c24.255,0,29.413,10.69,29.413,18.05c0,7.262-4.512,11.191-11.351,14.117 c8.254,2.216,15.255,7.363,15.255,17.948c0,14.415-15.255,21.879-31.51,21.879H0V0.003z M28.384,28.038h14.958 c5.94,0,9.683-1.313,9.683-5.949c0-3.633-3.873-4.939-9.683-4.939H28.384V28.038z M28.384,54.86h16.635 c5.94,0,11.097-1.316,11.097-6.86c0-4.232-3.221-6.558-9.928-6.558H28.384V54.86z M199.811,21.832 174.011,21.832 174.011,0 254,0 254,21.832 228.196,21.832 228.196,71.996 199.811,71.996 M119.01,0.003 150.86,0.003 190.665,71.996 158.814,71.996 M120.328,72 149.55,72 134.941,45.565 M114.383,8.379 79.209,72 111.06,72 130.306,37.181" />
    </g>
  </Icon>
);

export default BatIcon;
