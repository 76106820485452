import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { deviceType } from '../../model/enums';
import SubtitleItem from '../../components/SubtitleItem';

const PhoneNumberSubtitle = ({ device }) => {
  return device && device.type === deviceType.bat2Gsm && (
    <SubtitleItem title={<FormattedMessage id="common.phone-number" defaultMessage="Phone number" />}>
      {device.phoneNumber}
    </SubtitleItem>
  );
};

PhoneNumberSubtitle.propTypes = {
  device: PropTypes.shape({
    type: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};

PhoneNumberSubtitle.defaultProps = {
  device: null,
};

export default PhoneNumberSubtitle;
