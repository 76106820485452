import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import { changeDateFormat } from '../store/Auth.actions';
import bem from '../utils/bem';

const dateFormats = [
  'DD.MM.YYYY',
  'MM/DD/YYYY',
  'YYYY.MM.DD',
  'YYYY.DD.MM',
];

const component = bem.component('date-format-selector');

export const DateFormatSelector = ({
  auth, changeDateFormat, className, noStyle,
}) => {
  return (
    <UncontrolledDropdown className={noStyle ? className : component.b(className)}>
      <DropdownToggle color="primary" outline caret>
        {dateFormats.includes(auth.dateFormat) ? auth.dateFormat : dateFormats[0]}
      </DropdownToggle>
      <DropdownMenu>
        {dateFormats.map((item) => {
          return (
            <DropdownItem key={item} onClick={() => changeDateFormat(item, auth.isAuthenticated)}>
              {item}
            </DropdownItem>
          );
        })
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

DateFormatSelector.propTypes = {
  changeDateFormat: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    lang: PropTypes.string,
    dateFormat: PropTypes.string,
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  noStyle: PropTypes.bool,
};

DateFormatSelector.defaultProps = {
  className: null,
  noStyle: false,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeDateFormat,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DateFormatSelector);
