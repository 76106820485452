import {
  GET_LOCATION,
  GETBY_LOCATION,
  PUT_LOCATION,
  GET_LOCATION_MODAL,
} from './Location.actions';

const initialState = {
  items: [],
  modal: [],
  item: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION: {
      const {
        size, offset, limit, links,
      } = action;
      return {
        ...state, items: action.payload || [], size, offset, limit, links,
      };
    }
    case GETBY_LOCATION:
      return { ...state, item: action.payload };
    case PUT_LOCATION:
      return {
        ...state,
        item: action.payload,
        items: state.items.map(f => (f.id === action.payload.id ? action.payload : f)),
      };
    case GET_LOCATION_MODAL:
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};


export default reducer;
