import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Nav,
  NavItem,
  Collapse,
  NavLink as BSNavLink,
} from '@veit/veit-web-controls';

import {
  IMBird,
  IMSettings,
  IMGuide,
  IMFeedback,
  IMCloudUpload,
  IMAnalytics,
  IMHome,
} from '@veit/veit-web-controls/dist/icons';

import BatIcon from '../BatIcon';
import { openDialogSendFeedback } from '../../store/ModalFeedback.actions';
import { openDialogImport } from '../../store/ModalImport.actions';
import { scopeFlock } from '../../store/Flock.actions';
import { roleType } from '../../model/enums';
import bm from '../../utils/bem';
import { getPath } from '../../utils/queryCache';
import settings from '../../settings';
// import { name } from 'faker/lib/locales/az';
// import id from 'faker/lib/locales/id_ID';

const nav = [
  {
    path: '^/locations',
    to: '/locations',
    name: 'Locations',
    id: 'common.locations',
    Icon: IMHome,
    subNav: [],
  },
  {
    path: '^/flock',
    to: '/flocks',
    name: 'Flocks',
    id: 'common.flocks',
    Icon: IMBird,
    subNav: [
      {
        path: '^/flocks*/*((?!finished|scheduled|all).)*$', to: '/flocks', name: 'Active', id: 'common.active',
      },
      {
        path: `^/flocks*/${scopeFlock.scheduled}`, to: `/flocks/${scopeFlock.scheduled}`, name: 'Scheduled', id: 'common.scheduled',
      },
      {
        path: `^/flocks*/${scopeFlock.finished}`, to: `/flocks/${scopeFlock.finished}`, name: 'Finished', id: 'common.finished',
      },
      {
        path: `^/flocks*/${scopeFlock.all}`, to: `/flocks/${scopeFlock.all}`, name: 'All', id: 'common.all',
      },
    ],
  },
  {
    path: '^/compare',
    to: '/compare',
    name: 'Analytics',
    id: 'common.analytics',
    Icon: IMAnalytics,
    subNav: [],
  },
  {
    path: '^/farm',
    to: '/farms',
    name: 'Manager',
    id: 'common.manager',
    Icon: IMSettings,
    subNav: [
      {
        path: '^/farm', to: '/farms', name: 'Farms', id: 'common.farms',
      },
      {
        path: '^/scale',
        to: '/scales',
        name: 'Scales',
        id: 'common.scales',
        thirdNav: [
          {
            path: '^/scale', to: '/scales', name: 'BAT2', id: 'common.bat2',
          },
          {
            path: '^/bat1', to: '/bat1', name: 'BAT1', id: 'common.bat1',
          },
        ],
      },
      // {
      //   path: '^/bat1', to: '/bat1', name: 'BAT1', id: 'common.bat1',
      // },
      {
        path: '^/bird', to: '/birds', name: 'Breeds', id: 'common.breeds',
      },
      {
        path: '^/user', to: '/users', name: 'Users', id: 'common.users',
      },
    ],
  },
];

const botNav = [
  {
    path: '/import-data', to: '/import-data', name: 'Import data', Icon: IMCloudUpload, action: props => props.openDialogImport(), id: 'common.import-data',
  },
  {
    path: '/help', to: '/help', name: 'Help', Icon: IMGuide, action: () => window.open(settings.help.cloud, '_blank'), id: 'common.help',
  },
  {
    path: '/feedback', to: '/feedback', name: 'Feedback', Icon: IMFeedback, action: props => props.openDialogSendFeedback(), id: 'common.feedback',
  },
];

const bem = bm.component('sidebar');

class Sidebar extends Component {
  match = (path) => {
    // return this.props.location.pathname.startsWith(path);
    return new RegExp(path).test(this.props.location.pathname);
  }

  isActive = (location) => {
    return (location.exact
      ? this.props.location.pathname === location.path
      : this.match(location.path))
      || (location.subNav && location.subNav.find(f => this.isActive(f)) != null)
      || (location.thirdNav && location.thirdNav.find(f => this.isActive(f)) != null);
  }

  filter = (link) => {
    const user = this.props.auth.user || {};
    return link.admin ? roleType.isAdmin(user.role) : true;
  }

  mapItems = (items) => {
    return items.filter(this.filter).map((n) => {
      const { Icon, subNav } = n;
      return (
        <React.Fragment key={n.to}>
          <NavItem
            onClick={n.action ? () => n.action(this.props) : null}
            className={bem.e('nav-item', n.subNav ? 'nav-item-simple' : null)}
            active={this.isActive(n, subNav != null)}
          >
            <BSNavLink tag={n.action ? 'div' : props => <NavLink {...props} />} className={bem.e('nav-item-collapse')} to={getPath(n.to)}>
              <div className="d-flex">
                <Icon className={bem.e('nav-item-icon')} />
                <span className=" align-self-start">
                  <FormattedMessage id={n.id} defaultMessage={n.name} />
                </span>
              </div>
            </BSNavLink>
          </NavItem>
          {subNav && ( // 2nd level of nesting
            <Collapse isOpen={this.isActive(n)}>
              {
                subNav.filter(this.filter).map(s => (
                  <>
                    <NavItem
                      key={s.to}
                      className={bem.e('nav-item', 'nav-item-sub', s.thirdNav ? 'nav-item-sub-simple' : null)}
                      active={this.isActive(s)}
                    >
                      <BSNavLink tag={props => <NavLink {...props} />} to={getPath(s.to)}>
                        <span>
                          <FormattedMessage id={s.id} defaultMessage={s.name} />
                        </span>
                      </BSNavLink>
                    </NavItem>
                    {s.thirdNav && ( // third level of menu nesting
                      <Collapse isOpen={this.isActive(s)}>
                        {
                          s.thirdNav.filter(this.filter).map(m => (
                            <NavItem
                              key={m.to}
                              className={bem.e('nav-item', 'nav-item-third')}
                              active={this.isActive(m)}
                            >
                              <BSNavLink tag={props => <NavLink {...props} />} to={getPath(m.to)}>
                                <span>
                                  {/* -&nbsp;&nbsp;&nbsp; */}
                                  <FormattedMessage id={m.id} defaultMessage={m.name} />
                                </span>
                              </BSNavLink>
                            </NavItem>
                          ))}
                      </Collapse>
                    )
                  }
                  </>
                ))}
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <div className={bem.b()}>
        <div className="bwc-sidebar__content">
          <BatIcon className={bem.e('logo')} />
          <Nav vertical>
            {
              this.mapItems(nav)
            }
          </Nav>
          <div style={{ flex: 1 }}></div>
          <Nav vertical>
            {
              this.mapItems(botNav)
            }
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  show: PropTypes.bool,
};

Sidebar.defaultProps = {
  show: true,
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  openDialogSendFeedback,
  openDialogImport,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
