import { ApiClient, UserApi } from '@veit/bat-cloud-api/lib/index';

import auth from '../utils/auth';
import rest from './rest.actions';

export const LOGIN = 'LOGIN';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';
export const LOGOUT_REDIRECT = 'LOGOUT_REDIRECT';
export const REFRESH = 'REFRESH';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_WEIGHT_UNIT = 'CHANGE_WEIGHT_UNIT';
export const CHANGE_DATE_FORMAT = 'CHANGE_DATE_FORMAT';

function updateApiHeader() {
  const session = auth.getCachedSession();

  const accessToken = auth.isUserSignedIn() ? session.getAccessToken().getJwtToken() : '';
  const refreshToken = session.getRefreshToken().getToken();

  ApiClient.instance.authentications.OAuth2.accessToken = accessToken;

  return {
    accessToken,
    refreshToken,
  };
}

updateApiHeader();

const userApi = new UserApi();
const restUser = rest(userApi);

export const loginRedirect = () => async (dispatch) => {
  dispatch({ type: LOGIN_REDIRECT });
  auth.signIn();
};

export const logoutRedirect = () => async (dispatch) => {
  dispatch({ type: LOGOUT_REDIRECT, payload: updateApiHeader() });
  auth.signOut();
};

export const login = () => (dispatch) => {
  dispatch({ type: LOGIN, payload: updateApiHeader() });
};

export const refresh = () => async (dispatch) => {
  dispatch({ type: REFRESH, payload: updateApiHeader() });
};

export const changeLanguage = (language, isAuthenticated) => (dispatch) => {
  dispatch({ type: CHANGE_LANG, payload: language });
  if (isAuthenticated) {
    userApi.userCurrentLocaleLocalePut(language);
  }
};

export const changeWeightUnit = (weightUnit, isAuthenticated) => (dispatch) => {
  dispatch({ type: CHANGE_WEIGHT_UNIT, payload: weightUnit });
  if (isAuthenticated) {
    userApi.userCurrentWeightUnitWeightUnitPut(weightUnit);
  }
};

export const changeDateFormat = (dateFormat, isAuthenticated) => (dispatch) => {
  dispatch({ type: CHANGE_DATE_FORMAT, payload: dateFormat });
  if (isAuthenticated) {
    userApi.userCurrentDateFormatDateFormatPut(dateFormat);
  }
};

export const getCurrentUser = restUser.createGetBy(GET_AUTH_USER, 'userCurrentGet');
