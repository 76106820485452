
import { defineMessages } from 'react-intl';

import { categoryType } from '../model/enums';
import intl from '../setup/RIntl';

const messages = defineMessages({
  [categoryType.none]: { id: 'common.none', defaultMessage: 'None' },
  [categoryType.male]: { id: 'common.sex-male', defaultMessage: 'Male' },
  [categoryType.female]: { id: 'common.sex-female', defaultMessage: 'Female' },
  [categoryType.undefined]: { id: 'common.undefined', defaultMessage: 'Undefined' },
  [categoryType.light]: { id: 'common.light', defaultMessage: 'Light' },
  [categoryType.ok]: { id: 'common.ok', defaultMessage: 'Ok' },
  [categoryType.heavy]: { id: 'common.heavy', defaultMessage: 'Heavy' },
});

export const getCategoryName = (sorting) => {
  const value = messages[sorting];
  return value == null ? sorting : intl.t(value);
};

const CategoryName = ({ category }) => getCategoryName(category);

export default CategoryName;
