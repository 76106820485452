import { GET_GATEWAY, GET_NUMBERS } from './Gateway.actions';

const initialState = {
  items: [],
  item: null,
  numbers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GATEWAY:
      return { ...state, items: action.payload };
    case GET_NUMBERS:
      return { ...state, numbers: action.payload };
    default:
      return state;
  }
};

export default reducer;
