export const order = {
  asc: 'asc',
  desc: 'desc',
};

export const farmOrder = {
  name: 'name',
  address: 'address',
  country: 'country',
  activeFlocks: 'activeFlocksCount',
  scheduledlocks: 'scheduledFlocksCount',
  locations: 'locationsCount',
  devices: 'devicesCount',
};

export const locationOrder = {
  name: 'name',
  date: 'date',
  age: 'age',
  category: 'category',
  weight: 'weight',
  flock: 'flock',
};

export const flockOrder = {
  name: 'name',
  date: 'date',
  age: 'age',
  category: 'category',
  gain: 'gain',
  growth: 'growth',
  weight: 'weight',
  count: 'count',
  uniformity: 'uniformity',
  cv: 'cv',
  farm: 'farm',
};

export const birdOrder = {
  name: 'name',
  product: 'product',
  company: 'company',
  duration: 'duration',
  sex: 'sex',
};

export const userOrder = {
  name: 'name',
  email: 'email',
  role: 'role',
  roleExceptions: 'roleExceptions',
};

export const deviceOrder = {
  name: 'name',
  type: 'type',
  farm: 'farm',
  location: 'location',
  date: 'date',
  age: 'age',
  value: 'value',
  flock: 'flock',
  status: 'status',
  category: 'category',
};

export const fileOrder = {
  name: 'name',
  farm: 'farm',
  location: 'location',
};
