function isArray(array) {
  return !(array == null || !Array.isArray(array));
}

function isArrayWithValue(array) {
  return !(!isArray(array) || array.length < 1);
}

function range(count, from = 0) {
  return [...Array(count)].map((_, i) => from + i);
}

function indexOf(array, value, map) {
  if (!isArrayWithValue(array) || value == null) return -1;
  const mapArray = map == null ? array : array.map(map);
  return mapArray.indexOf(value);
}

function closest(array, value, map) {
  if (array == null
    || !Array.isArray(array)
    || array.length < 1
    || value == null
    || (map != null && !(map instanceof Function))) return null;

  return array.reduce((prev, curr) => {
    const prevVal = map == null ? prev : map(prev);
    const currVal = map == null ? curr : map(curr);
    return (Math.abs(currVal - value) < Math.abs(prevVal - value) ? curr : prev);
  });
}

function closestLower(array, value, map) {
  let closest = null;
  array.some((a) => {
    const val = map(a);
    if (val >= value) {
      return true;
    }
    closest = a;
    return false;
  });
  return closest;
}

function closestUpper(array, value, map) {
  let closest = null;
  array.some((a) => {
    const val = map(a);
    if (val >= value) {
      closest = a;
      return true;
    }
    return false;
  });
  return closest;
}

function next(array, value, map) {
  const index = indexOf(array, value, map);
  if (index === -1 || array.length <= index + 1) return null;
  return array[index + 1];
}

function prev(array, value, map) {
  const index = indexOf(array, value, map);
  if (index < 1) return null;
  return array[index - 1];
}

function lastOrDefault(array, defaultValue) {
  if (!isArrayWithValue(array)) return defaultValue;
  return array[array.length - 1];
}

function firstOrDefault(array, defaultValue) {
  if (!isArrayWithValue(array)) return defaultValue;
  return array[0];
}

function toggleByKey(array, item, getKey = v => v) {
  if (!isArray(array) || item == null || getKey == null) return array;
  const itemKey = getKey(item);
  const arrayItem = array.find(f => getKey(f) === itemKey);
  if (arrayItem == null) {
    return [...array, item];
  }
  return array.filter(f => f !== arrayItem);
}

function toggle(array, item, add) {
  if (!isArrayWithValue(array)) return [item];
  const index = array.indexOf(item);
  const newArray = [...array];
  if ((add === true || add == null) && index === -1) {
    newArray.push(item);
  }
  if ((add === false || add == null) && index !== -1) {
    newArray.splice(index, 1);
  }
  return newArray;
}

function omit(obj, omitKey) {
  return Object.keys(obj).reduce((result, key) => {
    return key !== omitKey ? { ...result, [key]: obj[key] } : result;
  }, {});
}

function replace(array, item, getKey) {
  if (!isArrayWithValue(array) || item == null) return array;
  const key = getKey(item);
  return array.map(i => (getKey(i) === key ? item : i));
}

function addOrReplace(array, item, getKey) {
  if (!isArray(array) || item == null) return array;
  const key = getKey(item);
  const index = array.findIndex(i => getKey(i) === key);
  return index === -1 ? [...array, item] : array.map(i => (getKey(i) === key ? item : i));
}

export default {
  isArrayWithValue,
  range,
  indexOf,
  closest,
  closestLower,
  closestUpper,
  next,
  prev,
  lastOrDefault,
  firstOrDefault,
  toggle,
  toggleByKey,
  omit,
  replace,
  addOrReplace,
};
