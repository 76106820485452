import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import bem from '../utils/bem';
import PageSkeleton from './PageSkeleton';
import PageNotFound from './PageNotFound';
import SearchInput from './filters/SearchInput';
import PageContent from './PageContent';

function setTitle(title) {
  const newTitle = `BAT WEB | ${title}`;
  if (document.title !== newTitle) {
    document.title = newTitle;
  }
}

const Page = ({
  title, subtitle, actions, children, className, isFetching, emptyPage, notFound,
  header, footer, overflow, paginate, filter,
}) => {
  setTitle(title);
  if (isFetching === true) return <PageSkeleton hasSubtitle={subtitle != null} />;
  if (notFound) return <PageNotFound />;
  let overLimit = false;
  if (paginate != null && paginate.onChange != null && paginate.offset > paginate.size) {
    overLimit = true;
  }
  const bm = bem.component('page');
  const mod = overflow ? bm.m('overflow') : null;
  return (
    <div className={bm.b(mod, className, paginate ? bm.m('paginate') : bm.m('non-paginate'))}>
      <div className={bm.e('header')}>
        <div className={bm.e('title')}>
          <Label type="title">{title}</Label>
        </div>
        <div className={bm.e('actions', 'text-right')}>
          {actions}
        </div>
      </div>
      <div className={bm.e('subtitle')}>{subtitle}</div>
      {filter && (
        <div className={bm.e('filter')}>
          {filter.search && <SearchInput />}
          {filter.body && (<div className={bm.e('filter-body')}>{filter.body}</div>)}
        </div>
      )}
      <div className={bm.e('body')}>
        {header}
        <PageContent paginate={paginate} emptyPage={emptyPage} overLimit={overLimit}>
          {children}
        </PageContent>
        {footer}
      </div>
      {isFetching && <div className="overlay"></div>}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  actions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  notFound: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
  overflow: PropTypes.bool,
  emptyPage: PropTypes.shape({
    isEmpty: PropTypes.bool,
    icon: PropTypes.func,
    action: PropTypes.node,
    text: PropTypes.node,
  }),
  paginate: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    size: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }),
  filter: PropTypes.shape({
    search: PropTypes.bool,
    body: PropTypes.node,
  }),
};

Page.defaultProps = {
  title: '',
  subtitle: null,
  actions: null,
  children: null,
  header: null,
  footer: null,
  className: null,
  isFetching: false,
  emptyPage: null,
  notFound: null,
  overflow: false,
  paginate: null,
  filter: null,
};

export default Page;
