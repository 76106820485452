import { fetchAll } from '../../store/Fetch.actions';
import { getDevice, expandDevice, scopeDevice } from '../../store/Device.actions';
import { getLocation } from '../../store/Location.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';

const fetchData = (fetchMetadata, pagination) => (dispatch) => {
  fetchAll(() => [
    getDevice({
      expand: [
        expandDevice.farm,
        expandDevice.description,
      ],
      scope: scopeDevice.connector,
      ...pagination,
    })(dispatch),
    fetchMetadata ? getFarm({ expand: [expandFarm.address, expandFarm.country] })(dispatch) : null,
    fetchMetadata ? getLocation()(dispatch) : null,
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
