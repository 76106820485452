import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Table,
  Label,
} from '@veit/veit-web-controls';

import DateFormat from '../../components/DateFormat';

const ViewWeighing = ({ weighings, weightUnit, ...otherProps }) => {
  return (
    <Table type="data" {...otherProps} className="narrow">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="common.time" defaultMessage="Time" />
          </th>
          <th>
            <FormattedMessage values={{ unit: <FormattedMessage id={`units.${weightUnit}`} defaultMessage="g" /> }} id="common.weight-unit" defaultMessage="Weight({unit})" />
          </th>
          <th>
            <FormattedMessage id="common.category" defaultMessage="Category" />
          </th>
        </tr>
      </thead>
      <tbody>
        {weighings.map((d, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            <td>
              <Label>
                <DateFormat date={d.dateTime} format="HH:mm:ss" />
              </Label>
            </td>
            <td>
              <Label>{d.weight}</Label>
            </td>
            <td>
              <Label>{d.category}</Label>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

ViewWeighing.propTypes = {
  weighings: PropTypes.arrayOf(PropTypes.shape({
    dateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    weight: PropTypes.number,
    category: PropTypes.string,
  })),
  weightUnit: PropTypes.string,
};

ViewWeighing.defaultProps = {
  weighings: [],
  weightUnit: null,
};

export default ViewWeighing;
