import * as ModalActions from './Modal.actions';
import { putUser } from './User.actions';
import { getCurrentUser } from './Auth.actions';

export const EDIT_PROFILE = 'EDIT_PROFILE';

export const openDialogEditProfile = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_PROFILE, data)(dispatch);
};

export const finishDialogEditProfile = data => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => putUser(data.id, data), EDIT_PROFILE)(dispatch)
    .then(() => {
      getCurrentUser()(dispatch);
    });
};

export default ModalActions;
