import React from 'react';
import Icon from 'react-icon-base';

const IMCorner = (props) => {
  return (
    <Icon viewBox="0 0 7 7" {...props}>
      <g>
        <path d="M 3 0 L 3 4 M 3 4 L 7 4 L 7 3 L 4 3 L 4 0 L 3 0 Z" />
      </g>
    </Icon>
  );
};

export default IMCorner;
