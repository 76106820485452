import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

const isDevelopment = process.env.NODE_ENV === 'development';

ReactGA.initialize(process.env.REACT_APP_GA);

class GAListener extends React.Component {
  componentDidMount() {
    if (!isDevelopment) {
      this.sendPageView(this.props.history.location);
      this.props.history.listen(this.sendPageView);
    }
  }

  sendPageView = (location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}

GAListener.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.object,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default GAListener;
