import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import { connect } from 'react-redux';

const Location = ({ locations, locationId }) => {
  if (locationId == null || locations == null) return null;
  const location = locations.find(f => f.id === locationId);
  return location == null ? null : <Label>{location.name}</Label>;
};

Location.propTypes = {
  locationId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
};

Location.defaultProps = {
  locations: null,
  locationId: null,
};

const mapStateToProps = (state) => {
  return {
    locations: state.location.items,
  };
};

export default connect(mapStateToProps)(Location);
