import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalActions, { finishDialogSendGatewayRequest } from '../../store/ModalGateway.actions';
import bem from '../../utils/bem';
import {
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from '@veit/veit-web-controls';

import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import { enableKey, disableKey } from '../../utils/keyEvents';
import ModalWithLoader from '../ModalWithLoader';

class GatewayRequestModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogSendGatewayRequest(this.props.modal.data);
  }

  isInvalid = () => {
    const form = this.props.modal.data;
    return (form.country == null || form.country.trim().length < 2);
  }

  render() {
    if (this.props.modal.data == null) return null;
    const form = this.props.modal.data;
    const bm = bem.modal('feedback');
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        contentClassName={bm.b('modal-medium')}
      >
        <ModalHeader className={bm.e('header')} toggle={this.cancel}>Request SMS gateway</ModalHeader>
        <ModalBody className={bm.e('body')}>
          <div className={bm.e('content')}>
            <div className={bm.e('content-message')}>
              <Label>Country</Label>
              <Input
                type="text"
                value={form.country || ''}
                onChange={e => this.props.updateFormEvent(e, 'country')}
              />
              <Label>More details</Label>
              <Input
                type="textarea"
                value={form.detail}
                onChange={e => this.props.updateFormEvent(e, 'detail')}
                onFocus={() => disableKey(keyCodes.enter)}
                onBlur={() => enableKey(keyCodes.enter)}
              />
            </div>
          </div>
          <Button color="primary" disabled={this.isInvalid()} onClick={this.finish}>Send Request</Button>
        </ModalBody>
        <SubmitHandler keyCode={keyCodes.enter} action={this.finish} />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormEvent: ModalActions.updateFormEvent,
  finishDialogSendGatewayRequest,
}, dispatch);

GatewayRequestModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  finishDialogSendGatewayRequest: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(GatewayRequestModal);
