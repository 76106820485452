import React from 'react';
import ContentLoader from 'react-content-loader';
import { Inline } from '@veit/veit-web-controls';
import PropTypes from 'prop-types';
import bem from '../utils/bem';

const animationSpeed = 2;
const bm = bem.component('page');

export const PageSkeletonBody = () => (
  <div className={bm.e('body')}>
    <ContentLoader speed={animationSpeed} preserveAspectRatio="none" width={800} height={800}>
      <rect x="0" y="0" rx="4" ry="4" width="800" height="800" />
    </ContentLoader>
  </div>
);

const PageSkeleton = ({ hasSubtitle }) => {
  return (
    <div className={bm.b()}>
      <Inline className={bm.e('header')}>
        <div className={bm.e('title')}>
          <ContentLoader speed={animationSpeed} width={800} style={{ height: '100px' }}>
            <rect x="0" y="15" rx="0" ry="0" width="800" height="60" />
            {hasSubtitle ? <rect x="0" y="84" rx="0" ry="0" width="600" height="24" /> : null}
          </ContentLoader>
        </div>
        <div className={bm.e('actions')}>
          <ContentLoader speed={animationSpeed} width={540} style={{ height: '76px' }}>
            <rect x="300" y={hasSubtitle ? '0' : '10'} rx="34" ry="34" width="230" height="68" />
          </ContentLoader>
        </div>
      </Inline>
      <PageSkeletonBody />
    </div>
  );
};

PageSkeleton.propTypes = {
  hasSubtitle: PropTypes.bool.isRequired,
};

export default PageSkeleton;
