
export default function convertGramsToPounds(value, round = true) {
  const converted = value / 453.59237;
  return round ? +(converted.toFixed(3)) : converted;
}

export function convertPoundsToGrams(value) {
  const converted = value * 453.59237;
  return Math.round(converted);
}
