import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalActions, { finishDialogEditLoraDevice } from '../../../store/ModalAdmin.actions';
import { deleteLoraDevice } from '../../../store/Lora.actions';
import bem from '../../../utils/bem';
import {
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
  Button,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../../ModalWithLoader';
import DeleteModal from '../../DeleteModal';
import ModalContent, { hasStep1Error } from './ModalContent';

const bm = bem.modal('edit-lora-device');

class EditLoraDevice extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  delete = () => {
    this.props.deleteLoraDevice(this.props.modal.data.devEUI).then(this.cancel);
  }

  finish = () => {
    this.props.finishDialogEditLoraDevice(this.props.modal.data);
  }

  render() {
    if (this.props.modal.data == null) return null;
    const form = this.props.modal.data;
    const { companies } = this.props;
    const isReadonly = form.aws != null;
    const company = companies.find(f => f.id === form.companyId) || {};
    const errors = hasStep1Error(form);
    const hasError = Object.values(errors).find(f => f === true) != null;

    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        contentClassName={bm.b('modal-medium')}
      >
        <ModalHeader className={bm.e('header')} toggle={this.cancel}>
          {isReadonly ? 'LoRa Device' : 'Edit LoRa Device Keys'}
        </ModalHeader>
        <ModalBody className={bm.e('body')}>
          <div className={bm.e('content')}>
            {isReadonly && (
              <React.Fragment>
                <Label>AWS Device ID</Label>
                <Input
                  type="text"
                  value={(form.aws || {}).id}
                  disabled
                />
                {form.devEUI != null && (
                  <React.Fragment>
                    <Label>Company</Label>
                    <Input
                      type="text"
                      value={company.name}
                      disabled
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <ModalContent
              edit
              form={form}
              updateFormEvent={this.props.updateFormEvent}
              updateForm={this.props.updateForm}
            />
          </div>
          <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button outline color="primary" onClick={this.cancel}>
                Cancel
              </Button>
              {!isReadonly && (
                <DeleteModal
                  confirm={this.delete}
                  subtitle={`Delete registered LoRa Device Keys with EUI ${form.devEUI}?`}
                  name="LoRa Device"
                />
              )}
            </div>
            <div>
              {!isReadonly && (
                <Button color="primary" onClick={this.finish} disabled={hasError}>
                  Save Changes
                </Button>
              )}
            </div>
          </ModalFooter>
        </ModalBody>
      </ModalWithLoader>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.company.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormEvent: ModalActions.updateFormEvent,
  updateForm: ModalActions.updateForm,
  finishDialogEditLoraDevice,
  deleteLoraDevice,
}, dispatch);

EditLoraDevice.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  deleteLoraDevice: PropTypes.func.isRequired,
  finishDialogEditLoraDevice: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

EditLoraDevice.defaultProps = {
  companies: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLoraDevice);
