import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
  Container, Row, Col, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu,
  DropdownItem, AddButton, Button,
} from '@veit/veit-web-controls';

import DropDownWithFilter from '../../components/DropDownWithFilter';
import LabelWithTag from '../../components/LabelWithTag';
import DialogModal from '../../modals/DialogModal';
import DeleteModal from '../../modals/DeleteModal';
import RoleName from '../../components/RoleName';
import Page from '../../components/Page';
import RoleException from '../../components/RoleException';
import QueryRedirect from '../../components/QueryRedirect';
import goToConnect from '../../utils/goToConnect';
import { roleType, authType } from '../../model/enums';
import array from '../../utils/array';
import bem from '../../utils/bem';
import intl from '../../setup/RIntl';

import {
  deleteUser, putUser, updateForm, updateFormObject, updateFormEvent,
} from '../../store/User.actions';
import { resetPasswordDialogUser } from '../../store/ModalUser.actions';

import fetchData from './fetchData';

const messages = defineMessages({
  editUser: { id: 'users.edit-user', defaultMessage: 'Edit User' },
  user: { id: 'common.user', defaultMessage: 'User' },
});

export function getRoles(user) {
  const roles = [roleType.noAccess, roleType.user, roleType.admin, roleType.companyAdmin];
  return roleType.isAdmin(user.role) ? [...roles, roleType.veitOperator] : roles;
}

export function filterRoleExceptions(user) {
  const filterdExceptions = {};
  const roleExceptions = user.roleExceptions || {};
  Object.keys(roleExceptions).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(roleExceptions, key)
      && roleExceptions[key] !== user.role) {
      filterdExceptions[key] = roleExceptions[key];
    }
  });
  return filterdExceptions;
}

const bm = bem.view('user');

class User extends Component {
  componentDidMount() {
    this.props.fetchData(this.props.id, true);
  }

  saveUser = () => {
    this.props.putUser(this.props.id, this.getUser()).then(() => {
      this.props.fetchData(this.props.id);
    });
  }

  deleteUser = () => {
    this.props.deleteUser(this.props.id).then(() => {
      this.props.goTo('/users');
    });
  }

  resetPassword = () => {
    this.props.resetPasswordDialogUser(this.props.user.item);
  }

  actions = () => {
    return authType.canUpdate(this.props.user.item) ? (
      <React.Fragment>
        <DeleteModal confirm={this.deleteUser} name={intl.t(messages.user)} />
        <DialogModal confirm={this.resetPassword} confirmText={<FormattedMessage id="users.modal.reset-password" defaultMessage="Reset Password" />} />
        <Button color="primary" onClick={this.saveUser}>
          <FormattedMessage id="common.save-changes" defaultMessage="Save changes" />
        </Button>
      </React.Fragment>
    ) : null;
  }

  addException = () => {
    const form = this.getUser();
    this.props.updateFormObject({
      roleExceptions: {
        ...(form.roleExceptions || {}),
        [form.farm.id]: form.role === roleType.noAccess ? roleType.user : roleType.noAccess,
      },
      farm: null,
    });
  };

  updateException = (farmId, role) => {
    const form = this.getUser();
    const roleExceptions = role == null
      ? array.omit(form.roleExceptions, farmId)
      : { ...form.roleExceptions, [farmId]: role };

    this.props.updateForm(roleExceptions, 'roleExceptions');
  }

  getUser = () => {
    const item = this.props.user.item || {};
    const edit = this.props.user.edit || {};
    return { ...item, ...edit };
  }

  render() {
    const user = this.getUser();
    const roles = getRoles(this.props.authUser);
    const farms = this.props.farm.items;
    const farmExceptions = Object.keys(filterRoleExceptions(user));

    if (this.props.id === this.props.authUser.id
      || (user.id === this.props.id && !authType.canUpdate(user))) {
      return <QueryRedirect to="/users" />;
    }

    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.editUser)}
        notFound={user.id !== this.props.id}
        isFetching={this.props.isFetching}
        actions={this.actions()}
      >
        <Container>
          <Row>
            <Col>
              <Label type="text">
                <FormattedMessage id="common.name" defaultMessage="Name" />
              </Label>
              <Input value={user.name || ''} onChange={e => this.props.updateFormEvent(e, 'name')} />
              <Label type="text">
                <FormattedMessage id="common.email" defaultMessage="Email" />
              </Label>
              <Input disabled value={user.email || ''} />
              <Label type="text">
                <FormattedMessage id="users.modal.default-role" defaultMessage="Default Role" />
              </Label>
              <UncontrolledDropdown>
                <DropdownToggle color="primary" outline caret>
                  <RoleName role={user.role} />
                </DropdownToggle>
                <DropdownMenu>
                  {
                    roles.map(r => (
                      <DropdownItem
                        disabled={r === roleType.veitOperator}
                        onClick={() => this.props.updateForm(r, 'role')}
                        key={r}
                      >
                        <RoleName role={r} />
                      </DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            {
              user.role !== roleType.companyAdmin && user.role !== roleType.veitOperator && (
                <Col>
                  <Label type="text">
                    <FormattedMessage id="common.farm" defaultMessage="Farm" />
                  </Label>
                  <DropDownWithFilter
                    placeholder={<FormattedMessage id="common.farm" defaultMessage="Farm" />}
                    items={farms.filter(f => farmExceptions.indexOf(f.id) === -1)}
                    getKey={k => k.id}
                    getValue={v => v.name}
                    selected={user.farm}
                    onChange={c => this.props.updateForm(c, 'farm')}
                    renderItem={i => (
                      <LabelWithTag>{i.name}</LabelWithTag>
                    )}
                  />
                  <AddButton className={bm.e('add-exception')} outline disabled={user.farm == null} onClick={this.addException}>
                    <FormattedMessage id="users.modal.add-role-exception" defaultMessage="Add role exception" />
                  </AddButton>
                  {
                    Object.keys(user.roleExceptions || {})
                      .map(m => (
                        <RoleException
                          key={m}
                          onChange={v => this.updateException(m, v)}
                          exception={user.roleExceptions[m]}
                          role={user.role}
                          farm={farms.find(f => f.id === m)}
                        />
                      ))
                  }
                </Col>
              )
            }
          </Row>
        </Container>
      </Page>
    );
  }
}

User.propTypes = {
  id: PropTypes.string.isRequired,
  goTo: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  putUser: PropTypes.func.isRequired,
  resetPasswordDialogUser: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  user: PropTypes.shape({
    item: PropTypes.object,
    edit: PropTypes.object,
  }),
  farm: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  authUser: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

User.defaultProps = {
  user: null,
};

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    user: state.user,
    farm: state.farm,
    authUser: state.auth.user,
    isFetching: state.fetch.isFetching,
  };
};

const mapDispatchToProps = {
  fetchData,
  deleteUser,
  putUser,
  resetPasswordDialogUser,
  updateForm,
  updateFormObject,
  updateFormEvent,
};

export default goToConnect('/users')(User, mapStateToProps, mapDispatchToProps);
