import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalActions, { finishDialogAddLoraDevice } from '../../../store/ModalAdmin.actions';
import bem from '../../../utils/bem';
import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../../ModalWithLoader';
import Footer from '../../Footer';

import ModalContent, { hasStep1Error } from './ModalContent';

const bm = bem.modal('add-lora-device');

class AddLoraDevice extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogAddLoraDevice(this.props.modal.data);
  }

  render() {
    if (this.props.modal.data == null) return null;
    const form = this.props.modal.data;
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        contentClassName={bm.b('modal-medium')}
      >
        <ModalHeader className={bm.e('header')} toggle={this.cancel}>Register LoRa Device Keys</ModalHeader>
        <ModalBody className={bm.e('body')}>
          <div className={bm.e('content')}>
            <div className={bm.e('content-message')}>
              <ModalContent
                form={form}
                updateFormEvent={this.props.updateFormEvent}
                updateForm={this.props.updateForm}
              />
            </div>
          </div>
          <Footer
            finisDialog={this.finish}
            finisDialogText="Register LoRa Device Keys"
            modal={this.props.modal}
            validators={{ 1: hasStep1Error }}
          />
        </ModalBody>
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormEvent: ModalActions.updateFormEvent,
  updateForm: ModalActions.updateForm,
  finishDialogAddLoraDevice,
}, dispatch);

AddLoraDevice.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  finishDialogAddLoraDevice: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(AddLoraDevice);
