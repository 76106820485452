import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';
import { ApiClient } from '@veit/bat-cloud-api/lib/ApiClient';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import configureStore from './setup/configureStore';
import App from './App';
import GAListener from './setup/GAListener';
import Localize from './setup/Localize';
import { IntlCapture } from './setup/RIntl';
import { init } from './utils/auth';
import { initQueryCache } from './utils/queryCache';
import ErrorBoundary from './components/ErrorBoundary';

// eslint-disable-next-line no-console
console.log(`BAT Cloud Version : ${process.env.REACT_APP_VERSION}`);

// Setup api-url
ApiClient.instance.basePath = process.env.REACT_APP_API;

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

// Get the application-wide store instance,
// prepopulating with state from the server where available.
const initialState = window.initialReduxState;
const store = configureStore(history, initialState);
init(store.dispatch);

// init sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  environment: process.env.NODE_ENV,
});

initQueryCache(window.location.pathname + window.location.search);

ReactDOM.render(
  <Provider store={store}>
    <Localize>
      <IntlCapture>
        <ConnectedRouter history={history}>
          <GAListener history={history}>
            <ErrorBoundary>
              <App />
              <ToastContainer />
            </ErrorBoundary>
          </GAListener>
        </ConnectedRouter>
      </IntlCapture>
    </Localize>
  </Provider>,
  document.getElementById('root'),
);
