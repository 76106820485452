import JSZip from 'jszip';

import { ApiClient } from '@veit/bat-cloud-api/lib/ApiClient';

function sendZipFile(content, fileName) {
  return ApiClient.instance.callApi(
    '/feedback',
    'POST',
    {},
    {},
    {},
    { file: new File([content], fileName, { type: 'application/zip' }) },
    {},
    ['OAuth2'],
    ['multipart/form-data'],
    ['application/json'],
    null,
  );
}

function browserInfo() {
  return {
    timeOpened: new Date(),
    timezone: (new Date()).getTimezoneOffset() / 60,
    pageon: window.location.pathname,
    referrer: document.referrer,
    // previousSites: window.history.length,
    browserName: window.navigator.appName,
    browserEngine: window.navigator.product,
    browserVersion1a: window.navigator.appVersion,
    browserVersion1b: window.navigator.userAgent,
    browserLanguage: window.navigator.language,
    browserOnline: window.navigator.onLine,
    browserPlatform: window.navigator.platform,
    // javaEnabled: window.navigator.javaEnabled(),
    dataCookiesEnabled: window.navigator.cookieEnabled,
    // dataCookies1: document.cookie,
    // dataCookies2: decodeURIComponent(document.cookie.split(';')),
    // dataStorage: localStorage,

    sizeScreenW: window.screen.width,
    sizeScreenH: window.screen.height,
    sizeDocW: document.width,
    sizeDocH: document.height,
    sizeInW: window.innerWidth,
    sizeInH: window.innerHeight,
    sizeAvailW: window.screen.availWidth,
    sizeAvailH: window.screen.availHeight,
    scrColorDepth: window.screen.colorDepth,
    scrPixelDepth: window.screen.pixelDepth,
    // latitude: window.position.coords.latitude,
    // longitude: window.position.coords.longitude,
    // accuracy: window.position.coords.accuracy,
    // altitude: window.position.coords.altitude,
    // altitudeAccuracy: window.position.coords.altitudeAccuracy,
    // heading: window.position.coords.heading,
    // speed: window.position.coords.speed,
    // timestamp: window.position.timestamp,
  };
}

export default function (form, state, messageType = 'feedback') {
  const zip = new JSZip();
  zip.file('feedback.txt', JSON.stringify({ ...form, messageType }, null, '\t'));
  if (form.sendData) {
    zip.file('state.json', JSON.stringify(state, null, '\t'));
    zip.file('browser.json', JSON.stringify(browserInfo(), null, '\t'));
  }
  return zip.generateAsync({ type: 'blob' })
    .then(file => sendZipFile(file, 'feedback.zip'));
}
