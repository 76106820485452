import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isFloat from 'validator/lib/isFloat';
import { FormattedMessage } from 'react-intl';
import SubtitleItem from '../../components/SubtitleItem';

import SubtitleWrapper from '../../components/SubtitleWrapper';
import DateFormat from '../../components/DateFormat';
import Duration from '../../components/Duration';


import LocationSubtitle from '../_subtitle/LocationSubtitle';
import WeightValue from '../../components/WeightValue';

function isInvalidNumber(number) {
  return number == null || !isFloat(`${number}`);
}

const Target = ({
  dateType, targetAge, targetWeight, weightUnit,
}) => {
  if (isInvalidNumber(targetAge) && isInvalidNumber(targetWeight)) return null;
  return targetWeight
    ? (
      <SubtitleItem title={<FormattedMessage id="flocks.target-weight" defaultMessage="Target weight" />}>
        <WeightValue value={targetWeight} weightUnit={weightUnit} />
      </SubtitleItem>
    ) : (
      <SubtitleItem title={<FormattedMessage id="flocks.target-age" defaultMessage="Target age" />}>
        <Duration unit={dateType} value={targetAge} />
      </SubtitleItem>
    );
};

Target.propTypes = {
  dateType: PropTypes.string,
  targetAge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  targetWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weightUnit: PropTypes.string,
};

Target.defaultProps = {
  dateType: 'day',
  targetAge: null,
  targetWeight: null,
  weightUnit: null,
};

const Subtitle = ({
  flock, bird, location, dateType, weightUnit, dateFormat, ...props
}) => {
  const useWeeks = dateType === 'week';
  const initialAge = useWeeks ? (flock.initialAge / 7) : flock.initialAge;
  const diff = dayjs().diff(flock.startDate, dateType, true);
  const currentAge = Math.ceil(diff + initialAge);
  const remainingDays = flock.targetAge - flock.initialAge - dayjs().diff(flock.startDate, 'day');
  const finalDate = dayjs().add(remainingDays, 'day').toDate();
  const isScheduled = dayjs(flock.startDate).toDate() > new Date();

  return (
    <SubtitleWrapper {...props}>
      {location && (
        <LocationSubtitle farm={location.farm} location={location} />
      )}
      {
        bird && flock.birdId === bird.id && (
          <React.Fragment>
            <SubtitleItem title={<FormattedMessage id="common.bird" defaultMessage="Bird" />} to={`/bird/${bird.id}`}>{bird.name}</SubtitleItem>
          </React.Fragment>
        )
      }
      {
        flock.endDate != null
          ? (
            <SubtitleItem title={<FormattedMessage id="common.finished" defaultMessage="Finished" />}>
              <DateFormat date={flock.endDate} format={dateFormat} />
            </SubtitleItem>
          ) : (
            <React.Fragment>
              {
                (isScheduled)
                  ? (
                    <SubtitleItem title={<FormattedMessage id="common.scheduled" defaultMessage="Scheduled" />}>
                      <DateFormat date={flock.startDate} format={dateFormat} />
                    </SubtitleItem>
                  ) : (
                    <SubtitleItem title={<FormattedMessage id="flocks.current-age" defaultMessage="Current age" />}>
                      <Duration unit={dateType} value={currentAge} />
                    </SubtitleItem>
                  )
              }
              <Target
                dateType={dateType}
                targetAge={flock.targetAge}
                targetWeight={flock.targetWeight}
                weightUnit={weightUnit}
              />
              {
                isInvalidNumber(flock.targetAge) ? null : (
                  <SubtitleItem title={<FormattedMessage id="flocks.final-age-on" defaultMessage="Final age on" />}>
                    <DateFormat date={finalDate} format={dateFormat} />
                  </SubtitleItem>
                )
              }

            </React.Fragment>
          )
      }
    </SubtitleWrapper>
  );
};

Subtitle.propTypes = {
  flock: PropTypes.shape({
    initialAge: PropTypes.number,
    targetWeight: PropTypes.number,
    targetAge: PropTypes.number,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    birdId: PropTypes.string,
  }).isRequired,
  bird: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    sex: PropTypes.string,
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    farm: PropTypes.object,
  }),
  dateType: PropTypes.string,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

Subtitle.defaultProps = {
  bird: null,
  location: null,
  dateType: 'day',
  weightUnit: null,
  dateFormat: null,
};

export default Subtitle;
