import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Table } from '@veit/veit-web-controls';
import { IMBird } from '@veit/veit-web-controls/dist/icons';

import { openDialogAddFlock } from '../../store/ModalFlock.actions';
import { scopeFlock } from '../../store/Flock.actions';

import Page from '../../components/Page';
import { AuthAddButton } from '../../components/AuthButton';
import QuerySort from '../../components/QuerySort';
import bem from '../../utils/bem';
import goToConnect from '../../utils/goToConnect';
import { flockOrder } from '../../model/order';
import intl from '../../setup/RIntl';

import fetchData from './fetchData';
import TableRow from './TableRow';
import FlockFilter from './FlockFilter';


const messages = defineMessages({
  flocks: { id: 'common.flocks', defaultMessage: 'Flocks' },
  active: { id: 'common.active', defaultMessage: 'Active' },
  scheduled: { id: 'common.scheduled', defaultMessage: 'Scheduled' },
  finished: { id: 'common.finished', defaultMessage: 'Finished' },
  all: { id: 'common.all', defaultMessage: 'All' },
});

class FlockList extends Component {
  state = {
    seeAllFlocks: true,
  }

  componentDidMount() {
    this.get(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter
      || prevProps.search !== this.props.search) {
      this.get(false);
    }
  }

  get = (fetchMetadata) => {
    this.props.fetchData(this.props.filter, fetchMetadata, this.props.search);
  }

  getBird = (birdId) => {
    return this.props.birds.find(f => f.id === birdId);
  }

  addFlockDialog = () => {
    this.props.openDialogAddFlock().then((result) => {
      if (result != null) {
        this.props.goTo(`/flock/${result.id}`);
      }
    });
  }

  seeAllFlocks = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prev => ({ seeAllFlocks: !prev.seeAllFlocks }));
  }

  getFarmId = (locationId) => {
    if (locationId == null) return null;
    const location = this.props.location.items.find(f => f.id === locationId);
    return location == null ? null : location.farmId;
  }

  getPageTitle = () => {
    switch (this.props.filter) {
      case 'scheduled': return messages.scheduled;
      case 'finished': return messages.finished;
      case 'all': return messages.all;
      default: return messages.active;
    }
  }

  getTitle = () => {
    return `${intl.t(this.getPageTitle())} ${intl.t(messages.flocks)}`;
  }

  actions() {
    return (
      <AuthAddButton canCreate={this.props.flock} onClick={this.addFlockDialog}>
        <FormattedMessage id="flocks.add-flock" defaultMessage="Add Flock" />
      </AuthAddButton>
    );
  }

  render() {
    const bm = bem.view('flock-list');
    const unit = this.props.weightUnit;
    return (
      <Page
        className={bm.b()}
        title={this.getTitle()}
        actions={this.actions()}
        isFetching={this.props.fetch.isFetching}
        emptyPage={{
          isEmpty: this.props.flock.items.length === 0,
          icon: IMBird,
          action: this.actions(),
        }}
        paginate={{ onChange: this.props.goToPage, ...this.props.flock }}
        filter={{
          search: true,
          body: <FlockFilter />,
        }}
      >
        <Table type="info">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.flock" defaultMessage="Flock" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.date}>
                  <FormattedMessage id="common.last-update" defaultMessage="Last update" />
                </QuerySort>
              </th>
              <th className="text-center">
                <QuerySort orderby={flockOrder.growth}>
                  <FormattedMessage id="common.status" defaultMessage="Status" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.age}>
                  <FormattedMessage id="common.age" defaultMessage="Age" />
                </QuerySort>
              </th>
              <th className="text-center">
                <QuerySort orderby={flockOrder.category}>
                  <FormattedMessage id="common.sex" defaultMessage="Sex" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.weight} style={{ marginLeft: '18px' }}>
                  <FormattedMessage values={{ unit: <FormattedMessage id={`units.${unit}`} defaultMessage="g" /> }} id="common.average-unit" defaultMessage="Average({unit})" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.gain}>
                  <FormattedMessage values={{ unit: <FormattedMessage id={`units.${unit}`} defaultMessage="g" /> }} id="common.gain-unit" defaultMessage="Gain({unit})" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.count}>
                  <FormattedMessage id="common.count-pcs" defaultMessage="Count(pcs)" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.uniformity}>
                  <FormattedMessage id="common.unifor-percent" defaultMessage="Unifor.(%)" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.cv}>
                  <FormattedMessage id="common.cv-percent" defaultMessage="CV(%)" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={flockOrder.farm}>
                  <FormattedMessage id="common.location" defaultMessage="Location" />
                </QuerySort>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.flock.items.map(i => (
                <TableRow
                  key={i.id}
                  flock={i}
                  seeAllFlocksHandler={this.seeAllFlocks}
                  seeAllFlocks={this.state.seeAllFlocks}
                  goTo={this.props.goTo}
                  bird={this.getBird(i.birdId)}
                  filter={this.props.filter}
                  weightUnit={unit}
                  dateFormat={this.props.dateFormat}
                />
              ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

FlockList.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogAddFlock: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  flock: PropTypes.shape({
    items: PropTypes.array,
    links: PropTypes.object,
  }).isRequired,
  farm: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  location: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  birds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  filter: PropTypes.string,
  search: PropTypes.string,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

FlockList.defaultProps = {
  filter: scopeFlock.active,
  search: null,
  weightUnit: null,
  dateFormat: null,
};

const mapDispatchToProps = {
  fetchData,
  openDialogAddFlock,
};

const mapStateToProps = (state, ownProps) => {
  return {
    flock: state.flock,
    farm: state.farm,
    location: state.location,
    fetch: state.fetch,
    birds: state.bird.items,
    filter: ownProps.match.params.filter,
    locale: state.auth.locale,
    search: ownProps.location.search,
    weightUnit: state.auth.weightUnit,
    dateFormat: state.auth.dateFormat,
  };
};


export default goToConnect('/flocks')(FlockList, mapStateToProps, mapDispatchToProps);
