import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

import deMessages from '../translations/de.json';
import enMessages from '../translations/en.json';
import frMessages from '../translations/fr.json';

const languages = {
  en: enMessages,
  de: deMessages,
  fr: frMessages,
};

const log = (message) => {
  const values = message.split('"').map((substr, i) => {
    return (i % 2) ? substr : null;
  }).filter(f => f != null && f.length > 0);
  if (values.length === 2) {
    // eslint-disable-next-line no-console
    console.log(`missing ${values[1]} : ${values[0]}`);
  }
};

const Localize = (props) => {
  return (
    <IntlProvider
      {...props}
      messages={languages[props.locale]}
      onError={log}
    />
  );
};

Localize.propTypes = {
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    locale: state.auth.locale,
  };
};

export default connect(mapStateToProps)(Localize);
