import * as batSocket from '../utils/batSocket';

export const WS_CONNECTION_CREATE = 'WS_CONNECTION_CREATE';
export const WS_CONNECTION_SUCCESS = 'WS_CONNECTION_SUCCESS';
export const WS_CONNECTION_FAILED = 'WS_CONNECTION_FAILED';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';

export const WS_MEMORY_NOT_CONNECTED = 'WS_MEMORY_NOT_CONNECTED';
export const WS_INVALID_VERSION = 'WS_INVALID_VERSION';

export const WS_MEM_CONFIG_REQUEST = 'WS_MEM_CONFIG_REQUEST';
export const WS_MEM_CONFIG_RESPONSE = 'WS_MEM_CONFIG_RESPONSE';

export const WS_BAT1_INFO_REQUEST = 'WS_BAT1_INFO_REQUEST';
export const WS_BAT1_INFO_RESPONSE = 'WS_BAT1_INFO_RESPONSE';

export const WS_MEM_STATS_REQUEST = 'WS_MEM_STATS_REQUEST';
export const WS_MEM_STATS_RESPONSE = 'WS_MEM_STATS_RESPONSE';

export const WS_BAT1_STATS_REQUEST = 'WS_BAT1_STATS_REQUEST';
export const WS_BAT1_STATS_RESPONSE = 'WS_BAT1_STATS_RESPONSE';

export const WS_READ_ERROR = 'WS_READ_ERROR';
export const WS_WRITE_ERROR = 'WS_WRITE_ERROR';

export const WS_UNKNOWN_ACTION = 'WS_UNKNOWN_ACTION';
export const WS_SET_STEP = 'WS_SET_STEP';

const mapActionType = {
  [batSocket.actionType.getBat1Info]: WS_BAT1_INFO_RESPONSE,
  [batSocket.actionType.getBat1Stats]: WS_BAT1_STATS_RESPONSE,
  [batSocket.actionType.getMemModuleConfig]: WS_MEM_CONFIG_RESPONSE,
  [batSocket.actionType.getMemModuleStats]: WS_MEM_STATS_RESPONSE,
  [batSocket.actionType.getMemModuleFull]: WS_MEM_STATS_RESPONSE,
  [batSocket.actionType.invalidVersion]: WS_INVALID_VERSION,
  [batSocket.actionType.notConnected]: WS_MEMORY_NOT_CONNECTED,
  [batSocket.actionType.readError]: WS_READ_ERROR,
  [batSocket.actionType.writeError]: WS_WRITE_ERROR,
};

const messageHandler = dispatch => (message) => {
  const actionType = mapActionType[message.type] || WS_UNKNOWN_ACTION;
  dispatch({ type: actionType, payload: message.data });
};

export const openSocket = () => (dispatch) => {
  dispatch({ type: WS_CONNECTION_CREATE });
  return batSocket.connect().then(
    () => {
      batSocket.setMessageHandler(messageHandler(dispatch));
      dispatch({ type: WS_CONNECTION_SUCCESS });
      return Promise.resolve();
    },
    () => {
      dispatch({ type: WS_CONNECTION_FAILED });
      return Promise.reject();
    },
  );
};

const execute = (action = () => { }) => (dispatch) => {
  if (batSocket.isOpen()) {
    action();
  } else {
    openSocket()(dispatch).then(() => action());
  }
};

export const closeSocket = () => (dispatch) => {
  batSocket.setMessageHandler(null);
  if (batSocket.isOpen) {
    batSocket.disconnect();
  }
  dispatch({ type: WS_DISCONNECTED });
};

export const getConfig = () => (dispatch) => {
  execute(() => {
    dispatch({ type: WS_MEM_CONFIG_REQUEST });
    batSocket.getMemConfig();
  })(dispatch);
};

export const getStats = () => (dispatch) => {
  execute(() => {
    dispatch({ type: WS_MEM_STATS_REQUEST });
    batSocket.getMemStats();
  })(dispatch);
};

export const getMemFull = () => (dispatch) => {
  execute(() => {
    dispatch({ type: WS_MEM_STATS_REQUEST });
    batSocket.getMemFull();
  })(dispatch);
};

export const getBat1Info = () => (dispatch) => {
  execute(() => {
    dispatch({ type: WS_BAT1_INFO_REQUEST });
    batSocket.getBat1Info();
  })(dispatch);
};

export const getBat1Stats = device => (dispatch) => {
  execute(() => {
    dispatch({ type: WS_BAT1_STATS_REQUEST });
    batSocket.getBat1Stats(device);
  })(dispatch);
};

export const setStep = step => (dispatch) => {
  dispatch({ type: WS_SET_STEP, payload: step });
};
