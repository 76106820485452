import dayjs from 'dayjs';
import { sexType } from '../model/enums';
import { sortString } from './sort';

export function isActiveFlock(flock) {
  if (flock == null || flock.startDate == null || flock.endDate != null) return false;
  return dayjs(flock.startDate).isBefore(dayjs());
}

export function isScheduledFlock(flock) {
  if (flock == null || flock.startDate == null || flock.endDate != null) return false;
  return dayjs(flock.startDate).isAfter(dayjs());
}

function sortByAge(a, b) {
  const today = dayjs();
  const aProgress = today.diff(dayjs(a.startDate), 'day') + (a.initialAge || 0);
  const bProgress = today.diff(dayjs(b.startDate), 'day') + (b.initialAge || 0);
  return aProgress === bProgress ? 0 : aProgress < bProgress ? 1 : -1;
}

function sortByClosedDate(a, b) {
  const aClosed = dayjs(a.endDate);
  const bClosed = dayjs(b.endDate);
  return aClosed.isSame(bClosed) ? 0 : aClosed.isBefore(bClosed) ? 1 : -1;
}

function sortByStartDate(a, b) {
  const aStart = dayjs(a.startDate);
  const bStart = dayjs(b.startDate);
  return aStart.isSame(bStart) ? 0 : aStart.isAfter(bStart) ? 1 : -1;
}

export function sortByProgress(flocks) {
  if (flocks == null) return [];
  const closedFlocks = flocks.filter(f => f.endDate != null).sort(sortByClosedDate);
  const scheduledFlocks = flocks.filter(isScheduledFlock).sort(sortByStartDate);
  const activeFlocks = flocks.filter(isActiveFlock).sort(sortByAge);
  return [...activeFlocks, ...scheduledFlocks, ...closedFlocks];
}

export function sensorsInUse(from, flocks, devices = []) {
  const inUse = {};
  if (from == null || flocks == null || flocks.length === 0) return inUse;

  flocks.forEach((flock) => {
    if (flock.endDate == null || flock.endDate > from) {
      if (flock.deviceId) {
        inUse[flock.deviceId] = true;
        const device = (devices || []).find(f => f.id === flock.deviceId);
        if (device != null && device.locationId != null) inUse[device.locationId] = true;
      } else if (flock.locationId) {
        inUse[flock.locationId] = true;
        const devicesInLocation = (devices || []).filter(f => f.locationId === flock.locationId);
        devicesInLocation.forEach((d) => {
          inUse[d.id] = true;
        });
      }
    }
  });

  return inUse;
}

function sortSelector(s = {}) {
  const { category, type } = s;
  const sex = category === sexType.male || category === sexType.female ? category : '';
  return `${sex}_${type}`;
}

export function sortLastValuesBySex(lastValues) {
  if (lastValues == null || lastValues.length <= 1) {
    return lastValues;
  }
  return sortString(lastValues, sortSelector);
}
