import pagination from '../../utils/pagination';
import { fetchAll } from '../../store/Fetch.actions';
import { getFlock, expandFlock, scopeFlock } from '../../store/Flock.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';
import { getLocation } from '../../store/Location.actions';
import { getBird } from '../../store/Bird.actions';

const fetchData = (scope, fetchMetadata, query) => (dispatch) => {
  fetchAll(() => [
    getFlock({
      expand: [
        expandFlock.lastStatistics,
        expandFlock.endDate,
        expandFlock.location,
        expandFlock.farm,
        expandFlock.device,
      ],
      scope: scope == null ? scopeFlock.active : scope,
      ...pagination(query),
    })(dispatch),
    fetchMetadata ? getBird()(dispatch) : null,
    fetchMetadata ? getFarm({ expand: [expandFarm.address, expandFarm.country] })(dispatch) : null,
    fetchMetadata ? getLocation()(dispatch) : null,
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
