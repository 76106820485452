import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';
import DateFormat from '../../components/DateFormat';

const Progress = ({ flock, dateFormat }) => {
  const { endDate, startDate } = flock;
  const isScheduled = dayjs(startDate).toDate() > new Date();
  return endDate != null
    ? (
      <React.Fragment>
        <Label className="upper">
          <FormattedMessage id="common.finished" defaultMessage="Finished" />
        </Label>
        <Label type="info"><DateFormat date={endDate} format={dateFormat} /></Label>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {
          isScheduled
            ? (
              <React.Fragment>
                <Label className="upper">
                  <FormattedMessage id="common.scheduled" defaultMessage="Scheduled" />
                </Label>
                <Label type="info"><DateFormat date={startDate} format={dateFormat} /></Label>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Label>
                  <DateFormat
                    date={startDate}
                    diff="weeks"
                    wrapper={count => <FormattedMessage values={{ count }} id="common.count-weeks" defaultMessage="{count, plural, one {# week} other {# weeks}}" />}
                  />
                </Label>
                <Label type="info">
                  <FormattedMessage id="common.growth" defaultMessage="Growth" />
                </Label>
              </React.Fragment>
            )
        }
      </React.Fragment>
    );
};

Progress.propTypes = {
  flock: PropTypes.shape({
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  dateFormat: PropTypes.string,
};

Progress.defaultProps = {
  dateFormat: null,
};

export default Progress;
