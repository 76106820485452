import { Component } from 'react';
import PropTypes from 'prop-types';
import { registerEvent, unregisterEvent } from '../utils/keyEvents';

function freeze(obj) {
  return Object.freeze ? Object.freeze(obj) : obj;
}

export const keyCodes = freeze({
  arrowLeft: 37,
  arrowRight: 39,
  enter: 13,
  escape: 27,
});

class SubmitHandler extends Component {
  componentDidMount() {
    registerEvent(this.props.keyCode, this.keyDownHandler);
  }

  componentWillUnmount() {
    unregisterEvent(this.props.keyCode, this.keyDownHandler);
  }

  keyDownHandler = () => {
    const { disabled, action } = this.props;
    if (!disabled && action) {
      action();
    }
  }

  render() {
    return null;
  }
}

SubmitHandler.propTypes = {
  action: PropTypes.func.isRequired,
  keyCode: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

SubmitHandler.defaultProps = {
  disabled: false,
};

export default SubmitHandler;
