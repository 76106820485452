import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ModalActions, { finishDialogAddFarm } from '../../store/ModalFarm.actions';

import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Steps from '../Steps';
import Step1, { hasStep1Error } from './Step1';
import Step2 from './Step2';

class AddFarmModal extends Component {
  steps = 2;

  getStep() {
    switch (this.props.modal.step) {
      case 2:
        return <Step2 modal={this.props.modal} />;
      default:
        return <Step1 modal={this.props.modal} />;
    }
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogAddFarm(this.props.modal.data);
  }

  render() {
    const { step } = this.props.modal;

    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="farms.add-farm" defaultMessage="Add Farm" />
        </ModalHeader>
        <div>
          <Steps steps={this.steps} actualStep={step} />
        </div>
        <ModalBody>
          {this.getStep()}
        </ModalBody>
        <Footer
          step={step}
          maxStep={this.steps}
          finisDialog={this.finish}
          finisDialogText={<FormattedMessage id="farms.add-farm" defaultMessage="Add Farm" />}
          modal={this.props.modal}
          validators={{ 1: hasStep1Error }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogAddFarm,
}, dispatch);

AddFarmModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogAddFarm: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
    data: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(AddFarmModal);
