export default {
  batAgent: {
    minVersion: process.env.REACT_APP_AGENT_VERSION,
    address: process.env.REACT_APP_AGENT_ADDRESS,
    protocol: process.env.REACT_APP_AGENT_PROTOCOL,
  },
  help: {
    cloud: process.env.REACT_APP_HELP_CLOUD,
    addGsm: process.env.REACT_APP_HELP_ADD_GSM,
    addCable: process.env.REACT_APP_HELP_ADD_CABLE,
    import: process.env.REACT_APP_HELP_IMPORT,
    importFile: process.env.REACT_APP_HELP_IMPORT_FILE,
  },
};
