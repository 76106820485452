import { GET_STATUS } from './Status.actions';

const initialState = {
  flockGrowthHasError: false,
  devicesHasError: false,
  dataUpdateHasError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};


export default reducer;
