import React from 'react';
import { Label } from '@veit/veit-web-controls';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import GrowthStatus from '../_partials/GrowthStatus';
import Progress from '../FlockList/Progress';
import { flockType } from '../../types';
import { sortLastValuesBySex } from '../../utils/flock';
import {
  LastUpdate, LastAverage, LastCount, LastSex, LastGain, LastCv, LastUniformity,
} from '../_table';
import PropTypes from 'prop-types';

const FlockTabRow = ({ flock, weightUnit, dateFormat }) => {
  const stats = sortLastValuesBySex((flock || {}).lastStatistics);
  const closed = flock.endDate != null || dayjs(flock.startDate).isAfter(dayjs());
  const history = useHistory();
  return (
    <tr className="has-pointer" onClick={() => history.push(`/flock/all/${flock.id}`)}>
      <td>
        <Label>{flock.name}</Label>
        {flock.bird && (<Label type="info">{flock.bird.name}</Label>)}
      </td>
      <td>
        <LastUpdate lastStatistics={flock.lastStatistics} dateFormat={dateFormat} />
      </td>
      <td className="text-center">
        {!closed && <GrowthStatus lastStatistics={stats} />}
      </td>
      <td><Progress flock={flock} /></td>
      <td className="text-center">
        <LastSex lastStatistics={stats} />
      </td>
      <td>
        <LastAverage lastStatistics={stats} weightUnit={weightUnit} />
      </td>
      <td>
        <LastGain lastStatistics={stats} weightUnit={weightUnit} />
      </td>
      <td>
        <LastCount lastStatistics={stats} />
      </td>
      <td>
        <LastUniformity lastStatistics={stats} />
      </td>
      <td>
        <LastCv lastStatistics={stats} />
      </td>
    </tr>
  );
};

FlockTabRow.propTypes = {
  flock: flockType,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

FlockTabRow.defaultProps = {
  flock: null,
  weightUnit: null,
  dateFormat: null,
};

export default FlockTabRow;
