import { ApiClient } from '@veit/bat-cloud-api/lib/ApiClient';
import auth from './auth';
import { getResponseMessage } from './format';
import validateToken from './auth-validate';

export const run = (path, httpMethod = 'GET', body = {}, queryParams = {}) => {
  return ApiClient.instance.callApi(path, httpMethod, {}, queryParams, {}, {}, body, ['OAuth2'], ['application/json'], ['application/json'], Object);
};

export const create = (action, request, getResult = r => r.data.data) => async (dispatch) => {
  if (!(await validateToken(dispatch)).resolve()) {
    return Promise.reject();
  }
  dispatch({ type: `${action}_REQUEST` });
  return request().then((result) => {
    dispatch({ type: action, payload: getResult(result) });
    return result;
  }).catch((error) => {
    dispatch({
      type: `${action}_${error.status === 412 ? 'WARN' : 'ERROR'}`,
      payload: getResponseMessage(error),
      report: true,
    });
    if (error.status === 401) auth.signOut();
    throw error;
  });
};
