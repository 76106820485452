import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@veit/veit-web-controls';
import SectionTitle from './SectionTitle';

const SectionText = ({
  title, subtitle, children, invert,
}) => {
  return (
    <Row className={invert ? 'invert' : null}>
      <Col lg={{ size: 7, offset: 5 }}>
        <SectionTitle>{title}</SectionTitle>
        <h3>{subtitle}</h3>
        <p>{children}</p>
      </Col>
    </Row>
  );
};

SectionText.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  invert: PropTypes.bool,
};

SectionText.defaultProps = {
  invert: false,
};

export default SectionText;
