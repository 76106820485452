import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionText from './SectionText';

const Section3 = () => {
  return (
    <section className="section-3">
      <SectionText
        title={<FormattedMessage id="home.bat2-compatible" defaultMessage="BAT2 compatible" />}
        subtitle={<FormattedMessage id="home.connect-your-scale" defaultMessage="Connect your scales today!" />}
        invert
      >
        {/* eslint-disable-next-line max-len */}
        <FormattedMessage id="home.do-you-use" defaultMessage="Do you use BAT2 scales? Just connect them and enjoy the comfort of the BAT Cloud." />
      </SectionText>
    </section>
  );
};

export default Section3;
