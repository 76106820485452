import {
  GET_COMPARE,
  GETBY_FLOCK_COMPARE,
  PUT_COMPARE,
  POST_COMPARE,
  DELETE_COMPARE,
} from './Analytics.actions';

const initialState = {
  compare: [],
  stats: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPARE: {
      return {
        ...state,
        compare: action.payload || initialState,
        stats: {},
        links: action.links,
      };
    }
    case GETBY_FLOCK_COMPARE: {
      return {
        ...state,
        stats: { ...state.stats, ...action.payload },
      };
    }
    case POST_COMPARE:
      return {
        ...state,
        compare: [...state.compare, action.payload],
      };
    case PUT_COMPARE:
      return {
        ...state,
        compare: state.compare.map(m => (m.id === action.payload.id ? action.payload : m)),
      };
    case DELETE_COMPARE:
      return {
        ...state,
        compare: state.compare.filter(m => m.id !== action.payload),
      };
    default:
      return state;
  }
};


export default reducer;
