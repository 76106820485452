import queryString from 'query-string';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return queryString.parse(action.payload.location.search);
    default:
      return state;
  }
};

export default reducer;
