import { statsType, sexType } from '../model/enums';

export const groupByDay = (stats, types = [statsType.automatic, statsType.manual]) => {
  return Object.values((stats || []).reduce((result, stat) => {
    if (types != null && types.indexOf(stat.type) === -1) return result;
    const sex = stat.category === sexType.male || stat.category === sexType.female
      ? stat.category : sexType.irrelevant;
    const day = `${stat.dateTime.getFullYear()}-${stat.dateTime.getMonth()}-${stat.dateTime.getDate()}-${sex}`;
    const dayStats = result[day] || {};
    dayStats[stat.type || statsType.automatic] = stat;
    return { ...result, [day]: dayStats };
  }, {}));
};

export const groupBySex = (stats) => {
  return Object.values((stats || []).reduce((result, stat) => {
    const { category } = stat;
    const sex = category === sexType.male || category === sexType.female
      ? category
      : sexType.irrelevant;
    const sexStats = result[sex] || [];
    sexStats.push(stat);
    return { ...result, [sex]: sexStats };
  }, {}));
};

export const getAdjacent = (stats, stat) => {
  const st = stats.find(f => f.dateTime === stat.dateTime && f.category === stat.category);
  const index = stats.indexOf(st);

  let next = null;
  let prev = null;
  if (index >= 0 && index < stats.length - 1) next = stats[index + 1];
  if (index > 0 && index <= stats.length - 1) prev = stats[index - 1];
  return {
    stat, stats, next, prev,
  };
};

export default {
  groupByDay,
};
