import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import * as ModalActions from '../../store/Modal.actions';
import {
  Input,
  Label,
  ButtonSwitch,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';
import { helpActionType, helpActionTypeName } from '../../model/enums';

export function hasModalContentError({ slug, title }) {
  return {
    slug: slug == null || !validator.isLength(validator.trim(slug), { min: 1 }),
    title: title == null || !validator.isLength(validator.trim(title), { min: 1 }),
  };
}

const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';

function slugify(value) {
  let str = value
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase();

  // eslint-disable-next-line no-plusplus
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  return str.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
}

const ModalContent = ({
  modal, updateFormEvent, updateForm,
}) => {
  const form = modal.data;
  if (form == null) return null;
  return (
    <React.Fragment>
      <Label type="text">Title</Label>
      <Input value={form.title || ''} onChange={e => updateFormEvent(e, 'title')} />
      <Label type="text">Slug</Label>
      <Input value={form.slug || ''} onChange={e => updateForm(slugify(e.target.value), 'slug')} />
      <Label type="text">Page action</Label>
      <UncontrolledDropdown>
        <DropdownToggle color="primary" outline caret>
          {form.actions ? helpActionTypeName[form.actions] : helpActionTypeName.none}
        </DropdownToggle>
        <DropdownMenu>
          {Object.keys(helpActionType).map(p => (
            <DropdownItem
              onClick={() => updateForm(p, 'actions')}
              key={p}
            >
              {helpActionTypeName[p]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <Label type="text">Disable help page</Label>
      <ButtonSwitch
        options={[
          { title: 'Enabled', value: false },
          { title: 'Disabled', value: true },
        ]}
        selected={form.disabled || false}
        onChange={v => updateForm(v, 'disabled')}
      />
    </React.Fragment>
  );
};

ModalContent.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  farms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    farms: state.farm.items,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFormEvent: ModalActions.updateFormEvent,
  updateForm: ModalActions.updateForm,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
