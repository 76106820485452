import * as ModalActions from './Modal.actions';

import { postLoraDevice, putLoraDevice, postLoraScale } from './Lora.actions';

export const ADD_LORA_DEVICE = 'ADD_LORA_DEVICE';
export const ADD_LORA_SCALE = 'ADD_LORA_SCALE';
export const EDIT_LORA_DEVICE = 'EDIT_LORA_DEVICE';


export const openDialogAddLoraDevice = data => (dispatch) => {
  return ModalActions.openDialog(ADD_LORA_DEVICE, data)(dispatch);
};

export const finishDialogAddLoraDevice = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postLoraDevice(data), ADD_LORA_DEVICE)(dispatch);
};

export const openDialogAddLoraScale = data => (dispatch) => {
  return ModalActions.openDialog(ADD_LORA_SCALE, data)(dispatch);
};

export const finishDialogAddLoraScale = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postLoraScale(data), ADD_LORA_SCALE)(dispatch);
};

export const openDialogEditLoraDevice = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_LORA_DEVICE, data)(dispatch);
};

export const finishDialogEditLoraDevice = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putLoraDevice(data.devEUI, data), EDIT_LORA_DEVICE)(dispatch);
};

export default ModalActions;
