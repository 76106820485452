import { disableKey, enableKey } from '../utils/keyEvents';
import { keyCodes } from '../components/SubmitHandler';

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const FINISH_DIALOG = 'FINISH_DIALOG';
export const FINISH_DIALOG_REQUEST = 'FINISH_DIALOG_REQUEST';
export const FINISH_DIALOG_ERROR = 'FINISH_DIALOG_ERROR';
export const CANCEL_DIALOG = 'CANCEL_DIALOG';
export const UPDATE_DIALOG_FORM = 'UPDATE_DIALOG_FORM';
export const SET_STEP_DIALOG = 'SET_STEP_DIALOG';
export const DIALOG_SET_LOADER = 'DIALOG_SET_LOADER';

const promises = [];

export const isOpen = (modal, target) => {
  return modal != null && modal.target === target && modal.type === OPEN_DIALOG;
};

export const updateForm = (value, name) => (dispatch) => {
  dispatch({ type: UPDATE_DIALOG_FORM, payload: { [name]: value } });
};

export const updateFormObject = object => (dispatch) => {
  dispatch({ type: UPDATE_DIALOG_FORM, payload: object });
};

export const updateFormEvent = (event, name) => (dispatch) => {
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  updateForm(value, name)(dispatch);
};

export const openDialog = (target, data, step, useKeyNavigation, metadata = {}) => (dispatch) => {
  if (!useKeyNavigation) {
    disableKey(keyCodes.arrowLeft);
    disableKey(keyCodes.arrowRight);
  }
  dispatch({
    type: OPEN_DIALOG,
    target,
    payload: data,
    step,
    metadata,
  });
  return new Promise((res) => { promises.push(res); });
};

export const resolveDialog = (result) => {
  enableKey(keyCodes.arrowLeft);
  enableKey(keyCodes.arrowRight);
  if (promises.length !== 0) {
    const promise = promises.pop();
    promise(result);
  }
};

export const finishDialog = (target, data) => (dispatch) => {
  dispatch({ type: FINISH_DIALOG, target, payload: data });
  resolveDialog(data || {});
};

export const finishDialogRequest = (action, target, data) => (dispatch) => {
  dispatch({ type: FINISH_DIALOG_REQUEST, target, payload: data });
  return action()(dispatch)
    .then((response) => {
      finishDialog(target, Object.assign({}, response, data || {}))(dispatch);
      return response;
    })
    .catch((error) => {
      dispatch({ type: DIALOG_SET_LOADER, target, payload: false });
      return Promise.reject(error);
    });
};

export const resolveDialogRequest = (action, target, data) => (dispatch) => {
  return action()(dispatch)
    .then((response) => {
      resolveDialog(data || {});
      return response;
    })
    .catch((error) => {
      dispatch({ type: DIALOG_SET_LOADER, target, payload: false });
      return Promise.reject(error);
    });
};

export const cancelDialog = () => (dispatch) => {
  dispatch({ type: CANCEL_DIALOG });
  resolveDialog(null);
};

export const setStep = step => (dispatch) => {
  dispatch({ type: SET_STEP_DIALOG, payload: step });
};

export const setLoader = isExecuting => (dispatch) => {
  dispatch({ type: DIALOG_SET_LOADER, payload: isExecuting });
};
