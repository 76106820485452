import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Container,
  Row,
  Col,
} from '@veit/veit-web-controls';
import Avatar from 'react-avatar';

import ModalWithLoader from '../ModalWithLoader';
import ModalActions, { finishDialogEditProfile } from '../../store/ModalProfile.actions';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import { LanguageSelector } from '../../components/LanguageSelector';
import { changeLanguage, changeWeightUnit, changeDateFormat } from '../../store/Auth.actions';
import { WeightUnitSelector } from '../../components/WeightUnitSelector';
import { DateFormatSelector } from '../../components/DateFormatSelector';

class EditProfileModal extends Component {
  componentDidMount() {
    this.props.updateFormObject({
      ...this.props.user,
      localeOriginal: this.props.user.locale,
      weightUnitOriginal: this.props.user.weightUnit,
      dateFormatOriginal: this.props.user.dateFormat,
    });
  }

  onChangeFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.props.updateForm(e.target.result, 'image');
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  changeLanguage = (lang) => {
    this.props.updateForm(lang, 'locale');
    this.props.changeLanguage(lang, false);
  }

  changeWeightUnit = (unit) => {
    this.props.updateForm(unit, 'weightUnit');
    this.props.changeWeightUnit(unit, false);
  }

  changeDateFormat = (format) => {
    this.props.updateForm(format, 'dateFormat');
    this.props.changeDateFormat(format, false);
  }

  cancel = () => {
    const {
      locale, localeOriginal, weightUnit, weightUnitOriginal, dateFormat, dateFormatOriginal,
    } = this.props.modal.data;
    if (locale !== localeOriginal) {
      this.props.changeLanguage(localeOriginal, false);
    }
    if (weightUnit !== weightUnitOriginal) {
      this.props.changeWeightUnit(weightUnitOriginal, false);
    }
    if (dateFormat !== dateFormatOriginal) {
      this.props.changeDateFormat(dateFormatOriginal, false);
    }
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditProfile(this.props.modal.data);
  }

  render() {
    const form = this.props.modal.data;
    if (form == null) return null;
    const { updateFormEvent } = this.props;
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="modals.profile.edit-profile" defaultMessage="Edit Profile" />
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col
                xs="5"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {
                  form.image && (
                    <Button style={{ marginBottom: '10px' }} outline onClick={() => this.props.updateForm(null, 'image')} className="btn-narrow" color="primary">
                      <FormattedMessage id="common.remove" defaultMessage="Remove" />
                    </Button>
                  )
                }
                <Avatar
                  style={{ cursor: 'pointer' }}
                  color="#469acf"
                  // onClick={() => this.upload.click()}
                  size="80"
                  round
                  textSizeRatio={1.75}
                  name={form.name || 'A'}
                  src={form.image}
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={(ref) => { this.upload = ref; }}
                  style={{ display: 'none' }}
                  onChange={this.onChangeFile.bind(this)}
                />
              </Col>
              <Col xs="7">
                <Label type="text">
                  <FormattedMessage id="common.email" defaultMessage="E-mail" />
                </Label>
                <Input type="text" disabled defaultValue={form.email || ''} />
                <Label type="text">
                  <FormattedMessage id="common.name" defaultMessage="Name" />
                </Label>
                <Input type="text" value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} />
                <Label type="text">
                  <FormattedMessage id="common.language" defaultMessage="Language" />
                </Label>
                <LanguageSelector
                  noStyle
                  auth={{
                    locale: form.locale,
                    isAuthenticated: false,
                  }}
                  changeLanguage={this.changeLanguage}
                />
                <Label type="text">
                  <FormattedMessage id="common.units" defaultMessage="Units" />
                </Label>
                <WeightUnitSelector
                  noStyle
                  auth={{
                    weightUnit: form.weightUnit,
                    isAuthenticated: false,
                  }}
                  changeWeightUnit={this.changeWeightUnit}
                />
                <Label type="text">
                  <FormattedMessage id="common.date-format" defaultMessage="Date format" />
                </Label>
                <DateFormatSelector
                  noStyle
                  auth={{
                    dateFormat: form.dateFormat,
                    isAuthenticated: false,
                  }}
                  changeDateFormat={this.changeDateFormat}
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.finish}>
            <FormattedMessage id="common.save-changes" defaultMessage="Save changes" />
          </Button>
          <div style={{ flex: '1' }}></div>
          <Button color="primary" outline onClick={this.cancel}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </Button>
        </ModalFooter>
        <SubmitHandler action={this.finish} keyCode={keyCodes.enter} />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
  updateFormObject: ModalActions.updateFormObject,
  changeLanguage,
  changeWeightUnit,
  changeDateFormat,
  finishDialogEditProfile,
}, dispatch);

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

EditProfileModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  finishDialogEditProfile: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  changeWeightUnit: PropTypes.func.isRequired,
  changeDateFormat: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    locale: PropTypes.string,
    weightUnit: PropTypes.string,
    dateFormat: PropTypes.string,
  }).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal);
