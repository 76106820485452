import React from 'react';
import { toast } from 'react-toastify';
import { ToastSuccess, ToastWarn, ToastError } from '../components/ToastMessage';

const options = { hideProgressBar: true, closeButton: false };

export default {
  error: (message, detail, duration = 10000) => toast(
    <ToastError message={message} detail={detail} />, { ...options, autoClose: duration },
  ),
  warn: (message, detail, duration = 10000) => toast(
    <ToastWarn message={message} detail={detail} />, { ...options, autoClose: duration },
  ),
  success: (message, detail, duration = 10000) => toast(
    <ToastSuccess message={message} detail={detail} />, { ...options, autoClose: duration },
  ),
};
