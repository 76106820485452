import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import SexIcon from '../../components/SexIcon';

const LastSexGroup = ({ lastStatistics }) => {
  if (lastStatistics == null || lastStatistics.lenght === 0) return null;
  return lastStatistics.map((ls) => {
    return (
      <Label key={`${ls[0].category}`}>
        <SexIcon sex={ls[0].category} />
      </Label>
    );
  });
};

LastSexGroup.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    dateTime: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }))),
};

LastSexGroup.defaultProps = {
  lastStatistics: [],
};

export default LastSexGroup;
