/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import bem from '../utils/bem';

const bm = bem.component('dropdown-filter');

const DropDownInput = ({
  value, values, onChange, placeholder, disabled,
  ...otherProps
}) => {
  const [state, setState] = useState({ isOpen: false, filter: value || '' });

  const toggle = (v, stayOpen) => {
    if (state.isOpen) {
      if (value !== v) onChange(v);
      setState({ isOpen: stayOpen || false, filter: v || '' });
    } else {
      setState({ isOpen: true, filter: value || '' });
    }
  };
  return (
    <Dropdown
      direction="down"
      className={bm.b()}
      isOpen={state.isOpen}
      toggle={() => toggle(state.filter)}
      {...otherProps}
    >
      <DropdownToggle color="primary" caret disabled={disabled} tag="div">
        {
          state.isOpen
            ? (
              <input
                autoFocus
                value={state.filter}
                onChange={e => setState({ filter: e.target.value, isOpen: true })}
              />
            )
            : value == null ? <span className={bm.e('placeholder')}>{placeholder}</span> : <span>{value}</span>
        }
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            escapeWithReference: true,
          },
        }}
      >
        {state.filter && state.filter !== '' && (
          <DropdownItem className={bm.e('action')} onClick={() => setState({ isOpen: true, filter: '' })} toggle={false}>
            <FormattedMessage id="common.clear" defaultMessage="Clear" />
          </DropdownItem>
        )}
        {
          values
            .filter(f => f != null && f.toLowerCase().indexOf((state.filter || '').toLowerCase()) !== -1)
            .map(m => (
              <DropdownItem key={m} onClick={() => toggle(m)} toggle={false}>
                {m}
              </DropdownItem>
            ))
        }
      </DropdownMenu>
    </Dropdown>
  );
};

DropDownInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  values: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

DropDownInput.defaultProps = {
  value: null,
  values: [],
  disabled: false,
};

export default DropDownInput;
