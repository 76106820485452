import React from 'react';
import PropTypes from 'prop-types';

import HistogramChart from '../../charts/Histogram';


export function calcHistogram(weighings, stepsCount = 39, percentage = 30) {
  const center = Math.round(weighings.reduce((p, c) => p + c.weight, 0) / weighings.length);
  const min = Math.round(center * (100 - percentage) / 100);
  const stepsFromCenter = Math.floor(stepsCount / 2);
  const stepSize = Math.max(Math.round((center - min) / stepsFromCenter), 1);

  const steps = weighings.reduce((p, c) => {
    const { weight } = c;
    let step = Math.round((center - weight) / stepSize);
    step = step > stepsFromCenter
      ? stepsFromCenter
      : step < -stepsFromCenter ? -stepsFromCenter : step;
    const target = center - (step * stepSize);
    const count = p[target] || 0;
    return { ...p, [target]: count + 1 };
  }, {});

  return {
    center,
    stepSize,
    stepsCount,
    steps,
  };
}

function ViewHistogram({ weighings, histogram, weightUnit }) {
  const activeHistogram = histogram || calcHistogram(weighings);
  return (<HistogramChart data={activeHistogram} weightUnit={weightUnit} />);
}

ViewHistogram.propTypes = {
  weighings: PropTypes.arrayOf(PropTypes.shape({
    dateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    weight: PropTypes.number,
    category: PropTypes.string,
  })),
  histogram: PropTypes.shape({
    center: PropTypes.number,
  }),
  weightUnit: PropTypes.string,
};

ViewHistogram.defaultProps = {
  weighings: [],
  histogram: null,
  weightUnit: null,
};

export default ViewHistogram;
