import { create, run } from '../utils/api';

export const GET_LORA_GATEWAY = 'GET_LORA_GATEWAY';
export const GET_LORA_DEVICE = 'GET_LORA_DEVICE';
export const POST_LORA_DEVICE = 'POST_LORA_DEVICE';
export const PUT_LORA_DEVICE = 'PUT_LORA_DEVICE';
export const DELETE_LORA_DEVICE = 'DELETE_LORA_DEVICE';

export const POST_LORA_SCALE = 'POST_LORA_SCALE';
export const GET_LORA_LOCATIONS = 'GET_LORA_LOCATIONS';

export const getLoraGateways = () => create(GET_LORA_GATEWAY, () => run('/lora/gateway'));
export const getLoraDevices = () => create(GET_LORA_DEVICE, () => run('/lora/device'));
export const postLoraDevice = device => create(POST_LORA_DEVICE, () => run('/lora/device', 'POST', device));
export const putLoraDevice = (devEUI, device) => create(PUT_LORA_DEVICE, () => run(`/lora/device/${devEUI}`, 'PUT', device));
export const deleteLoraDevice = devEUI => create(DELETE_LORA_DEVICE, () => run(`/lora/device/${devEUI}`, 'DELETE'));

export const postLoraScale = device => create(POST_LORA_SCALE, () => run('/lora/scale', 'POST', device));

export const getLocations = companyId => create(GET_LORA_LOCATIONS, () => run(`/lora/locations/${companyId}`));
