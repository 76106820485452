import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressCircular = ({ percent, children }) => {
  return (
    <CircularProgressbarWithChildren value={percent}>
      {children}
    </CircularProgressbarWithChildren>
  );
};

ProgressCircular.propTypes = {
  percent: PropTypes.number,
  children: PropTypes.node,
};

ProgressCircular.defaultProps = {
  percent: 0,
  children: null,
};

export default ProgressCircular;
