import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalBody, Container, Row, Col,
} from '@veit/veit-web-controls';
import IMBat2Stick from '@veit/veit-web-controls/dist/icons/IMBat2Stick';
import IMBat1Usb from '@veit/veit-web-controls/dist/icons/IMBat1Usb';

import bem from '../../utils/bem';
import { deviceType, deviceTypeName } from '../../model/enums';

const bm = bem.modal('add-device');

const DeviceBlock = ({ icon: Icon, type, onSelect }) => {
  return (
    <Col xs="12" md="6">
      <div tabIndex={0} role="button" className="device-type" onKeyPress={() => onSelect(type)} onClick={() => onSelect(type)}>
        <Icon />
        <div className={bm.e('device-title')}>{deviceTypeName[type]}</div>
      </div>
    </Col>
  );
};

DeviceBlock.propTypes = {
  icon: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const SelectDeviceImport = ({ onSelect }) => {
  return (
    <ModalBody className={bm.e('select-type')}>
      <Container>
        <Row>
          <DeviceBlock
            icon={IMBat1Usb}
            type={deviceType.bat1}
            onSelect={onSelect}
          />
          <DeviceBlock
            icon={IMBat2Stick}
            type={deviceType.bat2}
            onSelect={onSelect}
          />
        </Row>
      </Container>
    </ModalBody>
  );
};

SelectDeviceImport.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default SelectDeviceImport;
