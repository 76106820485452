import { CognitoAuth } from 'amazon-cognito-auth-js';
import { login, logoutRedirect } from '../store/Auth.actions';
import authData from '../setup/auth.config';

const auth = new CognitoAuth(authData);

let callback = null;

function resolve(result) {
  if (callback) {
    callback(result);
    callback = null;
  }
}

export const init = (dispatch) => {
  auth.useCodeGrantFlow();
  auth.userhandler = {
    onSuccess: () => {
      login()(dispatch);
      resolve(true);
    },
    onFailure: () => {
      auth.clearCachedTokensScopes();
      logoutRedirect()(dispatch);
      resolve(false);
    },
  };
};

export default {
  signIn: () => auth.getSession(),
  signOut: () => auth.signOut(),
  parseTokens: response => auth.parseCognitoWebResponse(response),
  isUserSignedIn: () => auth.isUserSignedIn(),
  isValid: () => auth.getCachedSession().isValid(),
  getCachedSession: () => auth.getCachedSession(),
  refreshSession: () => {
    return new Promise((cb) => {
      callback = cb;
      auth.refreshSession(auth.getCachedSession().getRefreshToken().getToken());
    });
  },
};
