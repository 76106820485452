import React from 'react';
import PropTypes from 'prop-types';

const PublicLayout = ({ children }) => (
  <main>
    {children}
  </main>
);

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicLayout;
