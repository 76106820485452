import React from 'react';
import PropTypes from 'prop-types';
import { IMCheckMarkCircle, IMErrorMarkCircle, IMBat2Stick } from '@veit/veit-web-controls/dist/icons';
import { FormattedMessage } from 'react-intl';
import { dialogStates } from '../../store/ModalImport.actions';


import ProgressBase, { ProgressTemplate, TimeProgressTemplate } from '../import/ProgressBase';

const Downloading = ({ seconds }) => {
  return (
    <TimeProgressTemplate
      className="connecting"
      seconds={seconds}
      icon={IMBat2Stick}
      text={<FormattedMessage id="modals.import.reading-data" defaultMessage="Reading data from BAT2 memory stick ..." />}
    />
  );
};

Downloading.propTypes = {
  seconds: PropTypes.number.isRequired,
};

const MemoryNotConnected = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.disconnected" defaultMessage="BAT2 Memory stick is disconnected" />}
    />
  );
};

const Success = () => {
  return (
    <ProgressTemplate
      className="success"
      icon={IMCheckMarkCircle}
      text={<FormattedMessage id="modals.import.successfuly-imported" defaultMessage="Data were successfuly imported from memory stick" />}
    />
  );
};

const ReadError = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.read-error" defaultMessage="Failed to read data from memory stick" />}
    />
  );
};

const WriteError = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.write-error" defaultMessage="Failed to write data to memory stick" />}
    />
  );
};

const Progress = ({ state, seconds }) => {
  switch (state) {
    case dialogStates.memoryNotConnected: return <MemoryNotConnected />;
    case dialogStates.downloading:
    case dialogStates.uploading: return <Downloading seconds={seconds} />;
    case dialogStates.success: return <Success />;
    case dialogStates.readError: return <ReadError />;
    case dialogStates.writeError: return <WriteError />;
    default: return <ProgressBase state={state} />;
  }
};

Progress.propTypes = {
  state: PropTypes.string,
  seconds: PropTypes.number,
};

Progress.defaultProps = {
  state: null,
  seconds: 40,
};

export default Progress;
