import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import ModalActions, { finishDialogAddFlock } from '../../store/ModalFlock.actions';
import { modalGetBird, expandBird } from '../../store/Bird.actions';
import { modalGetLocation, expandLocation } from '../../store/Location.actions';
import { openDialogAddLocation } from '../../store/ModalLocation.actions';
import { modalGetFarm, expandFarm } from '../../store/Farm.actions';

import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import AnimateHeight from '../AnimateHeight';

import ModalWithLoader from '../ModalWithLoader';
import Steps from '../Steps';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';
import Step2, { hasStep2Error } from './Step2';

class AddFlockModal extends Component {
  steps = 2;

  componentDidMount() {
    this.fetchData();
  }

  getStep() {
    switch (this.props.modal.step) {
      case 2:
        return <Step2 modal={this.props.modal} />;
      default:
        return <Step1 modal={this.props.modal} addLocationAction={this.addLocationAction} />;
    }
  }

  addLocationAction = () => {
    this.props.openDialogAddLocation().then((l) => {
      if (l != null) {
        this.props.modalGetLocation({ expand: expandLocation.scalesCount });
        this.props.updateForm(l.id, 'locationId');
      }
    });
  }


  fetchData() {
    this.props.modalGetBird({ expand: expandBird.duration });
    this.props.modalGetLocation({ expand: expandLocation.scalesCount });
    this.props.modalGetFarm({ expand: expandFarm.housesCount });
  }

  render() {
    const { step } = this.props.modal;
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.props.cancelDialog}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.props.cancelDialog}>
          <FormattedMessage id="flocks.add-flock" defaultMessage="Add Flock" />
        </ModalHeader>
        <div>
          <Steps steps={this.steps} actualStep={step} />
        </div>
        <ModalBody>
          <AnimateHeight step={step}>
            {this.getStep()}
          </AnimateHeight>
        </ModalBody>
        <Footer
          step={step}
          maxStep={this.steps}
          finisDialog={() => this.props.finishDialogAddFlock(this.props.modal.data)}
          finisDialogText={<FormattedMessage id="flock.add-flock" defaultMessage="Add Flock" />}
          modal={this.props.modal}
          validators={{
            1: hasStep1Error,
            2: hasStep2Error,
          }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateForm: ModalActions.updateForm,
  finishDialogAddFlock,
  modalGetBird,
  modalGetLocation,
  modalGetFarm,
  openDialogAddLocation,
}, dispatch);

AddFlockModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogAddFlock: PropTypes.func.isRequired,
  modalGetBird: PropTypes.func.isRequired,
  modalGetLocation: PropTypes.func.isRequired,
  modalGetFarm: PropTypes.func.isRequired,
  openDialogAddLocation: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(AddFlockModal);
