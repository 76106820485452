import {
  GET_COMPANY,
  GET_CURRENT_COMPANY,
  POST_COMPANY,
  PUT_COMPANY,
  DELETE_COMPANY,
} from './Company.actions';

const initialState = {
  items: [],
  item: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return { ...state, items: action.payload, links: action.links };
    case GET_CURRENT_COMPANY:
      return { ...state, item: action.payload };
    case PUT_COMPANY:
      return { ...state, item: action.payload };
    case POST_COMPANY:
      return { ...state, items: [action.payload, ...state.items] };
    case DELETE_COMPANY:
      return { ...state, items: state.items.filter(f => f.id !== action.payload) };
    default:
      return state;
  }
};

export default reducer;
