import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  ButtonSwitch,
} from '@veit/veit-web-controls';

import DateFormat from '../../components/DateFormat';
import SexName from '../../components/SexName';
import bem from '../../utils/bem';
import formatDate from '../../utils/format';
import { statsType, categoryType } from '../../model/enums';

import ModalActions from '../../store/ModalFile.actions';
import { getAllSamplesByLocation, getAllSamplesByFlock } from '../../store/Samples.actions';
import ModalWithLoader from '../ModalWithLoader';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import ViewHistogram, { calcHistogram } from './ViewHistogram';
import ViewSorting from './ViewSorting';
import ViewWeighing from './ViewWeighing';
import ViewDetails from './ViewDetails';
import convertGramsToPounds from '../../utils/unitConversion';

const bm = bem.modal('weighing');

const views = {
  histogram: 0,
  sorting: 1,
  weighing: 2,
  details: 3,
};

const getValidStats = (stats) => {
  const defaultValue = { histogram: { stepsCount: 0 } };
  return stats.filter(
    f => f.manual || ((f.automatic || defaultValue).histogram || {}).stepsCount > 0,
  );
};

const findNext = (index, stats) => {
  if (index === 0 || stats.length <= 0) return null;
  const filtered = getValidStats(stats.slice(0, index));
  return filtered.length > 0 ? filtered[filtered.length - 1] : null;
};

const findPrev = (index, stats) => {
  if (index >= stats.length - 1) return null;
  const filtered = getValidStats(stats.slice(index + 1, stats.length));
  return filtered.length > 0 ? filtered[0] : null;
};

const isSexCategory = (category) => {
  return category === categoryType.male || category === categoryType.female;
};

const getDate = dateTime => formatDate(dateTime, 'YYYY-MM-DD');


class WeighingModal extends Component {
  state = {
    view: views.histogram,
    viewType: statsType.automatic,
  };

  componentDidMount() {
    this.getLocationSamples();
  }

  componentDidUpdate(prevProps) {
    if (getDate(prevProps.modal.data.dateTime) !== getDate(this.props.modal.data.dateTime)) {
      this.getLocationSamples();
    }
  }

  getLocationSamples = () => {
    const { dateTime, flockId, locationId } = this.props.modal.data;
    this.props.setLoader(true);
    let result = null;
    if (flockId != null) {
      result = this.props.getAllSamplesByFlock(locationId || flockId, getDate(dateTime));
    } else {
      result = this.props.getAllSamplesByLocation(locationId || flockId, getDate(dateTime));
    }
    result.then(() => { this.props.setLoader(false); });
  }

  goTo = (target) => {
    const index = this.props.modal.data.stats.indexOf(target);
    this.props.updateFormObject({
      dateTime: (target.manual || target.automatic).dateTime,
      index,
    });
  }

  getDayData = () => {
    const { stats, index } = this.props.modal.data;
    return {
      stat: stats[index],
      next: findNext(index, stats),
      prev: findPrev(index, stats),
    };
  }

  getView = (stat, category, hasSex, weightUnit) => {
    const { view, viewType } = this.state;
    const { samples } = this.props;

    const type = (viewType === statsType.automatic && stat.automatic)
      || (viewType === statsType.manual && !stat.manual)
      ? statsType.automatic
      : statsType.manual;

    const preFiltered = samples.filter(
      f => f.type === type && (hasSex ? f.category === category : !isSexCategory(f.category)),
    );

    let filtered = preFiltered;
    if (weightUnit === 'lb') {
      const doAct = a => convertGramsToPounds(a);
      filtered = preFiltered.map(o => ({ ...o, weight: doAct(o.weight) }));
    }


    const selectedView = (hasSex && view === views.sorting)
      || (type === statsType.automatic && (filtered.length === 0 || view === views.sorting))
      ? views.histogram
      : view;

    return {
      selectedView, type, filtered,
    };
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  render() {
    const { dateTime } = this.props.modal.data;
    const { stat, next, prev } = this.getDayData();
    const { category } = stat.manual || stat.automatic;
    const hasSex = category === categoryType.male || category === categoryType.female;
    const {
      selectedView,
      type,
      filtered,
    } = this.getView(stat, category, hasSex, this.props.weightUnit);

    const day = (stat.automatic || {}).day
      || (stat.automatic || {}).flockDay
      || (stat.manual || {}).flockDay;

    const isManual = type === statsType.manual;
    let manualCalculatedHistogram = null;
    if (isManual) {
      // for manual weighing need original samples in grams for calculating
      const { samples } = this.props;
      const filteredSamples = samples.filter(
        f => f.type === type && (hasSex ? f.category === category : !isSexCategory(f.category)),
      );
      manualCalculatedHistogram = calcHistogram(filteredSamples);
    }

    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        className={bm.b()}
      >
        <ModalHeader toggle={this.cancel}>
          <DateFormat date={dateTime} format={this.props.dateFormat} />
          {day && (
            <React.Fragment>
              {' - '}
              <FormattedMessage id="common.day" defaultMessage="Day" />
              {` ${day}`}
            </React.Fragment>
          )}
          <Label>
            {hasSex && (
              <React.Fragment>
                <SexName sex={category} />
                <span> - </span>
              </React.Fragment>
            )}
            <FormattedMessage
              values={{ count: filtered.length }}
              id="modals.histogram.measurements-in-total"
              defaultMessage="{count, plural, one {# MEASUREMENT} other {# MEASUREMENTS}} IN TOTAL"
            />
          </Label>
        </ModalHeader>
        <ModalBody>
          <div className={bm.e('navigation')}>
            <div className={stat.automatic && stat.manual ? null : 'disable'}>
              <ButtonSwitch
                style={{ display: 'none' }}
                options={[
                  stat.automatic ? { title: <FormattedMessage id="common.automatic" defaultMessage="Automatic" />, value: statsType.automatic } : null,
                  stat.manual ? { title: <FormattedMessage id="common.manual" defaultMessage="Manual" />, value: statsType.manual } : null,
                ].filter(f => f != null)}
                selected={type}
                onChange={v => this.setState({ viewType: v })}
              />
            </div>
            <div>
              <ButtonSwitch
                style={{ display: 'none' }}
                options={[
                  { title: <FormattedMessage id="common.histogram" defaultMessage="Histogram" />, value: 0 },
                  isManual && !hasSex ? { title: <FormattedMessage id="common.sorting" defaultMessage="Sorting" />, value: 1 } : null,
                  isManual || filtered.length > 0 ? { title: <FormattedMessage id="common.weighings" defaultMessage="Weighings" />, value: 2 } : null,
                ].filter(f => f != null)}
                selected={selectedView}
                onChange={v => this.setState({ view: v })}
              />
            </div>
          </div>
          <div className={bm.e('body')}>
            {selectedView === views.histogram && (
              <ViewHistogram
                histogram={isManual ? manualCalculatedHistogram : (stat.automatic || {}).histogram}
                weighings={filtered}
                weightUnit={this.props.weightUnit}
              />
            )}
            {selectedView === views.sorting && <ViewSorting weighings={filtered} />}
            {selectedView === views.weighing && (
              <ViewWeighing weighings={filtered} weightUnit={this.props.weightUnit} />
            )}
            {selectedView === views.details && <ViewDetails />}
          </div>
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row>
              <Col>
                <Button outline color="primary" onClick={this.cancel}>
                  <FormattedMessage id="common.close" defaultMessage="Close" />
                </Button>
              </Col>
              <Col>
                <Button disabled={!prev} outline color="primary" onClick={() => this.goTo(prev)}>
                  <FormattedMessage id="common.previous" defaultMessage="Previous" />
                </Button>
                <Button disabled={!next} color="primary" onClick={() => this.goTo(next)}>
                  <FormattedMessage id="common.next" defaultMessage="Next" />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
        <SubmitHandler
          disabled={!next}
          keyCode={keyCodes.arrowLeft}
          action={() => this.goTo(next)}
        />
        <SubmitHandler
          disabled={!prev}
          keyCode={keyCodes.arrowRight}
          action={() => this.goTo(prev)}
        />
        <SubmitHandler keyCode={keyCodes.escape} action={this.cancel} />
      </ModalWithLoader>
    );
  }
}

WeighingModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  getAllSamplesByLocation: PropTypes.func.isRequired,
  getAllSamplesByFlock: PropTypes.func.isRequired,
  samples: PropTypes.arrayOf(PropTypes.shape({
    dateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    weight: PropTypes.number,
    category: PropTypes.string,
    type: PropTypes.string,
  })),
  modal: PropTypes.shape({
    data: PropTypes.shape({
      dateTime: PropTypes.object,
      locationId: PropTypes.string,
      flockId: PropTypes.string,
      next: PropTypes.object,
      prev: PropTypes.object,
      stat: PropTypes.object,
      stats: PropTypes.array,
      index: PropTypes.number,
    }),
  }).isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

WeighingModal.defaultProps = {
  samples: [],
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = state => ({
  samples: state.samples,
  weightUnit: state.auth.weightUnit,
  dateFormat: state.auth.dateFormat,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormObject: ModalActions.updateFormObject,
  setLoader: ModalActions.setLoader,
  getAllSamplesByLocation,
  getAllSamplesByFlock,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WeighingModal);
