import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

import bem from '../../utils/bem';
import GatewayList from './GatewayList';
import LoraGatewayList from './LoraList/LoraGatewayList';
import LoraDeviceList from './LoraList/LoraDeviceList';
import CompanyList from './CompanyList';
import Sidebar from './Sidebar';

const bm = bem.view('admin');

const Admin = () => {
  return (
    <div className={bm.b()}>
      <div className={bm.e('nav', 'sidebar-open')}>
        <Sidebar />
      </div>
      <div className={bm.e('content')}>
        <Switch>
          <Route path="/admin/lora/gateways" component={LoraGatewayList} />
          <Route path="/admin/lora/devices" component={LoraDeviceList} />
          <Route path="/admin/gateways" component={GatewayList} />
          <Route path="/admin" component={CompanyList} />
        </Switch>
      </div>
    </div>
  );
};

export default Admin;
