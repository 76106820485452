import React from 'react';
import PropTypes from 'prop-types';
import { authType } from '../model/enums';
import { Button, AddButton } from '@veit/veit-web-controls';

const AuthButton = (props) => {
  const {
    tag: Tag, canExecute, canDelete, canUpdate, canCreate, ...otherProps
  } = props;
  const execute = canExecute
    || authType.canUpdate(canUpdate)
    || authType.canCreate(canCreate)
    || authType.canDelete(canDelete);
  return execute ? <Tag {...otherProps} /> : null;
};

export const AuthAddButton = (props) => {
  return <AuthButton tag={AddButton} {...props} />;
};

AuthButton.propTypes = {
  tag: PropTypes.elementType,
  canExecute: PropTypes.bool,
  canDelete: PropTypes.shape({ links: PropTypes.object }),
  canUpdate: PropTypes.shape({ links: PropTypes.object }),
  canCreate: PropTypes.shape({ links: PropTypes.object }),
};

AuthButton.defaultProps = {
  tag: Button,
  canExecute: false,
  canDelete: null,
  canUpdate: null,
  canCreate: null,
};


export default AuthButton;
