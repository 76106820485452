import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Card = ({ className, tag: Tag, ...otherProps }) => {
  return (
    <Tag className={classNames('bwc-card', className)} {...otherProps} />
  );
};

Card.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.elementType,
};

Card.defaultProps = {
  className: null,
  tag: 'div',
};

export default Card;
