import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutRedirect } from '../../store/Auth.actions';

class Logout extends Component {
  componentDidMount() {
    this.props.logoutRedirect();
  }

  render() {
    return null;
  }
}
Logout.propTypes = {
  logoutRedirect: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logoutRedirect,
}, dispatch);

export default connect(null, mapDispatchToProps)(Logout);
