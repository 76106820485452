import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import bem from '../utils/bem';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from '@veit/veit-web-controls';

import ModalWithLoader from './ModalWithLoader';
import SubmitHandler, { keyCodes } from '../components/SubmitHandler';

const bm = bem.modal('dialog');

class DialogModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState(prev => ({
      modal: !prev.modal,
    }));
  }

  confirm = () => {
    this.toggle();
    this.props.confirm();
  }

  render() {
    return (
      <React.Fragment>
        <Button className={this.props.narrow ? 'btn-narrow' : ''} color="primary" outline onClick={this.toggle}>
          {this.props.confirmText}
        </Button>
        <div className={bm.b()}>
          <ModalWithLoader keyboard={false} className={bm.e('modal')} centered isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <ModalBody>
              <Label type="title">
                <FormattedMessage id="modals.are-you-sure" defaultMessage="Are you sure?" />
              </Label>
              <Label className="subtitle">
                {this.props.text}
              </Label>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" outline onClick={this.toggle}>
                <FormattedMessage id="modals.dont-do-it" defaultMessage="Don’t do it" />
              </Button>
              <Button color="danger" onClick={this.confirm}>
                {this.props.confirmText}
              </Button>
            </ModalFooter>
            <SubmitHandler keyCode={keyCodes.enter} action={this.confirm} />
            <SubmitHandler keyCode={keyCodes.escape} action={this.toggle} />
          </ModalWithLoader>
        </div>
      </React.Fragment>
    );
  }
}

DialogModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  confirmText: PropTypes.node,
  text: PropTypes.node,
  narrow: PropTypes.bool,
};

DialogModal.defaultProps = {
  confirmText: null,
  text: null,
  narrow: false,
};

export default DialogModal;
