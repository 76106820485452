/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import bem from '../utils/bem';

const bm = bem.component('dropdown-filter');

const filterSearch = (value, search) => {
  const s = `${search}`.toLowerCase();
  return `${(value || '')}`.toLowerCase().indexOf(s) !== -1;
};

const DropDownWithFilter = ({
  selected, items, renderItem, getKey, getValue, onChange, actions, placeholder, disabled,
  ...otherProps
}) => {
  const [state, setState] = useState({ isOpen: false, filter: '' });
  const render = renderItem || getValue;

  return (
    <Dropdown
      direction="down"
      className={bm.b()}
      isOpen={state.isOpen}
      toggle={() => setState({ isOpen: !state.isOpen, filter: '' })}
      {...otherProps}
    >
      <DropdownToggle color="primary" caret disabled={disabled} tag="div">
        {
          state.isOpen
            ? (
              <input
                autoFocus
                value={state.filter}
                onChange={e => setState({ filter: e.target.value, isOpen: true })}
              // placeholder={selected != null ? getValue(selected) : null}
              />
            )
            : selected == null ? <span className={bm.e('placeholder')}>{placeholder}</span> : <span>{render(selected)}</span>
        }
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            escapeWithReference: true,
          },
        }}
      >
        {selected && (
          <DropdownItem className={bm.e('action')} onClick={() => onChange(null)}>
            <FormattedMessage id="common.clear" defaultMessage="Clear" />
          </DropdownItem>
        )}
        {!selected && actions.map(a => (
          <DropdownItem key={a.text} className={bm.e('action')} onClick={a.onClick}>
            {a.text}
          </DropdownItem>
        ))}
        {
          items
            .filter(f => f != null && filterSearch(getValue(f), state.filter))
            .map(m => (
              <DropdownItem key={getKey(m)} onClick={() => onChange(m)}>
                {render(m)}
              </DropdownItem>
            ))
        }
      </DropdownMenu>
    </Dropdown>
  );
};

DropDownWithFilter.propTypes = {
  getValue: PropTypes.func.isRequired,
  getKey: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.any,
  })),
  placeholder: PropTypes.node.isRequired,
  selected: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  renderItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

DropDownWithFilter.defaultProps = {
  renderItem: null,
  selected: null,
  actions: [],
  items: [],
  disabled: false,
};

export default DropDownWithFilter;
