import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
  Table, Label, Button,
} from '@veit/veit-web-controls';
import { IMDialMeter } from '@veit/veit-web-controls/dist/icons';

import { openDialogAddDevice, openDialogEditDevice } from '../../store/ModalDevice.actions';

import Page from '../../components/Page';
import QueryLink from '../../components/QueryLink';
import QuerySort from '../../components/QuerySort';
import bem from '../../utils/bem';
import { deviceTypeName, deviceType } from '../../model/enums';
import { sortString } from '../../utils/sort';
import pagination from '../../utils/pagination';
import { deviceOrder } from '../../model/order';
import goToConnect from '../../utils/goToConnect';
import intl from '../../setup/RIntl';

import fetchData from './fetchData';
import Location from './Location';
import Farm from '../_partials/Farm';

const messages = defineMessages({
  myConnectors: { id: 'devices.my-connectors', defaultMessage: 'My Connectors' },
});

class ConnectorList extends Component {
  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getData();
    }
  }

  getData = (fetchMetadata) => {
    this.props.fetchData(fetchMetadata, pagination(this.props.location.search));
  }

  getValue = (deviceId, unit) => {
    const { average } = (this.props.stats.items.find(f => f.id === deviceId) || {});
    return average == null ? null : `${average} ${unit}`;
  }

  resolveDialog = (result) => {
    if (result == null) return;
    const last = this.props.device.items.length === 1;
    if (result.delete && last) {
      this.props.goToPage(0);
    } else {
      this.getData(false);
    }
  }

  editDeviceHandler(e, device) {
    e.preventDefault();
    e.stopPropagation();
    this.props.openDialogEditDevice(device).then(this.resolveDialog);
  }

  render() {
    const bm = bem.view('device-list');
    const devices = this.props.device.items.filter(f => f.type === deviceType.terminal);
    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.myConnectors)}
        actions={<Button to="/houses" tag={QueryLink} color="primary" outline><FormattedMessage id="houses.manage-houses" defaultMessage="Manage houses" /></Button>}
        isFetching={this.props.fetch.isFetching}
        emptyPage={{
          isEmpty: this.props.device.items.length === 0,
          icon: IMDialMeter,
          text: <FormattedMessage id="common.nothing-here" defaultMessage="Nothing here." />,
        }}
        paginate={{ onChange: this.props.goToPage, ...this.props.device }}
        filter={{ search: true }}
      >
        <Table type="data">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.device" defaultMessage="Device" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={deviceOrder.type}>
                  <FormattedMessage id="common.type" defaultMessage="Type" />
                </QuerySort>
              </th>
              {/* <th>Connected</th>
              <th>Active</th> */}
              <th>
                <QuerySort orderby={deviceOrder.farm}>
                  <FormattedMessage id="common.farm" defaultMessage="Farm" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={deviceOrder.house}>
                  <FormattedMessage id="common.house" defaultMessage="House" />
                </QuerySort>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortString(devices, s => s.name).map(d => (
              <tr key={d.id}>
                <td><Label>{d.name}</Label></td>
                <td><Label>{deviceTypeName[d.type]}</Label></td>
                {/* <td><StatusIcon hasError={!d.connected} /></td>
                <td><StatusIcon hasError={!d.active} /></td> */}
                <td><Farm farmId={d.farmId} /></td>
                <td><Location locationId={d.locationId} /></td>
                <td className="text-center">
                  <Button onClick={e => this.editDeviceHandler(e, d)} className="btn-narrow" color="primary">
                    <FormattedMessage id="devices.edit-connector" defaultMessage="Edit Connector" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

ConnectorList.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogAddDevice: PropTypes.func.isRequired,
  openDialogEditDevice: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  device: PropTypes.shape({
    items: PropTypes.array,
  }),
  stats: PropTypes.shape({
    items: PropTypes.array,
  }),
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
};

ConnectorList.defaultProps = {
  device: null,
  stats: null,
  fetch: {},
};

const mapStateToProps = (state) => {
  return {
    device: state.device,
    stats: state.stats,
    fetch: state.fetch,
    locale: state.auth.locale,
  };
};

const mapDispatchToProps = {
  fetchData,
  openDialogAddDevice,
  openDialogEditDevice,
};

export default goToConnect('/connectors')(ConnectorList, mapStateToProps, mapDispatchToProps);
