import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';

const LastCount = ({ lastStatistics }) => {
  if (lastStatistics == null || lastStatistics.lenght === 0) return null;
  return lastStatistics.map((ls) => {
    return ls == null || ls.count == null ? null : (
      <Label key={`${ls.category}_${ls.type}`}>
        {ls.count}
      </Label>
    );
  });
};

LastCount.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    category: PropTypes.string,
    gain: PropTypes.number,
  })),
  scaleType: PropTypes.string,
};

LastCount.defaultProps = {
  lastStatistics: [],
};

export default LastCount;
