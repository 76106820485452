import React from 'react';
import PropTypes from 'prop-types';

import SortingChart from '../../charts/Sorting';

const calcSortings = (weighings) => {
  return (weighings || []).reduce((p, c) => {
    const { category } = c;
    const count = p[category] || 0;
    return { ...p, [category]: count + 1 };
  }, {});
};

const ViewSorting = ({ weighings }) => {
  const sorting = calcSortings(weighings);
  return (
    <SortingChart
      data={{
        keys: Object.keys(sorting),
        values: Object.values(sorting),
      }}
    />
  );
};

ViewSorting.propTypes = {
  weighings: PropTypes.arrayOf(PropTypes.shape({
    dateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    weight: PropTypes.number,
    category: PropTypes.string,
  })),
};

ViewSorting.defaultProps = {
  weighings: [],
};

export default ViewSorting;
