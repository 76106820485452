import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { IMNext, IMPrevious } from '@veit/veit-web-controls/dist/icons';
import { Label } from '@veit/veit-web-controls';

import SubmitHandler, { keyCodes } from './SubmitHandler';

const guidRegex = /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/i;

export const getGuid = (string) => {
  const match = guidRegex.exec(string);
  return match ? match[0] : null;
};

const ArrowNavigation = ({
  urlBase, text, next, prev, goTo,
}) => {
  const goToPage = (link) => {
    const base = urlBase.endsWith('/') ? urlBase : `${urlBase}/`;
    goTo(`${base}${link}`);
  };

  return (
    <div className="arrow-navigation">
      {prev && (
        <Link to={prev}>
          <Label>
            <IMPrevious />
            <FormattedMessage id="common.previous" defaultMessage="Previous" /> {text}
          </Label>
        </Link>
      )}
      {next && (
        <Link to={next}>
          <Label>
            <FormattedMessage id="common.next" defaultMessage="Next" /> {text}
            <IMNext />
          </Label>
        </Link>
      )}
      <SubmitHandler keyCode={keyCodes.arrowLeft} action={() => goToPage(prev)} disabled={!prev} />
      <SubmitHandler keyCode={keyCodes.arrowRight} action={() => goToPage(next)} disabled={!next} />
    </div>
  );
};

ArrowNavigation.propTypes = {
  goTo: PropTypes.func,
  className: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
  text: PropTypes.node,
  urlBase: PropTypes.string,
};

ArrowNavigation.defaultProps = {
  className: null,
  next: null,
  prev: null,
  goTo: null,
  text: '',
  urlBase: '',
};

export default ArrowNavigation;
