import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Icon } from '../../content/svg/veit_plus.svg';

const SectionTitle = ({ children }) => {
  return (
    <h2 className="section-title">
      <Icon />
      {children}
    </h2>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.node,
};

SectionTitle.defaultProps = {
  children: null,
};

export default SectionTitle;
