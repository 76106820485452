import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import { FormattedMessage } from 'react-intl';
import bem from '../utils/bem';

const bm = bem.component('icon-page');

const IconPage = ({
  icon: Icon, action, text, column,
}) => {
  return (
    <div className={bm.b()}>
      <Icon className={bm.e('icon')} />
      <div className={bm.e('content', column ? 'column' : null)}>
        <Label className={bm.e('text')} tag="span">{text}</Label>
        {action}
      </div>
    </div>
  );
};

IconPage.propTypes = {
  icon: PropTypes.func.isRequired,
  action: PropTypes.node,
  text: PropTypes.node,
  column: PropTypes.bool,
};

IconPage.defaultProps = {
  action: null,
  text: <FormattedMessage id="common.nothing-here-start" defaultMessage="Nothing here. Start with" />,
  column: false,
};

export default IconPage;
