import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainLayout from '../components/Layout/MainLayout';
import Startup from './Startup';
import ModalContainer from './ModalContainer';

const LayoutRoute = ({
  component: Component, layout: Layout, publicPath, isAuthenticated, roles, ...rest
}) => {
  const ActiveLayout = Layout == null ? MainLayout : Layout;
  return (
    <Route
      {...rest}
      render={props => (
        <ActiveLayout>
          <Startup roles={roles} publicPath={!!publicPath}>
            <Component {...props} />
            <ModalContainer />
          </Startup>
        </ActiveLayout>
      )}
    />
  );
};


LayoutRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func,
  publicPath: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

LayoutRoute.defaultProps = {
  layout: null,
  publicPath: false,
  isAuthenticated: false,
  roles: [],
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(LayoutRoute);
