import {
  GET_FARM,
  GETBY_FARM,
  PUT_FARM,
  GET_FARM_MODAL,
} from './Farm.actions';

const initialState = {
  items: [],
  item: null,
  links: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FARM: {
      const {
        size, offset, limit, links,
      } = action;
      return {
        ...state,
        items: action.payload || [],
        size,
        offset,
        limit,
        links,
      };
    }
    case GETBY_FARM:
      return { ...state, item: action.payload };
    case PUT_FARM:
      return {
        ...state,
        item: action.payload,
        items: state.items.map(f => (f.id === action.payload.id ? action.payload : f)),
      };
    case GET_FARM_MODAL:
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};


export default reducer;
