import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Table } from '@veit/veit-web-controls';
import { IMFarm } from '@veit/veit-web-controls/dist/icons';

import { openDialogImport } from '../../store/ModalImport.actions';
import { openDialogAddLocation } from '../../store/ModalLocation.actions';
import { openDialogAddFlock } from '../../store/ModalFlock.actions';

import Page from '../../components/Page';
import QuerySort from '../../components/QuerySort';
import { AuthAddButton } from '../../components/AuthButton';
import bem from '../../utils/bem';
import pagination from '../../utils/pagination';
import goToConnect from '../../utils/goToConnect';
import { locationOrder } from '../../model/order';
import intl from '../../setup/RIntl';

import fetchData from './fetchData';
import DeviceFilter from './DeviceFilter';
import TableRow from './TableRow';

const messages = defineMessages({
  locations: { id: 'common.locations', defaultMessage: 'Locations' },
});

class OverviewList extends Component {
  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search) {
      this.getData(false);
    }
  }

  getData = (fetchMetadata) => {
    this.props.fetchData(fetchMetadata, pagination(this.props.search));
  }

  addLocationDialog = () => {
    this.props.openDialogAddLocation().then(() => this.getData());
  }

  createFlock = (locationId) => {
    this.props.openDialogAddFlock({ locationId }).then((result) => {
      if (result != null) {
        this.getData();
      }
    });
  };

  actions = () => {
    return (
      <AuthAddButton canCreate={this.props.location} onClick={this.addLocationDialog}>
        <FormattedMessage id="modals.location.add-location" defaultMessage="Add Location" />
      </AuthAddButton>
    );
  }

  render() {
    const bm = bem.view('overview-list');
    const locations = this.props.location.items;
    const unit = this.props.weightUnit;
    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.locations)}
        actions={this.actions()}
        isFetching={this.props.fetch.isFetching}
        emptyPage={{
          isEmpty: locations.length === 0,
          icon: IMFarm,
          action: this.actions(),
        }}
        paginate={{ onChange: this.props.goToPage, ...this.props.location }}
        filter={{
          search: true,
          body: <DeviceFilter />,
        }}
      >
        <Table type="info">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.location" defaultMessage="Location" />
                </QuerySort>
              </th>
              <th>
                <FormattedMessage id="common.status" defaultMessage="Status" />
              </th>
              <th>
                <QuerySort orderby={locationOrder.date}>
                  <FormattedMessage id="common.last" defaultMessage="Last update" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={locationOrder.category}>
                  <FormattedMessage id="common.sex" defaultMessage="Sex" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={locationOrder.weight} style={{ marginLeft: '18px' }}>
                  <FormattedMessage id="common.average-weight-g" defaultMessage="Average Weight" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={locationOrder.age}>
                  <FormattedMessage id="common.age" defaultMessage="Age" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={locationOrder.flock}>
                  <FormattedMessage id="common.flock" defaultMessage="Flock" />
                </QuerySort>
              </th>
            </tr>
          </thead>
          <tbody>
            {locations.map(d => (
              <TableRow
                key={d.id}
                location={d}
                farms={this.props.farm.items}
                createFlock={this.createFlock}
                weightUnit={unit}
                dateFormat={this.props.dateFormat}
              />
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

OverviewList.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogImport: PropTypes.func.isRequired,
  openDialogAddLocation: PropTypes.func.isRequired,
  openDialogAddFlock: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  location: PropTypes.shape({
    items: PropTypes.array,
    links: PropTypes.object,
  }),
  farm: PropTypes.shape({
    items: PropTypes.array,
  }),
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  search: PropTypes.string,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

OverviewList.defaultProps = {
  location: {},
  farm: {},
  fetch: {},
  search: null,
  weightUnit: 'g',
  dateFormat: '',
};

const mapStateToProps = (state, ownProps) => {
  return {
    location: state.location,
    farm: state.farm,
    fetch: state.fetch,
    locale: state.auth.locale,
    weightUnit: state.auth.weightUnit,
    dateFormat: state.auth.dateFormat,
    search: ownProps.location.search,
  };
};

const mapDispatchToProps = {
  fetchData,
  openDialogImport,
  openDialogAddLocation,
  openDialogAddFlock,
};

export default goToConnect('/locations')(OverviewList, mapStateToProps, mapDispatchToProps);
