import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@veit/veit-web-controls';
import IMCheckMarkCircle from '@veit/veit-web-controls/dist/icons/IMCheckMarkCircle';

import bem from '../../utils/bem';

const bm = bem.component('step-success');

const Success = ({ subtitle, goTo }) => {
  return (
    <div className={bm.b()}>
      <IMCheckMarkCircle />
      <div className={bm.e('title')}>
        <FormattedMessage id="common.success" defaultMessage="Success!" />
      </div>
      <div className={bm.e('subtitle')}>
        {/* You need to configure your GSM Scale now. */}
        {subtitle}
      </div>
      <Button color="primary" onClick={goTo}>
        <FormattedMessage id="devices.modal.show-scale" defaultMessage="Show Scale" />
      </Button>
    </div>
  );
};

Success.propTypes = {
  goTo: PropTypes.func.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

Success.defaultProps = {
  subtitle: null,
};

export default Success;
