import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import { unitType, deviceType, statsType } from '../../model/enums';
import TypeIcon from './TypeIcon';
import convertGramsToPounds from '../../utils/unitConversion';

const LastAverage = ({
  lastStatistics, scaleType, sexOnly, noIcon, weightUnit,
}) => {
  if (lastStatistics == null || lastStatistics.length === 0) return null;
  return lastStatistics.map((ls) => {
    return ls == null || ls.average == null ? null : (
      <Label key={`${ls.category}_${ls.type}`} style={{ display: 'flex', alignItems: 'center' }}>
        {!noIcon && (
          <TypeIcon style={{ marginRight: '4px' }} type={scaleType === deviceType.bat1 && !sexOnly ? statsType.manual : ls.type} />
        )}
        {weightUnit === 'lb' ? `${convertGramsToPounds(ls.average)} ${unitType.lb}` : `${ls.average} ${unitType.g}`}
      </Label>
    );
  });
};

LastAverage.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.number,
    dateTime: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  })),
  sexOnly: PropTypes.bool,
  scaleType: PropTypes.string,
  noIcon: PropTypes.bool,
  weightUnit: PropTypes.string,
};

LastAverage.defaultProps = {
  lastStatistics: [],
  scaleType: null,
  sexOnly: false,
  noIcon: false,
  weightUnit: null,
};

export default LastAverage;
