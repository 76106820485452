import { fetchAll } from '../../store/Fetch.actions';
import { getDeviceBy, expandDevice, scopeDevice } from '../../store/Device.actions';
import { getStatsDeviceBy } from '../../store/Stats.actions';
import { getFarmBy, expandFarm } from '../../store/Farm.actions';
import { getLocationBy } from '../../store/Location.actions';
import { loadQuery } from '../../utils/queryCache';

const fetchData = id => (dispatch) => {
  const queryParams = loadQuery('/scales');
  fetchAll(() => [
    getDeviceBy(id, {
      expand: expandDevice.description,
      scope: scopeDevice.scale,
      orderby: queryParams.orderby,
      order: queryParams.order,
    })(dispatch)
      .then(async (r) => {
        if (r != null) {
          await Promise.all([
            getStatsDeviceBy(r.id)(dispatch),
            r.locationId ? getLocationBy(r.locationId)(dispatch) : null,
            r.farmId ? getFarmBy(r.farmId, { expand: [expandFarm.country] })(dispatch) : null,
          ]);
        }
      }),
  ])(dispatch);
};

export default fetchData;
