/* source: https://dev.to/murilovarela/an-easy-scroll-box-implementation-2b6a */
/* eslint-disable no-param-reassign */
import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { Button } from '@veit/veit-web-controls';
import IMNext from '@veit/veit-web-controls/dist/icons/IMNext';
import IMPrevious from '@veit/veit-web-controls/dist/icons/IMPrevious';

const scrollSize = 260;
const timing = (1 / 60) * 1000;
// eslint-disable-next-line no-bitwise
const decay = v => -0.1 * ((1 / timing) ^ 4) + v;

function useScrollBox(scrollRef) {
  const [clickStartX, setClickStartX] = useState();
  const [scrollStartX, setScrollStartX] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [direction, setDirection] = useState(0);
  const [momentum, setMomentum] = useState(0);
  const [lastScrollX, setLastScrollX] = useState(0);
  const [speed, setSpeed] = useState(0);

  const scrollWrapperCurrent = scrollRef.current;
  const handleLastScrollX = useCallback(
    throttle((screenX) => {
      setLastScrollX(screenX);
    }, timing),
    [],
  );
  const handleMomentum = useCallback(
    throttle((nextMomentum) => {
      setMomentum(nextMomentum);
      scrollRef.current.scrollLeft += nextMomentum * timing * direction;
    }, timing),
    [scrollWrapperCurrent, direction],
  );
  useEffect(() => {
    if (direction !== 0) {
      if (momentum > 0.1 && !isDragging) {
        handleMomentum(decay(momentum));
      } else if (isDragging) {
        setMomentum(speed);
      } else {
        setDirection(0);
      }
    }
  }, [momentum, isDragging, speed, direction, handleMomentum]);

  useEffect(() => {
    if (scrollRef.current) {
      const handleDragStart = (e) => {
        setClickStartX(e.screenX);
        setScrollStartX(scrollRef.current.scrollLeft);
        setDirection(0);
      };
      const handleDragMove = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (clickStartX !== undefined && scrollStartX !== undefined) {
          const touchDelta = clickStartX - e.screenX;
          scrollRef.current.scrollLeft = scrollStartX + touchDelta;

          if (Math.abs(touchDelta) > 1) {
            setIsDragging(true);
            setDirection(touchDelta / Math.abs(touchDelta));
            setSpeed(Math.abs((lastScrollX - e.screenX) / timing));
            handleLastScrollX(e.screenX);
          }
        }
      };

      const handleDragEnd = () => {
        if (clickStartX !== undefined) {
          setClickStartX(undefined);
          setScrollStartX(undefined);
          setIsDragging(false);
        }
      };

      const handleWheel = (e) => {
        scrollRef.current.scrollLeft += -e.wheelDelta;
      };

      if (scrollRef.current.ontouchstart === undefined) {
        scrollRef.current.onmousedown = handleDragStart;
        scrollRef.current.onmousemove = handleDragMove;
        scrollRef.current.onmouseup = handleDragEnd;
        scrollRef.current.onmouseleave = handleDragEnd;
        scrollRef.current.onwheel = handleWheel;
      }
    }
  }, [scrollWrapperCurrent, clickStartX, isDragging,
    scrollStartX, handleLastScrollX, lastScrollX, scrollRef]);

  return {
    clickStartX, scrollStartX, isDragging, direction, momentum, lastScrollX, speed,
  };
}

function ScrollBox({ children, showButtons }) {
  const scrollWrapperRef = useRef();
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const { isDragging } = useScrollBox(scrollWrapperRef);
  const scrollChange = throttle(({ scrollLeft, scrollWidth, clientWidth }) => {
    setShowLeft(scrollLeft > 0);
    setShowRight(scrollWidth - scrollLeft > clientWidth);
  }, timing);

  if (showButtons && scrollWrapperRef.current != null) {
    scrollWrapperRef.current.onscroll = e => scrollChange(e.target);
  }

  useEffect(() => {
    if (scrollWrapperRef.current != null) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollWrapperRef.current;
      const left = scrollLeft > 0;
      const right = scrollWidth - scrollLeft > clientWidth;
      if (showLeft !== left) setShowLeft(left);
      if (showRight !== right) setShowRight(right);
    }
  }, [children, showLeft, showRight]);

  const onClick = (value) => {
    scrollWrapperRef.current.scrollLeft += value;
  };

  return (
    <div className="bwc-scroll-box">
      {showButtons && showLeft && (<Button color="primary" className="bwc-scroll-box__button left" onClick={() => onClick(-scrollSize)}><IMPrevious /></Button>)}
      <div className="bwc-scroll-box__wrapper" ref={scrollWrapperRef}>
        <div className="bwc-scroll-box__container" role="list" style={{ pointerEvents: isDragging ? 'none' : undefined }}>
          {children}
        </div>
      </div>
      {showButtons && showRight && (<Button color="primary" className="bwc-scroll-box__button right" onClick={() => onClick(scrollSize)}><IMNext /></Button>)}
    </div>
  );
}

ScrollBox.propTypes = {
  children: PropTypes.node.isRequired,
  showButtons: PropTypes.bool,
};

ScrollBox.defaultProps = {
  showButtons: false,
};

export default ScrollBox;
