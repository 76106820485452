
import { fetchAll } from '../../store/Fetch.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';

const fetchData = (fetchMetadata, pagination = {}) => (dispatch) => {
  fetchAll(() => [
    getFarm({
      expand: expandFarm.all(),
      ...pagination,
    })(dispatch),
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
