import React from 'react';
import PropTypes from 'prop-types';
import {
  IMMaleSign,
  IMFemaleSign,
  IMMaleFemaleSign,
} from '@veit/veit-web-controls/dist/icons';


import { sexType } from '../model/enums';

function getSexSignIcon(sex) {
  switch (sex) {
    case sexType.male:
      return IMMaleSign;
    case sexType.female:
      return IMFemaleSign;
    case sexType.mixed:
      return IMMaleFemaleSign;
    default:
      return null;
  }
}

const SexIcon = ({ sex, className, ...otherProps }) => {
  const Icon = getSexSignIcon(sex);
  return Icon == null ? <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 1024 1024" /> : <Icon {...otherProps} />;
};

SexIcon.propTypes = {
  sex: PropTypes.string,
  className: PropTypes.string,
};

SexIcon.defaultProps = {
  sex: null,
  className: null,
};

export default SexIcon;
