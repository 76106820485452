import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Table, Label, Button } from '@veit/veit-web-controls';

import DateFormat from '../../components/DateFormat';
import SexIcon from '../../components/SexIcon';
import Round from '../../components/Round';
import { deviceType } from '../../model/enums';
import convertGramsToPounds from '../../utils/unitConversion';

const EmptyDetail = () => (
  <Label type="subtitle" style={{ textAlign: 'center', padding: '10px' }}>
    <FormattedMessage id="common.no-data" defaultMessage="No Data" />
  </Label>
);

const SourceTabWeighing = ({
  locationId, stats, openDetail, type, weightUnit, dateFormat, ...otherProps
}) => {
  return stats.length === 0 ? <EmptyDetail /> : (
    <Table type="data" {...otherProps} className="narrow">
      <thead>
        <tr>
          <th>
            {
              type === deviceType.bat1
                ? (<FormattedMessage id="common.file" defaultMessage="File" />)
                : (<FormattedMessage id="common.day" defaultMessage="Day" />)
            }
          </th>
          <th className="text-center">
            <FormattedMessage id="common.date" defaultMessage="Date" />
          </th>
          <th className="text-center">
            <FormattedMessage id="common.sex" defaultMessage="Sex" />
          </th>
          {
            type !== deviceType.bat1 && (
              <th>
                <FormattedMessage values={{ unit: <FormattedMessage id={`units.${weightUnit}`} defaultMessage="g" /> }} id="common.gain-unit" defaultMessage="Gain({unit})" />
              </th>
            )
          }
          <th>
            <FormattedMessage values={{ unit: <FormattedMessage id={`units.${weightUnit}`} defaultMessage="g" /> }} id="common.weight-unit" defaultMessage="Weight({unit})" />
          </th>
          <th>
            <FormattedMessage id="common.count-pcs" defaultMessage="Count(pcs)" />
          </th>
          <th>
            <FormattedMessage id="common.unifor-percent" defaultMessage="Unifor.(%)" />
          </th>
          <th>
            <FormattedMessage id="common.cv-percent" defaultMessage="CV(%)" />
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        {stats.map((d, i) => (
          <tr key={`${d.dateTime || i}_${d.category}`}>
            <td>
              <Label>{type === deviceType.bat1 ? d.fileName : d.day}</Label>
            </td>
            <td className="text-center">
              <Label><DateFormat date={d.dateTime} format={dateFormat} /></Label>
            </td>
            <td className="text-center"><SexIcon sex={d.category} /></td>
            {
              type !== deviceType.bat1 && (
                <td><Label>{weightUnit === 'lb' ? convertGramsToPounds(d.gain) : d.gain}</Label></td>
              )
            }
            <td><Label>{weightUnit === 'lb' ? convertGramsToPounds(d.average) : d.average}</Label></td>
            <td><Label>{d.count}</Label></td>
            <td><Label><Round value={d.uni} decimals={1} /></Label></td>
            <td><Label><Round value={d.cv} decimals={1} /></Label></td>
            <td>
              {(d.histogram && d.histogram.center) ? (
                <Button color="link" onClick={() => openDetail({ ...d, locationId }, stats)} style={{ padding: 'unset', margin: 'unset', height: 'unset' }}>
                  <FormattedMessage id="common.detail" defaultMessage="Detail" />
                </Button>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};


SourceTabWeighing.propTypes = {
  openDetail: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  stats: PropTypes.arrayOf(PropTypes.object),
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

SourceTabWeighing.defaultProps = {
  stats: [],
  weightUnit: null,
  dateFormat: null,
};


export default SourceTabWeighing;
