import { fetchAll } from '../../store/Fetch.actions';
import { getBird, getBirdCompany, expandBird } from '../../store/Bird.actions';

const fetchData = (scope, fetchMetadata, pagination = {}) => (dispatch) => {
  fetchAll(() => [
    getBird({
      expand: [
        expandBird.duration,
        expandBird.scope,
        expandBird.sex,
        expandBird.dateType,
      ],
      scope,
      ...pagination,
    })(dispatch),
    getBirdCompany({ scope })(dispatch),
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
