import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  Container,
  Row,
  Col,
  Table,
  Input,
} from '@veit/veit-web-controls';

import Round from '../../components/Round';
import { getSortingName } from '../../components/SortingName';
import CategoryName from '../../components/CategoryName';

const ViewDetails = () => {
  const subcategory = [];
  const stat = { fileName: 'Test 1', category: 'none' };
  return (
    <Container>
      <Row>
        <Col>
          <Label type="text">
            <FormattedMessage id="common.file-name" defaultMessage="File name" />
          </Label>
          <Input value={stat.fileName} disabled />
        </Col>
        <Col>
          <Label type="text">
            <FormattedMessage id="common.sorting" defaultMessage="Sorting" />
          </Label>
          <Input value={getSortingName(stat.category)} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table type="data" style={{ height: 'unset' }} className="narrow">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="common.category" defaultMessage="Category" />
                </th>
                <th>
                  <FormattedMessage values={{ unit: <FormattedMessage id="units.g" defaultMessage="g" /> }} id="common.average-unit" defaultMessage="Average({unit})" />
                </th>
                <th>
                  <FormattedMessage id="common.count-pcs" defaultMessage="Count(pcs)" />
                </th>
                <th>
                  <FormattedMessage id="common.unifor-percent" defaultMessage="Unifor.(%)" />
                </th>
                <th>
                  <FormattedMessage id="common.cv-percent" defaultMessage="CV(%)" />
                </th>
              </tr>
            </thead>
            <tbody>
              {(subcategory || []).map((d, i) => (
                <tr key={`${d.dateTime || i}_${d.category}`}>
                  <td><Label><CategoryName category={d.category} /></Label></td>
                  <td><Label>{d.average}</Label></td>
                  <td><Label>{d.count}</Label></td>
                  <td><Label><Round value={d.uni} decimals={1} /></Label></td>
                  <td><Label><Round value={d.cv} decimals={1} /></Label></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewDetails;
