import validateToken from '../utils/auth-validate';
import auth from '../utils/auth';
import { getResponseMessage } from '../utils/format';

function map(queryParams) {
  const { expand, orderby, ...otherProps } = queryParams || {};
  const params = { ...otherProps };
  if (expand != null) {
    if (Array.isArray(expand)) params.expand = expand.reduce((p, c) => `${p},${c}`);
    if (typeof expand === 'string' || expand instanceof String) params.expand = expand;
  }
  if (orderby != null) {
    params.orderBy = orderby;
  }
  return params;
}

const request = async (request, action, dispatch, payload, response) => {
  if (!(await validateToken(dispatch)).resolve()) {
    return Promise.reject();
  }
  dispatch({ type: `${action}_REQUEST`, payload });
  return request()
    .then((result) => {
      const data = {
        type: action,
        payload: response || result.data,
        offset: result.offset,
        limit: result.limit,
        size: result.size,
        links: result.links,
      };
      dispatch(data);
      return Promise.resolve(data.payload);
    })
    .catch((error) => {
      dispatch({
        type: `${action}_${error.status === 412 ? 'WARN' : 'ERROR'}`,
        payload: getResponseMessage(error),
        report: true,
      });
      if (error.status === 401) auth.signOut();
      return Promise.reject(error);
    });
};

const rest = (api) => {
  return {
    createGet: (action, method) => queryParams => dispatch => request(
      () => api[method](map(queryParams)), action, dispatch, queryParams,
    ),
    createGetBy: (action, method) => (id, queryParams) => dispatch => request(
      () => api[method](id, map(queryParams)), action, dispatch, id,
    ),
    createPost: (action, method) => item => dispatch => request(
      () => api[method](item), action, dispatch, item,
    ),
    createPut: (action, method) => (id, item) => dispatch => request(
      () => api[method](id, item), action, dispatch, item,
    ),
    createPutItem: (action, method) => item => dispatch => request(
      () => api[method](item), action, dispatch, item,
    ),
    createDelete: (action, method) => id => dispatch => request(
      () => api[method](id), action, dispatch, id, id,
    ),
  };
};

export default rest;
