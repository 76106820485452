
import { fetchAll } from '../../store/Fetch.actions';
import { getDevice, expandDevice, scopeDevice } from '../../store/Device.actions';
import { getLocation } from '../../store/Location.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';

const fetchData = (fetchMetadata, pagination = {}) => (dispatch) => {
  return fetchAll(() => [
    getDevice({
      expand: [
        expandDevice.lastStatistics,
        expandDevice.farm,
        expandDevice.location,
        expandDevice.description,
      ],
      scope: scopeDevice.scale,
      ...pagination,
    })(dispatch),
    fetchMetadata
      ? getFarm({
        expand: [expandFarm.address, expandFarm.country, expandFarm.devicesCount],
      })(dispatch)
      : null,
    fetchMetadata ? getLocation()(dispatch) : null,
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
