import React from 'react';
import PropTypes from 'prop-types';
import SubtitleWrapper from '../../components/SubtitleWrapper';
import FarmSubtitle from './FarmSubtitle';
import { FormattedMessage } from 'react-intl';
import SubtitleItem from '../../components/SubtitleItem';

const LocationSubtitle = ({
  farm, location, wrap, locationId, farmId,
}) => {
  const Wrapper = wrap ? SubtitleWrapper : React.Fragment;
  return (
    <Wrapper>
      {farm && (farmId === '' || farm.id === farmId) && <FarmSubtitle farm={farm} />}
      {location && (locationId === '' || location.id === locationId) && (
        <SubtitleItem
          title={<FormattedMessage id="common.location" defaultMessage="Location" />}
          to={location.deleted ? null : `/locations/${location.id}`}
        >
          {location.name}
        </SubtitleItem>
      )}
    </Wrapper>
  );
};

LocationSubtitle.propTypes = {
  farm: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.string,
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    deleted: PropTypes.instanceOf(Date),
  }),
  wrap: PropTypes.bool,
  locationId: PropTypes.string,
  farmId: PropTypes.string,
};

LocationSubtitle.defaultProps = {
  farm: null,
  location: null,
  wrap: false,
  locationId: '',
  farmId: '',
};

export default LocationSubtitle;
