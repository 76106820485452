import {
  GET_HELP_PAGE,
  GETBY_HELP_PAGE,
  POST_HELP_PAGE,
  PUT_HELP_PAGE,
  PUT_HELP_PAGE_CONTENT,
  DELETE_HELP_PAGE,
} from './Help.actions';

const initialState = {
  items: [],
  item: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HELP_PAGE:
      return { ...state, items: action.payload };
    case GETBY_HELP_PAGE:
      return { ...state, item: action.payload };
    case POST_HELP_PAGE:
      return { ...state, items: [...state.items, action.payload] };
    case PUT_HELP_PAGE:
    case PUT_HELP_PAGE_CONTENT:
      return {
        ...state,
        item: action.payload,
        items: state.items.map(f => (f.id === action.payload.id ? action.payload : f)),
      };
    case DELETE_HELP_PAGE:
      return {
        ...state,
        items: state.items.filter(f => f.id !== action.payload),
      };
    default:
      return state;
  }
};


export default reducer;
