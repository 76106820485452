import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import {
  ButtonSwitch,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from '@veit/veit-web-controls';


import FormLabel from '../../components/FormLabel';
import * as ModalActions from '../../store/Modal.actions';
import { dateType } from '../../model/enums';

export function hasStep2Error({ duration }) {
  return {
    duration: !validator.isInt(`${duration}` || '', { min: 1 }),
  };
}

const Step2 = ({ modal, updateForm, edit }) => {
  const form = modal.data;
  if (form == null) return null;
  const hasErrors = hasStep2Error(form);
  const useWeeks = form.dateType === dateType.week;
  const durationDays = form.duration || 36;
  const duration = useWeeks ? Math.floor(durationDays / 7) : durationDays;
  return (
    <React.Fragment>
      <Label type="text">
        <FormattedMessage id="common.sex" defaultMessage="Sex" />
      </Label>
      <ButtonSwitch
        options={[
          { title: <FormattedMessage id="common.irrelevant" defaultMessage="Irrelevant" />, value: 'irrelevant' },
          { title: <FormattedMessage id="common.sex-male" defaultMessage="Male" />, value: 'male' },
          { title: <FormattedMessage id="common.sex-female" defaultMessage="Female" />, value: 'female' },
        ]}
        selected={form.sex || 'irrelevant'}
        onChange={v => updateForm(v, 'sex')}
      />
      {!edit && (
        <Label type="info">
          <FormattedMessage id="breed.modal.what-sex" defaultMessage="What sex is your breed" />
        </Label>
      )}
      <FormLabel required={hasErrors.duration}>
        <FormattedMessage id="common.duration" defaultMessage="Duration" />
      </FormLabel>
      <InputGroup>
        <Input
          type="number"
          value={form.duration === '' ? '' : duration}
          onChange={e => updateForm(e.target.value === '' ? '' : e.target.value * (useWeeks ? 7 : 1), 'duration')}
        />
        <UncontrolledDropdown addonType="append">
          <DropdownToggle caret color="primary" outline className="form-control">
            {dateType.getPlural(form.dateType || dateType.day)}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => updateForm(dateType.week, 'dateType')}>
              <FormattedMessage id="common.weeks" defaultMessage="weeks" />
            </DropdownItem>
            <DropdownItem onClick={() => updateForm(dateType.day, 'dateType')}>
              <FormattedMessage id="common.days" defaultMessage="days" />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </InputGroup>
      {!edit && (
        <Label type="info">
          <FormattedMessage id="breed.modal.breed-duration" defaultMessage="Breed standard duration" />
        </Label>
      )}
    </React.Fragment>
  );
};

Step2.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};

Step2.defaultProps = {
  edit: false,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
}, dispatch);


export default connect(null, mapDispatchToProps)(Step2);
