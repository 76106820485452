import * as ModalActions from './Modal.actions';
import {
  deleteLocation, putLocation, postLocation,
} from './Location.actions';

export const ADD_LOCATION = 'ADD_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';

// flock actions
export const openDialogAddLocation = data => (dispatch) => {
  return ModalActions.openDialog(ADD_LOCATION, data)(dispatch);
};

export const openDialogEditLocation = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_LOCATION, data)(dispatch);
};

export const finishDialogAddLocation = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postLocation(data), ADD_LOCATION)(dispatch);
};

export const finishDialogEditLocation = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putLocation(data.id, data), EDIT_LOCATION)(dispatch);
};

export const deleteDialogLocation = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteLocation(id), EDIT_LOCATION, { delete: true })(dispatch);
};

export default ModalActions;
