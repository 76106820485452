import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ModalActions, { finishDialogSendFeatureRequest } from '../../store/ModalFeature.actions';
import bem from '../../utils/bem';
import {
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from '@veit/veit-web-controls';

import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import { enableKey, disableKey } from '../../utils/keyEvents';
import ModalWithLoader from '../ModalWithLoader';

class FeatureRequestModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogSendFeatureRequest(this.props.modal.data);
  }

  render() {
    if (this.props.modal.data == null) return null;
    const form = this.props.modal.data;
    const bm = bem.modal('feedback');
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        contentClassName={bm.b('modal-medium')}
      >
        <ModalHeader className={bm.e('header')} toggle={this.cancel}>
          <FormattedMessage id="feature.modal.title" defaultMessage="Are you interested in this feature?" />
        </ModalHeader>
        <ModalBody className={bm.e('body')}>
          <Label className={bm.e('subtitle')}>
            <FormattedMessage id="feature.modal.subtitle" defaultMessage="Let us know why" />
          </Label>
          <div className={bm.e('content')}>
            <div className={bm.e('content-message')}>
              <Label>
                <FormattedMessage id="feature.modal.more-details" defaultMessage="More details" />
              </Label>
              <Input
                type="textarea"
                value={form.detail}
                onChange={e => this.props.updateFormEvent(e, 'detail')}
                onFocus={() => disableKey(13)}
                onBlur={() => enableKey(13)}
              />
            </div>
          </div>
          <Button color="primary" onClick={this.finish}>
            <FormattedMessage id="feature.modal.send-request" defaultMessage="Send Request" />
          </Button>
        </ModalBody>
        <SubmitHandler keyCode={keyCodes.enter} action={this.finish} />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormEvent: ModalActions.updateFormEvent,
  finishDialogSendFeatureRequest,
}, dispatch);

FeatureRequestModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  finishDialogSendFeatureRequest: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(FeatureRequestModal);
