import React from 'react';
import PropTypes from 'prop-types';
import arrayUtils from '../utils/array';
import { Button, Label } from '@veit/veit-web-controls';

const getColor = (step, actual) => {
  if (step === actual) return 'primary';
  if (step > actual) return 'muted';
  return 'green';
};

const Steps = ({ steps, actualStep }) => {
  const listOfSteps = arrayUtils.range(steps, 1);
  return (
    <React.Fragment>
      {
        listOfSteps.map(step => (
          <React.Fragment key={step}>
            <Button
              color={getColor(step, actualStep)}
              className="btn-dot"
              style={{ fontSize: '10px', color: 'white', cursor: 'initial' }}
            >
              {step}
            </Button>
            {
              step === steps
                ? '' : (
                  <Label type="info" tag="span" style={{ cursor: 'default' }}>
                    {step < actualStep ? ' ──── ' : ' ··········· '}
                  </Label>
                )}
          </React.Fragment>
        ))
      }
    </React.Fragment>
  );
};

Steps.propTypes = {
  steps: PropTypes.number,
  actualStep: PropTypes.node,
};

Steps.defaultProps = {
  steps: 2,
  actualStep: 1,
};

export default Steps;
