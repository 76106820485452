import {
  OPEN_DIALOG,
  FINISH_DIALOG,
  FINISH_DIALOG_REQUEST,
  FINISH_DIALOG_ERROR,
  CANCEL_DIALOG,
  UPDATE_DIALOG_FORM,
  SET_STEP_DIALOG,
  DIALOG_SET_LOADER,
} from './Modal.actions';

function errors(state) {
  if (state == null || state.data == null || state.validators == null) return {};
  let result = {};
  Object.keys(state.validators)
    .forEach((key) => {
      const validatator = state.validators[key];
      if (Array.isArray(validatator)) {
        const res = validatator
          .filter(f => f instanceof Function)
          .reduce((r, v) => ({ ...r, ...v(state.data) }), {});

        result = { ...result, [key]: res };
      }

      if (typeof validatator === 'function') {
        result = { ...result, [key]: validatator(state.data) };
      }
      result[key].hasError = Object.keys((result[key] || {}))
        .reduce((r, v) => r || result[key][v], false);
    });
  return result;
}

const updateLastState = (state, update) => {
  const otherState = state.slice(0, -1);
  const lastState = state.slice(-1)[0] || {};
  const updated = { ...lastState, ...(update || {}) };
  return [[...otherState, updated], update];
};

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return [...state, {
        type: action.type,
        data: Object.assign({}, action.payload || {}),
        base: Object.assign({}, action.payload || {}),
        target: action.target,
        step: action.step == null ? 1 : action.step,
      }];
    case CANCEL_DIALOG:
    case FINISH_DIALOG:
    case '@@router/LOCATION_CHANGE':
      return [...state.slice(0, -1)];
    //  return updateLastState(state, { ...action.payload, update: true })[0];
    case SET_STEP_DIALOG: {
      const [newState, update] = updateLastState(state, { step: action.payload });
      return updateLastState(newState, { errors: errors(update) })[0];
    }
    case UPDATE_DIALOG_FORM: {
      return updateLastState(state, {
        data: {
          ...state.slice(-1)[0].data,
          ...action.payload,
        },
      })[0];
    }
    case FINISH_DIALOG_REQUEST:
      return updateLastState(state, { isExecuting: true })[0];
    case FINISH_DIALOG_ERROR:
      return updateLastState(state, { isExecuting: false })[0];
    case DIALOG_SET_LOADER:
      return updateLastState(state, { isExecuting: action.payload === true })[0];
    default:
      return state;
  }
};


export default reducer;
