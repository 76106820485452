import React from 'react';
import { unitType } from '../model/enums';
import PropTypes from 'prop-types';
import convertGramsToPounds from '../utils/unitConversion';

function WeightValue({ value, weightUnit, showUnit = true }) {
  const convertedValue = weightUnit === unitType.g ? value : convertGramsToPounds(value);
  const unit = weightUnit === unitType.g ? unitType.g : unitType.lb;
  return (showUnit === true ? (
    <>{convertedValue} {unit}</>
  ) : <>{convertedValue}</>);
}

WeightValue.propTypes = {
  value: PropTypes.number,
  weightUnit: PropTypes.string,
  showUnit: PropTypes.bool,
};

WeightValue.defaultProps = {
  value: 0,
  weightUnit: null,
  showUnit: true,
};

export default WeightValue;
