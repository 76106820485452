import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Table, Button, Input, AddButton, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from '@veit/veit-web-controls';

import DeleteModal from '../../../modals/DeleteModal';
import bem from '../../../utils/bem';
import { goTo } from '../../../store/Router.actions';
import { getCompany, deleteCompany, postCompany } from '../../../store/Company.actions';
import { getCurrentUser } from '../../../store/Auth.actions';
import {
  updateGroups, updateDeviceMap, updateLastValues, getUsageReport, changeCompany,
} from '../../../store/Manage.actions';

import toastMessage from '../../../utils/toastMessage';

class CompanyList extends Component {
  state = {
    companyName: '',
  }

  componentDidMount() {
    this.props.getCompany();
  }

  connect = (companyId) => {
    const { user } = this.props.auth;
    this.props.changeCompany(companyId).then(() => {
      this.props.getCurrentUser(user.id).then(() => {
        if (companyId != null) this.props.goTo('/locations');
      });
    });
  }

  create = () => {
    this.props.postCompany({ name: this.state.companyName }).then(() => this.setState({ companyName: '' }));
  }

  delete = (id) => {
    this.props.deleteCompany(id);
  }

  updateDeviceGroups = () => {
    updateGroups().then((result) => {
      // eslint-disable-next-line no-console
      console.log(result.data.data);
      toastMessage.success('Device groups updated.');
    });
  }

  updateDeviceMaping = () => {
    updateDeviceMap().then((result) => {
      // eslint-disable-next-line no-console
      console.log(result.data.data);
      toastMessage.success('Device groups updated.');
    });
  }

  updateAllLastValues = () => {
    updateLastValues().then((result) => {
      // eslint-disable-next-line no-console
      console.log(result.data.data);
      toastMessage.success('Last values updated.');
    });
  }

  getFileName = (headers) => {
    let filename = 'cloud-usage-report.csv'; // default filename
    const disposition = (headers || {})['content-disposition'];
    if (disposition == null) {
      return filename;
    }
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const match = /filename\*?=([^']*'')?([^;]*)/.exec(disposition)[2];
      if (match != null && match.trim() !== '') {
        filename = match;
      }
    }
    return filename;
  }

  usageReport = () => {
    getUsageReport().then((result) => {
      // eslint-disable-next-line no-console
      console.log(result);
      if (result == null || result.data == null) {
        toastMessage.error('Usage report is empty.');
        return;
      }
      // data are received as text -> need to package to blob or file
      const fileName = this.getFileName(result.response.header);
      const pom = document.createElement('a');
      const url = URL.createObjectURL(new Blob([result.data], { type: 'text/csv' }));
      pom.href = url;
      pom.setAttribute('download', fileName);
      pom.click();
      toastMessage.success('Usage report was downloaded.');
    });
  }


  render() {
    const bm = bem.view('company-list');
    const { user } = this.props.auth;
    return (
      <div className={bm.b()}>
        <Table>
          <thead>
            <tr>
              <th>
                Company Name
              </th>
              <th>Database</th>
              <th></th>
              <th style={{ width: '1%' }}>
                <UncontrolledDropdown>
                  <DropdownToggle className="sub-actions" outline color="primary">
                    ---
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className="btn-narrow" color="primary" onClick={this.updateDeviceMaping}>Update Device Mapping</DropdownItem>
                    <DropdownItem className="btn-narrow" color="primary" onClick={this.updateDeviceGroups}>Update Groups</DropdownItem>
                    <DropdownItem className="btn-narrow" color="primary" onClick={this.updateAllLastValues}>Update Last Values</DropdownItem>
                    <DropdownItem className="btn-narrow" color="primary" onClick={this.usageReport}>Usage report</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.company.items.map(c => (
              <tr key={c.id}>
                <td>{c.name}</td>
                <td>{c.id}</td>
                <td>
                  {
                    user.companyId === c.id
                      ? (
                        <Button className="btn-narrow" color="primary" onClick={() => this.connect()}>Disconnect</Button>
                      ) : (
                        <Button className="btn-narrow" color="primary" onClick={() => this.connect(c.id)}>Connect</Button>
                      )
                  }
                </td>
                <td><DeleteModal advaced narrow name="Company" confirm={() => this.delete(c.id)} /></td>
              </tr>
            ))}
            <tr>
              <td>New Company </td>
              <td colSpan="2">
                <Input
                  onChange={e => this.setState({ companyName: e.target.value })}
                  value={this.state.companyName}
                />
              </td>
              <td>
                <AddButton disabled={this.state.companyName.length < 2} onClick={this.create}>
                  Create
                </AddButton>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

CompanyList.propTypes = {
  getCompany: PropTypes.func.isRequired,
  postCompany: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  changeCompany: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    user: PropTypes.object,
  }).isRequired,
  company: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    company: state.company,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getCompany,
  postCompany,
  deleteCompany,
  changeCompany,
  getCurrentUser,
  goTo,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
