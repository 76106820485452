import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Input,
} from '@veit/veit-web-controls';

import ModalActions, { finishDialogRegisterUser } from '../../store/ModalUser.actions';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import AnimateHeight from '../AnimateHeight';
import ModalWithLoader from '../ModalWithLoader';
import RegistrResult from './RegisterResult';

class RegisterUserModal extends Component {
  state = {
    email: '',
    name: '',
    company: '',
    code: null,
  }

  updateFormEvent = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  setResult = (response) => {
    this.setState({ code: response.status || 500 });
  }

  finish = () => {
    this.props.finishDialogRegisterUser({
      email: this.state.email,
      name: this.state.name,
      company: this.state.company,
    })
      .then(this.setResult)
      .catch(this.setResult);
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  isValidForm = () => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return reg.test(this.state.email);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>
          {
            this.state.code == null
              ? <FormattedMessage id="modals.register.create-account" defaultMessage="Create account" />
              : this.state.code === 201
                ? <FormattedMessage id="modals.register.account-created" defaultMessage="Your account has been created" />
                : <FormattedMessage id="modals.register.failed-to-create" defaultMessage="Failed to create account" />
          }
        </ModalHeader>
        <ModalBody>
          <AnimateHeight step={this.state.code == null}>
            {this.state.code == null
              ? (
                <React.Fragment>
                  <center>
                    <Label>
                      <FormattedMessage id="modals.register.join-existing" defaultMessage="To join existing company is possible only by invitation." />
                    </Label>
                  </center>
                  <Label>
                    <FormattedMessage id="common.email" defaultMessage="Email" />
                  </Label>
                  <Input type="text" name="email" value={this.state.email} onChange={this.updateFormEvent} />
                  <Label>
                    <FormattedMessage id="common.name" defaultMessage="Name" />
                  </Label>
                  <Input type="text" name="name" value={this.state.name} onChange={this.updateFormEvent} />
                  <Label>
                    <FormattedMessage id="modals.register.company-name" defaultMessage="Company name" />
                  </Label>
                  <Input type="text" name="company" value={this.state.company} onChange={this.updateFormEvent} />
                  <Button disabled={!this.isValidForm()} color="primary" style={{ width: '100%', marginBottom: '40px' }} onClick={this.finish}>
                    <FormattedMessage id="common.sign-up" defaultMessage="Sign up" />
                  </Button>
                  <SubmitHandler keyCode={keyCodes.enter} action={this.finish} />
                </React.Fragment>
              ) : (
                <RegistrResult
                  code={this.state.code}
                  email={this.state.email}
                  close={this.cancel}
                />
              )}
          </AnimateHeight>
        </ModalBody>
      </ModalWithLoader>
    );
  }
}

RegisterUserModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogRegisterUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogRegisterUser,
}, dispatch);


export default connect(null, mapDispatchToProps)(RegisterUserModal);
