import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SubtitleWrapper from '../../components/SubtitleWrapper';
import Duration from '../../components/Duration';
import SubtitleItem from '../../components/SubtitleItem';
import SexName from '../../components/SexName';
import { sexType } from '../../model/enums';

const Subtitle = ({ bird, ...props }) => {
  return (
    <SubtitleWrapper {...props}>
      <SubtitleItem title={<FormattedMessage id="common.company" defaultMessage="Company" />}>
        {bird.company}
      </SubtitleItem>
      <SubtitleItem title={<FormattedMessage id="common.product" defaultMessage="Product" />}>
        {bird.product}
      </SubtitleItem>
      <SubtitleItem title={<FormattedMessage id="common.duration" defaultMessage="Duration" />}>
        {bird && <Duration value={bird.duration} unit={bird.dateType} />}
      </SubtitleItem>
      <SubtitleItem title={<FormattedMessage id="common.sex" defaultMessage="Sex" />}>
        {bird.sex !== sexType.irrelevant && <SexName sex={bird.sex} />}
      </SubtitleItem>
    </SubtitleWrapper>
  );
};

Subtitle.propTypes = {
  bird: PropTypes.shape({
    company: PropTypes.string,
    product: PropTypes.string,
    dateType: PropTypes.string,
    duration: PropTypes.number,
    sex: PropTypes.string,
  }).isRequired,
};

export default Subtitle;
