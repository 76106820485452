import { getAdjacent } from '../utils/stats';
import * as ModalActions from './Modal.actions';
import {
  deleteFile, postFile, putFile, DELETE_FILE,
} from './File.actions';

export const ADD_FILE = 'ADD_FILE';
export const EDIT_FILE = 'EDIT_FILE';
export const SHOW_FILE = 'SHOW_FILE';
export const GOTO_FILE = 'GOTO_FILE';

export const openDialogFile = (stat, stats) => (dispatch) => {
  return ModalActions.openDialog(SHOW_FILE, getAdjacent(stats, stat), null, true)(dispatch);
};

export const goToFile = (stat, stats) => (dispatch) => {
  return ModalActions.updateFormObject(getAdjacent(stats, stat))(dispatch);
};

export const openDialogAddFile = data => (dispatch) => {
  return ModalActions.openDialog(ADD_FILE, data, 0)(dispatch);
};

export const openDialogEditFile = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_FILE, data)(dispatch);
};

export const finishDialogAddFile = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postFile(data), ADD_FILE)(dispatch);
};

export const finishDialogEditFile = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putFile(data.id, data), EDIT_FILE)(dispatch);
};

export const deleteDialogFile = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteFile(id), DELETE_FILE, { delete: true })(dispatch);
};

export default ModalActions;
