import React from 'react';
import validator from 'validator';
import {
  Label,
  Input,
} from '@veit/veit-web-controls';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormLabel from '../../components/FormLabel';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ModalActions from '../../store/Modal.actions';

export function hasStep1Error({ name }) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 1 }),
  };
}

const Step1 = ({ modal, updateFormEvent }) => {
  const form = modal.data;
  if (form == null) return null;
  const hasError = hasStep1Error(form);
  return (
    <React.Fragment>
      <FormLabel required={hasError.name}>
        <FormattedMessage id="farms.modal.farm-name" defaultMessage="Farm name" />
      </FormLabel>
      <Input value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} autoFocus />
      <Label type="text">
        <FormattedMessage id="farms.modal.address" defaultMessage="Address" />
      </Label>
      <Input value={form.address || ''} onChange={e => updateFormEvent(e, 'address')} />
      <Label type="text">
        <FormattedMessage id="farms.modal.country" defaultMessage="Country" />
      </Label>
      <Input value={form.country || ''} onChange={e => updateFormEvent(e, 'country')} />
    </React.Fragment>
  );
};

Step1.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
}, dispatch);

export default connect(null, mapDispatchToProps)(Step1);
