import React from 'react';
import PropTypes from 'prop-types';
import { CalendarPopover, Input } from '@veit/veit-web-controls';
import IMCalendar from '@veit/veit-web-controls/dist/icons/IMCalendar';

import formatDate from '../utils/format';
import bem from '../utils/bem';

const bm = bem.component('date-input');

const DateInput = ({
  disabled,
  value,
  onChange,
  dateFormat,
  ...otherProps
}) => {
  let format = 'DD.MM.YYYY';
  if (dateFormat !== undefined && dateFormat !== '' && dateFormat !== null) {
    format = dateFormat;
  }
  return (
    <span className={bm.b()}>
      <CalendarPopover
        disabled={disabled}
        value={value}
        onChange={onChange}
        {...otherProps}
      >
        <IMCalendar className={bm.e('icon')} />
        <Input
          className={bm.e('input')}
          readOnly
          disabled={disabled}
          // type="date"
          value={formatDate(value, format)}
        />
      </CalendarPopover>
    </span>

  );
};

DateInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.objectOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
};

DateInput.defaultProps = {
  disabled: false,
  dateFormat: null,
};

export default DateInput;
