import React, { Component } from 'react';

import bem from '../../utils/bem';
import Page from '../../components/Page';

class Environment extends Component {
  render() {
    const bm = bem.view('environment');
    return (
      <Page className={bm.b()} title="Environment">
      </Page>
    );
  }
}

export default Environment;
