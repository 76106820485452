import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ModalActions, { finishDialogSendFeedback } from '../../store/ModalFeedback.actions';
import bem from '../../utils/bem';
import {
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from '@veit/veit-web-controls';
import { IMFeedback, IMSadFace } from '@veit/veit-web-controls/dist/icons';

import AnimateHeight from '../AnimateHeight';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import { enableKey, disableKey } from '../../utils/keyEvents';
import ModalWithLoader from '../ModalWithLoader';

class FeedbackModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogSendFeedback(this.props.modal.data);
  }

  getTitle = () => {
    switch (this.props.modal.data.positive) {
      case true:
        return (
          <span>
            <IMFeedback style={{ color: 'green' }} />
            <FormattedMessage id="feedback.modal.glad" defaultMessage="Glad to hear that" />
          </span>
        );
      case false:
        return (
          <span>
            <IMSadFace style={{ color: 'red' }} />
            <FormattedMessage id="feedback.modal.sorry" defaultMessage="Sorry to hear that" />
          </span>
        );
      default:
        return <FormattedMessage id="common.feedback" defaultMessage="Feedback" />;
    }
  }

  render() {
    if (this.props.modal.data == null) return null;
    const form = this.props.modal.data;
    const bm = bem.modal('feedback');
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        contentClassName={bm.b('modal-medium')}
      >
        <ModalHeader className={bm.e('header')} toggle={this.cancel}>{this.getTitle()}</ModalHeader>
        <ModalBody className={bm.e('body')}>
          <AnimateHeight step={form.positive == null ? 0 : 1}>
            <Label className={bm.e('subtitle')}>
              {
                form.positive == null
                  ? <FormattedMessage id="feedback.modal.how-do-you" defaultMessage="How do you feel about us?" />
                  : <FormattedMessage id="feedback.modal.let-us-know" defaultMessage="Let us know more" />
              }
            </Label>
            <div className={bm.e('content')}>
              {
                form.positive == null
                  ? (
                    <React.Fragment>
                      <button type="button" className={bm.e('icon')} onClick={() => this.props.updateForm(true, 'positive')}>
                        <IMFeedback style={{ color: 'green' }} />
                      </button>
                      <button type="button" className={bm.e('icon')} onClick={() => this.props.updateForm(false, 'positive')}>
                        <IMSadFace style={{ color: 'red' }} />
                      </button>
                    </React.Fragment>
                  ) : (
                    <div className={bm.e('content-message')}>
                      <Label>
                        <FormattedMessage id="feedback.modal.more-details" defaultMessage="More details" />
                      </Label>
                      <Input
                        type="textarea"
                        value={form.detail}
                        onChange={e => this.props.updateFormEvent(e, 'detail')}
                        onFocus={() => disableKey(13)}
                        onBlur={() => enableKey(13)}
                      />
                      <Label type="info" className={bm.e('content-checkbox')}>
                        <Input type="checkbox" checked={form.sendData || false} onChange={e => this.props.updateFormEvent(e, 'sendData')} />
                        <span onClick={() => this.props.updateForm(!form.sendData, 'sendData')} role="presentation">
                          <FormattedMessage id="feedback.modal.include-diagnostics" defaultMessage="Include diagnostics data with information about my device and app usage. Data will be processed strictly by developers to solve the problem, no later than 12 months. After that, they’ll be deleted." />
                        </span>
                      </Label>
                    </div>
                  )
              }
            </div>
            {
              form.positive == null ? null : (
                <Button color="primary" onClick={this.finish}>
                  <FormattedMessage id="feedback.modal.send-feedback" defaultMessage="Send Feedback" />
                </Button>
              )}
          </AnimateHeight>
        </ModalBody>
        <SubmitHandler keyCode={keyCodes.enter} action={this.finish} />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
  finishDialogSendFeedback,
}, dispatch);

FeedbackModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  finishDialogSendFeedback: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(FeedbackModal);
