import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import { defineMessages, FormattedMessage } from 'react-intl';
import { debounce } from 'throttle-debounce';
import {
  Input, Label, Table, Button, ButtonSwitch,
} from '@veit/veit-web-controls';

import ModalActions from '../../store/ModalCompare.actions';
import { birdType, flockType } from '../../types';
import intl from '../../setup/RIntl';
import { roleType } from '../../model/enums';

import FormLabel from '../../components/FormLabel';
import SelectBird from '../../components/SelectBird';

const messages = defineMessages({
  shared: { id: 'common.shared', defaultMessage: 'Shared' },
  personal: { id: 'common.personal', defaultMessage: 'Personal' },
});

export function hasModalContentError({ name }) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 1 }),
  };
}

const ModalContent = ({
  modal, updateFormEvent, updateForm, flocks, birds, user,
}) => {
  const form = modal.data;
  if (form == null) return null;
  const hasErrors = hasModalContentError(form);
  const bird = birds.find(f => f.id === form.birdId);
  const flockIds = (form.selectedFlocks || []).map(m => m.flockId);
  const selected = flocks.reduce((p, c) => {
    const index = flockIds.indexOf(c.id);
    return index === -1 ? p : [...p, [form.selectedFlocks[index], c]];
  }, []);

  const changeColor = debounce(300, (color, p) => {
    updateForm(form.selectedFlocks.map(m => (p.flockId === m.flockId ? { ...m, color } : m)), 'selectedFlocks');
  });

  const changeVisibility = (hidden, p) => {
    updateForm(form.selectedFlocks.map(m => (p.flockId === m.flockId ? { ...m, hidden } : m)), 'selectedFlocks');
  };

  return (
    <React.Fragment>
      <FormLabel required={hasErrors.name}>
        <FormattedMessage id="compare.comparison-name" defaultMessage="Comparison name" />
      </FormLabel>
      <Input value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} />
      <Label>
        <FormattedMessage id="common.bird" defaultMessage="Breed" />
      </Label>
      <SelectBird
        birds={birds}
        bird={bird}
        onChange={b => updateForm(b.id, 'birdId')}
      />
      <Label>
        <FormattedMessage id="common.access" defaultMessage="Access" />
      </Label>
      <ButtonSwitch
        disabled={!roleType.canUpdate(user.role)}
        options={[
          { title: intl.t(messages.personal), value: user.id },
          { title: intl.t(messages.shared), value: null },
        ]}
        selected={form.userId}
        onChange={v => updateForm(v, 'userId')}
      />
      <Table type="data" className="narrow">
        <thead>
          <tr>
            <th><Label><FormattedMessage id="common.flock" defaultMessage="Flock" /></Label></th>
            <th><Label><FormattedMessage id="common.visibility" defaultMessage="Visibility" /></Label></th>
            <th><Label><FormattedMessage id="common.color" defaultMessage="Color" /></Label></th>
          </tr>
        </thead>
        <tbody>
          {selected.map(([p, f]) => (
            <tr key={f.id}>
              <td><Label>{f.name}</Label></td>
              <td>
                <Button style={{ padding: 'unset' }} className="btn-narrow" color="link" onClick={() => changeVisibility(!p.hidden, p)}>
                  {p.hidden ? <FormattedMessage id="common.hidden" defaultMessage="Hidden" /> : <FormattedMessage id="common.visible" defaultMessage="Visible" />}
                </Button>
              </td>
              <td>
                <input
                  type="color"
                  style={{
                    margin: 'unset', border: 'unset', height: '35px', minWidth: '35px', cursor: 'pointer',
                  }}
                  value={p.color}
                  onChange={e => changeColor(e.target.value, p)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

ModalContent.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  flocks: PropTypes.arrayOf(flockType).isRequired,
  birds: PropTypes.arrayOf(birdType).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  return {
    flocks: state.flock.items,
    birds: state.bird.items,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
