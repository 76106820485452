import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';

import { farmType } from '../types';
import DropDownWithFilter from './DropDownWithFilter';

function isNullOrWhiteSpace(str) {
  return !str || str.trim() === '';
}

const Farm = ({ farm }) => {
  const name = isNullOrWhiteSpace(farm.name) ? null : farm.name;
  const address = isNullOrWhiteSpace(farm.address) ? null : farm.address;
  const country = isNullOrWhiteSpace(farm.country) ? null : farm.country;

  return name || address || country ? (
    <Label type="info">
      {name} {name && (address || country) ? ' · ' : null} {address}{address && country ? ', ' : null}{country}
    </Label>
  ) : null;
};

Farm.defaultProps = {
  farm: {},
};

Farm.propTypes = {
  farm: farmType,
};

const SelectLocation = ({
  locations, farms, location, onChange, addLocationAction, ...otherProps
}) => {
  const locationsWithFarm = locations.map(l => ({
    ...l,
    farm: l.farm || farms.find(f => f.id === l.farmId),
  }));

  const locationWithFarm = location ? {
    ...location,
    farm: location.farm || farms.find(f => f.id === location.farmId),
  } : null;

  return (
    <DropDownWithFilter
      items={locationsWithFarm}
      selected={locationWithFarm}
      getKey={k => k.id}
      getValue={v => `${v.name} ${v.farm ? `${v.farm.name} ${v.farm.address} ${v.farm.country}` : null}`}
      onChange={onChange}
      actions={addLocationAction ? [{
        onClick: addLocationAction,
        text: <FormattedMessage id="common.create-new-location" defaultMessage="Create New Location" />,
      }] : []}
      placeholder={<FormattedMessage id="common.location" defaultMessage="Location" />}
      renderItem={i => (
        <React.Fragment>
          <Label>{i.name}</Label>
          <Farm farm={i.farm || farms.find(f => f.id === i.farmId)} />
        </React.Fragment>
      )}
      {...otherProps}
    />
  );
};

SelectLocation.propTypes = {
  onChange: PropTypes.func.isRequired,
  addLocationAction: PropTypes.func,
  location: PropTypes.shape({
    farm: PropTypes.object,
    farmId: PropTypes.string,
  }),
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    farm: PropTypes.object,
    farmId: PropTypes.string,
  })),
  farms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
};


SelectLocation.defaultProps = {
  addLocationAction: null,
  locations: [],
  farms: [],
  location: null,
};

export default SelectLocation;
