import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import SexIcon from '../../components/SexIcon';

const LastSex = ({ lastStatistics }) => {
  if (lastStatistics == null || lastStatistics.lenght === 0) return null;
  return lastStatistics.map((ls) => {
    return ls == null || ls.average == null ? null : (
      <Label key={`${ls.category}_${ls.type}`}>
        <SexIcon sex={ls.category} />
      </Label>
    );
  });
};

LastSex.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.number,
    category: PropTypes.string,
    dateTime: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  })),
  scaleType: PropTypes.string,
};

LastSex.defaultProps = {
  lastStatistics: [],
  scaleType: null,
};

export default LastSex;
