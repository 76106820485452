const defaultSteps = 39;
const defaultStepSize = 1;
const defaultCenter = 20;

const createStep = (key, steps) => {
  return {
    step: key,
    count: (steps || {})[key] || 0,
  };
};


export default ({
  center, steps, stepSize, stepsCount,
}) => {
  const result = [createStep(center || defaultCenter, steps)];
  const minStepsCount = stepsCount > 0 ? stepsCount : defaultSteps;
  const minStepSize = stepSize > 0 ? stepSize : defaultStepSize;
  const minCenter = center > 0 ? center : defaultCenter;

  for (let index = 1; index <= Math.floor(minStepsCount / 2); index += 1) {
    result.push(createStep(minCenter + index * minStepSize, steps));
    result.unshift(createStep(minCenter - index * minStepSize, steps));
  }
  return {
    keys: result.map(m => m.step),
    values: result.map(m => m.count),
  };
};
