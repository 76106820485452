import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ModalActions, { finishDialogEditLocation, deleteDialogLocation } from '../../store/ModalLocation.actions';
import { getFarm } from '../../store/Farm.actions';

import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import ModalContent, { hasModalContentError } from './ModalContent';

class EditLocationModal extends Component {
  componentDidMount() {
    this.props.getFarm();
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditLocation(this.props.modal.data);
  }

  delete = () => {
    this.props.deleteDialogLocation(this.props.modal.data.id);
  }

  render() {
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="locations.edit-location" defaultMessage="Edit Location" />
        </ModalHeader>
        <ModalBody>
          <ModalContent modal={this.props.modal} />
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          deleteDialog={{
            action: this.delete,
            name: 'Location',
            item: this.props.modal.data,
          }}
          validators={{ 1: hasModalContentError }}
          modal={this.props.modal}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogEditLocation,
  deleteDialogLocation,
  getFarm,
}, dispatch);

EditLocationModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogEditLocation: PropTypes.func.isRequired,
  deleteDialogLocation: PropTypes.func.isRequired,
  getFarm: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditLocationModal);
