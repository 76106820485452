export const filter = {
  search: 'search',
};

export const flockFilter = {
  ...filter,
  farm: 'farm',
  sex: 'sex',
  bird: 'bird',
};

export const deviceFilter = {
  ...filter,
  farm: 'farm',
  location: 'location',
};

export const birdFilter = {
  ...filter,
  company: 'company',
  product: 'product',
};

export const fileFilter = {
  ...filter,
  farm: 'farm',
  location: 'location',
};
