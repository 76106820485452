import {
  GET_DEVICE,
  GET_DEVICE_MODAL,
  GETBY_DEVICE,
  GETBY_PARENT_DEVICE,
  POST_REQUEST_SCALE_DATA,
  POST_REQUEST_SCALE_DATA_REQUEST,
  GET_REQUEST_DATA_COUNTRIES,
} from './Device.actions';

const initialState = {
  items: [],
  modal: [],
  item: null,
  parent: null,
  gateway: null,
  gateways: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE: {
      const {
        size, offset, limit, links,
      } = action;
      return {
        ...state, items: action.payload || [], size, offset, limit, links,
      };
    }
    case GETBY_DEVICE:
      return { ...state, item: action.payload, links: action.links };
    case GET_DEVICE_MODAL:
      return { ...state, modal: action.payload };
    case GETBY_PARENT_DEVICE:
      return { ...state, parent: action.payload };
    case POST_REQUEST_SCALE_DATA:
      return { ...state, gateway: action.payload };
    case POST_REQUEST_SCALE_DATA_REQUEST:
      return { ...state, gateway: null };
    case GET_REQUEST_DATA_COUNTRIES:
      return { ...state, gateways: action.payload };
    default:
      return state;
  }
};


export default reducer;
