import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import PublicLayout from './components/Layout/PublicLayout';
import LayoutRoute from './setup/LayoutRoute';

import Home from './views/Home';
import Login from './views/Login';
import Logout from './views/Logout';
import Flock from './views/Flock';
import FlockList from './views/FlockList';
import Device from './views/Device';
import DeviceList from './views/DeviceList';
import ConnectorList from './views/ConnectorList';
import FarmList from './views/FarmList';
import BirdList from './views/BirdList';
import Bird from './views/Bird';
import Environment from './views/Environment';
import User from './views/User';
import UserList from './views/UserList';
import Tokens from './views/Tokens';
import OverviewList from './views/OverviewList';
import Overview from './views/Overview';
import Compare from './views/Compare';
import PageNotFound from './components/PageNotFound';
import Bat1List from './views/Bat1List';

// ADMIN
import Admin from './views/Admin';
import { roleType } from './model/enums';

export default () => (
  <Switch>
    <LayoutRoute
      exact
      publicPath
      path="/"
      layout={PublicLayout}
      component={Home}
    />
    <LayoutRoute
      exact
      publicPath
      path="/logout"
      layout={PublicLayout}
      component={Logout}
    />
    <LayoutRoute
      exact
      publicPath
      path="/login"
      layout={PublicLayout}
      component={Login}
    />
    <LayoutRoute
      path="/flocks/:filter?"
      component={FlockList}
    />
    <LayoutRoute
      path="/flock/:filter(scheduled|finished|all)?/:id/:tab?"
      component={Flock}
    />
    <LayoutRoute
      path="/scales"
      component={DeviceList}
    />
    <LayoutRoute
      path="/scale/:id"
      component={Device}
    />
    <LayoutRoute
      path="/connectors"
      component={ConnectorList}
    />
    <LayoutRoute
      path="/bat1"
      component={Bat1List}
    />
    <LayoutRoute
      path="/farms"
      component={FarmList}
    />
    <LayoutRoute
      path="/birds/:filter?"
      component={BirdList}
    />
    <LayoutRoute
      path="/bird/:filter?/:id"
      component={Bird}
    />
    <LayoutRoute
      path="/environment"
      component={Environment}
    />
    <LayoutRoute
      path="/users"
      component={UserList}
    />
    <LayoutRoute
      path="/user/:id"
      component={User}
    />
    <LayoutRoute
      path="/locations/:id/:tab?"
      component={Overview}
    />
    <LayoutRoute
      path="/locations"
      component={OverviewList}
    />
    <LayoutRoute
      path="/compare/:id?"
      component={Compare}
    />
    <Route
      path="/tokens"
      component={Tokens}
    />
    <LayoutRoute
      path="/admin/:path?"
      layout={PublicLayout}
      component={Admin}
      roles={[roleType.veitOperator]}
    />
    <LayoutRoute
      publicPath
      layout={PublicLayout}
      component={PageNotFound}
    />
  </Switch>
);
