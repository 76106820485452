import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@veit/veit-web-controls';
import DropDownWithFilter from '../../components/DropDownWithFilter';
import LabelWithTag from '../../components/LabelWithTag';
import { setQueryParameter, clearFilters } from '../../store/Query.actions';
import { birdFilter } from '../../model/filter';

const BirdFilter = ({
  companies, query, setQueryParameter, clearFilters,
}) => {
  const farm = companies.find(f => f === query.company);
  return (
    <React.Fragment>
      <DropDownWithFilter
        placeholder={<FormattedMessage id="common.company" defaultMessage="Company" />}
        items={companies}
        getKey={k => k}
        getValue={v => v}
        selected={farm}
        onChange={c => setQueryParameter(birdFilter.company, c)}
        renderItem={i => <LabelWithTag>{i}</LabelWithTag>}
      />
      <Button color="link" onClick={() => clearFilters(birdFilter)}>
        <FormattedMessage id="common.clear-all" defaultMessage="Clear All" />
      </Button>
    </React.Fragment>
  );
};

BirdFilter.propTypes = {
  setQueryParameter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  query: PropTypes.shape({
    company: PropTypes.string,
  }),
  companies: PropTypes.arrayOf(PropTypes.string),
};

BirdFilter.defaultProps = {
  companies: [],
  query: {},
};

const mapStateToProps = state => ({
  companies: state.bird.companies,
  query: state.query,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setQueryParameter,
  clearFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BirdFilter);
