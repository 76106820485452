import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const sortingType = {
  none: 0,
  lightHeavy: 1,
  lightOkHeavy: 2,
  categorization: 3,
  fleshingScore: 4,
};
const savingType = {
  automatic: 0,
  manual: 1,
  manualBySex: 2,
};

function getSorting(sortingMode) {
  switch (sortingMode) {
    case sortingType.none: return <FormattedMessage id="common.none" defaultMessage="None" />;
    case sortingType.lightHeavy: return <FormattedMessage id="common.lightHeavy" defaultMessage="Light/Heavy" />;
    case sortingType.lightOkHeavy: return <FormattedMessage id="common.lightOkHeavy" defaultMessage="Light/Ok/Heavy" />;
    case sortingType.categorization: return <FormattedMessage id="common.categorization" defaultMessage="Categorization" />;
    case sortingType.fleshingScore: return <FormattedMessage id="common.fleshingScore" defaultMessage="Fleshing Score" />;
    default: return null;
  }
}

const FileSorting = ({ config }) => {
  if (config.savingMode == null) return null;
  return config.savingMode === savingType.manualBySex
    ? <FormattedMessage id="common.sex" defaultMessage="Sex" />
    : getSorting(config.sortingMode);
};

FileSorting.propTypes = {
  config: PropTypes.shape({
    savingMode: PropTypes.number,
    sortingMode: PropTypes.number,
  }),
};

FileSorting.defaultProps = {
  config: {},
};

export default FileSorting;
