import { fetchAll } from '../../store/Fetch.actions';
import { getLocation, expandLocation } from '../../store/Location.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';

const fetchData = (fetchMetadata, pagination = {}) => (dispatch) => {
  fetchAll(() => [
    getLocation({
      ...pagination,
      expand: [expandLocation.lastStatistics, expandLocation.activeFlock],
    })(dispatch),
    fetchMetadata
      ? getFarm({ expand: [expandFarm.address, expandFarm.country] })(dispatch)
      : null,
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
