import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import { statsType } from '../../model/enums';
import convertGramsToPounds from '../../utils/unitConversion';

const LastGain = ({ lastStatistics, weightUnit }) => {
  if (lastStatistics == null || lastStatistics.length === 0) return null;
  return lastStatistics.map((ls) => {
    return ls == null || ls.average == null ? null : (
      <Label key={`${ls.category}_${ls.type}`}>
        {ls.type === statsType.manual
          ? '-'
          : weightUnit === 'lb' ? convertGramsToPounds(ls.gain) : ls.gain
        }
      </Label>
    );
  });
};

LastGain.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    category: PropTypes.string,
    gain: PropTypes.number,
  })),
  scaleType: PropTypes.string,
  weightUnit: PropTypes.string,
};

LastGain.defaultProps = {
  lastStatistics: [],
  weightUnit: null,
};

export default LastGain;
