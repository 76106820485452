import AddBirdModal from './bird/AddBirdModal';
import EditBirdModal from './bird/EditBirdModal';

import EditComapnyModal from './company/EditCompanyModal';

import AddDeviceModal from './device/AddDeviceModal';
import EditDeviceModal from './device/EditDeviceModal';

import AddFarmModal from './farm/AddFarmModal';
import EditFarmModal from './farm/EditFarmModal';

import FeedbackModal from './feedback/FeedbackModal';
import FeatureRequestModal from './feature/FeatureRequestModal';

import AddFlockModal from './flock/AddFlockModal';
import EditFlockModal from './flock/EditFlockModal';
import CloseFlockDialog from './flock/CloseFlockDialog';

import AddLocationModal from './location/AddLocationModal';
import EditLocationModal from './location/EditLocationModal';

import InviteUserModal from './user/InviteUserModal';

import EditProfileModal from './profile/EditProfileModal';

import RegisterUserModal from './register/RegisterUserModal';

import AddHelpPageModal from './help/AddHelpPageModal';
import EditHelpPageModal from './help/EditHelpPageModal';

import GatewayRequestModal from './gateway/GatewayRequestModal';
import ExportStatsModal from './stats/ExportStatsModal';

import HistogramModal from './histogram/HistogramModal';
import FileModal from './file/FileModal';
import AddFileModal from './file/AddFileModal';
import EditFileModal from './file/EditFileModal';

import ImportMemoryModal from './importMemory/ImportMemoryModal';
import ImportFileModal from './importFile/ImportFileModal';
import ImportModal from './import/ImportModal';

import AddCompareModal from './compare/AddCompareModal';
import EditCompareModal from './compare/EditCompareModal';

import WeighingModal from './weighing/WeighingModal';

import AddLoraDevice from './admin/lora/AddLoraDevice';
import EditLoraDevice from './admin/lora/EditLoraDevice';
import AddLoraScale from './admin/lora/AddLoraScale';

export default {
  AddBirdModal,
  EditBirdModal,
  EditComapnyModal,
  AddDeviceModal,
  EditDeviceModal,
  AddFarmModal,
  EditFarmModal,
  FeedbackModal,
  FeatureRequestModal,
  AddFlockModal,
  EditFlockModal,
  AddLocationModal,
  EditLocationModal,
  EditProfileModal,
  InviteUserModal,
  RegisterUserModal,
  AddHelpPageModal,
  EditHelpPageModal,
  GatewayRequestModal,
  ExportStatsModal,
  HistogramModal,
  ImportMemoryModal,
  ImportFileModal,
  ImportModal,
  FileModal,
  CloseFlockDialog,
  AddCompareModal,
  EditCompareModal,
  WeighingModal,
  AddLoraDevice,
  EditLoraDevice,
  AddLoraScale,
  AddFileModal,
  EditFileModal,
};
