import {
  GET_USER,
  GETBY_USER,
  UPDATE_USER_FORM,
} from './User.actions';

const initialState = {
  items: [],
  item: null,
  edit: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER: {
      const {
        size, offset, limit, links,
      } = action;
      return {
        ...state, items: action.payload || [], size, offset, limit, links,
      };
    }
    case GETBY_USER:
      return { ...state, item: action.payload, edit: null };
    case UPDATE_USER_FORM:
      return { ...state, edit: { ...(state.edit || {}), ...action.payload } };
    default:
      return state;
  }
};


export default reducer;
