import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmail from 'validator/lib/isEmail';
import { FormattedMessage } from 'react-intl';
import * as ModalActions from '../../store/Modal.actions';
import {
  Input,
  Label,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Container,
  Row,
  Col,
  AddButton,
} from '@veit/veit-web-controls';
import RoleName from '../../components/RoleName';
import { roleType } from '../../model/enums';
import bem from '../../utils/bem';
import RoleException from '../../components/RoleException';
import FormLabel from '../../components/FormLabel';
import { getRoles, filterRoleExceptions } from '../../views/User/index';

export function hasModalContentError({ email }) {
  return {
    email: email == null || !isEmail(email),
  };
}

const bm = bem.modal('user');

const ModalContent = ({
  modal, updateFormObject, updateFormEvent, updateForm, edit, user, farms,
}) => {
  const form = modal.data;
  if (form == null) return null;
  const roles = getRoles(user);
  const farmExceptions = Object.keys(filterRoleExceptions(form));
  const hasErrors = hasModalContentError(form);
  const addException = () => {
    updateFormObject({
      roleExceptions: {
        ...(form.roleExceptions || {}),
        [form.farm.id]: form.role === roleType.noAccess ? roleType.user : roleType.noAccess,
      },
      farm: null,
    });
  };

  const updateException = (farmId, role) => {
    updateForm({
      ...form.roleExceptions,
      [farmId]: role,
    }, 'roleExceptions');
  };

  return (
    <Container>
      <Row>
        <Col>
          <FormLabel required={hasErrors.email}>
            <FormattedMessage id="common.email" defaultMessage="Email" />
          </FormLabel>
          <Input disabled={edit} value={form.email || ''} onChange={e => updateFormEvent(e, 'email')} />
          <Label type="text">
            <FormattedMessage id="common.name" defaultMessage="Name" />
          </Label>
          <Input value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} />
          <Label type="text">
            <FormattedMessage id="users.modal.default-role" defaultMessage="Default Role" />
          </Label>
          <UncontrolledDropdown>
            <DropdownToggle color="primary" outline caret>
              <RoleName role={form.role} />
            </DropdownToggle>
            <DropdownMenu>
              {
                roles.map(r => (
                  <DropdownItem
                    disabled={r === roleType.veitOperator}
                    onClick={() => updateForm(r, 'role')}
                    key={r}
                  >
                    <RoleName role={r} />
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </UncontrolledDropdown>
          {
            false && (
              <Label type="text">
                <input type="checkbox" /> <FormattedMessage id="users.modal.role-exception" defaultMessage="Add role exception for particular farm" />
              </Label>
            )
          }
        </Col>
        {
          false && (
            <Col>
              <Label type="text">
                <FormattedMessage id="common.farm" defaultMessage="Farm" />
              </Label>
              <UncontrolledDropdown>
                <DropdownToggle color="primary" outline caret>
                  {(form.farm || {}).name}
                </DropdownToggle>
                <DropdownMenu>
                  {
                    farms.filter(f => farmExceptions.indexOf(f.id) === -1).map(r => (
                      <DropdownItem
                        onClick={() => updateForm(r, 'farm')}
                        key={r.id}
                      >
                        {r.name}
                      </DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
              <AddButton className={bm.e('add-exception')} outline disabled={form.farm == null} onClick={addException}>
                <FormattedMessage id="users.modal.add-role-exception" defaultMessage="Add role exception" onClick={() => { }} />
              </AddButton>
              {
                Object.keys(form.roleExceptions || {})
                  .map(m => (
                    <RoleException
                      key={m}
                      updateException={updateException}
                      exception={form.roleExceptions[m]}
                      role={form.role}
                      farm={farms.find(f => f.id === m)}
                    />
                  ))
              }
            </Col>
          )
        }
      </Row>
    </Container>
  );
};

ModalContent.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  farms: PropTypes.arrayOf(PropTypes.object),
  updateFormObject: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};

ModalContent.defaultProps = {
  edit: false,
  farms: [],
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    farms: state.farm.items,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFormObject: ModalActions.updateFormObject,
  updateFormEvent: ModalActions.updateFormEvent,
  updateForm: ModalActions.updateForm,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
