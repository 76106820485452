import React from 'react';
import PropTypes from 'prop-types';
import bem from '../utils/bem';

const SubtitleWrapper = ({ className, tag: Tag, ...otherProps }) => {
  const bm = bem.component('subtitle-wrapper');
  return (
    <Tag className={bm.b(className)} {...otherProps} />
  );
};

SubtitleWrapper.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

SubtitleWrapper.defaultProps = {
  className: null,
  tag: 'div',
};

export default SubtitleWrapper;
