import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { setQueryCache, loadQuery } from './queryCache';
import { goTo } from '../store/Router.actions';

export default baseUrl => (ComposedComponent, mapStatetoProps, actionCreators = {}) => {
  class ReduxContainer extends React.PureComponent {
    constructor(props) {
      super(props);
      const { dispatch } = props;
      this.boundActionCreators = bindActionCreators({
        ...actionCreators,
        goTo,
      }, dispatch);
    }

    goToPage = (offset, limit) => {
      const { filter } = this.props;
      const filterPath = filter ? `/${filter}` : '';
      const queryParams = loadQuery(baseUrl + filterPath);
      const query = queryString.stringify({
        ...queryParams,
        offset: offset > 0 ? offset : null,
        limit: limit == null ? null : limit,
      }, { skipNull: true });
      const path = `${baseUrl}${filterPath}${query === '' ? '' : '?'}${query}`;
      setQueryCache(path);
      this.boundActionCreators.goTo(path);
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          {...this.boundActionCreators}
          goToPage={this.goToPage}
        />
      );
    }
  }
  ReduxContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.string,
  };

  ReduxContainer.defaultProps = {
    filter: null,
  };

  return connect(mapStatetoProps)(ReduxContainer);
};
