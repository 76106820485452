import rest from './rest.actions';
import { UserApi } from '@veit/bat-cloud-api/lib/api/UserApi';

export const USER = 'USER';
export const GET_USER = 'GET_USER';
export const GETBY_USER = 'GETBY_USER';
export const POST_USER = 'POST_USER';
export const PUT_USER = 'PUT_USER';
export const PUT_CURRENT_USER = 'PUT_USER';
export const DELETE_USER = 'DELETE_USER';
export const RESET_PASSWORD_USER = 'RESET_PASSWORD_USER';
export const REGISTER_USER = 'REGISTER_USER';

export const UPDATE_USER_FORM = 'UPDATE_USER_FORM';

const userApi = new UserApi();
const restUser = rest(userApi);

export const getUser = restUser.createGet(GET_USER, 'userGet');
export const getUserBy = restUser.createGetBy(GETBY_USER, 'userUserIdGet');
export const postUser = restUser.createPost(POST_USER, 'userPost');
export const putUser = restUser.createPut(PUT_USER, 'userUserIdPut');
export const putCurrentUser = restUser.createPutItem(PUT_CURRENT_USER, 'userCurrentPut');
export const deleteUser = restUser.createDelete(DELETE_USER, 'userUserIdDelete');
export const resetPasswordUser = restUser.createPut(RESET_PASSWORD_USER, 'userResetAccountUserIdPut');
export const registerUser = item => (dispatch) => {
  dispatch({ type: REGISTER_USER });
  return userApi.userRegistrationPost(item);
};

export const updateForm = (value, name) => (dispatch) => {
  dispatch({ type: UPDATE_USER_FORM, payload: { [name]: value } });
};

export const updateFormObject = object => (dispatch) => {
  dispatch({ type: UPDATE_USER_FORM, payload: object });
};

export const updateFormEvent = (event, name) => (dispatch) => {
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  updateForm(value, name)(dispatch);
};
