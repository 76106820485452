import rest from './rest.actions';
import { create, run } from '../utils/api';
import { DeviceApi } from '@veit/bat-cloud-api/lib/api/DeviceApi';

export const DEVICE = 'DEVICE';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_DEVICE_MODAL = 'GET_DEVICE_MODAL';
export const GETBY_PARENT_DEVICE = 'GETBY_PARENT_DEVICE';
export const GETBY_DEVICE = 'GETBY_DEVICE';
export const POST_DEVICE = 'POST_DEVICE';
export const PUT_DEVICE = 'PUT_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';

export const GET_REQUEST_DATA_COUNTRIES = 'GET_REQUEST_DATA_COUNTRIES';
export const POST_REQUEST_SCALE_DATA = 'POST_REQUEST_SCALE_DATA';
export const POST_REQUEST_SCALE_DATA_REQUEST = 'POST_REQUEST_SCALE_DATA_REQUEST';

const expand = {
  description: 'description',
  farm: 'farm',
  location: 'location',
  lastStatistics: 'lastStatistics',
};

export const expandDevice = Object.freeze({
  ...expand,
  all: Object.values(expand),
});

export const scopeDevice = Object.freeze({
  all: 'all',
  location: 'location',
  scale: 'scale',
  connector: 'connector',
});

const restDevice = rest(new DeviceApi());

export const getDevice = restDevice.createGet(GET_DEVICE, 'deviceGet');
export const getDevicesByFlock = restDevice.createGetBy(GET_DEVICE, 'deviceFlockFlockIdGet');
export const getDevicesByLocation = restDevice.createGetBy(GET_DEVICE, 'deviceLocationLocationIdGet');
export const getDeviceBy = restDevice.createGetBy(GETBY_DEVICE, 'deviceDeviceIdGet');
export const getDeviceByParent = restDevice.createGetBy(GETBY_PARENT_DEVICE, 'deviceDeviceIdGet');

export const postDevice = restDevice.createPost(POST_DEVICE, 'devicePost');
export const putDevice = restDevice.createPut(PUT_DEVICE, 'deviceDeviceIdPut');
export const deleteDevice = restDevice.createDelete(DELETE_DEVICE, 'deviceDeviceIdDelete');

export const modalGetDevice = restDevice.createGet(GET_DEVICE_MODAL, 'deviceGet');
export const getRequestDataCountries = restDevice.createGet(GET_REQUEST_DATA_COUNTRIES, 'deviceRequestDataCountriesGet');
export const postRequestScaleData = deviceId => create(POST_REQUEST_SCALE_DATA, () => run(`/device/requestdata/${deviceId}`, 'POST'));
