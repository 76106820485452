import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  ButtonSwitch,
} from '@veit/veit-web-controls';

import ModalActions, { dialogStates as ds, importData } from '../../store/ModalImport.actions';
import { openDialogAddDevice } from '../../store/ModalDevice.actions';
import { modalGetDevice, scopeDevice, expandDevice } from '../../store/Device.actions';
import { getStatsDeviceBy } from '../../store/Stats.actions';
import {
  openSocket, closeSocket, getStats, getConfig, getMemFull,
} from '../../store/BatSocket.actions';
import { goTo } from '../../store/Router.actions';
import ModalWithLoader from '../ModalWithLoader';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import SelectScale from '../../components/SelectScale';
import { sortString } from '../../utils/sort';
import bem from '../../utils/bem';
import Steps from '../Steps';
import settings from '../../settings';

import Progress from './Progress';

const bm = bem.modal('import-memory');

class ImportMemoryModal extends Component {
  state = {
    deviceId: null,
    importSamples: false,
  };
  componentDidMount() {
    this.setState({ deviceId: this.props.modal.data.id });
    this.fetchData();
    this.props.getConfig();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.socket.state !== ds.uploading
      && this.props.socket.state === ds.uploading) {
      this.props.importData(this.state.deviceId, this.props.socket.stats);
    }
    if (prevProps.socket.state !== ds.success
      && this.props.socket.state === ds.success) {
      this.props.getStatsDeviceBy(this.state.deviceId);
    }
  }

  componentWillUnmount() {
    this.props.closeSocket();
  }

  fetchData = () => {
    this.props.modalGetDevice({
      scope: scopeDevice.scale,
      expand: [expandDevice.location, expandDevice.farm],
    });
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  import = () => {
    if (this.state.importSamples) {
      this.props.getMemFull();
    } else {
      this.props.getStats();
    }
  }

  openHelpdesk = () => {
    window.open(settings.help.import, '_blank');
  }

  upload = () => {
    this.props.importData(this.state.deviceId, this.props.socket.stats);
  }

  addScaleAction = () => {
    this.props.openDialogAddDevice({ fast: true }).then((t) => {
      if (t != null) {
        this.fetchData();
        this.setState({ deviceId: t.id });
      }
    });
  }

  viewData = () => {
    if (this.props.modal.data.id !== this.state.deviceId) {
      this.props.goTo(`/scale/${this.state.deviceId}`);
    } else {
      this.cancel();
    }
  }

  render() {
    const devices = this.props.devices.filter(f => f.id !== this.state.deviceId);
    const device = this.props.devices.find(f => f.id === this.state.deviceId);
    const { state } = this.props.socket;
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        className={bm.b()}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="modals.import.import-automatic-data" defaultMessage="Import automatic scale data" />
        </ModalHeader>
        <div>
          <Steps steps={4} actualStep={this.props.socket.step} />
        </div>
        <ModalBody style={{ padding: '32px 0' }}>
          {state === ds.form ? (
            <React.Fragment>
              <Label type="text">
                <FormattedMessage id="modals.import.scale-identification-number" defaultMessage="Scale identification number" />
              </Label>
              <Input disabled value={this.props.socket.config.identificationNumber} />
              <Label type="text">
                <FormattedMessage id="modals.import.import-weighings" defaultMessage="Import each weight" />
              </Label>
              <ButtonSwitch
                options={[
                  { title: <FormattedMessage id="common.no" defaultMessage="No" />, value: false },
                  { title: <FormattedMessage id="common.yes" defaultMessage="Yes" />, value: true },
                ]}
                selected={this.state.importSamples}
                onChange={v => this.setState({ importSamples: v })}
              />
              <Label tag="span" type={this.state.importSamples ? 'error' : 'info'}>
                <FormattedMessage
                  id="modals.import.reading-mem-info"
                  defaultMessage="Reading data from memory stick takes {count}"
                  values={{ count: this.state.importSamples ? 'almost 3 minutes' : 'up to 20 seconds' }}
                />
              </Label>
              <Label type="text">
                <FormattedMessage id="common.device" defaultMessage="Device" />
              </Label>
              <SelectScale
                scales={sortString(devices, s => s.name)}
                scale={device}
                onChange={s => this.setState({ deviceId: (s || {}).id })}
                addScaleAction={s => this.addScaleAction(s)}
              />
              <Label type="info">
                <FormattedMessage id="modals.import.device-info" defaultMessage="Choose a scale where yout want import data" />
              </Label>
            </React.Fragment>
          ) : <Progress state={state} seconds={this.state.importSamples ? 160 : 20} />}
        </ModalBody>
        <ModalFooter style={{ padding: '15px' }}>
          {state === ds.form && (
            <React.Fragment>
              <Button color="primary" outline onClick={this.cancel}>
                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
              </Button>
              <div style={{ flex: '1' }}></div>
              <Button color="primary" onClick={this.import} disabled={device == null}>
                <FormattedMessage id="modals.import.import-data" defaultMessage="Import Data" />
              </Button>
              <SubmitHandler keyCode={keyCodes.enter} action={this.import} />
            </React.Fragment>
          )}
          {(state === ds.memoryNotConnected
            || state === ds.connectingError
            || state === ds.readError
            || state === ds.writeError
          )
            && (
              <React.Fragment>
                <Button onClick={this.openHelpdesk} color="primary" outline>
                  <FormattedMessage id="modals.import.how-to-fix" defaultMessage="How To Fix The Problem" />
                </Button>
                <div style={{ flex: '1' }}></div>
                <Button color="primary" outline onClick={this.props.getConfig}>
                  <FormattedMessage id="modals.import.retry" defaultMessage="Retry" />
                </Button>
              </React.Fragment>
            )}
          {state === ds.invalidVersion && (
            <Button color="primary" outline onClick={this.openHelpdesk}>
              <FormattedMessage id="modals.import.how-to-update" defaultMessage="How to update BAT Agent" />
            </Button>
          )}
          {state === ds.uploadingError && (
            <Button color="primary" outline onClick={this.upload}>
              <FormattedMessage id="modals.import.try-upload-again" defaultMessage="Try Upload Again" />
            </Button>
          )}
          {state === ds.success && (
            <Button color="primary" outline onClick={this.viewData}>
              <FormattedMessage id="modals.import.show-data" defaultMessage="Show Data" />
            </Button>
          )}
        </ModalFooter>
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  openSocket,
  closeSocket,
  getConfig,
  getStats,
  getMemFull,
  importData,
  modalGetDevice,
  getStatsDeviceBy,
  cancelDialog: ModalActions.cancelDialog,
  openDialogAddDevice,
  goTo,
}, dispatch);

const mapStateToProps = (state) => {
  return {
    devices: state.device.modal,
    socket: state.socket,
  };
};

ImportMemoryModal.propTypes = {
  openSocket: PropTypes.func.isRequired,
  closeSocket: PropTypes.func.isRequired,
  getConfig: PropTypes.func.isRequired,
  getStats: PropTypes.func.isRequired,
  getMemFull: PropTypes.func.isRequired,
  importData: PropTypes.func.isRequired,
  modalGetDevice: PropTypes.func.isRequired,
  getStatsDeviceBy: PropTypes.func.isRequired,
  openDialogAddDevice: PropTypes.func.isRequired,
  cancelDialog: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  socket: PropTypes.shape({
    state: PropTypes.string,
    config: PropTypes.object,
    stats: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    step: PropTypes.number,
  }).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
    data: PropTypes.object,
  }).isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportMemoryModal);
