import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Label } from '@veit/veit-web-controls';

import LocationLink from '../_table/LocationLink';
import Progress from './Progress';

import {
  LastUpdate, LastAverage, LastCount, LastSex, LastGain, LastCv, LastUniformity, LastStatus,
} from '../_table';
import { sortLastValuesBySex } from '../../utils/flock';

const TableRow = ({
  flock, goTo, bird, filter, weightUnit, dateFormat,
}) => {
  const stats = sortLastValuesBySex((flock.lastStatistics || []).filter(f => f != null));
  const closed = flock.endDate != null || dayjs(flock.startDate).isAfter(dayjs());
  const filterPath = filter ? `${filter}/` : '';
  return (
    <tr className="has-pointer" onClick={() => goTo(`/flock/${filterPath}${flock.id}`)}>
      <td>
        <Label>{flock.name}</Label>
        <Label type="info">{bird && bird.name}</Label>
      </td>
      <td>
        <LastUpdate lastStatistics={flock.lastStatistics} dateFormat={dateFormat} />
      </td>
      <td className="text-center">
        {!closed && <LastStatus lastStatistics={flock.lastStatistics} />}
      </td>
      <td><Progress flock={flock} dateFormat={dateFormat} /></td>
      <td className="text-center">
        <LastSex lastStatistics={stats} />
      </td>
      <td>
        <LastAverage lastStatistics={stats} weightUnit={weightUnit} />
      </td>
      <td>
        <LastGain lastStatistics={stats} weightUnit={weightUnit} />
      </td>
      <td>
        <LastCount lastStatistics={stats} />
      </td>
      <td>
        <LastUniformity lastStatistics={stats} />
      </td>
      <td>
        <LastCv lastStatistics={stats} />
      </td>
      <td onClick={e => e.stopPropagation()} aria-hidden="true">
        {flock.locationId && (
          <LocationLink
            to={`/locations/${flock.locationId}`}
            location={flock.location}
            farm={(flock.location || {}).farm}
          />
        )}
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  flock: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    lastStatistics: PropTypes.array,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    location: PropTypes.object,
    locationId: PropTypes.string,
  }).isRequired,
  bird: PropTypes.shape({
    name: PropTypes.string,
  }),
  farm: PropTypes.node,
  seeAllFlocks: PropTypes.bool.isRequired,
  seeAllFlocksHandler: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  filter: PropTypes.string,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

TableRow.defaultProps = {
  farm: null,
  bird: null,
  filter: null,
  dateFormat: null,
  weightUnit: null,
};

export default TableRow;
