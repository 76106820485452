import { fetchAll } from '../../store/Fetch.actions';
import { getCompare, getFlockCompareBy, getFlocksCompareBy } from '../../store/Analytics.actions';
import { getFlock, expandFlock } from '../../store/Flock.actions';
import { getBird, expandBird } from '../../store/Bird.actions';

export const fetchStats = (flockId, stats = {}) => (dispatch) => {
  if (stats[flockId] != null) return Promise.resolve({ [flockId]: stats[flockId] });
  return getFlockCompareBy(flockId)(dispatch);
};

export const fetchComparisonStats = (flockIds = [], stats = {}) => (dispatch) => {
  const ids = flockIds.filter(f => stats[f] == null);
  if (ids.length === 0) return Promise.resolve({});
  return getFlocksCompareBy(ids)(dispatch);
};

const fetchData = () => (dispatch) => {
  return fetchAll(() => [
    getCompare()(dispatch),
    getFlock({
      expand: [expandFlock.bird, expandFlock.location, expandFlock.lastStatistics],
    })(dispatch),
    getBird({ expand: expandBird.curvePoints })(dispatch),
  ])(dispatch);
};

export default fetchData;
