import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Inline,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import Avatar from 'react-avatar';

import { openDialogEditCompany } from '../../store/ModalCompany.actions';
import { openDialogEditProfile } from '../../store/ModalProfile.actions';
import { logoutRedirect } from '../../store/Auth.actions';
import { goTo } from '../../store/Router.actions';
import { roleType } from '../../model/enums';

class Header extends React.Component {
  render() {
    const {
      name, image, role, email,
    } = (this.props.auth.user || {});
    const isAdmin = roleType.isAdmin(role);
    return (
      <div className="bwc-header">
        <Inline style={{ height: '100%' }}>
          {this.props.action}
          <div style={{ flex: 1 }}></div>
          <UncontrolledDropdown>
            <DropdownToggle className="dropdown-toggle--clear" color="primary" outline caret>
              <div style={{
                display: 'flex', justifyContent: 'space-between', columnGap: '14px', alignItems: 'center', marginTop: '-6px', marginLeft: '-20px',
              }}
              >
                <Avatar
                  fgColor="white"
                  color="#469acf"
                  round
                  size="40"
                  src={image}
                  textSizeRatio={1.5}
                  style={{ fontFamily: 'inherit' }}
                  name={name || email || ''}
                />
                {name || email || ''}
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => this.props.openDialogEditProfile()}>
                <FormattedMessage id="menu.edit-profile" defaultMessage="Edit Profile" />
              </DropdownItem>
              <DropdownItem onClick={() => this.props.openDialogEditCompany()}>
                <FormattedMessage id="common.company" defaultMessage="Company" />
              </DropdownItem>
              {
                isAdmin ? (
                  <DropdownItem onClick={() => this.props.goTo('/admin')}>
                    Administration
                  </DropdownItem>
                ) : null
              }
              <DropdownItem onClick={() => this.props.logoutRedirect()}>
                <FormattedMessage id="menu.logout" defaultMessage="Logout" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Inline>
      </div>
    );
  }
}

Header.propTypes = {
  openDialogEditCompany: PropTypes.func.isRequired,
  openDialogEditProfile: PropTypes.func.isRequired,
  logoutRedirect: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  action: PropTypes.node,
};

Header.defaultProps = {
  action: null,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  openDialogEditCompany,
  openDialogEditProfile,
  logoutRedirect,
  goTo,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
