import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
} from '@veit/veit-web-controls';

import DeleteModal from './DeleteModal';
import SubmitHandler, { keyCodes } from '../components/SubmitHandler';
import * as ModalActions from '../store/Modal.actions';
import { authType } from '../model/enums';

class Footer extends Component {
  state = {
    finished: false,
  }

  finisDialog = () => {
    this.setState({ finished: true });
    this.props.finisDialog();
  }

  keyDownHandler = () => {
    const { step, maxStep } = this.props;
    if (step < maxStep) {
      this.props.setStep(step + 1);
    } else {
      this.finisDialog();
    }
  }

  render() {
    const {
      step, maxStep, finisDialogText, setStep, cancelDialog, deleteDialog,
      validators, children, modal,
    } = this.props;
    const validator = (validators || {})[step];
    const errors = validator != null ? validator((modal || {}).data) : {};
    const hasError = Object.values(errors).find(f => f === true) != null;
    const { finished } = this.state;
    return (
      <ModalFooter>
        <Container>
          <Row>
            {
              (deleteDialog || children) && (
                <Col>
                  {deleteDialog && authType.canDelete(deleteDialog.item)
                    && <DeleteModal name={deleteDialog.name} confirm={deleteDialog.action} />}
                  {children}
                </Col>
              )
            }
            <Col>
              {
                step > 1
                  ? (
                    <Button outline color="primary" onClick={() => setStep(step - 1)}>
                      <FormattedMessage id="common.back" defaultMessage="Back" />
                    </Button>
                  ) : (
                    <Button outline color="primary" onClick={cancelDialog}>
                      <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </Button>
                  )
              }
              {(!deleteDialog && !children) && <div style={{ flex: '1', minWidth: '15px' }}></div>}
              {
                step < maxStep
                  ? (
                    <Button disabled={hasError} color="primary" onClick={() => setStep(step + 1)}>
                      <FormattedMessage id="common.continue" defaultMessage="Continue" />
                    </Button>
                  ) : (
                    <Button disabled={finished || hasError} color="primary" onClick={this.finisDialog}>
                      {finisDialogText}
                    </Button>
                  )
              }
            </Col>
          </Row>
        </Container>
        <SubmitHandler
          keyCode={keyCodes.enter}
          action={this.keyDownHandler}
          disabled={finished || hasError}
        />
        <SubmitHandler keyCode={keyCodes.escape} action={cancelDialog} />
      </ModalFooter>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  setStep: ModalActions.setStep,
}, dispatch);

Footer.propTypes = {
  step: PropTypes.number,
  maxStep: PropTypes.number,
  finisDialog: PropTypes.func.isRequired,
  finisDialogText: PropTypes.node,
  setStep: PropTypes.func.isRequired,
  cancelDialog: PropTypes.func.isRequired,
  deleteDialog: PropTypes.shape({
    name: PropTypes.node,
    action: PropTypes.func,
    item: PropTypes.object,
  }),
  validators: PropTypes.shape({
    1: PropTypes.func,
  }),
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  children: PropTypes.node,
};

Footer.defaultProps = {
  step: 1,
  maxStep: 1,
  finisDialogText: <FormattedMessage id="common.save-changes" defaultMessage="Save changes" />,
  deleteDialog: null,
  validators: null,
  children: null,
};

export default connect(null, mapDispatchToProps)(Footer);
