import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getPath } from '../utils/queryCache';

const QueryLink = ({ to, ...otherProps }) => {
  return <Link to={getPath(to)} {...otherProps} />;
};

QueryLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default QueryLink;
