import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';
import ModalActions, { finishDialogEditFile, deleteDialogFile } from '../../store/ModalFile.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';
import { modalGetLocation, expandLocation } from '../../store/Location.actions';
import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';

class EditFileModal extends Component {
  componentDidMount() {
    this.fetchLocations();
    this.props.getFarm({ expand: [expandFarm.address, expandFarm.country] });
  }

  fetchLocations = () => {
    this.props.modalGetLocation({ expand: expandLocation.farm });
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditFile(this.props.modal.data);
  }

  delete = () => {
    this.props.deleteDialogFile(
      this.props.modal.data.id,
      this.props.modal.data.$redirect,
    );
  }

  render() {
    const typeName = <FormattedMessage id="common.file" defaultMessage="File" />;
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="manual.edit-file" defaultMessage="Edit File" />
        </ModalHeader>
        <ModalBody>
          <Step1 modal={this.props.modal} fetchLocations={this.fetchLocations} />
        </ModalBody>
        <Footer
          deleteDialog={{ action: this.delete, name: typeName, item: this.props.modal.data }}
          fluid
          finisDialog={this.finish}
          modal={this.props.modal}
          validators={{ 1: hasStep1Error }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogEditFile,
  deleteDialogFile,
  getFarm,
  modalGetLocation,
}, dispatch);

EditFileModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogEditFile: PropTypes.func.isRequired,
  deleteDialogFile: PropTypes.func.isRequired,
  getFarm: PropTypes.func.isRequired,
  modalGetLocation: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditFileModal);
