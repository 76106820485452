import {
  LOGIN, REFRESH, GET_AUTH_USER, LOGOUT_REDIRECT, CHANGE_LANG, CHANGE_WEIGHT_UNIT,
  CHANGE_DATE_FORMAT,
} from './Auth.actions';
import auth from '../utils/auth';
import { localeType, weightUnitType } from '../model/enums';

const getLocale = (user) => {
  if (user != null && localeType.isValid(user.locale)) {
    return user.locale;
  }
  const locale = (((navigator.userLanguage || navigator.language)
    .replace('-', '_'))
    .toLowerCase())
    .split('_')[0];
  return localeType.isValid(locale) ? locale : localeType.english;
};

const getWeightUnit = (user) => {
  if (user != null && weightUnitType.isValid(user.weightUnit)) {
    return user.weightUnit;
  }
  return weightUnitType.grams;
};

const getDateFormat = (user) => {
  if (user != null) {
    return user.dateFormat;
  }
  return '';
};

const initialState = () => {
  const session = auth.getCachedSession();
  return {
    isAuthenticated: false,
    locale: getLocale(),
    weightUnit: getWeightUnit(),
    dateFormat: getDateFormat(),
    accessToken: session.getAccessToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case LOGIN:
    case REFRESH:
      return {
        ...state, isAuthenticated: true, ...action.payload,
      };
    case GET_AUTH_USER:
      return {
        ...state,
        user: action.payload,
        locale: getLocale(action.payload),
        weightUnit: getWeightUnit(action.payload),
        dateFormat: getDateFormat(action.payload),
        isAuthenticated: action.payload != null,
      };
    case LOGOUT_REDIRECT:
      return initialState();
    case CHANGE_LANG:
      return {
        ...state,
        locale: action.payload,
        user: { ...state.user, locale: action.payload },
      };
    case CHANGE_WEIGHT_UNIT:
      return {
        ...state,
        weightUnit: action.payload,
        user: { ...state.user, weightUnit: action.payload },
      };
    case CHANGE_DATE_FORMAT:
      return {
        ...state,
        dateFormat: action.payload,
        user: { ...state.user, dateFormat: action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
