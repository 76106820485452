import {
  GET_LORA_DEVICE,
  GET_LORA_GATEWAY,
} from './Lora.actions';

const initialState = {
  gateways: [],
  devices: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LORA_DEVICE: {
      return {
        ...state,
        devices: action.payload || [],
      };
    }
    case GET_LORA_GATEWAY: {
      return {
        ...state,
        gateways: action.payload || [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
