import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Label } from '@veit/veit-web-controls';

const SubtitleItem = ({
  title, delimiter, children, to,
}) => {
  const Child = () => <Label tag="span">{children}</Label>;
  return (
    <Label tag="span">
      {title}{delimiter}
      {to ? (
        <Link to={to}>
          <Child />
        </Link>
      ) : (<Child />)}
    </Label>
  );
};

SubtitleItem.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  delimiter: PropTypes.string,
  to: PropTypes.string,
};

SubtitleItem.defaultProps = {
  children: null,
  delimiter: ' : ',
  to: null,
};

export default SubtitleItem;
