import rest from './rest.actions';
import { CompanyApi } from '@veit/bat-cloud-api/lib/api/CompanyApi';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_CURRENT_COMPANY = 'GET_CURRENT_COMPANY';
export const POST_COMPANY = 'POST_COMPANY';
export const PUT_COMPANY = 'PUT_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

const restCompany = rest(new CompanyApi());

export const getCompany = restCompany.createGet(GET_COMPANY, 'companyGet');
export const getCurrentCompany = restCompany.createGet(GET_CURRENT_COMPANY, 'companyCurrentGet');
export const postCompany = restCompany.createPost(POST_COMPANY, 'companyPost');
export const putCompany = restCompany.createPut(PUT_COMPANY, 'companyCompanyIdPut');
export const deleteCompany = restCompany.createDelete(DELETE_COMPANY, 'companyCompanyIdDelete');
