import React from 'react';
import PropTypes from 'prop-types';
import bem from '../utils/bem';
import { Button } from '@veit/veit-web-controls';

const bm = bem.component('switch');

const SwitchOption = ({
  option, value, onChange, getName, getValue,
}) => {
  const state = getValue(option) === getValue(value) ? 'on' : 'off';
  return (
    <Button
      color="none"
      className={bm.e('option', bm.m(state))}
      onClick={getValue(option) === getValue(value) ? null : () => onChange(option)}
    >
      {getName(option)}
    </Button>
  );
};

SwitchOption.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  getName: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
};

const Switch = ({
  value, options, onChange, getName, getValue,
}) => {
  return (
    <div className={bm.b()}>
      {
        (options || []).map(m => (
          <SwitchOption
            key={m}
            option={m}
            getName={getName}
            getValue={getValue}
            onChange={onChange}
            value={value}
          />
        ))
      }
    </div>
  );
};

Switch.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ]),
  getName: PropTypes.func,
  getValue: PropTypes.func,
};

Switch.defaultProps = {
  value: null,
  getName: option => option,
  getValue: option => option,
};

export default Switch;
