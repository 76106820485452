import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import bem from '../../utils/bem';
import IMCorner from '../../components/icons/IMCorner';

function isNullOrWhiteSpace(str) {
  return !str || str.trim() === '';
}

function farmLocation(farm) {
  if (farm == null) return null;
  const hasAddress = !isNullOrWhiteSpace(farm.address);
  const hascountry = !isNullOrWhiteSpace(farm.country);
  if (!hasAddress && !hascountry) {
    return null;
  }
  if (hasAddress && hascountry) {
    return ` · ${farm.address}, ${farm.country}`;
  }
  return ` · ${hasAddress ? farm.address : farm.country}`;
}

const bm = bem.component('location');

const Location = ({ location, farm }) => {
  return location == null ? null : (
    <div className={bm.b()}>
      {farm && (
        <div className={bm.e('row')}>
          <Label>{farm.name}</Label>
          <Label type="info">{farmLocation(farm)}</Label>
        </div>
      )}
      <div className={bm.e('row')}>
        <IMCorner />
        <Label>{location.name}</Label>
      </div>
    </div>
  );
};

Location.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  farm: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    country: PropTypes.string,
  }),
};

Location.defaultProps = {
  location: null,
  farm: null,
};

export default Location;
