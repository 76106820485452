import dayjs from 'dayjs';
import * as ModalActions from './Modal.actions';
import { postFlock, putFlock, deleteFlock } from './Flock.actions';

export const ADD_FLOCK = 'ADD_FLOCK';
export const EDIT_FLOCK = 'EDIT_FLOCK';
export const CLOSE_FLOCK = 'CLOSE_FLOCK';

const newFlockTemplate = data => ({
  targetAge: 0,
  targetWeight: 0,
  initialAge: 1,
  initialWeight: 40,
  startDate: dayjs().format('YYYY-MM-DD'),
  ...(data || {}),
});

const updateModel = data => ({
  ...data,
  targetAge: data.useFinalWeight === false ? data.targetAge : null,
  targetWeight: data.useFinalWeight === true ? data.targetWeight : null,
});

// flock actions
export const openDialogAddFlock = data => (dispatch) => {
  return ModalActions.openDialog(ADD_FLOCK, newFlockTemplate(data))(dispatch);
};

export const openDialogEditFlock = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_FLOCK, data)(dispatch);
};

export const openDialogCloseFlock = (data, save = true) => (dispatch) => {
  return ModalActions.openDialog(CLOSE_FLOCK, { ...data, save }, null, null)(dispatch);
};

export const finishDialogAddFlock = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => postFlock(updateModel(data)), ADD_FLOCK)(dispatch);
};

export const finishDialogEditFlock = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putFlock(data.id, updateModel(data)), EDIT_FLOCK)(dispatch);
};

export const finishDialogCloseFlock = (data, endDate = new Date()) => (dispatch) => {
  const update = { ...data, endDate, bird: null };
  return ModalActions.finishDialogRequest(
    () => (data.save ? putFlock(data.id, update) : () => Promise.resolve(update)),
    CLOSE_FLOCK,
  )(dispatch);
};

export const deleteDialogFlock = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteFlock(id), EDIT_FLOCK, { delete: true })(dispatch);
};

export default ModalActions;
