import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import ModalActions, { openDialogAddFarm } from '../../store/ModalFarm.actions';
import { getFarm } from '../../store/Farm.actions';

import SelectFarm from '../../components/SelectFarm';
import FormLabel from '../../components/FormLabel';
import { Input } from '@veit/veit-web-controls';

import { authType } from '../../model/enums';

export function hasModalContentError({ name, farmId }) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 1 }),
    farmId: farmId == null || !validator.isLength(validator.trim(farmId), { min: 32 }),
  };
}

const ModalContent = ({
  modal, farm, updateFormEvent, updateForm, openDialogAddFarm, getFarm,
}) => {
  const form = modal.data;
  if (form == null) return null;
  const farms = farm.items;
  const activeFarm = (farms || []).find(f => f.id === form.farmId);
  const hasErrors = hasModalContentError(form);

  const addFarmAction = (f) => {
    if (f != null) {
      getFarm();
      updateForm(f.id, 'farmId');
    }
  };

  return (
    <React.Fragment>
      <FormLabel required={hasErrors.farmId}>
        <FormattedMessage id="modals.select-farm" defaultMessage="Select farm" />
      </FormLabel>
      <SelectFarm
        farms={farms.filter(authType.canUpdate)}
        farm={activeFarm}
        onChange={c => updateForm((c || {}).id, 'farmId')}
        addFarmAction={
          authType.canCreate(farm) ? () => openDialogAddFarm().then(addFarmAction) : null
        }
      />
      <FormLabel required={hasErrors.name}>
        <FormattedMessage id="locations.modal.location-name" defaultMessage="Location name" />
      </FormLabel>
      <Input value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} />
    </React.Fragment>
  );
};

ModalContent.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  farm: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  openDialogAddFarm: PropTypes.func.isRequired,
  getFarm: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  return {
    farm: state.farm,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
  openDialogAddFarm,
  getFarm,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
