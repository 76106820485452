import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

import {
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import FormLabel from '../../../components/FormLabel';

export function hasStep1Error({
  devEUI, appKey, appEUI,
}) {
  return {
    devEUI: !validator.isLength(devEUI || '', { min: 16, max: 16 }),
    appEUI: !validator.isLength(appEUI || '', { min: 16, max: 16 }),
    appKey: !validator.isLength(appKey || '', { min: 32, max: 32 }),
  };
}

const ModalContent = ({
  form, updateFormEvent, updateForm, edit,
}) => {
  const hasError = hasStep1Error(form);
  const isReadonly = form.aws != null;
  return (
    <React.Fragment>
      <FormLabel required={hasError.devEUI}>DevEUI</FormLabel>
      <Input
        type="text"
        value={form.devEUI || (form.aws || {}).eui || ''}
        onChange={e => updateFormEvent(e, 'devEUI')}
        maxLength="16"
        disabled={(edit || isReadonly)}
      />
      {(!edit || form.devEUI != null) && (
        <React.Fragment>
          <FormLabel required={hasError.appEUI}>AppEUI</FormLabel>
          <Input
            type="text"
            value={form.appEUI || ''}
            onChange={e => updateFormEvent(e, 'appEUI')}
            maxLength="16"
            disabled={isReadonly}
          />
          <FormLabel required={hasError.appKey}>AppKey</FormLabel>
          <Input
            type="text"
            value={form.appKey || ''}
            onChange={e => updateFormEvent(e, 'appKey')}
            maxLength="32"
            disabled={isReadonly}
          />
          <FormLabel required={hasError.region}>RF Region</FormLabel>
          <UncontrolledDropdown>
            <DropdownToggle color="primary" outline caret disabled={isReadonly}>
              {(form.region || 'EU868').toUpperCase()}
            </DropdownToggle>
            <DropdownMenu>
              {['EU868', 'US915', 'AU915', 'AS923'].map(region => (
                <DropdownItem key={region} onClick={() => updateForm(region, 'region')}>
                  {region}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

ModalContent.propTypes = {
  updateFormEvent: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  form: PropTypes.shape({
    aws: PropTypes.shape({
      id: PropTypes.string,
    }),
    devEUI: PropTypes.string,
    appEUI: PropTypes.string,
    appKey: PropTypes.string,
    region: PropTypes.string,
  }),
};

ModalContent.defaultProps = {
  edit: false,
  form: null,
};

export default ModalContent;
