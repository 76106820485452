/* eslint-disable object-curly-newline */
import {
  WS_CONNECTION_CREATE,
  WS_CONNECTION_FAILED,
  WS_MEM_CONFIG_REQUEST,
  WS_MEM_STATS_REQUEST,
  WS_MEM_CONFIG_RESPONSE,
  WS_MEM_STATS_RESPONSE,
  WS_BAT1_INFO_REQUEST,
  WS_BAT1_STATS_REQUEST,
  WS_BAT1_INFO_RESPONSE,
  WS_BAT1_STATS_RESPONSE,
  WS_MEMORY_NOT_CONNECTED,
  WS_INVALID_VERSION,
  WS_READ_ERROR,
  WS_WRITE_ERROR,
  WS_SET_STEP,
  WS_DISCONNECTED,
} from './BatSocket.actions';

import { dialogStates as ds, IMPORT_DATA_SUCCESS, IMPORT_DATA_ERROR } from './ModalImport.actions';

const initialState = {
  state: ds.connecting,
  config: {},
  stats: [],
  step: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WS_CONNECTION_CREATE:
      return { ...state, state: ds.connecting, step: 1 };
    case WS_CONNECTION_FAILED:
      return { ...state, state: ds.connectingError };
    case WS_MEM_CONFIG_REQUEST:
      return { ...state, state: ds.downloading };
    case WS_MEM_STATS_REQUEST:
      return { ...state, state: ds.downloading, step: 3 };
    case WS_MEM_CONFIG_RESPONSE:
      return { ...state, config: action.payload, state: ds.form, step: 2 };
    case WS_MEM_STATS_RESPONSE:
      return { ...state, stats: action.payload, state: ds.uploading, step: 4 };
    case WS_BAT1_INFO_REQUEST:
      return { ...state, state: ds.downloading, step: 1 };
    case WS_BAT1_STATS_REQUEST:
      return { ...state, state: ds.downloading, step: 3 };
    case WS_BAT1_STATS_RESPONSE:
      return { ...state, state: ds.uploading, stats: action.payload, step: 3 };
    case WS_BAT1_INFO_RESPONSE:
      return { ...state, info: action.payload, state: ds.form };
    case IMPORT_DATA_SUCCESS:
      return { ...state, state: ds.success, step: 4 };
    case IMPORT_DATA_ERROR:
      return { ...state, state: ds.uploadingError };
    case WS_MEMORY_NOT_CONNECTED:
      return { ...state, state: ds.memoryNotConnected };
    case WS_INVALID_VERSION:
      return { ...state, state: ds.invalidVersion };
    case WS_READ_ERROR:
      return { ...state, state: ds.readError };
    case WS_WRITE_ERROR:
      return { ...state, state: ds.writeError };
    case WS_SET_STEP:
      return { ...state, step: action.payload };
    case WS_DISCONNECTED:
      return { ...initialState };
    default:
      return state;
  }
};


export default reducer;
