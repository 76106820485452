import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import ModalContainer from '../../setup/ModalContainer';
import ErrorBoundary from '../ErrorBoundary';
import Header from './Header';
import Content from './Content';
import Sidebar from './Sidebar';
import IMMenu from '@veit/veit-web-controls/dist/icons/IMMenu';


const useIsMobile = () => {
  const minimalWindowWidth = 768;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= minimalWindowWidth
    || window.innerHeight <= minimalWindowWidth);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= minimalWindowWidth
        || window.innerHeight <= minimalWindowWidth);
    }

    handleResize();
    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};


const MainLayout = ({ children }) => {
  const isMobile = useIsMobile();
  const [showSidebar, setShowSidebar] = useState(!isMobile);
  if (!isMobile && !showSidebar) {
    setShowSidebar(true);
  }

  return (
    <main className={showSidebar ? 'sidebar-open' : null}>
      <Sidebar show={showSidebar} />
      <Header
        action={(
          <button
            type="button"
            style={isMobile ? {
              display: 'inline', border: 'none', background: 'none', fontSize: '38px', textAlign: 'left', marginLeft: '-36px', marginBottom: '10px', marginRight: '10px', color: 'black',
            } : { display: 'none' }}
            onClick={
              () => setShowSidebar(!showSidebar)
         }>
            <IMMenu />
          </button>
        )}
      />
      <Content hideSidebar={() => setShowSidebar(true)}>
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </Content>
      {/* <ModalContainer /> */}
    </main>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
