import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Table, Label, StatusIcon, Button,
} from '@veit/veit-web-controls';
import IMBird from '@veit/veit-web-controls/dist/icons/IMBird';

import { openDialogShowWeighing } from '../../store/ModalWeighing.actions';
import WeighingName from '../../components/WeighingName';
import DateFormat from '../../components/DateFormat';
import SexIcon from '../../components/SexIcon';
import IconPage from '../../components/IconPage';
import Round from '../../components/Round';
import { statsType } from '../../model/enums';
import WeightValue from '../../components/WeightValue';

const WeighingTab = ({
  stats, locationId, flockId, openDialogShowWeighing, weightUnit, dateFormat, ...otherProps
}) => {
  return stats != null && stats.length > 0 ? (
    <Table type="data" {...otherProps}>
      <thead>
        <tr>
          <th className="text-center">
            <FormattedMessage id="common.day" defaultMessage="Day" />
          </th>
          <th className="text-center">
            <FormattedMessage id="common.date" defaultMessage="Date" />
          </th>

          <th className="text-center">
            <FormattedMessage id="common.growth" defaultMessage="Growth" />
          </th>
          <th className="text-center">
            <FormattedMessage id="common.sex" defaultMessage="Sex" />
          </th>
          <th>
            <FormattedMessage values={{ unit: <FormattedMessage id={`units.${weightUnit}`} defaultMessage="g" /> }} id="common.gain-unit" defaultMessage="Gain({unit})" />
          </th>
          <th>
            <FormattedMessage values={{ unit: <FormattedMessage id={`units.${weightUnit}`} defaultMessage="g" /> }} id="common.average-unit" defaultMessage="Average({unit})" />
          </th>
          <th>
            <FormattedMessage id="common.count-pcs" defaultMessage="Count(pcs)" />
          </th>
          <th>
            <FormattedMessage id="common.unifor-percent" defaultMessage="Unifor.(%)" />
          </th>
          <th>
            <FormattedMessage id="common.cv-percent" defaultMessage="CV(%)" />
          </th>
          <th>
            <FormattedMessage id="common.weighing" defaultMessage="Weighing" />
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        {stats.map((d, i) => (
          <tr key={`${d.dateTime || i}_${d.category}`}>
            <td className="text-center">
              <Label>{d.automatic ? d.automatic.day : d.manual.flockDay}</Label>
            </td>
            <td className="text-center">
              <Label>
                <DateFormat date={(d.automatic || d.manual).dateTime} format={dateFormat} />
              </Label>
            </td>
            <td className="text-center">
              {
                d.type !== statsType.manual && (<StatusIcon hasError={false} />)
              }
            </td>
            <td className="text-center">
              <SexIcon sex={(d.automatic || {}).category || (d.manual || {}).category} />
            </td>
            <td>
              {
                d.automatic
                && (
                <Label>
                  <WeightValue value={d.automatic.gain} weightUnit={weightUnit} showUnit={false} />
                </Label>
                )
              }
              {d.manual && <Label>-</Label>}
            </td>
            <td>
              {
                d.automatic
                && (
                  <Label>
                    <WeightValue
                      value={d.automatic.average}
                      weightUnit={weightUnit}
                      showUnit={false}
                    />
                  </Label>
                )
              }
              {
                d.manual
                && (
                  <Label>
                    <WeightValue
                      value={d.manual.average}
                      weightUnit={weightUnit}
                      showUnit={false}
                    />
                  </Label>
                )
              }
            </td>
            <td>
              {d.automatic && <Label>{d.automatic.count}</Label>}
              {d.manual && <Label>{d.manual.count}</Label>}
            </td>
            <td>
              {d.automatic && <Label><Round value={d.automatic.uni} decimals={1} /></Label>}
              {d.manual && <Label><Round value={d.manual.uni} decimals={1} /></Label>}
            </td>
            <td>
              {d.automatic && <Label><Round value={d.automatic.cv} decimals={1} /></Label>}
              {d.manual && <Label><Round value={d.manual.cv} decimals={1} /></Label>}
            </td>
            <td>
              {d.automatic && <Label><WeighingName type={d.automatic.type} /></Label>}
              {d.manual && <Label><WeighingName type={d.manual.type} /></Label>}
            </td>
            <td>
              {(d.manual || (d.automatic || {}).histogram) && (
                <Button
                  color="link"
                  onClick={() => openDialogShowWeighing({
                    locationId,
                    flockId,
                    dateTime: (d.manual || d.automatic).dateTime,
                    index: i,
                    stats,
                  })}
                >
                  <FormattedMessage id="common.detail" defaultMessage="Detail" />
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : <IconPage icon={IMBird} text={<FormattedMessage id="common.no-weighing-data" defaultMessage="No weighing data ..." />} />;
};

WeighingTab.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.object),
  locationId: PropTypes.string,
  flockId: PropTypes.string,
  openDialogShowWeighing: PropTypes.func.isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

WeighingTab.defaultProps = {
  stats: [],
  locationId: null,
  flockId: null,
  weightUnit: null,
  dateFormat: null,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  openDialogShowWeighing,
}, dispatch);


export default connect(null, mapDispatchToProps)(WeighingTab);
