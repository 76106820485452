import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import TypeIcon from './TypeIcon';
import WeightValue from '../../components/WeightValue';

function sort(a, b) {
  const aValue = (a.type || '').toUpperCase();
  const bValue = (b.type || '').toUpperCase();
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
}

const LastAverageGroup = ({ lastStatistics, weightUnit }) => {
  if (lastStatistics == null || lastStatistics.length === 0) return null;
  return lastStatistics.map((ls) => {
    return (
      <Label key={`${ls[0].category}`}>
        {ls.sort(sort).map((m, i) => (
          <span key={m.type} style={i > 0 ? { paddingLeft: '15px' } : null}>
            <TypeIcon type={m.type} /> <WeightValue value={m.average} weightUnit={weightUnit} />
          </span>
        ))}
      </Label>
    );
  });
};

LastAverageGroup.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    dateTime: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }))),
};

LastAverageGroup.defaultProps = {
  lastStatistics: [],
};

export default LastAverageGroup;
