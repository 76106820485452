import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SubtitleItem from '../../components/SubtitleItem';
import { deviceTypeName } from '../../model/enums';

const DeviceTypeSubtitle = ({ device }) => {
  return device && (
    <SubtitleItem title={<FormattedMessage id="common.device-type" defaultMessage="Device type" />}>
      {deviceTypeName[device.type]}
    </SubtitleItem>
  );
};

DeviceTypeSubtitle.propTypes = {
  device: PropTypes.shape({
    type: PropTypes.string,
  }),
};

DeviceTypeSubtitle.defaultProps = {
  device: null,
};

export default DeviceTypeSubtitle;
