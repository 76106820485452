import toastMessage from '../utils/toastMessage';

const defaultMessageDuration = 15000; // 15 sec

function debounce(func, wait, immediate) {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const debounceMessage = debounce((message, detail, duration, func) => {
  func(message, detail, duration);
}, 1000);

const errorRegEx = new RegExp('^(?!FETCH).*_ERROR');
const warnRegEx = new RegExp('^(?!FETCH).*_WARN');
export default (isDevelopment, duration = defaultMessageDuration) => () => next => (action) => {
  if (errorRegEx.test(action.type) && action.report) {
    debounceMessage('Error during request.', isDevelopment ? action.payload : null, duration, toastMessage.error);
  }
  if (warnRegEx.test(action.type) && action.report) {
    debounceMessage(action.payload, null, duration, toastMessage.warn);
  }
  return next(action);
};
