import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import ModalActions, { finishDialogAddLoraScale } from '../../../store/ModalAdmin.actions';
import { getLocations } from '../../../store/Lora.actions';
import bem from '../../../utils/bem';
import {
  ModalHeader,
  ModalBody,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';

import FormLabel from '../../../components/FormLabel';
import ModalWithLoader from '../../ModalWithLoader';
import Footer from '../../Footer';

const bm = bem.modal('add-lora-scale');

export function hasStep1Error({
  name, companyId, locationId,
}) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 1 }),
    companyId: companyId == null || !validator.isLength(validator.trim(companyId), { min: 1 }),
    locationId: locationId == null || !validator.isLength(validator.trim(locationId), { min: 1 }),
  };
}

class AddLoraScale extends Component {
  state = {
    locations: [],
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  onCompanyChanged = (company) => {
    this.props.updateFormObject({
      companyId: company.id,
      locationId: null,
    });
    this.props.getLocations(company.id).then((r) => {
      this.setState({ locations: r.data.data });
    });
  }

  finish = () => {
    this.props.finishDialogAddLoraScale(this.props.modal.data);
  }

  render() {
    if (this.props.modal.data == null) return null;
    const form = this.props.modal.data;
    const { companies, updateForm } = this.props;
    const hasError = hasStep1Error(form);
    const company = companies.find(f => f.id === form.companyId);
    const location = this.state.locations.find(f => f.id === form.locationId);
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
        contentClassName={bm.b('modal-medium')}
      >
        <ModalHeader className={bm.e('header')} toggle={this.cancel}>Add LoRa Scale from AWS</ModalHeader>
        <ModalBody className={bm.e('body')}>
          <div className={bm.e('content')}>
            <FormLabel>DevEUI</FormLabel>
            <Input
              type="text"
              value={form.aws.eui || ''}
              onChange={e => this.props.updateFormEvent(e, 'devEUI')}
              maxLength="16"
              disabled
            />
            <FormLabel required={hasError.name}>Name</FormLabel>
            <Input
              type="text"
              value={form.name || ''}
              onChange={e => this.props.updateFormEvent(e, 'name')}
            />
            <FormLabel required={hasError.companyId}>Company</FormLabel>
            <UncontrolledDropdown>
              <DropdownToggle color="primary" outline caret>
                {(company || {}).name || ''}
              </DropdownToggle>
              <DropdownMenu>
                {companies.map(cp => (
                  <DropdownItem key={cp.id} onClick={() => this.onCompanyChanged(cp)}>
                    {cp.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <FormLabel required={hasError.locationId}>Location</FormLabel>
            <UncontrolledDropdown>
              <DropdownToggle color="primary" outline caret>
                {(location || {}).name || ''}
              </DropdownToggle>
              <DropdownMenu>
                {this.state.locations.map(loc => (
                  <DropdownItem key={loc.id} onClick={() => updateForm(loc.id, 'locationId')}>
                    {loc.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <Footer
            finisDialog={this.finish}
            finisDialogText="Add LoRa Scale from AWS"
            modal={this.props.modal}
            validators={{ 1: hasStep1Error }}
          />
        </ModalBody>
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormEvent: ModalActions.updateFormEvent,
  updateForm: ModalActions.updateForm,
  updateFormObject: ModalActions.updateFormObject,
  finishDialogAddLoraScale,
  getLocations,
}, dispatch);

const mapStateToProps = state => ({
  companies: state.company.items,
});

AddLoraScale.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  finishDialogAddLoraScale: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLoraScale);
