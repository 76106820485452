import React from 'react';
import PropTypes from 'prop-types';
import IMNext from '@veit/veit-web-controls/dist/icons/IMNext';

const IMExpand = ({ expanded, style, ...otherProps }) => {
  const transform = expanded ? 'rotate(-90deg)' : 'rotate(90deg)';
  return <IMNext {...otherProps} style={{ ...style, transform }} />;
};

IMExpand.propTypes = {
  expanded: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

IMExpand.defaultProps = {
  expanded: false,
  style: {},
};

export default IMExpand;
