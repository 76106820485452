import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const WeightUnitName = ({ unit }) => {
  switch (unit) {
    case 'grams': return <FormattedMessage id="users.modal.weight-unit-grams" defaultMessage="grams" />;
    case 'pounds': return <FormattedMessage id="users.modal.weight-unit-pounds" defaultMessage="pounds" />;
    default: return <FormattedMessage id="users.modal.weight-unit-grams" defaultMessage="grams" />;
  }
};

WeightUnitName.propTypes = {
  unit: PropTypes.string,
};

WeightUnitName.defaultProps = {
  unit: null,
};

export default WeightUnitName;
