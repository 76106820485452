import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@veit/veit-web-controls';

export const timeUnits = {
  days: 'days',
  weeks: 'weeks',
};

function setTime(e, unit) {
  const { value } = e.target;
  const output = Number.isNaN(Number.parseFloat(value))
    ? value
    : unit === timeUnits.weeks ? Math.round(Number.parseFloat(value) * 7) : Math.round(value);

  return { target: { value: output } };
}

function getTime(value, unit) {
  if (Number.isNaN(Number.parseFloat(value))) return value;
  return unit === timeUnits.weeks ? Math.floor(parseFloat(value) / 7) : value;
}

const TimeInput = ({
  onChange, value, timeUnit, ...otherProps
}) => {
  return (
    <Input
      onChange={e => onChange(setTime(e, timeUnit))}
      value={getTime(value, timeUnit)}
      {...otherProps}
    />
  );
};

TimeInput.propTypes = {
  timeUnit: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TimeInput.defaultProps = {
  timeUnit: timeUnits.days,
  value: null,
};

export default TimeInput;
