import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SubtitleItem from '../../components/SubtitleItem';

const FlockSubtitle = ({ flock }) => {
  return flock && (
    <SubtitleItem title={<FormattedMessage id="common.flock" defaultMessage="Flock" />} to={`/flock/${flock.id}`}>
      {flock.name}
    </SubtitleItem>
  );
};

FlockSubtitle.propTypes = {
  flock: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

FlockSubtitle.defaultProps = {
  flock: null,
};

export default FlockSubtitle;
