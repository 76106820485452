function get(name, defaultValue = '') {
  const val = process.env[`REACT_APP_AUTH_${name}`] || defaultValue;
  return val;
}

function getJson(name, defaultValue) {
  const value = get(name, defaultValue);
  return value !== defaultValue ? JSON.parse(`${value}`) : defaultValue;
}

const config = {
  ClientId: get('CLIENT'),
  AppWebDomain: get('DOMAIN'),
  TokenScopesArray: getJson('SCOPES', []),
  RedirectUriSignIn: get('SIGNIN'),
  RedirectUriSignOut: get('SIGNOUT'),
  UserPoolId: get('USERPOOL'),
};
export default config;
