import * as ModalActions from './Modal.actions';
import { putCompany, getCurrentCompany } from './Company.actions';

export const EDIT_COMPANY = 'EDIT_COMPANY';

export const openDialogEditCompany = () => (dispatch) => {
  getCurrentCompany()(dispatch).then((result) => {
    return ModalActions.openDialog(EDIT_COMPANY, result)(dispatch);
  });
};

export const finishDialogEditCompany = data => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => putCompany(data.id, data), EDIT_COMPANY)(dispatch);
};

export default ModalActions;
