import React from 'react';
import PropTypes from 'prop-types';
import {
  IMCheckMarkCircle, IMErrorMarkCircle, IMBat1Usb,
} from '@veit/veit-web-controls/dist/icons';
import { FormattedMessage } from 'react-intl';
import { dialogStates } from '../../store/ModalImport.actions';

import ProgressBase, { ProgressTemplate } from '../import/ProgressBase';

export const Downloading = () => {
  return (
    <ProgressTemplate
      className="connecting"
      icon={IMBat1Usb}
      text={<FormattedMessage id="modals.import.reading-data-bat1" defaultMessage="Reading data from BAT1 scale ..." />}
    />
  );
};

const MemoryNotConnected = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.disconnected" defaultMessage="BAT2 Memory stick is disconnected" />}
    />
  );
};

const Success = () => {
  return (
    <ProgressTemplate
      className="success"
      icon={IMCheckMarkCircle}
      text={<FormattedMessage id="modals.import.successfuly-imported-bat1" defaultMessage="Data were successfuly imported from BAT1 scale" />}
    />
  );
};

const ReadError = () => {
  return (
    <ProgressTemplate
      className="error"
      icon={IMErrorMarkCircle}
      text={<FormattedMessage id="modals.import.read-bat1-error" defaultMessage="Failed to read data from BAT1 scale" />}
    />
  );
};

const Progress = ({ state }) => {
  switch (state) {
    case dialogStates.memoryNotConnected: return <MemoryNotConnected />;
    case dialogStates.downloading: return <Downloading />;
    case dialogStates.uploading: return <Downloading />;
    case dialogStates.success: return <Success />;
    case dialogStates.readError: return <ReadError />;
    default: return <ProgressBase state={state} />;
  }
};

Progress.propTypes = {
  state: PropTypes.string,
};

Progress.defaultProps = {
  state: null,
};

export default Progress;
