import React from 'react';
import { FormattedMessage } from 'react-intl';
import IM500 from '@veit/veit-web-controls/dist/icons/IM500';
import { Button } from '@veit/veit-web-controls/dist/index';
import IconPage from './IconPage';
import QueryLink from './QueryLink';


const PageError = () => {
  return (
    <FormattedMessage id="error.500" defaultMessage="Something went wrong :(">
      {text => (
        <IconPage
          text={text}
          icon={IM500}
          column
          action={(
            <Button tag={QueryLink} to="/flocks">
              <FormattedMessage id="error.go-to-dashboard" defaultMessage="Go to dashboard" />
            </Button>
          )}
        />
      )}
    </FormattedMessage>
  );
};

export default PageError;
