
import rest from './rest.actions';
import * as actions from '../utils/api';
import { ApiClient } from '@veit/bat-cloud-api/lib/ApiClient';
import { StatisticApi } from '@veit/bat-cloud-api/lib/api/StatisticApi';

export const DOWNLOAD_STATS = 'DOWNLOAD_STATS';
export const DOWNLOAD_SAMPLES = 'DOWNLOAD_SAMPLES';

export const GETBY_FLOCK_STATS = 'GETBY_FLOCK_STATS';
export const GETBY_DEVICE_STATS = 'GETBY_DEVICE_STATS';
export const GETBY_LOCATION_STATS = 'GETBY_LOCATION_STATS';
export const GETBY_SUBCATEGORY_STATS = 'GETBY_SUBCATEGORY_STATS';

const restStatsFlock = rest(new StatisticApi());
const restStatsDevice = rest(new StatisticApi());

export const getStatsFlockBy = restStatsFlock.createGetBy(GETBY_FLOCK_STATS, 'statisticAllByFlockFlockIdGet');
export const getStatsDeviceBy = restStatsDevice.createGetBy(GETBY_DEVICE_STATS, 'statisticAllByDeviceDeviceIdGet');
export const getStatsLocationBy = restStatsDevice.createGetBy(GETBY_LOCATION_STATS, 'statisticAllByLocationLocationIdGet');

const allowedQueryParams = ['from', 'to', 'time'];

export const run = (path, params, httpMethod = 'GET', body = {}) => {
  const queryParams = {};
  allowedQueryParams.forEach((name) => {
    queryParams[name] = (params || {})[name];
  });
  return ApiClient.instance.callApi(path, httpMethod, {}, queryParams, {}, {}, body, ['OAuth2'], ['application/json'], ['arraybuffer'], 'Blob');
};

export const create = (action, request) => (dispatch) => {
  dispatch({ type: `${action}_REQUEST` });
  return request().then((result) => {
    dispatch({ type: action });
    return result;
  });
};

export const getStatsCategory = (id, time) => actions.create(
  GETBY_SUBCATEGORY_STATS,
  () => actions.run(`/statistic/subcategory/${id}`, 'GET', null, { time }),
);

export const getStatsFlockByFormat = (id, params) => create(DOWNLOAD_STATS, () => run(`/statistic/allbyflock/${id}/${params.format}`, params));
export const getStatsDeviceByFormat = (id, params) => create(DOWNLOAD_STATS, () => run(`/statistic/allbydevice/${id}/${params.format}`, params));
export const getStatsLocationByFormat = (id, params) => create(DOWNLOAD_STATS, () => run(`/statistic/allbylocation/${id}/${params.format}`, params));

export const getSamplesLocationByFormat = (id, params) => create(DOWNLOAD_SAMPLES, () => run(`/samples/bylocation/${id}`, params));
