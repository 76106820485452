import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@veit/veit-web-controls';

export const weightUnits = {
  g: 'grams',
  kg: 'kilograms',
  lb: 'pounds',
};

function setWeight(e, unit) {
  const { value } = e.target;
  const output = Number.isNaN(Number.parseFloat(value))
    ? value
    : unit === weightUnits.kg ? Math.round(Number.parseFloat(value) * 1000)
      : unit === weightUnits.lb ? Math.round(Number.parseFloat(value) * 453.59237)
        : Math.round(value);

  return { target: { value: output } };
}

function getWeight(value, unit) {
  if (Number.isNaN(Number.parseFloat(value))) return value;

  switch (unit) {
    case weightUnits.kg:
      return parseFloat(value) / 1000;
    case weightUnits.lb:
      return +(parseFloat(value) / 453.59237).toFixed(3);
    default:
      return value;
  }
}

const WeightInput = ({
  onChange, value, weightUnit, ...otherProps
}) => {
  return (
    <Input
      onChange={e => onChange(setWeight(e, weightUnit))}
      value={getWeight(value, weightUnit)}
      {...otherProps}
    />
  );
};

WeightInput.propTypes = {
  weightUnit: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

WeightInput.defaultProps = {
  weightUnit: weightUnits.g,
  value: null,
};

export default WeightInput;
