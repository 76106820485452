import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { IMSortAsc, IMSortDesc, IMSort } from '@veit/veit-web-controls/dist/icons';
import bem from '../utils/bem';
import { setQueryCache } from '../utils/queryCache';
import { order } from '../model/order';

const buildQuery = (orderby, location) => {
  const queryParams = queryString.parse(location.search);
  if (queryParams.orderby === orderby) {
    queryParams.order = queryParams.order != null ? null : 'desc';
  }
  queryParams.orderby = orderby;
  const query = queryString.stringify(queryParams, { skipNull: true });
  const path = location.pathname + (query === '' ? '' : `?${query}`);
  setQueryCache(path);
  return path;
};

const bm = bem.component('query-sort');

const QuerySort = ({
  orderby, children, location, history, style,
}) => {
  const queryParams = queryString.parse(location.search);
  const isActive = queryParams.orderby === orderby;
  const isDesc = queryParams.order === order.desc;
  return (
    <div
      onKeyPress={null}
      tabIndex="0"
      role="button"
      className={bm.b(isActive ? 'active' : null)}
      onClick={() => history.push(buildQuery(orderby, location))}
      style={style}
    >
      {children}
      {!isActive && <IMSort />}
      {isActive && isDesc && <IMSortDesc />}
      {isActive && !isDesc && <IMSortAsc />}
    </div>
  );
};

QuerySort.propTypes = {
  orderby: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
};

QuerySort.defaultProps = {
  orderby: undefined,
  children: null,
  style: null,
};

export default withRouter(QuerySort);
