import React from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalActions, { openDialogImportData, openDialogImportFile } from '../../store/ModalImport.actions';
import { deviceType } from '../../model/enums';

import bem from '../../utils/bem';

import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import AnimateHeight from '../AnimateHeight';
import SelectDeviceImport from './SelectDeviceImport';
import ModalWithLoader from '../ModalWithLoader';

const bm = bem.modal('add-device');

const ImportModal = ({
  modal, cancelDialog, openDialogImportData, openDialogImportFile,
}) => {
  const { step } = modal;
  const cancel = () => cancelDialog();

  const selectImport = (type) => {
    cancelDialog();
    if (type === deviceType.bat1) {
      openDialogImportFile();
    } else {
      openDialogImportData();
    }
  };

  return (
    <ModalWithLoader
      centered
      isOpen
      keyboard={false}
      toggle={cancel}
      contentClassName="modal-medium"
      className={bm.b()}
    >
      <ModalHeader toggle={cancel}>
        <FormattedMessage id="common.import-data" defaultMessage="Import data" />
      </ModalHeader>
      <ModalBody style={{ maxWidth: 'unset' }}>
        <AnimateHeight step={step}>
          <React.Fragment>
            <SelectDeviceImport onSelect={selectImport} />
            <SubmitHandler keyCode={keyCodes.escape} action={cancel} />
          </React.Fragment>
        </AnimateHeight>
      </ModalBody>
    </ModalWithLoader>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  openDialogImportData,
  openDialogImportFile,
}, dispatch);

ImportModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  openDialogImportData: PropTypes.func.isRequired,
  openDialogImportFile: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(ImportModal);
