
import { fetchAll } from '../../store/Fetch.actions';
import { getFile, expandFile } from '../../store/File.actions';
import { getLocation } from '../../store/Location.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';

const fetchData = (fetchMetadata, pagination = {}) => (dispatch) => {
  return fetchAll(() => [
    getFile({
      expand: [
        expandFile.location,
        expandFile.farm,
      ],
      ...pagination,
    })(dispatch),
    fetchMetadata
      ? getFarm({
        expand: [expandFarm.address, expandFarm.country, expandFarm.devicesCount],
      })(dispatch)
      : null,
    fetchMetadata ? getLocation()(dispatch) : null,
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
