import {
  GET_SAMPLES_BY_LOCATION,
} from './Samples.actions';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAMPLES_BY_LOCATION:
      return action.payload || [];
    default:
      return state;
  }
};


export default reducer;
