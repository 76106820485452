import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';
import { statsType } from '../../model/enums';

const LastDay = ({ lastStatistics }) => {
  if (lastStatistics == null || lastStatistics.lenght === 0) return null;
  const stat = lastStatistics.find(f => f.type === statsType.automatic) || lastStatistics[0];
  return stat == null || !Number.isInteger(stat.day || stat.flockDay) ? null : (
    <Label>
      <FormattedMessage
        values={{ count: stat.day || stat.flockDay }}
        id="common.count-days"
        defaultMessage="{count, plural, one {# day} other {# days}}"
      />
    </Label>
  );
};

LastDay.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    category: PropTypes.string,
    day: PropTypes.number,
  })),
};

LastDay.defaultProps = {
  lastStatistics: [],
};

export default LastDay;
