import { push } from 'connected-react-router';
import * as ModalActions from './Modal.actions';
import {
  deleteBird, putBird, postBird, DELETE_BIRD,
} from './Bird.actions';

export const ADD_BIRD = 'ADD_BIRD';
export const EDIT_BIRD = 'EDIT_BIRD';

// bird actions
export const openDialogAddBird = data => (dispatch) => {
  return ModalActions.openDialog(ADD_BIRD, { ...(data || {}), duration: 36 })(dispatch);
};

export const openDialogEditBird = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_BIRD, data)(dispatch);
};

export const finishDialogAddBird = data => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => postBird(data), ADD_BIRD)(dispatch)
    .then((result) => {
      dispatch(push(`/bird/${result.id}`));
    });
};

export const finishDialogEditBird = data => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => putBird(data.id, data), EDIT_BIRD)(dispatch);
};

export const deleteDialogBird = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteBird(id), DELETE_BIRD, { delete: true })(dispatch)
    .then(() => {
      dispatch(push('/birds'));
    });
};

export default ModalActions;
