
import rest from './rest.actions';
import { StatisticApi } from '@veit/bat-cloud-api/lib/api/StatisticApi';
import { ComparisonApi } from '@veit/bat-cloud-api/lib/api/ComparisonApi';

const statisticApi = new StatisticApi();
const restComparison = rest(new ComparisonApi());

export const GET_COMPARE = 'GET_COMPARE';
export const GETBY_COMPARE = 'GETBY_COMPARE';
export const GETBY_FLOCK_COMPARE = 'GETBY_FLOCK_COMPARE';
export const POST_COMPARE = 'POST_COMPARE';
export const PUT_COMPARE = 'PUT_COMPARE';
export const DELETE_COMPARE = 'DELETE_COMPARE';

export const getCompare = restComparison.createGet(GET_COMPARE, 'comparisonGet');
export const postCompare = restComparison.createPost(POST_COMPARE, 'comparisonPost');
export const putCompare = restComparison.createPut(PUT_COMPARE, 'comparisonComparisonIdPut');
export const deleteCompare = restComparison.createDelete(DELETE_COMPARE, 'comparisonComparisonIdDelete');

export const getFlockCompareBy = flockId => async (dispatch) => {
  dispatch({ type: `${GETBY_FLOCK_COMPARE}_REQUEST` });
  const result = await statisticApi.statisticAllByFlockFlockIdGet(flockId);
  dispatch({ type: GETBY_FLOCK_COMPARE, payload: { [flockId]: result.data } });
  return result.data;
};

export const getFlocksCompareBy = (flockIds = []) => async (dispatch) => {
  dispatch({ type: `${GETBY_FLOCK_COMPARE}_REQUEST` });

  const requests = flockIds.map(flockId => statisticApi.statisticAllByFlockFlockIdGet(flockId));
  const results = await Promise.all(requests);
  const result = {};
  for (let index = 0; index < flockIds.length; index += 1) {
    const flockId = flockIds[index];
    result[flockId] = results[index].data;
  }

  dispatch({ type: GETBY_FLOCK_COMPARE, payload: result });
  return result;
};
