import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import dayjs from 'dayjs';

import array from '../../utils/array';
import { roundTo } from '../../components/Round';
import { sexType, statsType, unitType } from '../../model/enums';
import intl from '../../setup/RIntl';
import convertGramsToPounds from '../../utils/unitConversion';

export const messages = defineMessages({
  average: { id: 'common.weight', defaultMessage: 'Weight' },
  gain: { id: 'common.gain', defaultMessage: 'Gain' },
  count: { id: 'common.count', defaultMessage: 'Count' },
  uni: { id: 'common.unifor', defaultMessage: 'Unifor.' },
  cv: { id: 'common.cv', defaultMessage: 'CV' },
  day: { id: 'common.day', defaultMessage: 'Day' },
  date: { id: 'common.date', defaultMessage: 'Date' },
  automatic: { id: 'common.automatic', defaultMessage: 'Automatic' },
  manual: { id: 'common.manual', defaultMessage: 'Manual' },
  male: { id: 'common.sex-male', defaultMessage: 'Male' },
  female: { id: 'common.sex-female', defaultMessage: 'Female' },
  idealWeight: { id: 'common.ideal-weight', defaultMessage: 'Ideal weight' },
});

function setColors(irrelevant, male, female) {
  return {
    [sexType.irrelevant]: irrelevant,
    [sexType.male]: male,
    [sexType.female]: female,
  };
}

export const propToName = {
  average: {
    unit: unitType.g, color: '#EA6352', colors: setColors('#EA6352', '#a34539', '#5d2720'), key: 'average',
  },
  gain: {
    unit: unitType.g, color: '#F3C969', colors: setColors('#F3C969', '#aa8c49', '#61502a'), key: 'gain',
  },
  count: {
    unit: unitType.pieces, color: '#57A773', colors: setColors('#57A773', '#3c7450', '#22422e'), key: 'count',
  },
  uni: {
    unit: unitType.percent, color: '#535AEA', colors: setColors('#535AEA', '#3a3ea3', '#21245d'), key: 'uni',
  },
  cv: {
    unit: unitType.percent, color: '#6FBAD8', colors: setColors('#6FBAD8', '#4d8297', '#2c4a56'), key: 'cv',
  },
};

const growthCurve = {
  unit: null, color: '#757575', colors: setColors('#757575', '#757575', '#757575'), key: 'growthCurve',
};

const MS_PER_DAY = 1000 * 60 * 60 * 24;

function isWeightAxis(axis) {
  return axis != null && axis.key === propToName.average.key;
}

function approxPoint(curvePoints, minPoint, maxPoint, day) {
  if (minPoint == null || day < minPoint.day || maxPoint == null || day > maxPoint.day) return {};
  const lower = array.closestLower(curvePoints, day, v => v.day);
  const upper = array.closestUpper(curvePoints, day, v => v.day);
  const diffDay = upper.day - lower.day;
  const diffWeight = upper.weight - lower.weight;
  const daysFromLower = day - lower.day;
  return { day, weight: roundTo(lower.weight + (diffWeight / diffDay) * daysFromLower, 0) };
}

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

function fillMissingDays(points, minDate, maxDate) {
  if (
    points == null
    || minDate == null
    || maxDate == null
    // || min === max
    || minDate > maxDate
  ) return [];
  const dict = {};
  const sexes = [];
  points.forEach((point) => {
    const diff = dateDiffInDays(minDate, point.dateTime);
    const sex = point.category === sexType.female || point.category === sexType.male
      ? point.category
      : sexType.irrelevant;
    if (sexes.indexOf(sex) === -1) {
      sexes.push(sex);
    }
    dict[`${diff}_${sex}`] = point;
  });

  const min = 0;
  const max = dateDiffInDays(minDate, maxDate);
  return sexes.map(m => array
    .range(max - min + 1, min)
    .map((day) => { return dict[`${day}_${m}`] != null ? dict[`${day}_${m}`] : { day: null, dateTime: new Date(minDate.getTime() + day * 86400000) }; }));
}

function groupByTypeReduce(p, c) {
  const result = { ...p };
  Object.keys(c).forEach((key) => {
    const stat = c[key];
    if (result[key] == null) {
      result[key] = [stat];
    } else {
      result[key].push(stat);
    }
  });
  return result;
}

function getSex(stats) {
  const sex = ((stats || [])[0] || {}).category;
  return sex === sexType.male || sex === sexType.female ? sex : sexType.irrelevant;
}

function getSexText(sex) {
  switch (sex) {
    case sexType.male: return `${intl.t(messages.male)} `;
    case sexType.female: return `${intl.t(messages.female)} `;
    default: return '';
  }
}

function createDataset(stat, axis, isAutomatic, yAxisID, weightUnit, showLine = true) {
  const sex = getSex(stat);
  const sexText = getSexText(sex);
  const sexColor = axis.colors[sex];
  const isWeightAxis = axis.unit !== null && axis.unit === 'g';
  const values = stat.map(i => (i.count === 0 ? null : (
    isWeightAxis && weightUnit === 'lb'
      ? convertGramsToPounds(i[axis.key])
      : roundTo(i[axis.key]))
  ));
  let unitValue = '-';
  // if axis is weight, unit is always g
  if (isWeightAxis) {
    unitValue = weightUnit;
  } else if (axis.unit !== null) {
    unitValue = axis.unit;
  }

  return {
    id: `${axis.key}_automatic`,
    label: `${sexText}${intl.t(isAutomatic ? messages.automatic : messages.manual)} ${intl.t(messages[axis.key])} (${unitValue})`,
    fill: false,
    borderColor: sexColor,
    data: values,
    spanGaps: true,
    cubicInterpolationMode: 'monotone',
    yAxisID,
    showLine,
    pointBackgroundColor: !isAutomatic ? sexColor : undefined,
  };
}

const Graphs = ({
  stats, leftAxis, rightAxis, flock, weightUnit,
}) => {
  if (stats == null || stats.length === 0) return null;
  const groupStats = stats.reduce(groupByTypeReduce, {});

  const automatic = groupStats.automatic || [];
  const manual = groupStats.manual || [];

  const orderStats = [...automatic, ...manual].sort((a, b) => (a.dateTime - b.dateTime));
  if (orderStats.length === 0) return null;
  const last = orderStats[orderStats.length - 1];
  const first = orderStats[0];
  const stAutomatic = fillMissingDays(automatic, first.dateTime, last.dateTime);
  const stManual = fillMissingDays(manual, first.dateTime, last.dateTime);

  const chart = {
    labels: stAutomatic
      .reduce((pr, c) => (pr.map((m, i) => (m.day == null ? c[i] : m))),
        stAutomatic[0] || stManual[0] || [])
      .map(i => (i.type === statsType.manual ? i.flockDay : i.day)),
    datasets: [],
  };

  const showLineManual = stAutomatic.length === 0;

  if (leftAxis) {
    stAutomatic.forEach((st) => {
      chart.datasets.unshift(createDataset(st, leftAxis, true, 'left', weightUnit));
    });
    stManual.forEach((st) => {
      chart.datasets.unshift(createDataset(st, leftAxis, false, 'left', weightUnit, showLineManual));
    });
  }

  if (rightAxis) {
    stAutomatic.forEach((st) => {
      chart.datasets.push(createDataset(st, rightAxis, true, 'right', weightUnit));
    });
    stManual.forEach((st) => {
      chart.datasets.push(createDataset(st, rightAxis, false, 'right', weightUnit, showLineManual));
    });
  }

  if ((isWeightAxis(rightAxis) || isWeightAxis(leftAxis)) && flock != null && flock.bird != null) {
    const statsSerie = (((stAutomatic || [])[0] || (stManual || [])[0]) || []);
    const flockAge = dayjs(flock.startDate).toDate();
    const firstDay = dateDiffInDays(flockAge, statsSerie[0].dateTime) + flock.initialAge;
    const curvePoints = flock.bird.curvePoints.sort((a, b) => (a.day - b.day));
    const minPoint = curvePoints[0];
    const maxPoint = curvePoints[curvePoints.length - 1];
    const points = statsSerie.map((m, i) => {
      const day = firstDay + i;
      return curvePoints.find(f => f.day === day)
        || approxPoint(curvePoints, minPoint, maxPoint, day);
    });
    const useRightAxis = isWeightAxis(rightAxis);
    const axis = useRightAxis ? rightAxis : leftAxis;
    chart.datasets.unshift({
      id: growthCurve.key,
      label: `${intl.t(messages.idealWeight)} (${axis.unit || '-'})`,
      fill: false,
      borderColor: growthCurve.color,
      data: points.map(i => (i.weight == null ? null : roundTo(i.weight))),
      spanGaps: true,
      cubicInterpolationMode: 'monotone',
      yAxisID: useRightAxis ? 'right' : 'left',
      showLine: true,
      pointBackgroundColor: undefined,
    });
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: false,
    animation: false,
    tooltips: {
      enabled: true,
      intersect: false,
      mode: 'x',
      callbacks: {
        title: (item) => {
          if (item[0] && item[0].label !== 'null') return `${intl.t(messages.day)} ${item[0].label}`;
          const { index } = item[0];
          const stat = ((stAutomatic[0]) || [])[index] || ((stManual[0]) || [])[index] || {};
          return stat.dateTime != null ? `${intl.t(messages.date)} ${dayjs(stat.dateTime).format('DD/MM/YYYY')}` : '';
        },
        // label: (item) => {
        //   return item;
        // },
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: true,
          borderDash: [4, 8],
        },
        offset: true,
        ticks: {
          callback: (value) => {
            if (value == null) return null;
            return `${value}`;
          },
        },
      }],
      yAxes: [{
        id: 'left',
        position: 'left',
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: weightUnit === 'lb' ? 0.220 : 100, // default y axes range. If real values are bigger it is reevaluate automatically
        },
      }],
    },
  };

  if (rightAxis) {
    options.scales.yAxes.push({
      id: 'right',
      position: 'right',
      gridLines: {
        drawOnChartArea: false,
      },
      ticks: {
        suggestedMin: 0,
        suggestedMax: weightUnit === 'lb' ? 0.110 : 50, // default y axes range. If real values are bigger it is reevaluate automatically
      },
    });
  }

  return (<Line data={chart} options={options} redraw />);
};

Graphs.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.number,
  })),
  leftAxis: PropTypes.shape({
    key: PropTypes.string,
    color: PropTypes.string,
    unit: PropTypes.string,
  }),
  rightAxis: PropTypes.shape({
    key: PropTypes.string,
    color: PropTypes.string,
    unit: PropTypes.string,
  }),
  flock: PropTypes.shape({
    bird: PropTypes.shape({
      curvePoints: PropTypes.array,
    }),
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initialAge: PropTypes.number,
  }),
  weightUnit: PropTypes.string,
};

Graphs.defaultProps = {
  stats: [],
  flock: null,
  leftAxis: null,
  rightAxis: null,
  weightUnit: null,
};

export default Graphs;
