import * as ModalActions from './Modal.actions';
import {
  deleteDevice, postDevice, putDevice, DELETE_DEVICE,
} from './Device.actions';

export const ADD_DEVICE = 'ADD_DEVICE';
export const EDIT_DEVICE = 'EDIT_DEVICE';

export const openDialogAddDevice = data => (dispatch) => {
  return ModalActions.openDialog(ADD_DEVICE, data, 0)(dispatch);
};

export const openDialogEditDevice = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_DEVICE, data)(dispatch);
};

export const finishDialogAddDevice = data => (dispatch) => {
  const action = data.fast === true
    ? ModalActions.finishDialogRequest
    : ModalActions.resolveDialogRequest;
  return action(() => postDevice(data), EDIT_DEVICE)(dispatch);
};

export const finishDialogEditDevice = data => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => putDevice(data.id, data), EDIT_DEVICE)(dispatch);
};

export const deleteDialogDevice = id => (dispatch) => {
  return ModalActions
    .finishDialogRequest(() => deleteDevice(id), DELETE_DEVICE, { delete: true })(dispatch);
};

export default ModalActions;
