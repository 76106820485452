import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
  Table, Label, AddButton,
} from '@veit/veit-web-controls';
import { IMSettings } from '@veit/veit-web-controls/dist/icons';

import { openDialogAddFarm, openDialogEditFarm } from '../../store/ModalFarm.actions';
import Page from '../../components/Page';
import AuthButton, { AuthAddButton } from '../../components/AuthButton';
import QuerySort from '../../components/QuerySort';

import bem from '../../utils/bem';
import intl from '../../setup/RIntl';
import goToConnect from '../../utils/goToConnect';
import pagination from '../../utils/pagination';
import { farmOrder } from '../../model/order';
import fetchData from './fetchData';

const bm = bem.view('farm-list');

const messages = defineMessages({
  manageFarms: { id: 'farms.manage-farms', defaultMessage: 'Manage farms' },
});

class FarmList extends Component {
  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getData();
    }
  }

  addFarmHandler = () => {
    this.props.openDialogAddFarm().then(this.resolveDialog);
  }

  getData = (fetchMetadata) => {
    this.props.fetchData(fetchMetadata, pagination(this.props.location.search));
  }

  editFarmHandler = (farm) => {
    this.props.openDialogEditFarm(farm).then(this.resolveDialog);
  }

  resolveDialog = (result) => {
    if (result == null) return;
    const last = this.props.farm.items.length === 1;
    if (result.delete && last) {
      this.props.goToPage();
    } else {
      this.getData();
    }
  }

  actions() {
    return (
      <React.Fragment>
        <AuthAddButton canCreate={this.props.farm} onClick={this.addFarmHandler}>
          <FormattedMessage id="farms.add-farm" defaultMessage="Add Farm" />
        </AuthAddButton>
      </React.Fragment>
    );
  }

  render() {
    const farms = this.props.farm.items;
    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.manageFarms)}
        actions={this.actions()}
        isFetching={this.props.isFetching}
        emptyPage={{
          isEmpty: farms.length === 0,
          icon: IMSettings,
          action: <AddButton onClick={this.addFarmHandler}><FormattedMessage id="farms.add-farm" defaultMessage="Add Farm" /></AddButton>,
        }}
        paginate={{ onChange: this.props.goToPage, ...this.props.farm }}
        filter={{ search: true }}
      >
        <Table type="data">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.farm" defaultMessage="Farm" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={farmOrder.address}>
                  <FormattedMessage id="common.address" defaultMessage="Address" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={farmOrder.country}>
                  <FormattedMessage id="common.country" defaultMessage="Country" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={farmOrder.activeFlocks}>
                  <FormattedMessage id="flocks.active-flocks" defaultMessage="Active flocks" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={farmOrder.scheduledlocks}>
                  <FormattedMessage id="flocks.scheduled-flocks" defaultMessage="Scheduled Flocks" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={farmOrder.locations}>
                  <FormattedMessage id="common.locations" defaultMessage="Locations" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={farmOrder.devices}>
                  <FormattedMessage id="common.scales" defaultMessage="Scales" />
                </QuerySort>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {farms.map(d => (
              <tr key={d.id}>
                <td><Label>{d.name}</Label></td>
                <td><Label>{d.address}</Label></td>
                <td><Label>{d.country}</Label></td>
                <td><Label>{d.activeFlocksCount || 0}</Label></td>
                <td><Label>{d.scheduledFlocksCount || 0}</Label></td>
                <td><Label>{d.locationsCount || 0}</Label></td>
                <td><Label>{d.devicesCount || 0}</Label></td>
                <td className="text-center">
                  <AuthButton canUpdate={d} className="btn-narrow" onClick={() => this.editFarmHandler(d)} color="primary">
                    <FormattedMessage id="farms.edit-farm" defaultMessage="Edit Farm" />
                  </AuthButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

FarmList.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogAddFarm: PropTypes.func.isRequired,
  openDialogEditFarm: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  farm: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      country: PropTypes.string,
    })),
    links: PropTypes.object,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

FarmList.defaultProps = {
  farm: null,
};

const mapStateToProps = (state) => {
  return {
    farm: state.farm,
    isFetching: state.fetch.isFetching,
    locale: state.auth.locale,
  };
};

const mapDispatchToProps = {
  fetchData,
  openDialogAddFarm,
  openDialogEditFarm,
};

export default goToConnect('/farms')(FarmList, mapStateToProps, mapDispatchToProps);
