import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@veit/veit-web-controls';
import AuthButton from '../../components/AuthButton';

const Actions = ({
  editFlock, copyFlock, closeFlock, exportData, flock,
}) => {
  return (
    <React.Fragment>
      {!flock.endDate && (
        <AuthButton canUpdate={flock} color="primary" onClick={closeFlock}>
          <FormattedMessage id="flocks.modal.close-flock" defaultMessage="Close Flock" />
        </AuthButton>
      )}
      <AuthButton canUpdate={flock} color="primary" outline onClick={copyFlock}>
        <FormattedMessage id="flocks.rerun-flock" defaultMessage="Rerun Flock" />
      </AuthButton>
      <AuthButton canUpdate={flock} color="primary" outline onClick={editFlock}>
        <FormattedMessage id="flocks.edit-flock" defaultMessage="Edit Flock" />
      </AuthButton>
      <Button color="primary" outline onClick={exportData}>
        <FormattedMessage id="devices.export" defaultMessage="Export" />
      </Button>
      {/* <Button color="primary" outline>Import Data</Button> */}
    </React.Fragment>
  );
};

Actions.propTypes = {
  editFlock: PropTypes.func.isRequired,
  copyFlock: PropTypes.func.isRequired,
  closeFlock: PropTypes.func.isRequired,
  exportData: PropTypes.func.isRequired,
  flock: PropTypes.shape({
    links: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
};

export default Actions;
