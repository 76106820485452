import { fetchAll } from '../../store/Fetch.actions';
import { getUser } from '../../store/User.actions';

const fetchData = (fetchMetadata, pagination = {}) => (dispatch) => {
  fetchAll(() => [
    getUser({ ...pagination })(dispatch),
  ], !fetchMetadata)(dispatch);
};

export default fetchData;
