import React from 'react';
import { FormattedMessage } from 'react-intl';
import bem from '../utils/bem';

const bm = bem.component('loader');
const Loader = () => {
  return (
    <div className={bm.b()}>
      <div>
        <FormattedMessage id="loader.preparing" defaultMessage="Preparing." />
      </div>
      <div>
        <FormattedMessage id="loader.please-wait" defaultMessage="Please wait ..." />
      </div>
    </div>
  );
};

export default Loader;
