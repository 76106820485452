import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@veit/veit-web-controls';

const ModalWithLoader = ({
  isExecuting, children, dispatch, ...otherProps
}) => {
  return (
    <Modal backdrop="static" {...otherProps} autoFocus={false}>
      {children}
      {isExecuting ? (
        <React.Fragment>
          <div className="modal-content-loader"></div>
          <div className="modal-content-backdrop"></div>
        </React.Fragment>
      ) : null}
    </Modal>
  );
};

ModalWithLoader.propTypes = {
  isExecuting: PropTypes.bool,
  children: PropTypes.node,
  dispatch: PropTypes.func,
};

ModalWithLoader.defaultProps = {
  isExecuting: false,
  children: null,
  dispatch: null,
};

const mapStateToProps = (state) => {
  return {
    isExecuting: state.modal.length > 0 ? state.modal[state.modal.length - 1].isExecuting : false,
  };
};

export default connect(mapStateToProps)(ModalWithLoader);
