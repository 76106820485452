import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import StatusIcon from '@veit/veit-web-controls/dist/Components/StatusIcon';
import Label from '@veit/veit-web-controls/dist/Components/Label';
import Tooltip from '../../components/Tooltip';
import SexIconAlt from './SexIconAlt';
import { unitType } from '../../model/enums';

const oldDataBoundary = 3; // days
const olderDataBoundary = 15; // days

const GainTooltip = ({ lastStatistics }) => {
  return (
    <div className="bwc-gain-tooltip">
      <Label>
        <FormattedMessage id="common.gain" defaultMessage="Gain" />
        <span className="bwc-gain-tooltip__separator">:</span>
      </Label>
      <div>
        {lastStatistics.map(ls => (
          <Label key={`${ls.category}`}>
            <SexIconAlt sex={ls.category} count={lastStatistics.length} /> {`${ls.gain} ${unitType.g}`}
          </Label>
        ))}
      </div>
    </div>
  );
};

GainTooltip.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    gain: PropTypes.number,
    sex: PropTypes.string,
  })).isRequired,
};

const DeviceStatusIcon = ({ lastStatistics }) => {
  if (lastStatistics == null || lastStatistics.length === 0) {
    return null;
  }
  const hasNegativeGain = lastStatistics.filter(f => f.gain < 0).length > 0;
  const diffDays = Math.floor(dayjs().diff(dayjs(lastStatistics[0].dateTime), 'days'));
  const isOld = diffDays >= oldDataBoundary;
  const message = isOld
    ? (
      <Label>
        <FormattedMessage
          values={{ count: diffDays }}
          id="common.last-update-days"
          defaultMessage="Updated {count, plural, one {# day} other {# days}} ago."
        />
      </Label>
    )
    : <GainTooltip lastStatistics={lastStatistics} />;
  return (
    <Tooltip text={message}>
      <StatusIcon hasError={isOld || hasNegativeGain} className={!isOld ? null : diffDays >= olderDataBoundary ? 'is-older' : 'is-old'} />
    </Tooltip>
  );
};

DeviceStatusIcon.propTypes = {
  lastStatistics: PropTypes.arrayOf(PropTypes.shape({
    dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  })),
};

DeviceStatusIcon.defaultProps = {
  lastStatistics: [],
};

export default DeviceStatusIcon;
