import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import array from '../../utils/array';
import { colorShade } from '../../utils/colors';
import { roundTo } from '../../components/Round';
import { sexType, unitType } from '../../model/enums';
import intl from '../../setup/RIntl';
import convertGramsToPounds from '../../utils/unitConversion';

export const messages = defineMessages({
  average: { id: 'common.weight', defaultMessage: 'Weight' },
  gain: { id: 'common.gain', defaultMessage: 'Gain' },
  count: { id: 'common.count', defaultMessage: 'Count' },
  uni: { id: 'common.unifor', defaultMessage: 'Unifor.' },
  cv: { id: 'common.cv', defaultMessage: 'CV' },
  day: { id: 'common.day', defaultMessage: 'Day' },
  date: { id: 'common.date', defaultMessage: 'Date' },
  automatic: { id: 'common.automatic', defaultMessage: 'Automatic' },
  manual: { id: 'common.manual', defaultMessage: 'Manual' },
  male: { id: 'common.sex-male', defaultMessage: 'Male' },
  female: { id: 'common.sex-female', defaultMessage: 'Female' },
  idealWeight: { id: 'common.ideal-weight', defaultMessage: 'Ideal weight' },
});

function setColors(irrelevant, male, female) {
  return {
    [sexType.irrelevant]: irrelevant,
    [sexType.male]: male,
    [sexType.female]: female,
  };
}

export const propToName = {
  average: {
    unit: unitType.g, color: '#EA6352', colors: setColors('#EA6352', '#a34539', '#5d2720'), key: 'average',
  },
  gain: {
    unit: unitType.g, color: '#F3C969', colors: setColors('#F3C969', '#aa8c49', '#61502a'), key: 'gain',
  },
  count: {
    unit: unitType.pieces, color: '#57A773', colors: setColors('#57A773', '#3c7450', '#22422e'), key: 'count',
  },
  uni: {
    unit: unitType.percent, color: '#535AEA', colors: setColors('#535AEA', '#3a3ea3', '#21245d'), key: 'uni',
  },
  cv: {
    unit: unitType.percent, color: '#6FBAD8', colors: setColors('#6FBAD8', '#4d8297', '#2c4a56'), key: 'cv',
  },
};

const growthCurve = {
  unit: null, color: '#757575', colors: setColors('#757575', '#757575', '#757575'), key: 'growthCurve',
};

function isWeightAxis(axis) {
  return axis != null && axis.key === propToName.average.key;
}

function approxPoint(curvePoints, minPoint, maxPoint, day) {
  if (minPoint == null || day < minPoint.day || maxPoint == null || day > maxPoint.day) return {};
  const lower = array.closestLower(curvePoints, day, v => v.day);
  const upper = array.closestUpper(curvePoints, day, v => v.day);
  const diffDay = upper.day - lower.day;
  const diffWeight = upper.weight - lower.weight;
  const daysFromLower = day - lower.day;
  return { day, weight: roundTo(lower.weight + (diffWeight / diffDay) * daysFromLower, 0) };
}

function fillMissingDays(points, minDate, maxDate) {
  if (
    points == null
    || minDate == null
    || maxDate == null
    // || min === max
    || minDate > maxDate
  ) return [];
  const dict = {};
  const sexes = [];
  points.forEach((point) => {
    const diff = point.flockDay - minDate;
    const sex = point.category === sexType.female || point.category === sexType.male
      ? point.category
      : sexType.irrelevant;
    if (sexes.indexOf(sex) === -1) {
      sexes.push(sex);
    }
    dict[`${diff}_${sex}`] = point;
  });

  const min = 0;
  const max = maxDate - minDate;
  return sexes.map(m => array
    .range(max - min + 1, min)
    .map((day) => { return dict[`${day}_${m}`] != null ? dict[`${day}_${m}`] : { day: null }; }));
}

function groupByTypeReduce(p, c) {
  const { type } = c;
  return { ...p, [type]: [...(p[type] || []), c] };
}

function getSex(stats) {
  const sex = ((stats || [])[0] || {}).category;
  return sex === sexType.male || sex === sexType.female ? sex : sexType.irrelevant;
}

function getSexText(sex) {
  switch (sex) {
    case sexType.male: return `${intl.t(messages.male)} `;
    case sexType.female: return `${intl.t(messages.female)} `;
    default: return '';
  }
}

const colorSexShade = { [sexType.male]: -50, [sexType.female]: 50 };

function createDataset(stat, axis, isAutomatic, yAxisID, color, weightUnit, showLine = true) {
  const sex = getSex(stat);
  const sexText = getSexText(sex);
  const colorValue = colorShade(color, colorSexShade[sex] || 0);
  let u = axis.unit;
  if ((axis.unit === 'g' || axis.unit === 'lb') && weightUnit === 'lb') {
    u = 'lb';
  }
  if ((axis.unit === 'lb' || axis.unit === 'g') && weightUnit === 'g') {
    u = 'g';
  }
  return {
    id: `${axis.key}_automatic`,
    label: `${sexText}${intl.t(isAutomatic ? messages.automatic : messages.manual)} ${intl.t(messages[axis.key])} (${u || '-'})`,
    fill: false,
    borderColor: colorValue,
    data: stat.map(i => (i.count === 0 ? null : u === 'lb' ? convertGramsToPounds(i[axis.key]) : roundTo(i[axis.key]))),
    spanGaps: true,
    cubicInterpolationMode: 'monotone',
    yAxisID,
    showLine,
    pointBackgroundColor: !isAutomatic ? colorValue : undefined,
    pointRadius: !showLine ? 3 : 2,
  };
}

const Graphs = ({
  allStats, flocks, selected, leftAxis, rightAxis, bird, weightUnit,
}) => {
  const flockIds = Object.keys(allStats || {});
  const max = flockIds.reduce((p, c) => Math.max(...(allStats[c].map(m => m.flockDay)), p), 0) + 1;
  const chart = {
    labels: [...Array(max).keys()].map(m => m),
    datasets: [],
  };
  for (let index = 0; index < flockIds.length; index += 1) {
    const flockId = flockIds[index];
    const config = selected.find(f => f.flockId === flockId) || { color: 'black' };
    const stats = allStats[flockId] || [];
    const flock = (flocks || []).find(f => f.id === flockId);

    // eslint-disable-next-line no-continue
    if (stats.length === 0 || flock == null || config == null || config.hidden) continue;

    const groupStats = stats.reduce(groupByTypeReduce, {});
    const automatic = groupStats.automatic || [];
    const manual = groupStats.manual || [];

    const orderStats = [...automatic, ...manual].sort((a, b) => (a.flockDay - b.flockDay));
    if (orderStats.length === 0) return null;
    const last = orderStats[orderStats.length - 1];
    const stAutomatic = fillMissingDays(automatic, 0, last.flockDay);
    const stManual = fillMissingDays(manual, 0, last.flockDay);

    const showLineManual = stAutomatic.length === 0;

    if (leftAxis) {
      stAutomatic.forEach((st) => {
        chart.datasets.unshift(createDataset(st, leftAxis, true, 'left', config.color, weightUnit));
      });
      stManual.forEach((st) => {
        chart.datasets.unshift(createDataset(st, leftAxis, false, 'left', config.color, weightUnit, showLineManual));
      });
    }

    if (rightAxis) {
      stAutomatic.forEach((st) => {
        chart.datasets.push(createDataset(st, rightAxis, true, 'right', config.color, weightUnit));
      });
      stManual.forEach((st) => {
        chart.datasets.push(createDataset(st, rightAxis, false, 'right', config.color, weightUnit, showLineManual));
      });
    }
  }

  if ((isWeightAxis(rightAxis) || isWeightAxis(leftAxis)) && bird != null) {
    const statsSerie = [...Array(max).keys()].map(m => m);
    const firstDay = 0;
    const curvePoints = bird.curvePoints.sort((a, b) => (a.day - b.day));
    const minPoint = curvePoints[0];
    const maxPoint = curvePoints[curvePoints.length - 1];
    const points = statsSerie.map((m, i) => {
      const day = firstDay + i;
      return curvePoints.find(f => f.day === day)
        || approxPoint(curvePoints, minPoint, maxPoint, day);
    });
    const useRightAxis = isWeightAxis(rightAxis);
    const axis = useRightAxis ? rightAxis : leftAxis;
    if (weightUnit === 'lb') {
      axis.unit = 'lb';
    }
    chart.datasets.unshift({
      id: growthCurve.key,
      label: `${intl.t(messages.idealWeight)} (${axis.unit || '-'})`,
      fill: false,
      borderColor: growthCurve.color,
      data: points.map(i => (i.weight == null ? null : weightUnit === 'lb' ? convertGramsToPounds(i.weight) : roundTo(i.weight))),
      spanGaps: true,
      cubicInterpolationMode: 'monotone',
      yAxisID: useRightAxis ? 'right' : 'left',
      showLine: true,
      pointBackgroundColor: undefined,
    });
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      align: 'start',
      position: 'bottom',
    },
    animation: false,
    tooltips: {
      enabled: true,
      intersect: false,
      mode: 'x',
      callbacks: {
        title: (item) => {
          return `${intl.t(messages.day)} ${item[0].label}`;
        },
        // label: (item) => {
        //   return item;
        // },
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: true,
          borderDash: [4, 8],
        },
        offset: true,
        ticks: {
          callback: (value) => {
            if (value == null) return null;
            return `${value}`;
          },
        },
      }],
      yAxes: [{
        id: 'left',
        position: 'left',
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: weightUnit === 'lb' ? 0.05 : 20,
        },
      }],
    },
  };

  if (rightAxis) {
    options.scales.yAxes.push({
      id: 'right',
      position: 'right',
      gridLines: {
        drawOnChartArea: false,
      },
      ticks: {
        suggestedMin: 0,
        suggestedMax: weightUnit === 'lb' ? 0.05 : 20,
      },
    });
  }

  return (<Line data={chart} options={options} redraw />);
};

Graphs.propTypes = {
  allStats: PropTypes.objectOf(PropTypes.array),
  leftAxis: PropTypes.shape({
    key: PropTypes.string,
    color: PropTypes.string,
    unit: PropTypes.string,
  }),
  rightAxis: PropTypes.shape({
    key: PropTypes.string,
    color: PropTypes.string,
    unit: PropTypes.string,
  }),
  selected: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    flockId: PropTypes.string,
  })),
  flocks: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      initialAge: PropTypes.number,
    }),
  ).isRequired,
  bird: PropTypes.shape({
    name: PropTypes.string,
    curvePoints: PropTypes.array,
  }),
  weightUnit: PropTypes.string,
};

Graphs.defaultProps = {
  allStats: {},
  selected: [],
  leftAxis: { key: propToName.average.key, unit: propToName.average.unit },
  rightAxis: null,
  bird: null,
  weightUnit: null,
};

export default Graphs;
