import rest from './rest.actions';
import { FlockApi } from '@veit/bat-cloud-api/lib/api/FlockApi';

export const FLOCK = 'FLOCK';
export const GET_FLOCK = 'GET_FLOCK';
export const GETBY_FLOCK = 'GETBY_FLOCK';
export const POST_FLOCK = 'POST_FLOCK';
export const PUT_FLOCK = 'PUT_FLOCK';
export const DELETE_FLOCK = 'DELETE_FLOCK';

const expand = {
  initialAge: 'initialAge',
  initialWeight: 'initialWeight',
  targetAge: 'targetAge',
  targetWeight: 'targetWeight',
  endDate: 'endDate',
  location: 'location',
  device: 'device',
  bird: 'bird',
  lastStatistics: 'lastStatistics',
  farm: 'farm',
};

export const expandFlock = Object.freeze({
  ...expand,
  all: Object.values(expand),
});

export const scopeFlock = Object.freeze({
  all: 'all',
  active: 'active',
  scheduled: 'scheduled',
  finished: 'finished',
});

const restFlock = rest(new FlockApi());

export const getFlock = restFlock.createGet(GET_FLOCK, 'flockGet');
export const getFlockBy = restFlock.createGetBy(GETBY_FLOCK, 'flockFlockIdGet');
export const postFlock = restFlock.createPost(POST_FLOCK, 'flockPost');
export const putFlock = restFlock.createPut(PUT_FLOCK, 'flockFlockIdPut');
export const deleteFlock = restFlock.createDelete(DELETE_FLOCK, 'flockFlockIdDelete');
