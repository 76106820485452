import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from '@veit/veit-web-controls';

import DateFormat from '../../components/DateFormat';
import bem from '../../utils/bem';

import ModalActions, { goToHistogram } from '../../store/ModalHistogram.actions';
import ModalWithLoader from '../ModalWithLoader';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import HistogramChart from '../../charts/Histogram';

const bm = bem.modal('histogram');


class HistogramModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  goTo = (stat) => {
    this.props.goToHistogram(stat, this.props.modal.data.stats);
  }

  render() {
    const { stat, next, prev } = this.props.modal.data;
    const histogram = stat.histogram || {};
    const hasData = Object.keys(histogram.steps || {}).length > 0;

    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        className={bm.b()}
      >
        <ModalHeader toggle={this.cancel}>
          <DateFormat date={stat.dateTime} format={this.props.dateFormat} />
          <Label>
            {
              hasData ? (
                <FormattedMessage
                  values={{ count: stat.count }}
                  id="modals.histogram.measurements-in-total"
                  defaultMessage="{count, plural, one {# MEASUREMENT} other {# MEASUREMENTS}} IN TOTAL"
                />
              ) : <FormattedMessage id="modals.histogram.no-histogram-data" defaultMessage="NO HISTOGRAM DATA" />
            }
          </Label>
        </ModalHeader>
        <ModalBody>
          <HistogramChart
            data={histogram}
            weightUnit={this.props.weightUnit}
          />
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row>
              <Col>
                <Button outline color="primary" onClick={this.cancel}>
                  <FormattedMessage id="modals.histogram.close-histogram" defaultMessage="Close Histogram" />
                </Button>
              </Col>
              <Col>
                <Button disabled={!next} outline color="primary" onClick={() => this.goTo(next)}>
                  <FormattedMessage id="common.previous-day" defaultMessage="Previous Day" />
                </Button>
                <Button disabled={!prev} color="primary" onClick={() => this.goTo(prev)}>
                  <FormattedMessage id="common.next-day" defaultMessage="Next Day" />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
        <SubmitHandler
          disabled={!next}
          keyCode={keyCodes.arrowLeft}
          action={() => this.goTo(next)}
        />
        <SubmitHandler
          disabled={!prev}
          keyCode={keyCodes.arrowRight}
          action={() => this.goTo(prev)}
        />
        <SubmitHandler keyCode={keyCodes.escape} action={this.cancel} />
      </ModalWithLoader>
    );
  }
}

HistogramModal.defaultProps = {
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = state => ({
  weightUnit: state.auth.weightUnit,
  dateFormat: state.auth.dateFormat,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  goToHistogram,
}, dispatch);

HistogramModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  goToHistogram: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistogramModal);
