export const GET_STATUS = 'GET_STATUS';

export const getStatus = () => (dispatch) => {
  dispatch({ type: `${GET_STATUS}_REQUEST` });
  return new Promise((resolve) => {
    const data = {
      flockGrowthHasError: Math.random() > 0.5,
      devicesHasError: Math.random() > 0.5,
      dataUpdateHasError: Math.random() > 0.5,
    };
    dispatch({ type: GET_STATUS, payload: data });
    resolve({ ...data });
  });
};
