import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Button,
} from '@veit/veit-web-controls';

import ModalActions, {
  openDialogCloseFlock, deleteDialogFlock, finishDialogEditFlock,
} from '../../store/ModalFlock.actions';

import { modalGetBird, expandBird } from '../../store/Bird.actions';
import { modalGetLocation, expandLocation } from '../../store/Location.actions';
import { modalGetFarm, expandFarm } from '../../store/Farm.actions';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';
import Step2, { hasStep2Error } from './Step2';

class EditFlockModal extends Component {
  componentDidMount() {
    const { data } = this.props.modal;
    const hasRefernce = data.locationId != null || data.deviceId != null;
    this.props.updateForm(hasRefernce, 'hasRefernce');
    this.fetchData();
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditFlock(this.props.modal.data);
  }

  delete = () => {
    this.props.deleteDialogFlock(this.props.modal.data.id);
  }

  closeFlock = () => {
    this.props.openDialogCloseFlock(this.props.modal.data, false).then((result) => {
      if (result != null) {
        this.props.updateForm(result.endDate, 'endDate');
      }
    });
  }

  isScheduled() {
    return dayjs(this.props.modal.data.startDate).toDate() > new Date();
  }

  fetchData() {
    this.props.modalGetBird({ expand: expandBird.duration });
    this.props.modalGetLocation({ expand: expandLocation.scalesCount });
    this.props.modalGetFarm({ expand: expandFarm.housesCount });
  }

  render() {
    const disabled = this.props.modal.data != null && this.props.modal.data.endDate != null;
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-fluid"
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="flocks.edit-flock" defaultMessage="Edit Flock" />
          {' '}
          {this.props.modal.data && this.props.modal.data.endDate && <FormattedMessage id="flocks.modal.finished" defaultMessage="[FINISHED]" />}
          {this.props.modal.data && this.isScheduled() && <FormattedMessage id="flocks.modal.scheduled" defaultMessage="[SCHEDULED]" />}
        </ModalHeader>
        <ModalBody style={{ maxWidth: 'unset', padding: '0' }}>
          <Container>
            <Row>
              <Col>
                <Step1 disabled={disabled} modal={this.props.modal} />
              </Col>
              <Col>
                <Step2 disabled={disabled} modal={this.props.modal} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <Footer
          finisDialog={this.finish}
          deleteDialog={{ action: this.delete, name: 'Flock', item: this.props.modal.data }}
          modal={this.props.modal}
          validators={{ 1: m => ({ ...hasStep2Error(m), ...hasStep1Error(m) }) }}
        >
          {!disabled && (
            <Button outline color="primary" onClick={this.closeFlock}>
              <FormattedMessage id="flocks.modal.close-flock" defaultMessage="Close Flock" />
            </Button>
          )}
          {disabled && (
            <Button outline color="primary" onClick={() => this.props.updateForm(null, 'endDate')}>
              <FormattedMessage id="flocks.modal.reopen-flock" defaultMessage="Reopen Flock" />
            </Button>
          )}
        </Footer>
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateForm: ModalActions.updateForm,
  finishDialog: ModalActions.finishDialog,
  openDialogCloseFlock,
  finishDialogEditFlock,
  deleteDialogFlock,
  modalGetBird,
  modalGetLocation,
  modalGetFarm,
}, dispatch);

EditFlockModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  finishDialog: PropTypes.func.isRequired,
  openDialogCloseFlock: PropTypes.func.isRequired,
  finishDialogEditFlock: PropTypes.func.isRequired,
  deleteDialogFlock: PropTypes.func.isRequired,
  modalGetBird: PropTypes.func.isRequired,
  modalGetLocation: PropTypes.func.isRequired,
  modalGetFarm: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditFlockModal);
