export const colors = {
  aqua: '#00ffff',
  azure: '#f0ffff',
  beige: '#f5f5dc',
  black: '#000000',
  blue: '#0000ff',
  brown: '#a52a2a',
  cyan: '#00ffff',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgrey: '#a9a9a9',
  darkgreen: '#006400',
  darkkhaki: '#bdb76b',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  darkviolet: '#9400d3',
  fuchsia: '#ff00ff',
  gold: '#ffd700',
  green: '#008000',
  indigo: '#4b0082',
  khaki: '#f0e68c',
  lightblue: '#add8e6',
  lightcyan: '#e0ffff',
  lightgreen: '#90ee90',
  lightgrey: '#d3d3d3',
  lightpink: '#ffb6c1',
  lightyellow: '#ffffe0',
  lime: '#00ff00',
  magenta: '#ff00ff',
  maroon: '#800000',
  navy: '#000080',
  olive: '#808000',
  orange: '#ffa500',
  pink: '#ffc0cb',
  purple: '#800080',
  violet: '#800080',
  red: '#ff0000',
  silver: '#c0c0c0',
  white: '#ffffff',
  yellow: '#ffff00',
};

export const colorsList = [
  colors.blue,
  colors.green,
  colors.orange,
  colors.pink,
  colors.purple,
  colors.azure,
  colors.beige,
  colors.brown,
  colors.cyan,
  colors.darkblue,
  colors.darkcyan,
  colors.darkgrey,
  colors.darkgreen,
  colors.darkkhaki,
  colors.darkmagenta,
  colors.darkolivegreen,
  colors.darkorange,
  colors.darkorchid,
  colors.darkred,
  colors.darksalmon,
  colors.darkviolet,
  colors.fuchsia,
  colors.gold,
  colors.indigo,
  colors.khaki,
  colors.lightblue,
  colors.lightcyan,
  colors.lightgreen,
  colors.lightgrey,
  colors.lightpink,
  colors.lightyellow,
  colors.lime,
  colors.magenta,
  colors.maroon,
  colors.navy,
  colors.olive,
  colors.violet,
  colors.red,
  colors.silver,
  colors.yellow,
];

export const getUniqueColor = (exclude = []) => {
  return colorsList.find(c => exclude.indexOf(c) === -1);
};

export const colorShade = (color, amt) => {
  let col = color.replace(/^#/, '');
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt]);

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};
