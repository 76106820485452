import { fetchAll } from '../../store/Fetch.actions';
import { getLocationBy, expandLocation } from '../../store/Location.actions';
import { getStatsLocationBy } from '../../store/Stats.actions';
import { loadQuery } from '../../utils/queryCache';

const fetchData = (id, params = {}) => (dispatch) => {
  const queryParams = loadQuery('/locations');
  fetchAll(() => [
    getLocationBy(id, {
      ...queryParams,
      expand: [expandLocation.activeFlock, expandLocation.farm],
    })(dispatch),
    getStatsLocationBy(id, params)(dispatch),
  ])(dispatch);
};

export default fetchData;
