import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from '@veit/veit-web-controls';

import ModalActions, { finishDialogEditCompany } from '../../store/ModalCompany.actions';
import { getCurrentCompany } from '../../store/Company.actions';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import AuthButton from '../../components/AuthButton';
import { authType } from '../../model/enums';

import ModalWithLoader from '../ModalWithLoader';

class EditCompanyModal extends Component {
  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditCompany(this.props.modal.data);
  }

  render() {
    const form = this.props.modal.data;
    if (form == null) return null;
    const { updateFormEvent } = this.props;
    const isReadOnly = !authType.canUpdate(form);
    return (
      <ModalWithLoader
        centered
        isOpen
        toggle={this.cancel}
      >
        <ModalHeader toggle={this.cancel}>
          <FormattedMessage id="company.modal.edit-company" defaultMessage="Edit Company" />
        </ModalHeader>
        <ModalBody style={{ maxWidth: 'unset', padding: '32px 0' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, padding: '0px 46px' }}>
              <Label type="text">
                <FormattedMessage id="company.modal.company-name" defaultMessage="Company name" />
              </Label>
              <Input readOnly={isReadOnly} type="text" value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} />
              <Label type="text">
                <FormattedMessage id="company.modal.vat-number" defaultMessage="VAT Number" />
              </Label>
              <Input readOnly={isReadOnly} type="text" value={form.vat || ''} onChange={e => updateFormEvent(e, 'vat')} />
              <Label type="text">
                <FormattedMessage id="company.modal.headquerters-address" defaultMessage="Headquarters address" />
              </Label>
              <Input readOnly={isReadOnly} type="text" value={form.address || ''} onChange={e => updateFormEvent(e, 'address')} />
              <div style={{ display: 'inline-flex' }}>
                <div style={{ marginRight: '10px' }}>
                  <Label type="text">
                    <FormattedMessage id="company.modal.zip-code" defaultMessage="Zip code" />
                  </Label>
                  <Input readOnly={isReadOnly} type="text" value={form.zip || ''} onChange={e => updateFormEvent(e, 'zip')} />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <Label type="text">
                    <FormattedMessage id="company.modal.city" defaultMessage="City" />
                  </Label>
                  <Input readOnly={isReadOnly} type="text" value={form.city || ''} onChange={e => updateFormEvent(e, 'city')} />
                </div>
              </div>
              <Label type="text">
                <FormattedMessage id="common.country" defaultMessage="Country" />
              </Label>
              <Input readOnly={isReadOnly} type="text" value={form.country || ''} onChange={e => updateFormEvent(e, 'country')} />
            </div>
            <div style={{ flex: 1, padding: '0px 46px' }}>
              <Label type="text">
                <FormattedMessage id="modals.contact-name" defaultMessage="Contact name" />
              </Label>
              <Input readOnly={isReadOnly} type="text" value={form.contactName || ''} onChange={e => updateFormEvent(e, 'contactName')} />
              <Label type="text">
                <FormattedMessage id="common.phone-number" defaultMessage="Phone number" />
              </Label>
              <Input readOnly={isReadOnly} type="text" value={form.phone || ''} onChange={e => updateFormEvent(e, 'phone')} />
              <Label type="text">
                <FormattedMessage id="common.email" defaultMessage="Email" />
              </Label>
              <Input readOnly={isReadOnly} type="text" value={form.email || ''} onChange={e => updateFormEvent(e, 'email')} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ maxWidth: 'unset', padding: '15px 46px' }}>
          <AuthButton canUpdate={form} color="primary" onClick={this.finish}>
            <FormattedMessage id="common.save-changes" defaultMessage="Save changes" />
          </AuthButton>
          <div style={{ flex: '1' }}></div>
          <Button color="primary" outline onClick={this.cancel}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </Button>
        </ModalFooter>
        {
          !isReadOnly && <SubmitHandler keyCode={keyCodes.enter} action={this.finish} />
        }
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormEvent: ModalActions.updateFormEvent,
  updateFormObject: ModalActions.updateFormObject,
  finishDialogEditCompany,
  getCurrentCompany,
}, dispatch);

EditCompanyModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  finishDialogEditCompany: PropTypes.func.isRequired,
  getCurrentCompany: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditCompanyModal);
