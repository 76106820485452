import { SamplesApi } from '@veit/bat-cloud-api/lib/api/SamplesApi';
import * as ModalActions from './Modal.actions';

import { create, run } from '../utils/api';

export const GET_SAMPLES_BY_LOCATION = 'GET_SAMPLES_BY_LOCATION';
export const GET_SAMPLES_BY_DEVICE = 'GET_SAMPLES_BY_DEVICE';

const samplesApi = new SamplesApi();

export const getAllSamplesByLocation = (locationId, date) => create(
  GET_SAMPLES_BY_LOCATION,
  () => samplesApi.samplesAllByLocationLocationIdDateGet(locationId, date),
  result => result.data,
);

export const getAllSamplesByFlock = (flockId, date) => create(
  GET_SAMPLES_BY_LOCATION,
  () => run(`/samples/allByFlock/${flockId}/${date}`),
  result => result.data.data,
);

export const getSamplesByLocation = (locationId, from, to) => create(
  GET_SAMPLES_BY_LOCATION,
  () => run(`/samples/byLocation/${locationId}`, { from, to }),
  result => result.data.data,
);

export default ModalActions;
