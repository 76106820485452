import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Table, Label } from '@veit/veit-web-controls';

import fetchData from './fetchData';
import { openDialogInviteUser, openDialogEditUser } from '../../store/ModalUser.actions';
import { goTo } from '../../store/Router.actions';

import Page from '../../components/Page';
import RoleName from '../../components/RoleName';
import AuthButton, { AuthAddButton } from '../../components/AuthButton';
import QuerySort from '../../components/QuerySort';
import bem from '../../utils/bem';
import pagination from '../../utils/pagination';
import { roleType } from '../../model/enums';
import goToConnect from '../../utils/goToConnect';
import { userOrder } from '../../model/order';
import intl from '../../setup/RIntl';

const messages = defineMessages({
  users: { id: 'users.manage-users', defaultMessage: 'Manage Users' },
});

class UserList extends Component {
  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getData(false);
    }
  }

  inviteUser = () => {
    this.props.openDialogInviteUser({
      companyId: this.props.current.companyId,
      role: roleType.user,
    }).then(this.resolveDialog);
  }

  editUser = (user) => {
    this.props.openDialogEditUser(user).then(this.resolveDialog);
  }

  getData = (fetchMetadata) => {
    this.props.fetchData(fetchMetadata, pagination(this.props.location.search));
  }

  canEdit = (user) => {
    if (this.props.current.role === roleType.undefined && user.role !== roleType.undefined) {
      return false;
    }
    return this.props.current.id !== user.id;
  }

  resolveDialog = (result) => {
    if (result == null) return;
    const last = this.props.user.items.length === 1;
    if (result.delete && last) {
      this.props.goToPage(0);
    } else {
      this.getData(false);
    }
  }

  render() {
    const bm = bem.view('user-list');
    const { user, current } = this.props;
    const users = user.items.filter(f => f.companyId === current.companyId && f.id !== current.id);
    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.users)}
        isFetching={this.props.isFetching}
        actions={(
          <AuthAddButton canCreate={user} onClick={this.inviteUser}>
            <FormattedMessage id="users.invite-user" defaultMessage="Invite User" />
          </AuthAddButton>
        )}
        paginate={{
          onChange: this.props.goToPage,
          ...{ ...user, items: users, size: users.length },
        }}
        filter={{ search: true }}
      >
        <Table type="data">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.name" defaultMessage="Name" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={userOrder.email}>
                  <FormattedMessage id="common.email" defaultMessage="Email" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={userOrder.role}>
                  <FormattedMessage id="users.modal.default-role" defaultMessage="Default Role" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={userOrder.roleExceptions}>
                  <FormattedMessage id="users.advance-settings" defaultMessage="Advanced Settings" />
                </QuerySort>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map(i => (
              <tr key={i.id}>
                <td><Label>{i.name}</Label></td>
                <td><Label>{i.email}</Label></td>
                <td><Label><RoleName role={i.role} /></Label></td>
                <td>
                  <Label>
                    {
                      i.roleExceptions != null && Object.keys(i.roleExceptions).length !== 0
                        ? <FormattedMessage values={{ count: Object.keys(i.roleExceptions).length }} id="users.n-exceptions" defaultMessage="{count, plural, one {# exception} other {# exceptions}}" />
                        : <FormattedMessage id="common.no" defaultMessage="No" />
                    }
                  </Label>
                </td>
                <td className="text-center">
                  {
                    this.canEdit(i) && (
                      <AuthButton canUpdate={i} className="btn-narrow" onClick={() => this.props.goTo(`user/${i.id}`)} color="primary">
                        <FormattedMessage id="users.edit-user" defaultMessage="Edit User" />
                      </AuthButton>
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

UserList.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogInviteUser: PropTypes.func.isRequired,
  openDialogEditUser: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  user: PropTypes.shape({
    items: PropTypes.array,
    links: PropTypes.object,
  }).isRequired,
  current: PropTypes.shape({
    id: PropTypes.string,
    companyId: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    current: state.auth.user,
    isFetching: state.fetch.isFetching,
    locale: state.auth.locale,
  };
};

const mapDispatchToProps = {
  fetchData,
  openDialogInviteUser,
  openDialogEditUser,
  goTo,
};

export default goToConnect('/users')(UserList, mapStateToProps, mapDispatchToProps);
