import settings from '../settings';

const { minVersion, address, protocol } = settings.batAgent;

let ws = null;
let connected = false;
let attempt = null;
let timeout = null;
let messageHandler = null;

let resolve = null;
let reject = null;
let createWebSocket = () => { };


const lunchAgent = () => {
  window.open(protocol, '_self');
};

const onClose = () => {
  connected = false;
  ws = null;
};

const onOpen = () => {
  connected = true;
  resolve();
};

const onError = () => {
  connected = false;
  if (attempt == null) {
    return;
  }
  attempt += 1;
  switch (attempt) {
    case 1:
      lunchAgent();
      timeout = setTimeout(createWebSocket, 1000);
      break;
    case 2:
    case 3:
      timeout = setTimeout(createWebSocket, attempt * 500);
      break;
    case null:
    case 4:
    default:
      reject();
      break;
  }
};

const onMessage = (m) => {
  if (messageHandler != null) {
    messageHandler(JSON.parse(m.data));
  }
};


createWebSocket = () => {
  ws = new WebSocket(address);
  ws.onopen = onOpen;
  ws.onerror = onError;
  ws.onmessage = onMessage;
  ws.onclose = onClose;
};

export const actionType = {
  getMemModuleConfig: 'GetMemModuleConfig',
  getMemModuleStats: 'GetMemModuleStats',
  getMemModuleFull: 'GetMemModuleFull',
  notConnected: 'NotConnected',
  invalidVersion: 'InvalidVersion',
  readError: 'ReadError',
  writeError: 'WriteError',
  getBat1Info: 'GetBat1Info',
  getBat1Stats: 'GetBat1Stats',
};

export const connect = () => {
  attempt = 0;
  return new Promise((res, rej) => {
    resolve = res;
    reject = rej;
    createWebSocket();
  });
};

export const disconnect = () => {
  clearTimeout(timeout);
  timeout = null;
  if (ws != null) {
    ws.close();
    ws = null;
  }
  connected = false;
};

export const isOpen = () => {
  return connected;
};

export const setMessageHandler = (handler) => {
  messageHandler = handler;
};

export const send = (type, data) => {
  if (ws != null && connected) {
    ws.send(JSON.stringify({ type, data, minVersion }));
  }
};

export const getMemConfig = () => {
  send(actionType.getMemModuleConfig);
};

export const getMemStats = () => {
  send(actionType.getMemModuleStats);
};

export const getMemFull = () => {
  send(actionType.getMemModuleFull);
};

export const getBat1Info = () => {
  send(actionType.getBat1Info);
};

export const getBat1Stats = (data) => {
  send(actionType.getBat1Stats, [data]);
};
