import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { IMCheckMark, IMErrorMark } from '@veit/veit-web-controls/dist/icons';

import Tooltip, { TooltipItem } from '../../components/Tooltip';
import intl from '../../setup/RIntl';
import au from '../../utils/array';
import { statsType } from '../../model/enums';
import { statisticsType } from '../../types';

export const statusClass = {
  ok: 'bwc-status--ok',
  warning: 'bwc-status--warning',
  error: 'bwc-status--error',
  old: 'bwc-status--old',
  older: 'bwc-status--older',
  oldest: 'bwc-status--oldest',
};


export const checkDate = (ls) => {
  if (!au.isArrayWithValue(ls)) return null;
  const diffDays = Math.floor(dayjs().diff(dayjs(ls[0].dateTime), 'days'));

  return diffDays > 3
    ? {
      key: 'date',
      className: diffDays > 7
        ? diffDays > 14 ? statusClass.oldest : statusClass.older
        : statusClass.old,
      text: intl.t({
        id: 'common.last-update-days',
        defaultMessage: 'Updated {count, plural, one {# day} other {# days}} ago',
      }, { count: diffDays }),
    }
    : null;
};

export const checkGain = (ls) => {
  if (!au.isArrayWithValue(ls)) return null;

  const automatic = ls.filter(s => s.type === statsType.automatic);
  if (!au.isArrayWithValue(automatic)) return null;

  return automatic.find(f => f.gain < 0) != null
    ? {
      key: 'gain',
      className: statusClass.error,
      text: intl.t({ id: 'common.gain-error', defaultMessage: 'Average weight gain is negative' }),
    }
    : null;
};

export const checkDay = (ls) => {
  if (!au.isArrayWithValue(ls)) return null;

  const automatic = ls.filter(s => s.type === statsType.automatic);
  if (!au.isArrayWithValue(automatic)) return null;

  return automatic.find(f => f.flockDay != null && f.flockDay !== f.day) != null
    ? {
      key: 'day',
      className: statusClass.warning,
      text: intl.t({ id: 'common.flock-age-error', defaultMessage: 'Flock age does not match scale data' }),
    }
    : null;
};

const LastStatus = ({ lastStatistics, checks = [] }) => {
  const errors = checks.map(m => m(lastStatistics)).filter(f => f != null);
  const hasError = errors.length !== 0;

  return lastStatistics == null || lastStatistics.length === 0 ? null : (
    <Tooltip
      disabled={!hasError}
      text={(
        <React.Fragment>
          {
            errors.map(m => (<TooltipItem key={m.key}>{m.text}</TooltipItem>))
          }
        </React.Fragment>
      )}
    >
      {hasError
        ? (<IMErrorMark className={`bwc-status ${errors[0].className}`} />)
        : (<IMCheckMark className={`bwc-status ${statusClass.ok}`} />)}
    </Tooltip>
  );
};

export default LastStatus;


LastStatus.propTypes = {
  lastStatistics: PropTypes.arrayOf(statisticsType),
  checks: PropTypes.arrayOf(PropTypes.func),
};

LastStatus.defaultProps = {
  lastStatistics: [],
  checks: [
    checkDate,
    checkGain,
    // checkDay,
  ],
};
