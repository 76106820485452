import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AddButton, Table, Button } from '@veit/veit-web-controls';

import ActionBar from '../ActionBar';
import DateFormat from '../../../components/DateFormat';
import bem from '../../../utils/bem';

import { getLoraDevices } from '../../../store/Lora.actions';
import { getCompany } from '../../../store/Company.actions';
import {
  openDialogAddLoraDevice, openDialogEditLoraDevice, openDialogAddLoraScale,
} from '../../../store/ModalAdmin.actions';

const bm = bem.view('gateway-list');

class LoraDeviceList extends Component {
  componentDidMount() {
    this.props.getLoraDevices();
    this.props.getCompany();
  }

  addLoraDevice = () => {
    this.props.openDialogAddLoraDevice({}).then(() => {
      this.props.getLoraDevices();
    });
  }

  addLoraScale = (device) => {
    this.props.openDialogAddLoraScale(device).then(() => {
      this.props.getLoraDevices();
    });
  }

  editLoraDevice = (device) => {
    this.props.openDialogEditLoraDevice(device).then(() => {
      this.props.getLoraDevices();
    });
  }

  render() {
    const { devices, companies } = this.props;
    return (
      <div className={bm.b()}>
        <ActionBar>
          <span></span>
          <AddButton onClick={this.addLoraDevice}>Register LoRa Keys</AddButton>
        </ActionBar>
        <Table>
          <thead>
            <tr>
              <th>Device Eui</th>
              <th>Company</th>
              <th>Last Uplink</th>
              <th>AWS</th>
              <th>BAT Cloud</th>
              <th style={{ width: '1%' }}></th>
            </tr>
          </thead>
          <tbody>
            {devices.map(c => (
              <tr key={c.devEUI || (c.aws || {}).eui}>
                <td>{c.devEUI || (c.aws || {}).eui}</td>
                <td>
                  {c.companyId == null && c.aws != null ? (
                    <Button className="btn-narrow" color="primary" onClick={() => this.addLoraScale(c)}>
                      Create LoRa Scale
                    </Button>
                  ) : (companies.find(f => f.id === c.companyId) || {}).name}
                </td>
                <td><DateFormat date={(c.aws || {}).lastUplink} format="DD/MM/YYYY HH:mm" /></td>
                <td>{c.aws ? 'YES' : 'NO'}</td>
                <td>{c.devEUI ? 'YES' : 'NO'}</td>
                <td style={{ width: '1%' }}>
                  <Button className="btn-narrow" color="primary" onClick={() => this.editLoraDevice(c)}>
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

LoraDeviceList.propTypes = {
  getLoraDevices: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  openDialogAddLoraDevice: PropTypes.func.isRequired,
  openDialogEditLoraDevice: PropTypes.func.isRequired,
  openDialogAddLoraScale: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({
  })),
  companies: PropTypes.arrayOf(PropTypes.shape({
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      view: PropTypes.string,
    }),
  }).isRequired,
};

LoraDeviceList.defaultProps = {
  devices: [],
  companies: [],
};

const mapStateToProps = state => ({
  devices: state.lora.devices,
  companies: state.company.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getLoraDevices,
  getCompany,
  openDialogAddLoraDevice,
  openDialogEditLoraDevice,
  openDialogAddLoraScale,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoraDeviceList);
