import { getAdjacent } from '../utils/stats';
import * as ModalActions from './Modal.actions';

export const SHOW_HISTOGRAM = 'SHOW_HISTOGRAM';
export const GOTO_HISTOGRAM = 'GOTO_HISTOGRAM';

export const openDialogHistogram = (stat, stats) => (dispatch) => {
  return ModalActions.openDialog(SHOW_HISTOGRAM, getAdjacent(stats, stat), null, true)(dispatch);
};

export const goToHistogram = (stat, stats) => (dispatch) => {
  return ModalActions.updateFormObject(getAdjacent(stats, stat))(dispatch);
};

export default ModalActions;
