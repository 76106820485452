import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import bem from '../utils/bem';

const bm = bem.component('toggle');

const Toggle = ({ isOn, onChange, disabled }) => {
  const id = uuidv4();
  return (
    <span className={bm.b(isOn ? bm.m('on') : null)}>
      <label
        className={bm.e('label')}
        htmlFor={id}
      >
        <input
          checked={isOn}
          onChange={onChange}
          className={bm.e('checkbox')}
          id={id}
          disabled={disabled}
          type="checkbox"
        />
        <span className={bm.e('button')} />
      </label>
    </span>
  );
};

Toggle.propTypes = {
  disabled: PropTypes.bool,
  isOn: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggle.defaultProps = {
  disabled: false,
  isOn: false,
  onChange: () => { },
};

export default Toggle;
