import { create, run } from '../utils/api';

export const GET_GATEWAY = 'GET_GATEWAY';
export const CREATE_GATEWAY = 'CREATE_GATEWAY';
export const UPDATE_GATEWAY = 'UPDATE_GATEWAY';
export const DELETE_GATEWAY = 'DELETE_GATEWAY';
export const GET_NUMBERS = 'GET_NUMBERS';

export const getGateways = () => create(GET_GATEWAY, () => run('/gateway'));
export const createGateway = gateway => create(CREATE_GATEWAY, () => run('/gateway', 'POST', { ...gateway, id: '00000000-0000-0000-0000-000000000000' }));
export const updateGateway = gateway => create(UPDATE_GATEWAY, () => run(`/gateway/${gateway.id}`, 'PUT', gateway));
export const deleteGateway = id => create(DELETE_GATEWAY, () => run(`/gateway/${id}`, 'DELETE'));
export const getNumbers = () => create(GET_NUMBERS, () => run('/gateway/numbers'));
