import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  ModalBody,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
} from '@veit/veit-web-controls';

import ModalActions, { openDialogAddLocation } from '../../store/ModalLocation.actions';
import { modalGetLocation, expandLocation } from '../../store/Location.actions';
import { getFileLocations } from '../../store/ModalImport.actions';
import DateFormat from '../../components/DateFormat';
import SelectLocation from '../../components/SelectLocation';
import FormLabel from '../../components/FormLabel';
import arrayUtils from '../../utils/array';
import { sortByLast } from './StepSelectScale';

class StepSelectLocation extends Component {
  componentDidMount() {
    this.fetchData();
    this.props.getFileLocations().then((res) => {
      const fls = ((res || {}).data || {}).data || [];
      const { files } = this.props.modal;
      const mod = files.map((m) => {
        const fl = fls.find(f => f.name === m.name);
        return fl == null ? m : { ...m, locationId: fl.locationId };
      });
      this.props.updateFormObject({ files: mod });
    });
  }

  fetchData = () => {
    this.props.modalGetLocation({ expand: expandLocation.farm });
  }

  onLocationChange = (f, l) => {
    const { modal } = this.props;
    this.props.updateFormObject({
      files: arrayUtils.replace(modal.files, { ...f, locationId: (l || {}).id }, k => k.name),
    });
  }

  addLocationAction = (f) => {
    this.props.openDialogAddLocation().then((l) => {
      if (l != null) {
        this.fetchData();
        this.onLocationChange(f, l);
      }
    });
  }

  render() {
    const {
      scale, locations, modal, dateFormat,
    } = this.props;
    return (
      <ModalBody style={{ padding: '32px 0' }}>
        <Label type="text">
          <FormattedMessage id="common.selected-scale" defaultMessage="Selected scale" />
        </Label>
        <UncontrolledDropdown>
          <DropdownToggle color="primary" outline caret disabled>
            {scale.name || ''}
          </DropdownToggle>
        </UncontrolledDropdown>
        <FormLabel required={modal.files.find(f => f.locationId == null) != null}>
          <FormattedMessage id="modals.import.select-location-for" defaultMessage="Select location for each file" />
        </FormLabel>
        <Table type="data">
          <thead>
            <tr>
              <th><Label><FormattedMessage id="common.file" defaultMessage="File" /></Label></th>
              <th><Label><FormattedMessage id="common.last" defaultMessage="Last update" /></Label></th>
              <th><Label><FormattedMessage id="common.location" defaultMessage="Location" /></Label></th>
            </tr>
          </thead>
          <tbody>
            {modal.files.sort(sortByLast).map(f => (
              <tr key={f.name}>
                <td><Label>{f.name}</Label></td>
                <td>
                  <Label>
                    <DateFormat date={(f.lastSample || {}).dateTime} format={dateFormat} />
                  </Label>
                </td>
                <td>
                  <SelectLocation
                    locations={locations}
                    location={locations.find(l => l.id === f.locationId)}
                    onChange={o => this.onLocationChange(f, o)}
                    addLocationAction={() => this.addLocationAction(f)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    );
  }
}

StepSelectLocation.propTypes = {
  updateFormObject: PropTypes.func.isRequired,
  openDialogAddLocation: PropTypes.func.isRequired,
  modalGetLocation: PropTypes.func.isRequired,
  getFileLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  modal: PropTypes.shape({
    files: PropTypes.array,
    map: PropTypes.object,
  }),
  scale: PropTypes.shape({
    name: PropTypes.string,
  }),
  dateFormat: PropTypes.string,
};

StepSelectLocation.defaultProps = {
  locations: [],
  scale: {},
  modal: { files: [] },
  dateFormat: null,
};

const mapStateToProps = state => ({
  locations: state.location.modal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openDialogAddLocation,
  modalGetLocation,
  getFileLocations,
  updateFormObject: ModalActions.updateFormObject,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepSelectLocation);
