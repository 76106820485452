import React from 'react';
import PropTypes from 'prop-types';
import SexIcon from '../../components/SexIcon';
import { sexType } from '../../model/enums';

const SexIconAlt = ({ sex, count }) => {
  if (count === 1) return null;
  return sex === sexType.male || sex === sexType.female
    ? <SexIcon sex={sex} />
    : <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 1024 1024" />;
};

SexIconAlt.propTypes = {
  sex: PropTypes.string,
  count: PropTypes.number.isRequired,
};

SexIconAlt.defaultProps = {
  sex: null,
};

export default SexIconAlt;
