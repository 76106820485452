import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import intl from '../../setup/RIntl';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  weightRange: {
    id: 'modals.histogram.weight-range',
    defaultMessage: 'Weight range',
  },
  weightRangeValue: {
    id: 'modals.histogram.weight-range-value',
    defaultMessage: 'Weight range : {min} - {max} g',
  },
  samplesCount: {
    id: 'modals.histogram.samples-count',
    defaultMessage: 'Samples count : {value}',
  },
  recommendedWeight: {
    id: 'modals.histogram.recommended-weight',
    defaultMessage: 'Recommended weight',
  },
});

const options = {
  animation: false,
  cornerRadius: 10,
  maintainAspectRatio: false,
  responsive: true,
  legend: false,
  backgroundColor: '#212529',
  layout: {
    padding: {
      left: 33,
      right: 0,
      top: 10,
      bottom: 0,
    },
  },
  tooltips: {
    displayColors: false,
    callbacks: {
      title: (tooltipItem) => {
        if (tooltipItem.length > 0) {
          return `${tooltipItem[0].label}`;
        }
        return null;
      },
      label: (tooltipItem) => {
        return intl.t(messages.samplesCount, { value: tooltipItem.value });
      },
    },
  },
  scales: {
    yAxes: [{
      gridLines: {
        drawOnChartArea: false,
        borderDash: [3, 6],
      },
      display: true,
      ticks: {
        beginAtZero: true,
        fontSize: 14,
        fontColor: '#97a4ba',
        callback: (value, index) => {
          if (index === 0) return '';
          return value;
        },
      },
    }],
    xAxes: [{
      gridLines: {
        offsetGridLines: false,
        drawOnChartArea: true,
        borderDash: [3, 6],
      },
      // offset: false,
      ticks: {
        fontSize: 14,
        fontColor: '#97a4ba',
        autoSkip: false,
        maxRotation: 0,
      },
    }],
  },
};

class SortingChart extends Component {
  state = {
    chartData: {
      labels: [],
      datasets: [],
    },
    ctx: null,
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.drawChart();
    }
  }

  drawChart = () => {
    let keys = [];
    let values = [];

    if (this.props.data != null) {
      ({ keys, values } = this.props.data);
    }

    const chartData = {
      labels: keys,
      datasets: [{
        data: values,
        backgroundColor: values.map(() => '#469acf'),
        categoryPercentage: 1,
        barPercentage: 0.8,
      }],
    };

    const ctx = document.getElementById('histogram').getContext('2d');


    this.setState(prevState => ({
      chartData,
      ctx: prevState.ctx || ctx,
    }));
  }

  render() {
    return (
      <Bar
        id="histogram"
        options={options}
        data={this.state.chartData}
      />
    );
  }
}

SortingChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    keys: PropTypes.array,
    values: PropTypes.array,
  }),
};

SortingChart.defaultProps = {
  data: null,
};

export default SortingChart;
