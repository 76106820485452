import rest from './rest.actions';
import { FarmApi } from '@veit/bat-cloud-api/lib/api/FarmApi';

export const FARM = 'FARM';
export const GET_FARM = 'GET_FARM';
export const GET_FARM_MODAL = 'GET_FARM_MODAL';
export const GETBY_FARM = 'GETBY_FARM';
export const POST_FARM = 'POST_FARM';
export const PUT_FARM = 'PUT_FARM';
export const DELETE_FARM = 'DELETE_FARM';

const exp = {
  address: 'address',
  country: 'country',
  contactName: 'contactName',
  phone: 'phone',
  email: 'email',
  activeFlocksCount: 'activeFlocksCount',
  scheduledFlocksCount: 'scheduledFlocksCount',
  locationsCount: 'locationsCount',
  devicesCount: 'devicesCount',
};

export const expandFarm = Object.freeze({
  ...exp,
  list: () => [
    exp.address,
    exp.country,
    exp.activeFlocksCount,
    exp.scheduledFlocksCount,
    exp.locationsCount,
    exp.devicesCount,
  ],
  detail: () => [
    exp.address,
    exp.country,
    exp.phone,
    exp.email,
    exp.contactName,
  ],
  all: () => Object.values(exp),
});

const restFarm = rest(new FarmApi());

export const getFarm = restFarm.createGet(GET_FARM, 'farmGet');
export const getFarmBy = restFarm.createGetBy(GETBY_FARM, 'farmFarmIdGet');
export const postFarm = restFarm.createPost(POST_FARM, 'farmPost');
export const putFarm = restFarm.createPut(PUT_FARM, 'farmFarmIdPut');
export const deleteFarm = restFarm.createDelete(DELETE_FARM, 'farmFarmIdDelete');

export const modalGetFarm = restFarm.createGet(GET_FARM_MODAL, 'farmGet');
