import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Label } from '@veit/veit-web-controls';
import { IMBird, IMHome, IMClock } from '@veit/veit-web-controls/dist/icons';

import DateFormat from '../../components/DateFormat';
import { flockType } from '../../types';
import Card from './Card';
import classNames from 'classnames';
import dayjs from 'dayjs';

const FlockRow = ({ icon: Icon, children }) => {
  return (
    <Label className="bwc-flock-row">
      {Icon && children && <Icon style={{ verticalAlign: 'none' }} />}
      {children}
    </Label>
  );
};

FlockRow.propTypes = {
  icon: PropTypes.elementType,
  children: PropTypes.node,
};

FlockRow.defaultProps = {
  icon: null,
  children: null,
};

const FlockCard = ({
  flock, expanded, onHide, onToggle, selected, dateFormat, ...otherProps
}) => {
  const lastDate = flock.lastStatistics ? flock.lastStatistics[0].dateTime : null;
  const isFinished = flock.endDate ? dayjs(flock.endDate).toDate() < new Date() : false;
  return (
    <Card
      className={classNames('bwc-flock-card')}
      style={selected ? {
        borderLeft: `${selected.hidden ? '2' : '8'}px solid ${selected.color}`,
        cursor: 'initial',
        paddingLeft: selected.hidden ? '11px' : null,
      } : null}
      onClick={selected ? null : onToggle}
      {...otherProps}
    >
      <Label style={{ fontWeight: '800', padding: '5px' }}>{flock.name}</Label>
      {expanded && (
        <React.Fragment>
          <FlockRow icon={IMBird}>
            {flock.bird && flock.bird.name}
          </FlockRow>
          <FlockRow icon={IMHome}>
            {flock.location && flock.location.name}
          </FlockRow>
        </React.Fragment>
      )}
      <div style={{ display: 'flex' }}>
        {lastDate ? (
          <FlockRow icon={IMClock}>
            <DateFormat date={lastDate} format={dateFormat} />
          </FlockRow>
        ) : (
          <FlockRow>
            <FormattedMessage id="common.no-data" defaultMessage="No Data" />
          </FlockRow>
        )}
        <div style={{ flex: 1 }}></div>
        <FlockRow>
          <Label style={{ textTransform: 'uppercase' }}>
            {isFinished ? (
              <FormattedMessage id="common.finished" defaultMessage="Finished" />
            ) : (
              <FormattedMessage id="common.active" defaultMessage="Active" />
            )}
          </Label>
        </FlockRow>
      </div>
      {selected && (
        <div style={{ display: 'flex', padding: '5px' }}>
          <Label className="action-label" style={{ color: '#469acf' }} onClick={() => onHide(selected.flockId, !selected.hidden)}>
            {selected.hidden ? <FormattedMessage id="common.show" defaultMessage="Show" /> : <FormattedMessage id="common.hide" defaultMessage="Hide" />}
          </Label>
          <div style={{ flex: 1 }}></div>
          <Label className="action-label" style={{ color: '#469acf' }} onClick={onToggle}>
            <FormattedMessage id="common.remove" defaultMessage="Remove" />
          </Label>
        </div>
      )}
    </Card>
  );
};

FlockCard.propTypes = {
  flock: flockType.isRequired,
  onToggle: PropTypes.func.isRequired,
  onHide: PropTypes.func,
  selected: PropTypes.shape({
    flockId: PropTypes.string,
    color: PropTypes.string,
    hidden: PropTypes.bool,
  }),
  expanded: PropTypes.bool,
  dateFormat: PropTypes.string,
};

FlockCard.defaultProps = {
  selected: null,
  expanded: false,
  onHide: null,
  dateFormat: null,
};

export default FlockCard;
