import { push } from 'connected-react-router';
import * as ModalActions from './Modal.actions';
import { postHelpPage, putHelpPage, deleteHelpPage } from './Help.actions';

export const ADD_HELP_PAGE = 'ADD_HELP_PAGE';
export const EDIT_HELP_PAGE = 'EDIT_HELP_PAGE';

export const openDialogAddHelpPage = data => (dispatch) => {
  return ModalActions.openDialog(ADD_HELP_PAGE, data)(dispatch);
};

export const openDialogEditHelpPage = data => (dispatch) => {
  return ModalActions.openDialog(EDIT_HELP_PAGE, data)(dispatch);
};

export const finishDialogAddHelpPage = data => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => postHelpPage(data), ADD_HELP_PAGE)(dispatch)
    .then(() => {
      dispatch(push(`/help/${data.slug}`));
    });
};

export const finishDialogEditHelpPage = data => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => putHelpPage(data), EDIT_HELP_PAGE)(dispatch)
    .then(() => {
      dispatch(push(`/help/${data.slug}`));
    });
};

export const deleteDialogHelpPage = id => (dispatch) => {
  ModalActions
    .finishDialogRequest(() => deleteHelpPage(id), EDIT_HELP_PAGE)(dispatch)
    .then(() => dispatch(push('/help')));
};

export default ModalActions;
