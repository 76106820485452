import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import array from '../../utils/array';
import intl from '../../setup/RIntl';

const messages = defineMessages({
  average: { id: 'common.weight', defaultMessage: 'Weight' },
  day: { id: 'common.day', defaultMessage: 'Day' },
  week: { id: 'common.week', defaultMessage: 'Week' },
});

function fillMissingDays(points, min, max) {
  if (
    points == null
    || min == null
    || max == null
    || min === max
    || min > max
  ) return [];

  const dict = {};
  points.forEach((point) => { dict[point.day] = point; });
  return array
    .range(max - min + 1, min)
    .map((day) => { return dict[day] != null ? dict[day] : { day }; });
}

const Graphs = ({ points, useWeeks, weightUnit }) => {
  if (points == null || points.length === 0) return null;
  const orderStats = points.sort((a, b) => a.day - b.day);
  const last = orderStats[points.length - 1];
  const first = orderStats[0];
  const st = fillMissingDays(orderStats, first.day, last.day);

  const chart = {
    labels: st.map(i => i.day),
    datasets: [],
  };

  chart.datasets.unshift({
    label: `${intl.t(messages.average)} (${weightUnit})`,
    fill: false,
    borderColor: '#CFE8F2',
    data: st.map(i => (i.weight)),
    spanGaps: true,
    cubicInterpolationMode: 'monotone',
    yAxisID: 'left',
  });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: false,
    animation: false,
    tooltips: {
      enabled: true,
      intersect: false,
      mode: 'x',
      callbacks: {
        title: (item) => {
          if (item[0] == null) return null;
          const value = item[0].label;
          const day = `${intl.t(messages.day)} ${value}`;
          const week = `${intl.t(messages.week)} ${Math.floor(value / 7)}`;
          const weekDay = value % 7 === 0 ? '' : ` (${day})`;
          return useWeeks ? `${week}${weekDay}` : day;
        },
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: true,
          borderDash: [4, 8],
        },
        offset: true,
        ticks: {
          callback: (value) => {
            if (value == null) return null;
            return useWeeks && value % 7 === 0 ? `${value / 7}` : useWeeks ? null : `${value}`;
          },
        },
      }],
      yAxes: [{
        id: 'left',
        position: 'left',
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: weightUnit === 'lb' ? 0.220 : 100,
        },
      }],
    },
  };

  return (<Line data={chart} options={options} />);
};

Graphs.propTypes = {
  useWeeks: PropTypes.bool.isRequired,
  points: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.number,
    weight: PropTypes.number,
  })),
  weightUnit: PropTypes.string,
};

Graphs.defaultProps = {
  points: [],
  weightUnit: null,
};

export default Graphs;
