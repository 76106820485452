
import { defineMessages } from 'react-intl';

import { sortingType } from '../model/enums';
import intl from '../setup/RIntl';

const messages = defineMessages({
  [sortingType.categories]: { id: 'common.categories', defaultMessage: 'Categories' },
  [sortingType.fleshingScore]: { id: 'common.fleshing-score', defaultMessage: 'Fleshing Score' },
  [sortingType.lightHeavy]: { id: 'common.light-heavy', defaultMessage: 'Light \\ Heavy' },
  [sortingType.lightOkHeavy]: { id: 'common.light-ok-heavy', defaultMessage: 'Light \\ Ok \\ Heavy' },
  [sortingType.none]: { id: 'common.none', defaultMessage: 'None' },
  [sortingType.sex]: { id: 'common.sex', defaultMessage: 'Sex' },
});

export const getSortingName = (sorting) => {
  const value = messages[sorting];
  return value == null ? sorting : intl.t(value);
};

const SortingName = ({ sorting }) => getSortingName(sorting);

export default SortingName;
