import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalActions, { finishDialogEditDevice, deleteDialogDevice } from '../../store/ModalDevice.actions';
import { getFarm, expandFarm } from '../../store/Farm.actions';
import { modalGetLocation, expandLocation } from '../../store/Location.actions';
import { getDeviceByParent } from '../../store/Device.actions';
import { deviceType } from '../../model/enums';

import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';

class EditDeviceModal extends Component {
  componentDidMount() {
    this.fetchLocations();
    this.props.getFarm({ expand: [expandFarm.address, expandFarm.country] });
    if (this.props.modal.data.parentId != null) {
      this.props.getDeviceByParent(this.props.modal.data.parentId);
    }
  }

  fetchLocations = () => {
    this.props.modalGetLocation({ expand: expandLocation.farm });
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    this.props.finishDialogEditDevice(this.props.modal.data);
  }

  delete = () => {
    this.props.deleteDialogDevice(
      this.props.modal.data.id,
      this.props.modal.data.$redirect,
    );
  }

  render() {
    const typeName = this.props.modal.data.type !== deviceType.terminal
      ? <FormattedMessage id="common.scale" defaultMessage="Scale" />
      : <FormattedMessage id="common.connector" defaultMessage="Connector" />;
    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-medium"
      >
        <ModalHeader toggle={this.cancel}>
          {
            this.props.modal.data.type !== deviceType.terminal
              ? <FormattedMessage id="devices.edit-scale" defaultMessage="Edit Scale" />
              : <FormattedMessage id="devices.edit-connector" defaultMessage="Edit Connector" />
          }
        </ModalHeader>
        <ModalBody>
          <Step1 modal={this.props.modal} fetchLocations={this.fetchLocations} />
        </ModalBody>
        <Footer
          deleteDialog={{ action: this.delete, name: typeName, item: this.props.modal.data }}
          fluid
          finisDialog={this.finish}
          modal={this.props.modal}
          validators={{ 1: hasStep1Error }}
        />
      </ModalWithLoader>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  finishDialogEditDevice,
  deleteDialogDevice,
  getDeviceByParent,
  getFarm,
  modalGetLocation,
}, dispatch);

EditDeviceModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  finishDialogEditDevice: PropTypes.func.isRequired,
  deleteDialogDevice: PropTypes.func.isRequired,
  getDeviceByParent: PropTypes.func.isRequired,
  getFarm: PropTypes.func.isRequired,
  modalGetLocation: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditDeviceModal);
