import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@veit/veit-web-controls';
import { sexType } from '../../model/enums';
import SexName from '../SexName';

const SexFilter = ({ sex, onChange }) => {
  return (
    <UncontrolledDropdown className="bwc-sex-filter">
      <DropdownToggle className="dropdown-toggle--clear" color="primary" outline caret>
        {
          sex != null ? <SexName sex={sex} /> : <FormattedMessage id="common.sex" defaultMessage="Sex" />
        }
      </DropdownToggle>
      <DropdownMenu>
        {sex && (
          <DropdownItem className="bwc-sex-filter__clear" onClick={() => onChange(null)}>
            <FormattedMessage id="common.clear" defaultMessage="Clear" />
          </DropdownItem>
        )}
        {[sexType.male, sexType.female, sexType.irrelevant]
          .filter(f => f !== sex)
          .map(m => (
            <DropdownItem key={m} onClick={() => onChange(m)}>
              <SexName sex={m} />
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

SexFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  sex: PropTypes.string,
};

SexFilter.defaultProps = {
  sex: null,
};

export default SexFilter;
