import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AnimateHeight extends Component {
  elRef = React.createRef();
  state = {
    height: null,
  }

  componentDidMount() {
    this.setHeight();
  }

  componentDidUpdate(prevProps) {
    if (this.props.step !== prevProps.step) {
      this.setHeight();
    }
  }

  setHeight = () => {
    const element = this.elRef.current;
    const { clientHeight } = element;

    element.style.overflow = 'hidden';
    if (this.state.height != null) element.style.height = `${this.state.height}px`;

    setTimeout(() => {
      element.style.height = `${clientHeight}px`;
      this.setState({ height: clientHeight });
    }, 0);
    setTimeout(() => {
      element.style.height = 'auto';
      element.style.overflow = 'unset';
    }, 500);
  }

  render() {
    return (
      <div style={{ width: '100%', transition: 'height .5s' }} ref={this.elRef}>
        {this.props.children}
      </div>
    );
  }
}

AnimateHeight.propTypes = {
  children: PropTypes.node,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]).isRequired,
};

AnimateHeight.defaultProps = {
  children: null,
};

export default AnimateHeight;
