import {
  FETCH_ALL, FETCH_ALL_SILENT, FETCH_ALL_ERROR, FETCH_ALL_SUCCESS,
  FETCH_PARTIAL, FETCH_PARTIAL_SUCCESS, FETCH_PARTIAL_ERROR,
} from './Fetch.actions';

const initialState = {
  isFetching: false,
  isFetchingPartial: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL:
      return { ...state, isFetching: true, error: null };
    case FETCH_ALL_SILENT:
      return { ...state, isFetching: 'silent', error: null };
    case FETCH_ALL_SUCCESS:
      return { ...state, isFetching: false, error: false };
    case FETCH_ALL_ERROR:
      return { ...state, isFetching: false, error: true };
    case FETCH_PARTIAL:
      return { ...state, isFetchingPartial: true };
    case FETCH_PARTIAL_SUCCESS:
      return { ...state, isFetchingPartial: false };
    case FETCH_PARTIAL_ERROR:
      return { ...state, isFetchingPartial: false };
    default:
      return state;
  }
};

export default reducer;
