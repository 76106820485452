import * as ModalActions from './Modal.actions';

export const SHOW_WEIGHING = 'SHOW_WEIGHING';

// actions
export const openDialogShowWeighing = data => (dispatch) => {
  return ModalActions.openDialog(SHOW_WEIGHING, data)(dispatch);
};

export default ModalActions;
