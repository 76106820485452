import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  IconInput, Button, Label, ButtonSwitch,
} from '@veit/veit-web-controls';
import IMSearch from '@veit/veit-web-controls/dist/icons/IMSearch';
import IMBird from '@veit/veit-web-controls/dist/icons/IMBird';

import { openDialogAddCompare, openDialogEditCompare } from '../../store/ModalCompare.actions';
import { putCompare, deleteCompare } from '../../store/Analytics.actions';

import DeleteModal from '../../modals/DeleteModal';
import Page from '../../components/Page';
import ScrollBox from '../../components/ScrollBox';
import SelectBird from '../../components/SelectBird';
import IconPage from '../../components/IconPage';
import IMExpand from '../../components/icons/IMExpand';
import intl from '../../setup/RIntl';
import bem from '../../utils/bem';
import { authType } from '../../model/enums';
import { getUniqueColor } from '../../utils/colors';
import { flockType, birdType } from '../../types';
import { SelectCurve } from '../_tabs/GraphsTab';
import FlockCard from './FlockCard';
import PresetCard from './PresetCard';
import Graph, { propToName } from './Graph';
import fetchData, { fetchStats, fetchComparisonStats } from './fetchData';
import AuthButton from '../../components/AuthButton';


const messages = defineMessages({
  searchDot: { id: 'common.search-dot', defaultMessage: 'Search ...' },
  compare: { id: 'flocks.compare-flocks', defaultMessage: 'Compare Flocks' },
  all: { id: 'common.all', defaultMessage: 'All' },
  shared: { id: 'common.shared', defaultMessage: 'Shared' },
  personal: { id: 'common.personal', defaultMessage: 'Personal' },
  flockToCompare: { id: 'compare.flock-to-compare', defaultMessage: 'Start by selecting flock to compare.' },
});

const bm = bem.view('compare');

class Compare extends Component {
  state = {
    search: '',
    searchPreset: '',
    filterPreset: false,
    expanded: false,
    available: false,
    birdId: null,
    flocks: [],
    curve: propToName.average,
  }

  componentDidMount() {
    this.props.fetchData().then(() => {
      if (this.getPresetId() != null) {
        const preset = this.setPreset();
        this.fetchComparisonData(preset);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { params } = prevProps.match;
    const presetId = this.getPresetId();
    if (presetId !== params.id) {
      const preset = this.setPreset();
      this.fetchComparisonData(preset);
    }
  }

  fetchComparisonData = (preset) => {
    if (preset != null) {
      this.props.fetchComparisonStats(
        (preset.selectedFlocks || []).map(m => m.flockId),
        this.props.analytics.stats,
      );
    }
  }

  setPreset = () => {
    const preset = this.getPreset();
    this.setState({
      flocks: preset == null ? [] : preset.selectedFlocks || [],
      birdId: preset == null ? null : preset.birdId,
    });
    return preset;
  }

  onChange = (e) => {
    this.setState({ search: e.target.value });
  }

  onChangePreset = (e) => {
    this.setState({ searchPreset: e.target.value });
  }

  getPresetId = () => (this.props.match.params || {}).id;

  getPreset = () => {
    const presetId = this.getPresetId();
    return this.props.analytics.compare.find(f => f.id === presetId);
  }

  toggleFlock = (flockId) => {
    const { flocks } = this.state;
    const flock = flocks.find(f => f.flockId === flockId);

    if (flock == null) {
      this.props.fetchStats(flockId, this.props.analytics.stats);
    }
    const update = flock == null
      ? [...flocks, {
        flockId,
        color: getUniqueColor(flocks.map(m => m.color)),
        hidden: false,
      }]
      : flocks.filter(f => f.flockId !== flockId);
    this.setState({ flocks: update });
    const preset = this.getPreset();
    if (preset != null && authType.canUpdate(preset)) {
      this.props.putCompare(preset.id, { ...preset, selectedFlocks: update });
    }
  }

  setVisibility = (flockId, hidden) => {
    this.setState(prev => ({
      flocks: prev.flocks.map(m => (m.flockId === flockId ? { ...m, hidden } : m)),
    }));
  }

  save = () => {
    const { user } = this.props;
    this.props.openDialogAddCompare({
      selectedFlocks: this.state.flocks,
      birdId: this.state.birdId,
      userId: user.id,
    }).then((result) => {
      if (result != null) {
        this.props.history.push(`/compare/${result.id}`);
      }
    });
  }

  edit = () => {
    this.props.openDialogEditCompare({ ...this.getPreset() }).then(() => {
      this.setPreset();
    });
  }

  delete = () => {
    this.props.deleteCompare(this.getPresetId()).then(() => {
      this.props.history.push('/compare');
    });
  }

  resetFilter = () => {
    this.setState({ searchPreset: '', filterPreset: null });
  }

  searchFlock = (flock, search) => {
    const values = `${flock.name} ${(flock.bird || { name: '' }).name} ${(flock.location || { name: '' }).name} ${((flock.location || {}).farm || { name: '' }).name} `.toLocaleLowerCase();
    return values.indexOf(search) !== -1;
  }

  searchPreset = (preset, filter, search, flockIds, birdIds) => {
    const sf = preset.selectedFlocks || [];
    const { userId, name, birdId } = preset;
    const resultAccess = filter == null
      || (filter && userId == null)
      || (!filter && userId != null);
    const resultName = name.toLocaleLowerCase().indexOf(search) !== -1;
    const resultBird = birdIds.indexOf(birdId) !== -1;
    const resultFlock = search === '' ? true : sf.filter(f => flockIds.indexOf(f.flockId) !== -1).length !== 0;
    return resultAccess && (resultName || resultBird || resultFlock);
  }

  getPresets = () => {
    const { flocks, birds, analytics } = this.props;
    const { searchPreset, filterPreset } = this.state;
    const sp = searchPreset.toLocaleLowerCase();
    const flockIds = flocks.filter(f => this.searchFlock(f, sp)).map(m => m.id);
    const birdIds = birds.filter(f => f.name.toLocaleLowerCase().indexOf(sp) !== -1).map(m => m.id);
    return analytics.compare.filter(f => this.searchPreset(f, filterPreset, sp, flockIds, birdIds));
  }

  getSelected = () => {
    const flockIds = this.state.flocks.map(m => m.flockId);
    return this.props.flocks.reduce((p, c) => {
      const isSelected = flockIds.indexOf(c.id) !== -1;
      return {
        selected: isSelected ? [...p.selected, c] : p.selected,
        others: !isSelected ? [...p.others, c] : p.others,
      };
    }, { selected: [], others: [] });
  }

  getSelectedStats = () => {
    const { stats } = this.props.analytics;
    const flockIds = this.state.flocks.map(m => m.flockId);
    return flockIds.reduce((p, c) => (stats[c] == null ? p : { ...p, [c]: stats[c] }), {});
  }

  render() {
    const { birds, analytics, flocks } = this.props;
    const {
      available, expanded, search, birdId, searchPreset, curve, filterPreset,
    } = this.state;
    const presets = analytics.compare;
    const presetId = this.getPresetId();
    const preset = this.getPreset();
    const { selected, others } = this.getSelected();
    const bird = birds.find(f => f.id === birdId);
    const options = Object.values(propToName).filter(f => f !== curve);
    const filteredOthers = others.filter(f => this.searchFlock(f, search.toLocaleLowerCase()));
    const filteredPresets = this.getPresets();

    return (
      <Page
        title={intl.t(messages.compare)}
        className={bm.b()}
        isFetching={this.props.fetch.isFetching}
        actions={(
          <React.Fragment>
            {preset == null && selected.length > 0 && (
              <AuthButton canCreate={analytics} color="primary" disabled={selected.length === 0} onClick={this.save}>
                <FormattedMessage id="common.save" defaultMessage="Save" />
              </AuthButton>
            )}
            {preset != null && (
              <React.Fragment>
                {authType.canDelete(preset) && (
                  <DeleteModal confirm={this.delete} name="Comparison" />
                )}
                <AuthButton canUpdate={preset} color="primary" onClick={this.edit}>
                  <FormattedMessage id="compare.edit-comparison" defaultMessage="Edit Comparison" />
                </AuthButton>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      >
        {presets.length > 0 && (
          <div className={bm.e('top-control')}>
            <div className={bm.e('top-panel', 'filter')}>
              <Label style={{ paddingTop: '5px' }} type="subtitle">
                <FormattedMessage id="compare.saved-comparisons" defaultMessage="Saved comparisons" />
              </Label>
              <IconInput
                icon={IMSearch}
                value={searchPreset}
                onChange={this.onChangePreset}
                placeholder={intl.t(messages.searchDot)}
                style={{ paddingBottom: '5px' }}
              />
              <ButtonSwitch
                options={[
                  { title: intl.t(messages.personal), value: false },
                  { title: intl.t(messages.shared), value: true },
                  { title: intl.t(messages.all), value: null },
                ]}
                selected={filterPreset}
                onChange={v => this.setState({ filterPreset: v })}
              />
            </div>
              {filteredPresets.length === 0 ? (
                <div className={bm.e('top-panel-reset')}>
                  <Label type="subtitle">
                    <FormattedMessage id="compare.no-comparison" defaultMessage="No Comparison was found" />
                  </Label>
                  <Button color="link" onClick={this.resetFilter}>
                    <FormattedMessage id="common.reset-filter" defaultMessage="Reset Filter" />
                  </Button>
                </div>
              ) : (
                <ScrollBox showButtons>
                  <div className={bm.e('top-panel', 'comparisons', 'custom-scroll')}>
                    {
                      filteredPresets.map(m => (
                        <PresetCard
                          key={m.id}
                          preset={m.id === preset ? preset : m}
                          active={presetId === m.id}
                          flocks={flocks}
                          bird={birds.find(f => m.birdId === f.id)}
                        />
                      ))}
                  </div>
                </ScrollBox>
              )}
          </div>
        )}
        <div className={bm.e('content-panel')}>
          <div className={bm.e('left-panel')}>
            {selected.length === 0 ? (
              <IconPage icon={IMBird} text={intl.t(messages.flockToCompare)} />
            ) : (
              <React.Fragment>
                <SelectCurve style={{ position: 'absolute' }} options={options} value={curve} onChange={c => this.setState({ curve: c })} weightUnit={this.props.weightUnit} />
                <SelectBird
                  style={{ position: 'absolute', right: '0px' }}
                  birds={birds}
                  bird={birds.find(f => f.id === birdId)}
                  onChange={b => this.setState({ birdId: (b || {}).id })}
                />
                <Graph
                  selected={this.state.flocks}
                  flocks={flocks}
                  allStats={this.getSelectedStats()}
                  bird={bird}
                  leftAxis={curve}
                  weightUnit={this.props.weightUnit}
                />
              </React.Fragment>
            )}
          </div>
          <div className={bm.e('right-panel', 'custom-scroll')}>
            {selected.length !== 0 && (
              <React.Fragment>
                <div className="selected-title" onClick={() => this.setState({ expanded: !expanded })} aria-hidden="true">
                  <Label type="subtitle">
                    <FormattedMessage id="flocks.selected-flocks" defaultMessage="Selected Flocks" />
                  </Label>
                  <IMExpand expanded={expanded} />
                </div>
                {selected.map(m => (
                  <FlockCard
                    key={m.id}
                    expanded={expanded}
                    flock={m}
                    selected={this.state.flocks.find(f => f.flockId === m.id)}
                    onToggle={() => this.toggleFlock(m.id)}
                    onHide={this.setVisibility}
                    dateFormat={this.props.dateFormat}
                  />
                ))}
                <hr />
              </React.Fragment>
            )}
            <div className="selected-title" onClick={() => this.setState({ available: !available })} aria-hidden="true">
              <Label type="subtitle">
                <FormattedMessage id="common.flocks" defaultMessage="Flocks" />
              </Label>
              <IMExpand expanded={available} />
            </div>
            <IconInput
              icon={IMSearch}
              value={search}
              onChange={this.onChange}
              placeholder={intl.t(messages.searchDot)}
            />
            {
              filteredOthers.map(m => (
                <FlockCard
                  key={m.id}
                  flock={m}
                  expanded={available}
                  onToggle={() => this.toggleFlock(m.id)}
                  dateFormat={this.props.dateFormat}
                />
              ))}
            {filteredOthers.length === 0 && others.length !== 0 && (
              <div className="reset-filter">
                <Label>
                  <FormattedMessage id="flocks.flock-not-found" defaultMessage="Flock was not found" />
                </Label>
                <Button color="link" onClick={() => this.setState({ search: '' })}>
                  <FormattedMessage id="common.reset-filter" defaultMessage="Reset Filter" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </Page>
    );
  }
}

Compare.propTypes = {
  fetchData: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  fetchComparisonStats: PropTypes.func.isRequired,
  putCompare: PropTypes.func.isRequired,
  deleteCompare: PropTypes.func.isRequired,
  openDialogAddCompare: PropTypes.func.isRequired,
  openDialogEditCompare: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  flocks: PropTypes.arrayOf(flockType),
  birds: PropTypes.arrayOf(birdType),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  analytics: PropTypes.shape({
    stats: PropTypes.object,
    compare: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        birdId: PropTypes.string,
        selectedFlocks: PropTypes.array,
      }),
    ),
  }).isRequired,
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

Compare.defaultProps = {
  flocks: [],
  birds: [],
  match: { params: {} },
  weightUnit: null,
  dateFormat: null,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchData,
  fetchStats,
  fetchComparisonStats,
  openDialogAddCompare,
  openDialogEditCompare,
  putCompare,
  deleteCompare,
}, dispatch);

const mapStateToProps = state => ({
  flocks: state.flock.items,
  birds: state.bird.items,
  analytics: state.analytics,
  fetch: state.fetch,
  user: state.auth.user,
  weightUnit: state.auth.weightUnit,
  dateFormat: state.auth.dateFormat,
});

export default connect(mapStateToProps, mapDispatchToProps)(Compare);
