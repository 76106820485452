import React from 'react';
import PropTypes from 'prop-types';
import mapHistogramData from '../../utils/histogram';

const maxWidth = 720;
const maxHeight = 240;

const HistogramPreview = ({
  data, width, height, ...otherProps
}) => {
  const { values, keys } = mapHistogramData(data);
  const max = Math.max(...values);
  const heightStep = maxHeight / (max === 0 ? 1 : max);
  const widthStep = maxWidth / values.length;
  return (
    <svg className="histogram-preview" {...otherProps} viewBox={`0 0 ${maxWidth} ${maxHeight}`} width={width} height={height}>
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: 'rgb(70, 154, 207)', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: 'rgb(125, 202, 233)', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g>
        {values.map((v, i) => (
          <rect
            key={keys[i]}
            className="histogram-preview__bar"
            x={i * widthStep}
            width={widthStep}
            y={maxHeight - v * heightStep}
            height={v * heightStep}
            fill="url(#grad1)"
          >
          </rect>
        ))}
      </g>
    </svg>
  );
};

HistogramPreview.propTypes = {
  data: PropTypes.shape({
    center: PropTypes.number,
    stepSize: PropTypes.number,
    stepsCount: PropTypes.number,
    steps: PropTypes.object,
  }),
  width: PropTypes.string,
  height: PropTypes.string,
};

HistogramPreview.defaultProps = {
  data: {},
  width: '120',
  height: '28',
};

export default HistogramPreview;
