import React from 'react';
import { Row, Col } from '@veit/veit-web-controls';
import { FormattedMessage } from 'react-intl';
import Scale from '../../content/images/bat2_scale.png';
import { ReactComponent as Logo } from '../../content/svg/BATcloud_logo_hor.svg';

const Section1 = () => {
  return (
    <section className="section-1">
      <Row>
        <Col lg={{ size: 6, offset: 1 }}>
          <Logo />
          <h1>
            <FormattedMessage id="home.application-linking" defaultMessage="Application linking farmers and poultry scales" />
          </h1>
        </Col>
        <Col lg="5">
          <img src={Scale} alt="bat2 scale" />
        </Col>
      </Row>
    </section>
  );
};

export default Section1;
