import * as ModalActions from './Modal.actions';
import toastMessage from '../utils/toastMessage';
import sendFeedback from '../utils/feedback';

export const SEND_FEATURE_REQUEST = 'SEND_FEATURE_REQUEST';

export const openDialogSendFeatureRequest = feature => (dispatch) => {
  return ModalActions.openDialog(SEND_FEATURE_REQUEST, { feature })(dispatch);
};

export const finishDialogSendFeatureRequest = data => (dispatch, getState) => {
  ModalActions
    .finishDialogRequest(() => () => sendFeedback(data, getState(), 'feature_request'), SEND_FEATURE_REQUEST)(dispatch)
    .then(() => toastMessage.success('Thanks for the feedback!'));
};

export default ModalActions;
