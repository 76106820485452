import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Label } from '@veit/veit-web-controls';

import AuthButton from '../../components/AuthButton';
import { goTo } from '../../store/Router.actions';
import LocationLink from '../_table/LocationLink';

const TableRow = ({
  file, editFileHandler,
}) => {
  return (
    <tr key={file.id}>
      <td><Label>{file.name}</Label></td>
      <td onClick={e => e.stopPropagation()} aria-hidden="true">
        {file.locationId && (
          <LocationLink to={`/locations/${file.locationId}`} location={file.location} farm={file.farm} showWarning />
        )}
      </td>
      <td className="narrow">
        <AuthButton canUpdate={file} onClick={e => editFileHandler(e, file)} className="btn-narrow" color="primary">
          <FormattedMessage id="manual.edit-file" defaultMessage="Edit File" />
        </AuthButton>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    farmId: PropTypes.string,
    location: PropTypes.object,
    farm: PropTypes.object,
    locationId: PropTypes.string,
  }).isRequired,
  editFileHandler: PropTypes.func.isRequired,
};

TableRow.defaultProps = {
};

const mapDispatchToProps = dispatch => bindActionCreators({
  linkTo: goTo,
}, dispatch);

export default connect(null, mapDispatchToProps)(TableRow);
