import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
  Table, Label, ButtonSwitch,
} from '@veit/veit-web-controls';
import { IMSettings } from '@veit/veit-web-controls/dist/icons';

import { openDialogAddBird, openDialogEditBird } from '../../store/ModalBird.actions';
import { scopeBird } from '../../store/Bird.actions';
import fetchData from './fetchData';

import FilterBird from './FilterBird';

import Page from '../../components/Page';
import QuerySort from '../../components/QuerySort';

import { AuthAddButton } from '../../components/AuthButton';
import bem from '../../utils/bem';
import intl from '../../setup/RIntl';
import goToConnect from '../../utils/goToConnect';
import pagination from '../../utils/pagination';
import { birdOrder } from '../../model/order';
import Duration from '../../components/Duration';
import SexIcon from '../../components/SexIcon';


const messages = defineMessages({
  manageBirds: { id: 'breed.manage-breeds', defaultMessage: 'Manage Breeds' },
});

class BirdList extends Component {
  componentDidMount() {
    this.getData(true);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter
      || prevProps.location.search !== this.props.location.search) {
      this.getData(false);
    }
  }

  getData = (fetchMetadata) => {
    this.props.fetchData(
      this.props.filter || scopeBird.private,
      fetchMetadata,
      pagination(this.props.location.search),
    );
  }

  action = (scope) => {
    if (this.props.filter === scope) return;
    const filter = scope === scopeBird.private ? '' : `/${scopeBird.public}`;
    this.props.goTo(`/birds${filter}`);
  }

  actions = () => {
    return (
      <React.Fragment>
        <ButtonSwitch
          options={[
            { title: <FormattedMessage id="common.private" defaultMessage="Private" />, value: scopeBird.private },
            { title: <FormattedMessage id="common.public" defaultMessage="Public" />, value: scopeBird.public }]}
          selected={this.props.filter || scopeBird.private}
          onChange={this.action}
        />
        <AuthAddButton canCreate={this.props.bird} onClick={() => this.props.openDialogAddBird()}>
          <FormattedMessage id="breed.add-breed" defaultMessage="Add Breed" />
        </AuthAddButton>
      </React.Fragment>
    );
  }

  editBirdHandler(e, bird) {
    e.preventDefault();
    e.stopPropagation();
    this.props.openDialogEditBird(bird);
  }

  render() {
    const bm = bem.view('bird-list');
    const birds = this.props.bird.items;
    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.manageBirds)}
        actions={this.actions()}
        isFetching={this.props.fetch.isFetching}
        emptyPage={{
          isEmpty: birds.length === 0,
          icon: IMSettings,
          text: <FormattedMessage id="common.nothing-here" defaultMessage="Nothing here." />,
        }}
        paginate={{ onChange: this.props.goToPage, ...this.props.bird }}
        filter={{ search: true, body: <FilterBird /> }}
      >
        <Table type="info">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.bird" defaultMessage="Bird" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={birdOrder.product}>
                  <FormattedMessage id="common.product" defaultMessage="Product" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={birdOrder.company}>
                  <FormattedMessage id="common.company" defaultMessage="Company" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={birdOrder.duration}>
                  <FormattedMessage id="common.duration" defaultMessage="Duration" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={birdOrder.sex}>
                  <FormattedMessage id="common.sex" defaultMessage="sex" />
                </QuerySort>
              </th>
              {/* <th>Active</th> */}
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {birds.map(d => (
              <tr className="has-pointer" onClick={() => this.props.goTo(`/bird/${d.id}`)} key={d.id}>
                <td><Label>{d.name}</Label></td>
                <td><Label>{d.product}</Label></td>
                <td><Label>{d.company}</Label></td>
                <td>
                  {d.duration != null && (
                    <Label>
                      <Duration value={d.duration} unit={d.dateType} />
                    </Label>
                  )}
                </td>
                <td><Label><SexIcon sex={d.sex} /></Label></td>
                {/* <td><StatusIcon /></td> */}
                {/* <td className="text-center">
                  <Button
                    onClick={e => this.editBirdHandler(e, d)}
                    className="btn-narrow"
                    color="primary"
                  >
                    Edit Bird
                  </Button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

BirdList.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogAddBird: PropTypes.func.isRequired,
  openDialogEditBird: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  bird: PropTypes.shape({
    items: PropTypes.array,
  }),
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  filter: PropTypes.string,
};

BirdList.defaultProps = {
  bird: null,
  filter: scopeBird.private,
};

const mapStateToProps = (state, ownProps) => {
  return {
    bird: state.bird,
    fetch: state.fetch,
    filter: ownProps.match.params.filter,
    locale: state.auth.locale,
  };
};

const mapDispatchToProps = {
  fetchData,
  openDialogAddBird,
  openDialogEditBird,
};

export default goToConnect('/birds')(BirdList, mapStateToProps, mapDispatchToProps);
