import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Table } from '@veit/veit-web-controls';
import { IMDialMeter } from '@veit/veit-web-controls/dist/icons';

import { openDialogAddFile, openDialogEditFile } from '../../store/ModalFile.actions';

import Page from '../../components/Page';
import QuerySort from '../../components/QuerySort';
import { AuthAddButton } from '../../components/AuthButton';
import bem from '../../utils/bem';
import pagination from '../../utils/pagination';
import goToConnect from '../../utils/goToConnect';
import { fileOrder } from '../../model/order';
import intl from '../../setup/RIntl';

import fetchData from './fetchData';
import TableRow from './TableRow';
import FileFilter from './FileFilter';

const messages = defineMessages({
  myFiles: { id: 'manual.my-files', defaultMessage: 'BAT1 Files' },
});

class Bat1List extends Component {
  state = { isFetching: true }

  componentDidMount() {
    this.getData(true).then(() => this.setState({ isFetching: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getData(false);
    }
  }

  getData = (fetchMetadata) => {
    return this.props.fetchData(fetchMetadata, pagination(this.props.location.search));
  }

  addFile = () => {
    this.props.openDialogAddFile().then(this.resolveDialog);
  }

  resolveDialog = (result) => {
    if (result == null) return;
    const last = this.props.file.items.length === 1;
    if (result.delete && last) {
      this.props.goToPage(0);
    } else {
      this.getData(false);
    }
  }

  actions = () => {
    return (
      <React.Fragment>
        <AuthAddButton canCreate={this.props.file} onClick={this.addFile}>
          <FormattedMessage id="manual.add-file" defaultMessage="Add File" />
        </AuthAddButton>
      </React.Fragment>
    );
  }

  editFileHandler = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openDialogEditFile(file).then(this.resolveDialog);
  }

  render() {
    const bm = bem.view('file-list');
    const files = this.props.file.items;
    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.myFiles)}
        actions={this.actions()}
        isFetching={this.props.fetch.isFetching || this.state.isFetching}
        emptyPage={{
          isEmpty: files.length === 0,
          icon: IMDialMeter,
          action: (
            <AuthAddButton canCreate={this.props.file} onClick={this.addFile}>
              <FormattedMessage id="manual.add-file" defaultMessage="Add File" />
            </AuthAddButton>
          ),
        }}
        paginate={{ onChange: this.props.goToPage, ...this.props.file }}
        filter={{
          search: true,
          body: <FileFilter />,
        }}
      >
        <Table type="data">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.file" defaultMessage="File" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={fileOrder.location}>
                  <FormattedMessage id="common.location" defaultMessage="Location" />
                </QuerySort>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map(d => (
              <TableRow
                key={d.id}
                file={d}
                editFileHandler={this.editFileHandler}
              />
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

Bat1List.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogAddFile: PropTypes.func.isRequired,
  openDialogEditFile: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  file: PropTypes.shape({
    items: PropTypes.array,
    links: PropTypes.object,
  }),
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

Bat1List.defaultProps = {
  file: null,
  fetch: {},
};

const mapStateToProps = (state) => {
  return {
    file: state.file,
    fetch: state.fetch,
    locale: state.auth.locale,
  };
};

const mapDispatchToProps = {
  fetchData,
  openDialogAddFile,
  openDialogEditFile,
};

export default goToConnect('/bat1')(Bat1List, mapStateToProps, mapDispatchToProps);
